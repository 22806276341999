import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import MobileFooter from './MobileFooter';
import { Link } from 'react-router-dom';
import axios from '../axios/axios';

class ActivateKitThankYou extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
      lastorderHistory:[]
     
    }
      this.toBookSession = this.toBookSession.bind(this)
  } 

  componentDidMount(){
    axios.get('cartservice/'+Cookies.get("userId")+'/orderHistoryForProduct', 
    { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
    ).then((response) => {
       if(response.data){this.setState({lastorderHistory:response.data},()=>{})}
      }).catch(error => {});
  }


  toBookSession(){
    window.location.href="/book-counselling"
  }
    
    render() {

    return (

    <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGene</title>
        <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <style dangerouslySetInnerHTML={{__html: "\n      .background-pay-card{\n        background: url(./img/kit/Kit-Background.png) no-repeat center;-webkit-background-size: cover;\n        -moz-background-size: cover;\n        -o-background-size: cover;\n        background-size: cover;\n        width:100%;\n        /* height:500px; */\n      }\n      .checked {\n  color: orange;}\n      " }} />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        
        <CommonHeder category="product-page"></CommonHeder>
      <div style={{paddingBottom: '76px'}} className="padding22" />
      <div style={{marginTop: '8rem'}} className="margin"></div>
        {/* <div class="container " style="box-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);border-radius: 40px;background-color: #EFF7FF;"> */}
        <div className="col-sm-12 col-lg-12 background-pay-card d-flex justify-content-between py-0 px-0" style={{maxWidth: '900px', margin: 'auto', backgroundColor: '#EFF7FF', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', borderRadius: '40px'}}>
          <div className="mx-auto" style={{paddingTop: '100px', paddingBottom: '25px'}}>
            {/* <h1 style="font-size: 36px;color:#2c333c;font-weight:900">Thank you!</h1> */}
            <h2 className="  text-center align-self-center roboto-black-36px " style={{fontWeight: 900, maxWidth: '650px'}}>
              Thank you {localStorage.getItem("name")}!<br /><br />
              <p style={{fontSize: '18px', fontWeight: 400}}>We appreciate your participation. You are now a health warrior.</p></h2>
            {/* <button class="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  "style="height:60px;width:210px;border-radius:30px; " type="submit"><i class="fas fa-print"> Print your invoice </i></button> */}
            {/* <button class="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  "style="height:auto;width:auto;border-radius:30px; " type="submit">Order Your truGeny's Kit Now</button> */}
            {this.state.lastorderHistory.preOrderService?'':<Link to={{ pathname: '/book-counselling', state: { session:"free session"} }}>  <button className="center-block btn btn-hover-white my-2 my-sm-0 center   "  style={{height: 'auto', width: 'auto', borderRadius: '30px', background: '#4F5BE7', color: 'white'}} type="submit"> Schedule Your Free Genetic Counselling Session </button></Link>}
            
            {/* <p class="text-center">Have Questions?</p> */}
            <br />
            {/* <p class="text-center" >You will receive a confirmatory email along with a 'what to expect'<br>
            guide document from our support team within the next 24 hours</p> */}
            {/* <button class="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white mb-5 "style="height:auto;width:auto;border-radius:30px; " type="submit"><i class="fas fa-print"> Print your invoice </i></button> */}
          </div>
        </div>
        {/* </div> */}
        <div style={{paddingTop: '12.25rem'}}>
        </div>
        <footer className="footer-sec pt-5 mt-5">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">
              <a style={{color: 'gray'}} href="al_termsofservices.html">Terms of Service</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="al_privacy_policy.html">Privacy Policy</a> &nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Consent for Research</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Mission</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Blog</a> &nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Customer Care</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Careers</a>&nbsp;|&nbsp;
            </div>
          </div>
        </footer>
        <MobileFooter></MobileFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
        );
    }
}
export default ActivateKitThankYou;