import React from 'react';
import { Collapse} from 'reactstrap';
import SubFooter from './SubFooter';
import CommonHeder from './CommonHeder';

class AllHelpSecurity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            q1: false,
            b1: true,

            q2: false,
            b2: true,

     

        }
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
       


    }

    toggle1() {
        this.setState(state => ({ q1: !state.q1 }));
        this.setState(state => ({ b1: !state.b1 }));


        this.setState({ q2: false }, function () { });
        this.setState({ b2: true }, function () { });


    }

    toggle2() {
        this.setState(state => ({ q2: !state.q2 }));
        this.setState(state => ({ b2: !state.b2 }));

        this.setState({ q1: false }, function () { });
        this.setState({ b1: true }, function () { });
       
    }

    


    render() {


        return (
            <div>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
                <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
                {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
                <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
                <title>truGeny</title>
                <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
                <link rel="stylesheet" href="./css/header_footer.css" />
                <link rel="stylesheet" href="./css/stylesheet_custom.css" />
                <link rel="stylesheet" href="stylesheet_custom.css" />
                <link rel="stylesheet" href="./css/fonts.css" />
                <link rel="stylesheet" href="./css/sticky.css" />
                <style dangerouslySetInnerHTML={{ __html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/banner-sp.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n" }} />
                <CommonHeder></CommonHeder>
                <div style={{ marginTop: '9rem' }}>
                </div>
                <hr id="hor-line" className style={{ marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)' }} />

                <div className="container pt-0 pb-2 mt-0">
                    <nav className="navbar container navbar-expand-xl  navbar-light d-none d-lg-block d-xl-block float-left py-0">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                            </span>
                        </button>
                        <ul className=" row navbar-nav mr-auto ">
                            <li className="nav-item ">
                                {/* <a class="nav-link float-right text-center activenavbar" href="index.html"><span class="activenavbar"><i  class=" i-button mr-1 fas fa-question-circle"></i></span>FAQs</a> */}
                            </li>
                            <li className="nav-item">
                                {/* <a class="nav-link" href="genetic-counseling.html">Generic Counseling</a> */}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:support@truGeny.com" data-toggle="tooltip" title="info@trugeny.com"><span><i className="i-button mr-1 fas fa-at" /></span>Email</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link " title="0870 128 82830" data-toggle="tooltip" href="tel:0870 128 82830" style={{ color: '#4f5be7' }}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>0870 128 82830</a>
                            </li>
                        </ul>
                    </nav>
                    <br />
                    <br />
                </div>
                <section className="heart-banner-sec-wrap banner-position">
                    <div className=" heart-banner-sec ">
                        <div className="container pt-4 mt-2">
                            <h1 className><strong>Security &amp; Privacy</strong></h1>
                            {/* <h2 class="roboto-reg-24px my-3">Get the right answers to your questions</h2> */}
                            <div className="input-group " style={{ height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem' }}>
                                <input type="text" className="form-control m-0" id="validationTooltip01" placeholder="Lorem ipsum dolor" name="name" style={{ borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff' }} required />
                                <div className="valid-tooltip">
                                    Looks good!
                </div>
                                <div className="input-group-prepend border-0 d-flex justify-content-center">
                                    <span className="btn btn-outline-primary input-group-text border-0 justify-content-center" style={{ cursor: 'pointer', borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem', backgroundColor: '#4f5be7', width: '160px', color: 'white' }} id="validationTooltip01">SERACH</span>
                                </div>
                            </div>
                        </div>{/* eo-container*/}
                        <div className="d-flex justify-content-center" id="secc1">
                            <a style={{ scrollBehavior: '[ auto | smooth ]' }} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
                        </div>
                    </div>{/* eo-heart-banner-sec */}
                </section>{/* eo-heart-banner-sec-wrap */}
                {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
                <section className="container ">
                    <nav aria-label="breadcrumb pt-5 ">
                        <ol className="breadcrumb " style={{ backgroundColor: '#fff !important', paddingTop: '3rem' }}>
                            <li className="breadcrumb-item"><a href="help.html" className="roboto-reg-18px" style={{ color: '#4f5be7' }}><b>Support</b></a></li>
                            {/* <li class="breadcrumb-item"><a href="#">Library</a></li> */}
                            <li className="breadcrumb-item active" aria-current="page"><a className="roboto-reg-18px">Security &amp; Privacy</a></li>
                        </ol>
                    </nav>
                    {/* Support / Security & Privacy */}
                    <div className=" mt-5">
                        <div className>
                            <div className="card-block  p-2 circle">
                                <div className="pr-5 pb-5 hc-accordian-wrap">
                                    <div className="accordion" id="accordionIhc">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                <h5 className="acc-header collapse-color mb-0">
                                                    Does truGeny protect my data?
                                                    {this.state.b1?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-minus-circle  rounded-circle " /></button>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q1}>
                                            <div id="b2" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                                <div className="card-body">
                                                    <p>truGeny ensures that all personally identifiable health information data is encrypted and stored securely.
                                                      The only people with access to your identity will be you and your genetic counsellor, so that when you
                                                      make your genetic counselling appointment, your counsellor can go though and discuss your report in
                                                        detail and answer all your questions.</p>
                                                </div>
                                            </div>
                                            </Collapse>
                                            
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="acc-header collapse-color mb-0">
                                                    What are truGeny’s privacy policy, terms of service and informed consent? Where can I read them?
                                                    {this.state.b2?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-minus-circle  rounded-circle " /></button>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q2}>
                                            <div id="b3" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                                                <div className="card-body">
                                                    <p>At truGeny your privacy is of utmost importance and we work hard to ensure that all your data is
                                                     absolutely secure. We collect information about you, to help us personalise your experience.</p>
                                                    <p><a href="">read our Privacy Policy</a></p>
                                                    <p><a href="">read our terms and conditions</a></p>
                                                    <p><a href="">read our informed consent form.</a></p>
                                                </div>
                                            </div>
                                            </Collapse>
                                            
                                        </div>
                                    </div>
                                </div>{/* eo bs-example */}
                                {/* end of accordian code */}
                            </div>
                        </div>{/* eo card-block */}
                    </div>{/* eo card body */}
                    {/* eo card */}
                    {/* eo-heart-accordian-sec-wrap */}
                </section>{/* eo-heart-accordian-sec */}
                {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
                {/* Footer Links */}
                <SubFooter></SubFooter>                
                {/* --------eo sticky form----------------- */}
                {/* Optional JavaScript */}
                {/* jQuery first, then Popper.js, then Bootstrap JS */}
            </div>


        )
    }

}
export default AllHelpSecurity;