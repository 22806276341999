import React from 'react'
// import HeaderLinks from './HeaderLinks';
import axios from '../axios/axios';
import swal from 'sweetalert';
import StickyContactMenu from './StickyContactMenu';
import MetaTags from 'react-meta-tags';

class Listen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          pemail:'',
          pphone:'',
          pname:'',
          pmessage:'',
    
            errors:{}
          };
          this.baseState = this.state;

        this.prospectReg = this.prospectReg.bind(this);

    }

    prospect = (e) => {
      const prospectForm = this.state
      prospectForm[e.target.name] = e.target.value;
      this.setState(prospectForm);
    }


    validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
  
      if (!fields["pname"]) {
        formIsValid = false;
        errors["pname"] = "*Please enter your name";
    }
  
  
      if (!fields["pemail"]) {
          formIsValid = false;
          errors["pemail"] = "*Please enter your email address";
      }
  
      if (typeof fields["pemail"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["pemail"])) {
              formIsValid = false;
              errors["pemail"] = "*Please enter valid email address";
          }
      }
  
      if (!fields["pphone"]) {
          formIsValid = false;
          errors["pphone"] = "*Please enter your mobile no.";
      }
  
      if (!fields["pmessage"]||fields["pmessage"]==="") {
        formIsValid = false;
        errors["pmessage"] = "*Please enter message.";
    }
  
      if (typeof fields["pphone"] !== "undefined") {
          if (!fields["pphone"].match(/^[0-9]{10}$/)) {
              formIsValid = false;
              errors["pphone"] = "*Please enter valid mobile no.";
          }
      }
      this.setState({
          errors: errors
      });
      return formIsValid;
    }

    prospectReg(e) {
      e.preventDefault();
        if (this.validateForm()) {
        const prospectForm = this.state
        prospectForm["source"] = "TruGenyGenes";
        this.setState(prospectForm);
          const { pname, pphone, pemail,pmessage ,source} = this.state;
          axios.post('users/prospect', { pname, pphone, pemail, pmessage,source })
              .then((response) => {
                this.setState(this.baseState);

                  swal("Thank you for your interest. We will get back you soon...").then(
                    setTimeout(() => {
                     this.redirect()
                  }, 4000)
                );
             
              }).catch(error => {
                if(error.response===undefined){
                  this.props.history.push("/error")
                }else{
                  this.setState({
                      errorMsg: error.response.data.message
                  })  
                }
                 
              });
              e.target.reset();
              this.setState(this.baseState);
            }
  
    
  }
 redirect(){
    window.location.href="know-your-genes"
 }

   render(){


       return(
        <div>

        <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="newsticky.css" />
                {/* <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
           */}
                {/* <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
           */}
            <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />


             <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />


            <title>TrueGeny</title>
            <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />


            <link rel="stylesheet" href="stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/header_footer.css" />
            <link rel="stylesheet" href="./css/fonts.css"/>
            <link rel="stylesheet" href="./css/sticky.css"/>
            <link rel="stylesheet" href="./styleinline.css" />
            <link rel="stylesheet" href="./style-mobile2.css" />
            <link rel="stylesheet" href="./style-mobile.css" />
            <link rel="stylesheet" href="./landing.css" />

            <MetaTags>
        <title>About - Most Trusted Genetic Counselling Company in India</title>
		<meta name="description" content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="About - Most Trusted Genetic Counselling Company in India"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n.background-index\n{\n  background:url('img/aboutpage/banner.png') no-repeat  center ;-webkit-background-size: cover;\n}\n}\n" }} />
        <div className="sec1-landing">



        <StickyContactMenu ></StickyContactMenu>
        <div className="  sec1-landing ">
            <div className="  container py-5">

            <h1 className="text-center pt-5"> <img  className="img-fluid roboto-reg-36px img-landing-logo pad-me" src="https://trugeny.in/TruGeny_logo.png" /></h1>

            </div>

          </div>
                  </div>
        <div className="container">
                <div className="row pt-3 pb-3 ">
                    <div className="col-sm-5 "><img className="img-fluid p-lg-3 p-md-3 p-sm-0" src="http://trugeny.in/e3.png"/></div>
                    <div className="col-sm-7 mt-lg-5 mt-sm-1 pt-sm-1 pt-lg-5 helth"><p>You inherit a lot from your family, including your HEALTH.</p><p></p></div>
                </div>







          <div id="section-1" className="d-flex justify-content-center">
            <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#section-1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
          </div>
        </div>



        <div className=" mb-3 mt-2 py-lg-5  text-center-sm l1">
                <br/>
                <br/>
                <h1 className="text-center roboto-black-36px "><b>Listen to your Genes</b></h1>
            <p className="container text-center roboto-reg-24 l2">Genes are passed on from a parent to the child. You inherit one set of genes from your father and one set from your mother. Genes control the way you look, the way your body works and genes are what makes you unique. Genes can also increase the risk in a family for developing certain diseases such as cancers, heart conditions amongst many others.
                </p>
             <p className="container text-center roboto-reg-24 l3">Learning about your genes can help you better understand your risks and take actions for preventing diseases and leading a healthier life.
                </p>
            <div className="text-center">
                <img className="img-fluid mx-auto display-img l4" src="img/landingpage/Illustration.png" />
              </div>
                <p className="text-center roboto-reg-24 padding-give"></p>
                <div className="container l5" >
                  <div className="row ">
                    <div className="col-sm">
                      <img className="mb-5 img-fluid d-none d-md-block" src="img/landingpage/Illustration.png"/>
                    </div>
                    <form className="needs-validation" onSubmit={this.prospectReg}>
                      <div className=" col-sm divme px-0">
                        <h3 className="roboto-black-36px px-3 l6" >Can't wait to learn more?</h3>
                        <p className="roboto-reg-18px text-center l7" >Get notified when we launch this amazing service.</p>
                        <p className="roboto-bold-18px text-center l8">Be amongst the first to know.</p>
                          <div className=" hero-ravi justify-content-center px-1 pb-2 l9">
                          <div className="form-group learn-text-box mb-3 l10" style={{ height: '60px', borderColor: this.state.errors.pmessage ? "#ff0000" : "" }}>
                            <select className="form-control roboto-reg-16px l11" id="exampleFormControlSelect1" type="text" name="pmessage" onChange={this.prospect}  >
                              <option className="dropdown-item roboto-reg-16px d-none" value="" selected="" disabled="">I am interested to learn about:</option>
                              <option className="dropdown-item roboto-reg-16px" value="My Cancer Risk">My Cancer Risk</option>
                              <option className="dropdown-item roboto-reg-16px" value="My Heart Health Risk">My Heart Health Risk</option>
                              <option className="dropdown-item roboto-reg-16px" value="Genetic Counselling">Genetic Counselling</option>
                              <option className="dropdown-item roboto-reg-16px" value="Pregnancy / Family Planning">Pregnancy / Family Planning</option>
                            </select>
                          </div>
                          <div className="learn-more-form input-group-prepend mb-3 l12" >
                          <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pname}</div>
                            </div>
                         <div className="learn-more-form input-group-prepend mb-3 l12" >

                      <input type="text" placeholder="Name" name="pname" onChange={this.prospect} className="roboto-reg-16px form-control l13"  aria-label="Text input with dropdown button"/>
                      <span className="input-group-text border-0 bg-white" id="validationTooltip01">
                        <img src="./img/homepage/i1.png"/></span>

                    </div>
                    <div className="learn-more-form input-group-prepend mb-3 l12" >
                          <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pphone}</div>
                            </div>

                          <div className="learn-text-box input-group-prepend mb-3 l14" >


                      <input type="tel" placeholder="Mobile" type="tel" onChange={this.prospect} name="pphone" pattern="[0-9]{10}"  className="form-control roboto-reg-16px l15"  />
                        <span className="input-group-text border-0 bg-white" id="validationTooltip01">
                          <img src="img/landingpage/Shape 1.png"/></span>
                      </div>


                      <div className="learn-more-form input-group-prepend mb-3 l12" >
                          <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pemail}</div>
                            </div>

                          <div className="learn-more-form input-group-prepend mb-3 l16" >

                            <input type="email" name="pemail" onChange={this.prospect} placeholder="E-mail"  aria-describedby="emailHelp" className="roboto-reg-16px form-control l17"  aria-label="Text input with dropdown button"/>
                            <span className="input-group-text border-0 bg-white" id="validationTooltip01"><img src="img/landingpage/Forma 2.png"/></span>
                          </div>
                          <div className="input-group-prepend mb-3 btn-custom1">
                            <button id="button1" className="btn btn-outline-primary  btn-hover-white mt-4 l18" name="submit" type="submit">Submit</button>

                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>









                   <div class="container divcenter">
                           <h1 class="text-center roboto-black-36px px-3 l19" >live healthier, happier and longer</h1>
                           <div class="container ">
                             <div class=" text-center card-deck ">

                               <div class="col-sm-6 col-md-6 col-lg-3">

                                 <div class="card bg-default mx-auto shadow-lg mb-5 bg-white  card-height-landing">
                                   <div class="card-body text-center ">

                                     <div class="card-block mt-2 p-2">
                                       <img class="card-img-top text-center mt-2 p-2 card-radius-circle" src="img/landingpage/Forma 1.png" alt="Card image cap"/>
                                     </div>
                                     <p class="card-text roboto-reg-18px px-2 l20">Access from anywhere and anytime. Even from the convenience of your home </p>
                                   </div>
                                 </div>
                               </div>
                               <div class="col-sm-6 col-md-6 col-lg-3">

                                 <div class="card bg-default mx-auto shadow-lg mb-5 bg-white  card-height-landing">
                                   <div class="card-body text-center ">

                                     <div class="card-block mt-2 p-2">
                                       <img class="card-img-top text-center mt-2 p-2 card-radius-circle" src="img/landingpage/Shape 1 copy.png" alt="Card image cap"/>
                                     </div>
                                     <p  class="card-text roboto-reg-18px l20">Personalised Counselling by certified genetic counsellors </p>
                                   </div>
                                 </div>
                               </div>
                               <div class="col-sm-6 col-md-6 col-lg-3 text-center">

                                 <div class="card bg-default mx-auto  shadow-lg mb-5 bg-white  card-height-landing">
                                   <div class="card-body text-center">
                                     <div class="card-block mt-2 p-2">
                                       <img class="card-img-top text-center mt-2 p-2 card-radius-circle " src="img/landingpage/Forma 3.png" alt="Card image cap"/>
                                     </div>
                                     <p class="card-text roboto-reg-18px l20">Highly accurate screening tests <b>&amp;</b> Reports with actionable insights </p>
                                   </div>
                                 </div>
                               </div>
                               <div class="col-sm-6 col-md-6 col-lg-3">

                                 <div class="card mx-auto bg-default shadow-lg  mb-5 bg-white card-height-landing">
                                   <div class="card-body text-center">
                                     <div class="card-block mt-2 p-2">
                                       <img class="card-img-top text-center mt-2 p-2 card-radius-circle" src="img/landingpage/Forma 4.png" alt="Card image cap"/>
                                     </div>
                                     <p class="card-text roboto-reg-18px card-text l20">Affordable pricing</p>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>














              <div className="footer mt-5 py-5 ">
                        <h2 class=" text-center " >Your Genes Matter. <b>TAKE CHARGE</b></h2>

                  <div className="text-center pt-3 ">
                  <img class="mt-2 img-fluid p4" src="https://trugeny.in/TruGeny_logo.png"/>
                  </div>

                  <div className="text-center p6">
                    <a className="roboto-bold-14px text-center  pr-5 pl-5 mr-5 mx-auto p-3 btn btn-outline-primary btn-hover-white text-white p3"  href="mailto:support@trugeny.com">
                    <i class="far fa-envelope"></i> support@trugeny.com</a>
                  </div>
            </div>





      </div>

       )
   }

}
export default Listen;
