import React from 'react'
import Footer from './Footer';
import { Collapse } from 'reactstrap';
import MainHeader from './MainHeader';
import $ from 'jquery';
import MobileFooter from './MobileFooter';
import MetaTags from 'react-meta-tags';


class CliniciansPrivacyPolicy extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
         
          q1:false,
          b1:true,
    
          q2:false,
          b2:true,
    

    
        }
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);


        
    }
    componentDidMount(){
      
      $(document).ready(function(){
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#myData h5").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
          });
      
        });
      });
    }
    toggle1() {
      this.setState(state => ({ q1: !state.q1 }));
      this.setState(state => ({ b1: !state.b1 }));
  
      
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});

  
    }
  
    toggle2() {
      this.setState(state => ({ q2: !state.q2 }));
      this.setState(state => ({ b2: !state.b2 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});

    }
  
    

  
   render(){
  

       return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <MetaTags>
        <title>Privacy Policy - truGeny</title>
		<meta name="description" content="truGeny privacy practices include data collection and security. You decide how your information is used and shared."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Privacy Policy - truGeny"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny privacy practices include data collection and security. You decide how your information is used and shared."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n  /* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n  .activenavbar {\n    color: #4f5be7!important;\n  }\n\n  .card-body {\n    text-align: left!important;\n  }\n\n  .heart-banner-sec-wrap .heart-banner-sec {\n    background: url(./img/login/privacy_policy.png);\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n\n  .collapse .show {\n    /* background-color: red!important; */\n  }\n\n  .accordion>.card .card-header::after {\n    margin-bottom: -1px;\n    background: blue;\n  }\n\n  .accordion>.card .card-header::before {\n    margin-bottom: -1px;\n    background: blue;\n  }\n  #h2inn:hover{\n    text-decoration:underline;\n  }\n  " }} />
        {/* <div class=""> */}
       <MainHeader></MainHeader>
        <section className="heart-banner-sec-wrap banner-position">
          <div className=" heart-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className><strong>Privacy Policy</strong></h1>
              {/* <h2 class="roboto-reg-24px my-4">Get the right answers to your questions</h2> */}
              <div className="input-group " style={{height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem'}}>
              <input type="text" className="form-control m-0" placeholder="Search for help.." id="myInput"  style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />
                <div className="valid-tooltip">
                  Looks good!
                </div>
                <div className="input-group-prepend border-0 d-flex justify-content-center">
                  <span className="btn btn-outline-primary input-group-text border-0 justify-content-center" style={{cursor: 'pointer', borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem', backgroundColor: '#4f5be7', width: '160px', color: 'white'}} id="validationTooltip01">SEARCH</span>
                </div>
              </div>
            </div>
            {/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1"> <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>
          {/* eo-heart-banner-sec */}
        </section>
        {/* eo-heart-banner-sec-wrap */}
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="container" id="myData">
          <div className=" mt-5">
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card ">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q1?'#2C333C':'#4F5BE7'}}>
                          Privacy Highlights
                          {this.state.b1?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q1}>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                      <div className="card-body border-0">
                      <a style={{color: '#000'}}>
                        <p className=" roboto-reg-18px">These "Privacy Highlights" provide an overview of some core
                          components of our data handling practices. Please be sure
                          to review the Full Privacy Statement.</p>
                      </a>
                      <p className="roboto-bold-24px pt-3 pb-1">Information We Collect</p>
                      <p className="roboto-reg-18px">We generally collect the following information:</p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>Information we receive
                        when you use our Services. </strong>
                      We collect Web-Behavior Information via cookies and other
                        similar tracking technologies when you use and access our
                        Services (our website, mobile apps, products, software
                        and other services). 
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>Information you share
                        directly with us. </strong> We collect and process your information when you place an order, 
                      create an account, register your TruGeny kit, complete research surveys,
                       post on our Forums or use other messaging features, and contact Customer Care. 
                       This information can generally be categorized as Registration Information, Self-Reported Information, 
                      and/or User Content as defined in our full Privacy Statement.
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>Information from
                        our DNA testing services. </strong>
                        With your consent, we extract your DNA from your saliva sample and 
                      analyze it to produce your Genetic Information
                       (the As, Ts, Cs, and Gs at particular locations in your genome) in order to provide you with TruGeny reports.
                      </p>
                      {/* -----------------------------------------------------------2nd------------------------------------------- */}
                      <p className="roboto-bold-24px pt-3 pb-1">How We Use Information</p>
                      <p className="roboto-reg-18px">We generally process Personal Information for the following reasons:</p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp; <strong>To provide our Services. </strong>
                     We process Personal Information in order to provide our Service, 
                      which includes processing payments, shipping kits to customers, creating customer accounts and authenticating logins,
                       analyzing saliva samples and DNA, 
                      and delivering results and powering tools like DNA Relatives.
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>To analyze and improve our Services. </strong>
                        We constantly work to improve and provide new reports, tools, and Services.
                       For example, we are constantly working to improve our ability to assign specific ancestries 
                       to your DNA segments and maximize the granularity of our results. We may also need to fix bugs or issues, 
                       analyze use of our website to improve the customer experience or assess our marketing campaigns.                
                             <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>For TruGeny Research, with your consent. </strong>
                        If you choose to consent to participate in TruGeny Research, 
                      TruGeny researchers can include your de-identified Genetic Information and Self-Reported 
                      Information in a large pool of customer data 
                      for analyses aimed at making scientific discoveries.
                      </p>
                      {/* --------------------------------------------------3rd--------------------------------------- */}
                      <p className="roboto-bold-24px pt-3 pb-1">Control: Your Choices</p>
                      <p className="roboto-reg-18px">TruGeny gives you the ability to share information in a variety of ways. You choose:</p>
                      <p className="roboto-reg-18px pl-2 "><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong> To store or discard your saliva sample </strong> after it has been analyzed.</p>     
                      
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp; <strong>Which health report(s) </strong>                  
                      you view and/or opt-in to view.
                      </p>
                      
                       
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>When and with whom you share your information </strong>
                        including friends, family members, health care professionals, or other individuals outside our Services, including through third party services that accept TruGeny data and social networks.
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To give or decline consent for TruGeny Research.
                        By agreeing to the Research Consent Document, Individual Data Sharing Consent Document, or participating in a TruGeny Research Community you can give consent for the use of your data for scientific research purposes.
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To delete your truGeny account and data at any time.
                        <br />
                      </p>
                      
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp; <strong>Access To Your Information</strong>
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2">
                      Your Personal Information may be shared information in the following ways:
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>With our service providers,</strong> including our genotyping laboratory, as necessary for them to provide their services to us.
                        <br />
                      </p>
                      
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>With research collaborators, </strong>only if you have given your explicit consent.
                        <br />
                      </p>
                     
                      <p className="roboto-reg-18px pl-2">
                      TruGeny will not sell, lease, or rent your individual-level information to any third party or to a third party for research purposes without your explicit consent.
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" /><strong>  We do not </strong>share customer data with any <strong>public databases.</strong></p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" /><strong>  We will not</strong> provide any person’s data (genetic or non-genetic) to an <strong> insurance company or employer.</strong></p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" /><strong>  We will not </strong> provide information to <strong>law enforcement or regulatory authorities </strong> unless required by law to comply with a valid court order, for genetic or Personal Information </p>

                      {/* --------------------------------------------------4th--------------------------------------- */}
                      <p className="roboto-bold-24px pt-3 pb-1">How We Secure Information</p>
                      <p className="roboto-reg-18px">TruGeny implements measures and systems to ensure confidentiality, integrity, and availability of TruGeny data. </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp; <strong> De-identification/Pseudonymization, encryption, and data segmentation.</strong>
                      Registration Information is stripped from Sensitive Information, including genetic and phenotypic data. This data is then assigned a random ID so the person who provided the data cannot reasonably be identified. TruGeny uses industry standard security measures to encrypt sensitive personal data both when it is stored (data-at-rest) and when it is being transmitted (data-in-flight). Additionally, data are segmented across logical database systems to further prevent re-identifiable.
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong>Limiting access to essential personnel. </strong>
                      We limit access of information to authorized personnel, based on job function and role. TruGeny access controls include multi-factor authentication, single sign-on, and a strict least-privileged authorization policy.
                        <br />
                      </p>
                      <p className="roboto-reg-18px pl-2"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;<strong> Detecting threats and managing vulnerabilities. </strong>
                      TruGeny uses state of the art intrusion detection and prevention measures to stop any potential attacks against its networks. We have integrated continuous vulnerability scanning in our build pipeline and regularly engage third party security experts to conduct penetration tests.
                      </p>
                      {/* --------------------------------------------------5th--------------------------------------- */}
                      <p className="roboto-bold-24px pt-3 pb-1">Risks and Considerations</p>
                      <p className="roboto-reg-18px">There may be some consequences of using TruGeny Services that you haven't considered.</p>
                      <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;You may discover things about yourself and/or your family members that may be upsetting or cause anxiety and that you may not have the ability to control or change.</p><p>
                      </p><p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;You may discover relatives who were previously unknown to you, or may learn that someone you thought you were related to is not your biological relative.
                        <br />
                      </p>
                      <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;In the event of a data breach it is possible that your data could be associated with your identity, which could be used against your interests.
                        <br />
                      </p>
                      {/* -------------------------------------------6th-------------------------------------- */}
                    </div>
                      </div>
                    </Collapse>

                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q2?'#2C333C':'#4F5BE7'}}>
                          Full Privacy Statement
                          {this.state.b2?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q2}>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                      <div className="card-body">
                      <a style={{color: '#000'}}>
                        <p className=" roboto-reg-18px">
                        This Privacy Statement applies to all websites owned and operated by Longevity Labs  ("TruGeny"), including www.TruGeny.com (https://www.TruGeny.com/), and any other websites, pages, features, or content we own or operate, and to your use of the TruGeny mobile app and any related Services. Our Privacy Statement is designed to help you better understand how we collect, use, store, process, and transfer your information when using our Services.
                        </p>
                        <p className=" roboto-reg-18px">
                        Please carefully review this Privacy Statement and our Terms of Service (https://www.TruGeny.com/about/tos/). By using our Services, you acknowledge all of the policies and procedures described in the foregoing documents. If  you do not agree with or you are not comfortable with any aspect of  this Privacy Statement or            our Terms of Service you should immediately discontinue use of our Services.
                        </p>
                      </a>
                      <a><h2 className="roboto-bold-24px pt-3 pb-1">Contents</h2></a><a>
                        <br></br>
                        <p className=" roboto-reg-18px"> 1. Key Definitions</p>
                        <p className=" roboto-reg-18px">  2. Information we collect</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />Information you provide directly to us  Information related to our genetic testing services</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />Information collected through tracking technology Other types of information</p>
                        <p className=" roboto-reg-18px"> 3. How we use your information</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />To provide you with Services and analyze and improve our Services To process, analyze and deliver your genetic testing results</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />To allow you to share your Personal Information with others</p>
                        <p className=" roboto-reg-18px"><i style={{marginLeft: '20px'}} /> To allow you to share your Personal Information for research purposes To recruit you for external research</p>
                        <p className=" roboto-reg-18px"><i style={{marginLeft: '20px'}} /> To provide customer support</p>
                        <p className=" roboto-reg-18px"><i style={{marginLeft: '20px'}} /> To conduct surveys or polls, and obtain testimonials To provide you with marketing communications</p>
                        <p className=" roboto-reg-18px"> 4. Information we share with third parties</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />General Service Providers</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />"Targeted advertising" service providers Aggregate Information</p>
                        <p className=" roboto-reg-18px"><i style={{marginLeft: '20px'}} /> Information we share with commonly owned entities As required by law</p>

                        <p className=" roboto-reg-18px"><i style={{marginLeft: '20px'}} /> Business Transactions</p>
                        <p className=" roboto-reg-18px"> 5. Your choices</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />Access to your account Marketing communications</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} />Sharing outside of the TruGeny Services Account Deletion</p>
                        <p className=" roboto-reg-18px"> 6. Security Measures</p>
                        <p className=" roboto-reg-18px"> 7. Children's Privacy</p>
                        <p className=" roboto-reg-18px"> 8. Linked Websites</p>
                        <p className=" roboto-reg-18px">  9. Information for Customers in Designated Countries</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} /> Privacy Shield</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} /> Our relationship with you</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} /> Legal bases for processing Personal Information from the EU Direct Marketing</p>
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} /> Privacy </p> 
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} /> Rights </p> 
                        <p className=" roboto-reg-18px"> <i style={{marginLeft: '20px'}} /> Complaints</p> 
                        <p className=" roboto-reg-18px">10. Changes to this Privacy Statement</p>
                        <p className=" roboto-reg-18px"> 11. Contact information</p>

                      </a><div className="container keydefinition"><a>
                        </a><a id="h1" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px pt-3 pb-1">1. Key Definitions</p></a>
                        <div id="b1" className="container" style={{display: 'block'}}>
                          <p><span><strong>1.Aggregate Information:</strong> information that has been combined with that of other users and analyzed or evaluated as a whole, such that no specific individual may be</span><br /><span>reasonably identified.</span></p>
                          <p><span><strong>2. De-identified Information:</strong> information that has been stripped of your Registration Information (e.g., your name and contact information) and other identifying data such that you cannot reasonably be identified as an individual, also known as pseudonymized information.</span></p>
                          <p><span><strong>3. Individual-level Information</strong>: information about a single individual's genotypes, diseases or other traits/characteristics, but which is not necessarily tied to Registration Information.</span></p>
                          <p id="hinn1"><span style={{cursor: 'pointer'}}><strong>4. Personal Information:</strong> information that can be used to identify you, either alone or in combination with other information. truGeny collects and stores the following types of Personal Information:</span></p>
                          <ol id="binn1" style={{listStyleType: 'lower-alpha', display: 'block'}}>
                            <li>
                              <p><span><strong>Registration Information:</strong> information you provide about yourself when registering for and/or purchasing our Services (e.g. name, email, address, user ID and</span><br /><span>password, and payment information).</span></p>
                            </li>
                            <li>
                              <p><span><strong>Genetic Information:</strong> information regarding your genotypes (i.e. the As, Ts, Cs, and Gs at particular locations in your genome), generated through processing of</span><br /><span>your saliva by truGeny or by its contractors, successors, or assignees; or otherwise processed by and/or contributed to truGeny.</span></p>
                            </li>
                            <li>
                              <p><span><strong>Self-Reported Information:</strong> information you provide directly to us, including your disease conditions, other health-related information, personal traits, ethnicity,</span><br /><span>family history, and other information that you enter into surveys, forms, or features while signed in to your truGeny account.</span></p>
                            </li>
                            <li>
                              <p><span><strong>&nbsp;Sensitive Information:</strong> information about your health, Genetic Information, and certain Self-Reported Information such as racial and ethnic origin, sexual orientation,</span><br /><span>and political affiliation.</span></p>
                            </li>
                            <li>
                              <p><span><strong>User Content:</strong> all information, data, text, software, music, audio, photographs, graphics, video, messages, or other materials - other than Genetic Information</span><br /><span>and Self-Reported Information-generated by users of truGeny Services and transmitted, whether publicly or privately, to or through truGeny</span></p>
                            </li>
                            <li>
                              <p><span><strong> Web-Behavior Information:</strong> information on how you use truGeny Services collected through log files, cookies, web beacons, and similar technologies, (e.g., browser type, domains, page views).</span></p>
                            </li>
                          </ol>
                        </div>
                        <a id="h2" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-3 mb-0">2. Information we collect</p></a>
                        <div id="b2" className="container" style={{display: 'block'}}>
                          <a id="h2inn" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Information you provide directly to us </p></a>
                          <div id="b2inn" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                              <li><span>Registration Information. When you purchase our Services or create a truGeny account and register your kit, we collect Personal Information, such as your name, date of birth, billing and shipping address, payment information (e.g., credit card) and contact information (e.g. email, phone number and license number).</span></li>
                              <li><span>&nbsp;Self-Reported Information. You have the option to provide us with additional information about yourself through surveys, forms, features and applications. For example,you may provide us with information about your personal traits (e.g., eye color, height), ethnicity, disease conditions (e.g. Type 2 Diabetes), other health-related information (e.g. pulse rate, cholesterol levels, visual acuity), and family history information (e.g. information similar to the foregoing about your family members). Before you disclose information about a family member, you should make sure you have permission from the family member to do so.</span></li>
                              <li><span>User Content. Some of our Services allow you to create and post or upload content, such as data, text, software, music, audio, photographs, graphics, video,messages, or other materials that you create or provide to us through either a public or private transmission ("User Content's"). For example, User Content includes any discussions, posts, or messages you send on truGeny's Forums.</span></li>
                              <li><span>Blogs and Forums. Our website offers publicly accessible blogs. Additionally, truGeny customers may participate in our online Forums. You should be aware that any information you provide or post in these areas may be read, collected, and used by others who access them. To request that we remove or de-identify your Personal Information from our blog or Forums, contact us at privacy@trugeny.com (mailto:privacy@trugeny.com). Please note that whenever you post something publicly, it may sometimes be impossible to remove all instances of the posted information, for example, if someone has taken a screenshot of your posting. Please exercise caution before choosing to share Personal Information publicly on our blogs, Forums or in any other posting. You may be required to register with a third party application to post a comment. To learn how the third party application uses your information, please review the third party&amp's privacy statement.</span></li>
                              <li><span>&nbsp;Social media features and widgets. Our Services include Social Media Features, such as the Facebook "Like" or "Share" button and widgets ("Features's"). These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. They may also allow third-party social media services to provide us information about you, including your name, email address, and other contact information. The information we receive is dependent upon your privacy settings with the social network. Features are either hosted by a third-party or hosted directly on our site. Your interactions with these Features are governed by the privacy statements of the third party companies providing them. You should always review and, if necessary, adjust your privacy settings on third party websites and services before linking or connecting them to our website or Service.</span></li>
                              <li><span>&nbsp;Third party services (e.g., social media). If you use a third party site, such as Facebook or Twitter, in connection with our Services to communicate with another person(e.g., to make or post referrals or to request that we communicate with another person), then in addition to that person's name and contact information, we may also collect other information (e.g., your profile picture, network, gender, username, user ID, age range, language, country, friends lists or followers) depending on your privacy settings on the third party site. We do not control the third party site's information practices, so please review the third party's privacy statement and your settings on the third party's site carefully.</span></li>
                              <li><span>&nbsp;Referral information and sharing. When you refer a person to truGeny or choose to share your truGeny results with another person, we will ask for that person's email address. We will use their email address solely, as applicable, to make the referral or to communicate your sharing request to them, and we will let your contact know that you requested the communication. By participating in a referral program or by choosing to share information with another person, you confirm that the person has given you consent for truGeny to communicate (e.g., via email) with him or her. The person you referred may contact us at privacy@trugeny.com(mailto:privacy@trugeny.com) to request that we remove this information from our database. For more information on our referral program, see here</span ></li>
                              <li><span>&nbsp;Gifts. If you provide us with Personal Information about others, or if others give us your information, for the purpose of ordering the Service as a gift, we will only use that information for the specific reason for which it was provided to us. Once a gift recipient registers for his or her Services and agrees to our Privacy Statement (https://www.trugeny.com/about/privacy/), our Terms of Service (https://www.trugeny.com/about/tos/), and if applicable, certain Consent Documents(https://www.trugeny.com/about/consent/), his or her Personal Information will be used in manners consistent with this Privacy Statement, and will not be shared with the purchaser, unless they independently choose to share their own Personal Information through the Services with the purchaser.</span></li>
                              <li><span>&nbsp;Customer service. When you contact Customer Care (https://customercare.truGeny.com) or correspond with us about our Service, we collect information to: track and respond to your inquiry; investigate any breach of our Terms of Service (https://www.trugeny.com/about/tos/), Privacy Statement(https://www.trugeny.com/about/privacy/) or applicable laws or regulations; and analyze and improve our Services.</span></li>
                            </ol>
                          </div>
                          <a id="hinn3" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Information related to our genetic testing services</p></a>
                          <div id="binn3" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                              <li><span><strong>Saliva Sample and Biobanking:</strong>&nbsp;To use our genetic testing services, you must purchase, or receive as a gift, a truGeny Personal Genetic Service testing kit, create an online account and register your kit, and ship your saliva sample to our third party laboratory. Our laboratory will extract your DNA from your saliva sample for analysis. During kit registration you are asked to review our Consent Document for Sample Storage and Additional Genetic Analyses (https://www.trugeny.com/about/biobanking/).Unless you consent to sample storage (“Biobanking”) and additional analyses, your saliva sample and DNA are destroyed after the laboratory completes its work, subject to the laboratory's legal and regulatory requirements. You can update your sample storage preference to discard a stored sample within your Account Settings once your sample has completed processing.</span></li>
                              <li><span><strong>Genetic Information: </strong>Information regarding your genotype (e.g. the As, Ts, Cs, and Gs at particular locations in your genome), your Genetic Information, is generated when we analyze and process your saliva sample, or when you otherwise contribute or access your Genetic Information through our Services.</span></li>
                            </ol>
                            <p><span>Genetic Information includes the truGeny results reported to you as part of our Services, and may be used for other purposes, as outlined in Section 3 below.</span></p>                          </div>
                          
                            <a id="h2inn" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Web-Behavior Information collected through tracking technology (e.g. from cookies and similar technologies) </p></a>
                            <p className="roboto-reg-18px">We and our third party service providers use cookies and similar technologies (such as web beacons tags scripts and device identifiers) to:</p>
                          <div id="b2inn" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                              <li><span>Privacy Policy - TruGeny AU, DE, FR & EU</span></li>
                              <li><span>Customize and improve your experience;</span></li>
                              <li><span>Provide security;</span></li>
                              <li><span>Analyze usage of our Services (such as to analyze your interactions with the results, reports, and other features of the Service);</span></li>
                              <li><span>Gather demographic information about our user base;</span></li>
                              <li><span>Offer our Services to you;</span></li>
                              <li><span>Monitor the success of marketing programs; and</span></li>
                              <li><span>Serve targeted advertising on our site and on other sites around the Internet.</span></li>
                              
                              </ol>
                          </div>
                          <p className="roboto-reg-18px">If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited. For more information, including the types of cookies found on TruGeny and how to control cookies, please read our Cookie Policy. (https://www.TruGeny.com/about/cookies/)</p>
                          <p className="roboto-reg-18px">We may receive reports based on the use of these technologies from third party service providers as de-identified, Individual-level Information or as Aggregate Information (as described in section 4.c). We and our third party service providers do not use your Sensitive Information, such as Genetic Information and Self-Reported Information, for targeted advertising.</p>
                          <p className="roboto-reg-18px"><strong>Google Analytics. </strong>Google Analytics is used to perform many of the tasks listed above. We use the User-ID feature of Google Analytics to combine behavioral information across devices and sessions (including authenticated and unauthenticated sessions). We have enabled the following Google Analytics Advertising features: Remarketing, Google Display Network Impression Reporting, Google Analytics Demographics and Interest Reporting, and DoubleClick Campaign Manager integration. We do not merge information collected through any Google advertising product with individual-level information collected elsewhere by our Service. <strong>Learn more about how Google collects and uses data here (https://policies.google.com/technologies/partner-sites).</strong> To opt out of Google Analytics Advertising Features please use Google Ad Settings (https://www.google.com/settings/ads). To opt out of Google Analytics entirely please use  this link (https://tools.google.com/dlpage/gaoptout).</p>

                          <a id="h3" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">Other Types of Information</p></a>
                          <p className="roboto-reg-18px">We continuously work to enhance our Services with new products, applications and features that may result in the collection of new and different types of information. We will update our Privacy Statement and/or obtain your prior consent to new processing, as needed.</p>
{/* 
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Information collected through tracking technology Other</p></a>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;types of information</p></a> */}
                          {/* <a style="color:black"href=""><p class="roboto-reg-18px  pb-1"><i style="color: #eab623;"class="fas fa-caret-right"></i>&nbsp;To analyze and improve our Services.</i></p></a> */}
                        </div>
                        <a id="h3" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">3. How we use your information</p></a>
                        <p className="roboto-reg-18px">TruGeny will use and share your Personal Information with third parties only in the ways that are described in this Privacy Statement.</p>
                        <div id="b3" className="container" style={{display: 'block'}}>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To provide you with Services and analyze and improve our Services</p></a>
                          <p className="roboto-reg-18px">We use the information described above in Section 2 to operate, provide, analyze and improve our Services. These activities may include, among other things, using your information in a manner consistent with this Privacy Statement to:</p>
                          <div id="b2inn" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                              <li><span>open your account, enable purchases and process payments, communicate with you, and implement your requests (e.g., referrals);</span></li>
                              <li><span>enable and enhance your use of our website and mobile application(s), including authenticating your visits, providing personalized content and information, and tracking your usage of our Services;</span></li>
                              <li><span>contact you about your account, and any relevant information about our Services (e.g. policy changes, security updates or issues, etc.);</span></li>
                              <li><span>enforce our Terms of Service and other agreements;</span></li>
                              <li><span>monitor, detect, investigate and prevent prohibited or illegal behaviors on our Services, to combat spam and other security risks; and</span></li>
                              <li><span>perform research & development activities, which may include, for example, conducting data analysis and research in order to develop new or improve existing products and services, and performing quality control activities.</span></li>
                              </ol>
                              </div>
                              <p className="roboto-reg-18px">For individuals located in the European Economic Area (“EEA”), United Kingdom, or Switzerland (collectively the “Designated Countries”): We process your Personal Information in this way to provide our Services to you in accordance with our Terms of Service. (https://www.TruGeny.com/en-eu/about/tos/)</p>

                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To process, analyze and deliver your genetic testing results</p></a>
                          <p className="roboto-reg-18px">As described above, to receive results through the Personal Genetic Service, you must create a TruGeny account, register your kit, and submit your saliva sample to our contracted genotyping laboratory, which processes and analyzes your sample to provide us with your raw Genetic Information. Once we receive your raw Genetic Information from the laboratory, we further analyze it to provide you with our health and/or ancestry reports, dependent on the Service purchased. TruGeny continuously works to improve our Services based on our research and product development, and genetic associations identified in scientific literature. If you are eligible to receive additional reports or updates in the future, you may be notified of or may directly access these updates.</p>
                          <p className="roboto-reg-18px">For individuals located in the Designated Countries: Our legal basis for processing your Sensitive Information for the purposes described above is based on  your consent. You may withdraw your consent at any time by deleting your Account via your Account Settings, however, the withdrawal of your consent will not affect the lawfulness of processing based on consent before its withdrawal.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To allow you to share your Personal Information with others</p></a>
                          <p className="roboto-reg-18px">TruGeny gives you the ability to share information, including Personal Information, through the Services. You have the option to share directly with individuals with TruGeny accounts through (i) our Forums, (ii) relative finding features (e.g., "DNA Relatives"), (iii) other sharing features and tools. You may also have the ability to share information directly with individuals who have not participated in our Service via a unique, shareable URL or through a social media platform (such information is "User Content"). Some sharing features, including receiving sharing invitations, may require that you opt-out, however you will always be required to take a positive action, such as opting in, to share sensitive data.</p>
                          <p className="roboto-reg-18px">For individuals located in the Designated Countries: Our legal basis for processing your Personal Information for the purpose described above is based on your consent. You may withdraw your consent at any time, however, the withdrawal of your consent will not affect the lawfulness of processing based on consent before its withdrawal.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To allow you to share your Personal Information for research purposes</p></a>
                          <p className="roboto-reg-18px">You have the choice to participate in TruGeny Research by providing your consent. "TruGeny Research" refers to research aimed at publication in peer- reviewed journals and other research funded by the federal government (such as the National Institutes of Health - NIH) conducted by TruGeny.</p>
                          <p className="roboto-reg-18px">TruGeny Research may be sponsored by, conducted on behalf of, or in collaboration with third parties, such as non-profit foundations, academic institutions or pharmaceutical companies. TruGeny Research may study a specific group or population, identify potential areas or targets for therapeutics development, conduct or support the development of drugs, diagnostics or devices to diagnose, predict or treat medical or other health conditions, work with public, private and/or non-profit entities on genetic research initiatives, or otherwise create, commercialize,
                           and apply this new knowledge to improve health care. TruGeny Research uses Aggregate and/or Individual-level Genetic Information and Self-Reported Information as specified in the appropriate Consent Document(s), as explained in greater detail below.</p>
                          <p className="roboto-reg-18px">Your De-identified Genetic and Self-Reported Information may be used for TruGeny Research only if you have consented to this use by completing a Consent Document. If you have completed the Main Research Consent Document:</p>
                          <div id="b2inn" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                            <li><span>Your Genetic Information and/or Self-Reported Information will be used for research purposes, but it will be de-identified and will not be linked to your Registration Information. </span></li>
                            <li><span>TruGeny may use individual-level Genetic Information and Self-Reported Information internally at TruGeny for research purposes.</span></li>
                            <li><span>TruGeny may share summary statistics, which do not identify any particular individual or contain individual-level information, with our qualified research collaborators.</span></li>
                            </ol>
                            </div>
                          <p className="roboto-reg-18px">If you have completed the Individual Level Data Sharing Consent (https://www.TruGeny.com/about/individual-data-consent/), or additional consent agreement, in addition to the uses above under the Main Consent Document, TruGeny may share De-identified Individual-level Genetic Information and Self- Reported Information with select third party research collaborators for TruGeny Research purposes.</p>
                          <p className="roboto-reg-18px"><strong>Withdrawing your Consent. </strong> You may withdraw your consent to participate in TruGeny Research at any time by changing your consent status within your Account Settings. If you experience difficulties changing your consent status, contact the Human Protections Administrator at hpa@TruGeny.com (mailto:hpa@TruGeny.com). TruGeny will not include your Genetic Information or Self-Reported Information in studies that start more than 30 days after you withdraw (it may take up to 30 days to withdraw your information after you withdraw your consent). Any research involving your data that has already been performed or published prior to your withdrawal from TruGeny Research will not be reversed, undone, or withdrawn. You may also discontinue your participation in TruGeny Research by deleting your TruGeny account (as described in section 5.d).</p>
                          <p className="roboto-reg-18px"><strong>What happens if you do NOT consent to TruGeny Research? </strong>If you choose not to complete a Consent Document or any additional agreement with TruGeny, your Personal Information will not be used for TruGeny Research. However, your Genetic Information and Self-Reported Information may still be used by us and shared with our third party service providers to as outlined in this Privacy Statement.</p>
                          <p className="roboto-reg-18px">For individuals located in the Designated Countries: Our legal basis for processing your Sensitive Information for the purpose described above is based on your consent. You may withdraw your consent at any time, however, the withdrawal of your consent will not affect the lawfulness of processing based on consent before its withdrawal.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To recruit you for external research</p></a>
                          <p className="roboto-reg-18px">Research is an important aspect of TruGeny’s Services and we want to ensure interested participants are aware of additional opportunities to contribute to interesting, novel scientific research conducted by academic institutions, healthcare organizations, pharmaceutical companies, and other groups. If you have chosen to participate in TruGeny Research, from time to time we may inform you of third party research opportunities for which you may be eligible. For example, if a university tells us about a new cancer research project, we may send an email to TruGeny research participants who potentially fit the relevant eligibility criteria based on their Self-Reported Information to make them aware of the research project and provide a link to participate with the research organization conducting the study. However we will not share Individual-level Genetic Information or Self-Reported Information with any third party without your consent. If you do not wish to receive these notifications, you can manage them by editing your preferences in your Account Settings.</p>
                          <p className="roboto-reg-18px">For individuals located in the Designated Countries: Our legal basis for processing your Sensitive Information for the purpose described above is based on your consent. You may withdraw your consent at any time, however, the withdrawal of your consent will not affect the lawfulness of processing based on consent before its withdrawal.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To provide customer support </p></a>
                          <p className="roboto-reg-18px">When you contact Customer Care, we may use or request Personal Information, including Sensitive Information, as necessary to answer your questions, resolve disputes, and/or investigate and troubleshoot problems or complaints. In some instances, we may be required to process one customer’s Personal Information to resolve another customer’s dispute or request. For example, if a customer reports behavior that violates our Terms of Service, we will separately process both customers’ Personal Information and respond separately to each individual as appropriate. We will not share your Personal Information with another customer without your consent.</p>
                          <p className="roboto-reg-18px">For  individuals located in the Designated Countries: Our legal basis for processing your Personal Information for the purpose described above depends on   the nature of the customer support request. Our legal basis can be to satisfy our contractual or legal obligations and/or our legitimate interest to improve our Services.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To conduct surveys or polls, and obtain testimonials</p></a>
                          <p className="roboto-reg-18px">We value your feedback and may send you surveys, polls, or requests for testimonials to improve and optimize our Services. You are in control of the information you would like to share with us. If you do not wish to receive these requests, you can manage them in your Account Settings.</p>
                          <p className="roboto-reg-18px">For individuals located in the Designated Countries: Our legal basis for processing your Personal Information for the purpose described above is based on our legitimate interest. We think it is important to continue improving our Services to ensure your continued enjoyment.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;To provide you with marketing communications</p></a>
                          <p className="roboto-reg-18px">By creating a TruGeny account, you are agreeing that we may send you product and promotional emails or notifications about our Services, and offers on new products, services, promotions or contests. You can unsubscribe from receiving these marketing communications at any time. To unsubscribe, click the email footer “unsubscribe” link or go to the “Preferences” section of your Account Settings to edit your email notification preferences. You may not opt-out of receiving non-promotional messages regarding your account, such as technical notices, purchase confirmations, or Service-related emails.</p>
                          <p className="roboto-reg-18px">Individuals located in Designated Countries should review Section 9.d. to understand our marketing practices in relation to the Designated Countries.</p>
                        </div>

                        
                        <a id="h4" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">4. Information we share with third parties</p></a>
                        <p className="roboto-reg-18px"><strong>a. General service providers.</strong></p>
                        <p className="roboto-reg-18px">We share the information described above in Section 2 with our third party service providers, as necessary for them to provide their services to us and help us perform our contract with you. Service providers are third parties (other companies or individuals) that help us to provide, analyze and improve our Services. While TruGeny directly conducts the majority of data processing activities required to provide our Services to you, we engage some third party service</p>
                        <p className="roboto-reg-18px">Providers to assist in supporting our Services, including in the following areas:</p>
                        <div id="b2inn" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                            <li><span><strong>Order fulfillment and shipping.</strong> Our payment processor processes certain Registration Information, such as your billing address and credit card information, as necessary to enable you to purchase a TruGeny kit from the TruGeny.com online store. Our distribution centers ship your kit(s) to you, and in some cases help return your kit safely to our third party laboratory so your sample can be processed</span></li>
                            <li><span><strong>Our CAP Accredited & NABL Certified  Lab.</strong>To use our genetic testing services, you must purchase, or receive as a gift, a TruGeny Personal Genetic Service testing kit, and ship your saliva sample to our third party laboratory. Once delivered, receiving personnel at the laboratory remove and discard kit packaging,  which in some cases may contain "sender information" (e.g., name, address), before testing personnel receive the samples for processing. Receiving personnel do not perform testing, and testing personnel handle saliva samples that are only identified by a unique barcode. When the laboratory has completed their analysis, they securely send the resulting Genetic Information to us identified by your unique barcode.</span><span><p>During kit registration, you are asked to review our Consent Document for Sample Storage and Additional Genetic Analyses. Unless you consent to Biobanking and additional analyses, your saliva sample and DNA are destroyed after the laboratory completes its work, subject to the laboratory's legal and regulatory requirements. Should you wish to update your sample storage preference to discard a stored sample, you can do so within your Account Settings once your sample has completed processing. As detailed further in Section 5.d. (Account Deletion) our contracted genotyping laboratory will retain certain information as necessary to comply with applicable regulatory and legal obligations.</p></span></li>
                            <li><span><strong>Customer Care support. </strong>Our Customer Care team uses a number of tools to help organize and manage the requests we receive. These tools help to ensure we provide timely, high quality support.</span></li>
                            <li><span><strong>Cloud storage, IT, and Security.</strong>Our cloud storage providers provide secure storage for information in TruGeny databases, ensure that our infrastructure can support continued use of  our Services by TruGeny customers, and protect data in the event of  a natural disaster or other disruption to the Service.          Our IT and security providers assist with intrusion detection and prevention measures to stop any potential attacks against our networks. We  have these       third party experts perform regular penetration tests and periodically audit TruGeny’s security controls.</span></li>
                            <li><span><strong>Marketing and analytics.</strong>When you use our Services, including our website or mobile app(s), our third party service providers may collect Web-Behavior Information about your visit, such as the links you clicked on, the duration of your visit, and the URLs you visited. This information can help us improve site navigability and assess our Marketing campaigns.</span><span><p>NOTE: Our service providers act on TruGeny's behalf. We implement procedures and maintain contractual terms with each service provider to protect the confidentiality and security of your information. However, we cannot guarantee the confidentiality and security of your information due to the inherent risks associated with storing and transmitting data electronically.</p></span></li>
                            
                            </ol>
                            </div>
                            <p className="roboto-reg-18px"><strong>b. "Targeted advertising" service providers</strong></p>
                            <p className="roboto-reg-18px">We permit third party advertising networks and providers to collect Web-Behavior Information regarding the use of our Services to help us to deliver targeted online advertisements ("ads") to you. They use cookies and similar technologies, to gather information about your browser's or device's visits and usage patterns on our Services and on other websites over time, which helps to better personalize ads to match your interests, and to measure the effectiveness of ad campaigns. 
Aggregate information</p>
<p className="roboto-reg-18px">We may share Aggregate Information, which is information that has been stripped of your name and contact information and combined with information of others so that you cannot reasonably be identified as an individual, with third parties. This Information is different from "Individual-level" information and is not Personal Information because it does not identify any particular individual or disclose any particular individual’s data. For example, Aggregate Information may include a statement that "30% of our female users share a particular genetic trait," without providing any data or testing results specific to any individual user. In contrast, Individual-level Genetic Information or Self-Reported Information consists of data about a single individual's genotypes, diseases or other traits/characteristics information and could reveal whether a specific user has a particular genetic trait, or consist of all of the Genetic Information about that user. TruGeny will ask for your consent to share Individual-level Genetic Information or Self-Reported Information with any third party, other than our service providers as necessary for us to provide the Services to you.</p>
                            <p className="roboto-reg-18px"><strong>c. Information we share with commonly owned entities</strong></p>
                            <p className="roboto-reg-18px">We may share some or all of your Personal Information with other companies under common ownership or control of TruGeny, which may include our subsidiaries, our corporate parent, or any other subsidiaries owned by our corporate parent in order to provide you better service and improve user experience. Generally, sharing such information is necessary for us to perform on our contract with you. We may provide additional notice and ask for your prior consent if we wish to share your Personal Information with our commonly owned entities in a materially different way than discussed in this Privacy Statement.</p>
                            <p className="roboto-reg-18px"><strong>d. As required by law</strong></p>
                            <p className="roboto-reg-18px">Under certain circumstances your Personal Information may be subject to processing pursuant to laws, regulations, judicial or other government subpoenas, or orders. For example, we may be required to disclose Personal Information in coordination with regulatory authorities in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. TruGeny will preserve and disclose any and all information to law enforcement agencies or others if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal or regulatory process (such as a judicial proceeding, court order, or government inquiry) or obligations that TruGeny may owe pursuant to ethical and other professional rules, laws, and regulations; (b) enforce the TruGeny Terms of Service (https://www.TruGeny.com/about/tos/) and other policies; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of TruGeny, its employees, its users, its clients, and the public.</p>
                            <p className="roboto-reg-18px"><strong>e. Business transactions</strong></p>
                            <p className="roboto-reg-18px">In the event that TruGeny goes through a business transition such as a merger, acquisition by another company, or sale of all or a portion of its assets your Personal Information will likely be among the assets transferred. In such a case, your information would remain subject to the promises made in any pre- existing Privacy Statement.</p>
                   
                        <a id="h5" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">5. Your choices</p></a>
                        <div id="b5" className="container" style={{display: 'block'}}>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Access to your account </p></a>
                          <p className="roboto-reg-18px">We provide access to your TruGeny data within your TruGeny account. You can access and download data processed by TruGeny within your Account Settings and within applicable Reports, Tools, and features. If you lose access to your TruGeny account or account email address, please contact Customer Care for assistance. If you lose access to your TruGeny account, in certain circumstances, we may require that you submit additional information sufficient to verify your identity before providing access or otherwise releasing information to you. If you choose not to submit the required documentation, or the information provided is not sufficient for the purposes sought, TruGeny will not be able to sufficiently verify your identity in order to complete your request.</p>
                          <p className="roboto-reg-18px">You may access, correct or update most of your Registration Information on your own within your Account Settings. You may also review and update your consent to TruGeny Research and Biobanking. You may be able to correct Self-Reported Information entered into a survey, form, or feature within your account, such as on the surveys page (https://you.TruGeny.com/research/all_questions_dashboard/), by clicking “Edit your answers here.” Please note that you may not be able to delete User Content that has been shared with others through the Service and that you may not be able to delete information that has been shared with third parties.</p>
                          <p className="roboto-reg-18px">Individuals located in Designated Countries should review Section 9.e. to understand their rights to access Personal Information.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Marketing communications</p></a>
                          <p className="roboto-reg-18px">As noted in Section 3.h. you may be asked to opt-in to receive product and promotional emails or notifications when creating your TruGeny account depending on where you are located. Otherwise, you may view or update your email notification preferences by visiting your Account Settings. You can also click the "unsubscribe" button at the bottom of promotional email communications.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Sharing outside of the truGeny Services Account</p></a>
                          <p className="roboto-reg-18px">You may decide to share your Personal Information with friends and/or family members, doctors or other health care professionals, and/or other individuals outside of our Services, including through third party services such as social networks and third party apps that connect to our website and mobile apps through our application programming interface ("API"). These third parties may use your Personal Information differently than we do under this Privacy Statement. Please make such choices carefully and review the privacy statements of all other third parties involved in the transaction. TruGeny does not endorse or sponsor any API applications, and does not affirm the accuracy or validity of any interpretations made by third party API applications.</p>
                          <p className="roboto-reg-18px">In general, it can be difficult to contain or retrieve Personal Information once it has been shared or disclosed. TruGeny will have no responsibility or liability for any consequences that may result because you have released or shared Personal Information with others. Likewise, if you are reading this because you have access to the Personal Information of a TruGeny customer through a multi-profile account, we urge you to recognize your responsibility to protect the privacy of each person within that account. Users with multi-profile accounts (i.e., where multiple family members register their kits to one account) should use caution in setting profile-level privacy settings.</p>
                          <a style={{color: 'black'}} href><p className="roboto-reg-18px  pb-1"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Deletion</p></a>
                          <p className="roboto-reg-18px">If you no longer wish to participate in our Services, or no longer wish to have your Personal Information be processed, you may delete your TruGeny account and Personal Information within your Account Settings. Once you submit your request, we will send an email to the email address linked to your TruGeny account detailing our account deletion policy and requesting that you to confirm your deletion request. Once you confirm your request to delete your account and data, your account will no longer be accessible while we process your request. Once you confirm your request, this process cannot be cancelled, undone, withdrawn, or reversed. When your account is deleted, all associated Personal Information is deleted and any stored samples are discarded, subject to the following limitations:</p>
                          <div id="b2inn" style={{display: 'block'}}>
                            <ol style={{listStyleType: 'lower-roman'}}>
                            <li><span>Information previously included in TruGeny Research. As stated in any applicable Consent Document, Genetic Information and/or Self-Reported Information that you have previously provided and for which you have given consent to use in TruGeny Research cannot be removed from completed studies that use that information. Your data will not be included in studies that start more than 30 days after your account is closed (it may take up to 30 days to withdraw your information after your account is closed).</span></li>
                            <li><span>Legal Retention Requirements. TruGeny and our third party laboratory will retain your Genetic Information, date of birth, and sex as required for compliance with applicable legal obligations. TruGeny will also retain limited information related to your account and data deletion request, including but not limited to, your email address, account deletion request identifier, and record of legal agreements for a limited period of time as required by contractual obligations, and/or as necessary for the establishment, exercise or defense of legal claims and for audit and compliance purposes.</span></li>
                            </ol>
                            </div>
                        </div>
                        <a id="h6" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">6. Security Measures</p></a>
                        <p className="roboto-reg-18px">TruGeny takes seriously the trust you place in us. TruGeny implements physical, technical, and administrative measures to prevent unauthorized access to or disclosure of your information, to maintain data accuracy, to ensure the appropriate use of information, and otherwise safeguard your Personal Information.</p>
                        <div id="b2inn" style={{display: 'block'}}>
                            <ol >
                            <li><span><strong>TruGeny produces secure applications by design. </strong> TruGeny incorporates explicit security reviews in the software development lifecycle, quality assurance testing and operational deployment.</span></li>
                            <li><span><strong>De-identification/Pseudonymization.</strong> Registration Information is stripped from Sensitive Information, including Genetic and Self-Reported Information. This data is then assigned a randomly generated ID so an individual cannot reasonably be identified.</span></li>
                            <li><span><strong>Encryption.</strong> TruGeny uses industry standard security measures to encrypt Sensitive Information both at rest and in transit.</span></li>
                            <li><span><strong>Separation of Environments.</strong> TruGeny ensures processing, production, and research environments are separated and access is restricted. Data, including Registration Information, Genetic Information, and Self-Reported Information are segmented across logical database systems to further prevent re-identifiability.</span></li>
                            <li><span><strong>Limiting access to essential personnel.</strong> We limit access to Personal Information to authorized personnel, based on job function and role. TruGeny access controls include multi-factor authentication, single sign-on, and strict least-privileged authorization policy.</span></li>
                            <li><span><strong>Detecting threats and managing vulnerabilities.</strong> TruGeny uses state of the art intrusion detection and prevention measures to stop any potential attacks against its networks. We have integrated continuous vulnerability scanning in our processes and regularly engage third party security experts to conduct penetration tests.</span></li>
                            <li><span><strong>Incident Management.</strong> TruGeny maintains a formal incident management program designed to ensure the secure, continuous delivery of its Services. TruGeny has implemented an incident management program using industry best practices, including guidance from the National Institute of Standards and Technology (NIST).</span></li>
                            <li><span><strong>Managing third party service providers.</strong>TruGeny requires service providers to implement and maintain accepted industry standard administrative, physical and technical safeguards to protect Personal Information. </span></li>
                            
                            </ol>
                            </div>
                            <p className="roboto-reg-18px"><strong>Your Responsibility. </strong> Please recognize that protecting your Personal Information is also your responsibility. We ask you to be responsible for safeguarding your password, secret questions and answers, and other authentication information you use to access our Services. You should not disclose your authentication information to any third party and should immediately notify TruGeny of any unauthorized use of your password. TruGeny cannot secure Personal Information that you release on your own or that you request us to release.</p>
                            <p className="roboto-reg-18px"></p>


                        
                        <a id="h7" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">7. Children's Privacy</p></a>
                        <p className="roboto-reg-18px">TruGeny is committed to protecting the privacy of children as well as adults. Neither TruGeny nor any of its Services are designed for, intended to attract, or directed toward children under the age of 18. A parent or guardian, however, may collect a saliva sample from, create an account for, and provide information related to, his or her child who is under the age of 18. The parent or guardian assumes full responsibility for ensuring that the information that he/she provides to TruGeny about his or her child is kept secure and that the information submitted is accurate.</p>
                        <a id="h8" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">8. Linked Websites</p></a>
                        <p className="roboto-reg-18px">TruGeny provides links to third party websites operated by organizations not affiliated with TruGeny. TruGeny does not disclose your information to organizations operating such linked third party websites. TruGeny does not review or endorse, and is not responsible for, the privacy practices of these organizations. We encourage you to read the privacy statements of each and every website that you visit. This Privacy Statement applies solely to information collected by TruGeny and our service providers on our behalf.</p>
                        <a id="h9" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">9.Changes to this Privacy Statement</p></a>
                        <p className="roboto-reg-18px">Whenever this Privacy Statement is changed in a material way, a notice will be posted as part of this Privacy Statement and on our website for 30 days. After 30 days the changes will become effective. In addition, all customers will receive an email with notification of the changes prior to the change becoming effective. TruGeny may provide additional "just-in-time" disclosures or additional information about the data collection, use and sharing practices of specific Services. Such notices may supplement or clarify TruGeny’s privacy practices or may provide you with additional choices about how TruGeny processes your Personal Information.</p>
                        <a id="h9" style={{color: 'black', cursor: 'pointer'}}><p className="roboto-bold-18px  pb-1">10. Contact Information</p></a>
                        <p className="roboto-reg-18px">If you have questions about this Privacy Statement, or wish to submit a complaint, please email TruGeny's Privacy Administratoat privacy@TruGeny.com (mailto:privacy@TruGeny.com), or send a letter to:</p>
                        
                        
                      </div>
                     
                    </div>
                      </div>
                                            
                    </Collapse>

                    </div>
                  </div>
                </div>
                {/* eo bs-example */}
                {/* end of accordian code */}
              </div>
            </div>
            {/* eo card-block */}
          </div>
          {/* eo card body */}
          {/* eo card */}
          {/* eo-heart-accordian-sec-wrap */}
        </section>
        {/* eo-heart-accordian-sec */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        <MobileFooter></MobileFooter>
        <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active">
                      <br />
                      <h3>How do you indentify your gender?</h3>
                      <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" defaultChecked /> Parents</label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" /> Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill"  href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
      
        {/* search */}
        {/* eo saerch */}
        {/* --------eo sticky form----------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>



        
       )
   }

}
export default CliniciansPrivacyPolicy;