import React from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import *as PayContants from './paymentContants';



class MainHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            modal: false,
            isActive:0,
            prevScrollpos: window.pageYOffset,
            visible: false,
            desktop:false, 
            mobilemenu:false,
          };  
          
          this.toggleMenu = this.toggleMenu.bind(this);
          this.toggle = this.toggle.bind(this);
          this.changeTab=this.changeTab.bind(this);
          this.logout = this.logout.bind(this);
          this.handleScroll = this.handleScroll.bind(this);
          this.resize = this.resize.bind(this);
          this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
   
    }
    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    };
    
    componentWillUnmount() {
       window.removeEventListener("scroll", this.handleScroll);
  
    };
    resize() {
      const desktop= window.innerWidth>=576;
      this.setState({desktop},()=>{});
   }

    toggleMenu() {
      this.setState({ menu: !this.state.menu })
  }
  toggle() {
      this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
      }));
  }
    changeTab(value) {

      localStorage.setItem("value",value)
    
    }
    logout() {
      Cookies.remove('username');
     Cookies.remove('jwt-token');
     this.props.history.push('/clinicians_login');
 }
 
  handleScroll = () => {
      const { prevScrollpos } = this.state;
  
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollpos > 50;
  
      this.setState({
        prevScrollpos: currentScrollPos,
        visible
      });
    };
    toggleMobileMenu() {
      this.setState({ mobilemenu: !this.state.mobilemenu },()=>{})
    }

 
   render(){
    let styles;
    let contactstyles;
    if(this.state.mobilemenu){
      styles = {
        sideBar1: {
          width: '250px',
        },
        bgwhit:{
          'margin-left': '250px'
        }
  
  
      };
    }else{
      styles = {
        sideBar1: {
          width: '0px',
        },
        bgwhit:{
          'margin-left': '0px'
        }
  
  
      };

    }

 
    const { sideBar1,bgwhit } = styles;
  
    const show = (this.state.menu) ? "show" : "";
    const dropdownOpen =(this.state.dropdownOpen) ? "show" : "";
       
    const tooltipStyle = {
      display: this.state.hover ? 'block' : 'none'
    }
    const showheader=this.props.category
    if(showheader==="AboutUs"){
        this.state.visible=false
    }
     if(showheader==="clinicians"){
      this.state.submenu=true
     }

    if(showheader==="genetic-counselling"||showheader==="tests"||showheader==="work"){
      this.state.visible=true
      this.state.submenu=true
    }

    if(showheader==="HelpIndivisual"||showheader==="help"||showheader==="helpClinicians"||showheader==="helpCounselling"||showheader==="geneticCounselling"||showheader==="helpSecurity"){
      this.state.helpmenu=true
    }
    
   


       return(
     <div>
        <div id="mySidebar" className="sidebar1" style={sideBar1} >
        {Cookies.get("username")?
          <div className="signin-register">
        <ul>
          <li><a style={{ color:  'green' , fontWeight:  'bold'  }} className="signinRegisterBtn" id="signin" href="/loginSuccess">{Cookies.get("username")}</a></li>
        </ul>
      </div>
             : <div className="signin-register">
                <ul>
                  <li><a style={{ color: showheader === "signin" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "signin" ? 'bold' : '' }} className="signinRegisterBtn" id="signin" href="/clinicians_login" >Sign in</a> <span>|</span> <a className="signinRegisterBtn" id="register" style={{ color: showheader === "register" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "register" ? 'bold' : '' }} href="/clinicians_register">Register</a></li><hr />
                </ul>
              </div>
        }
              <a  className="closebtn" onClick={this.toggleMobileMenu}>×</a>
              <ul className="main-navbar-nav navbar-nav">
             
  
                <li className="nav-item">
                  <a href="/clinicians_about-us" className={this.props.category === "about" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>About</a>
                  <hr />
                </li>
                <li className="nav-item">
                <a href="/clinicians" className={this.props.category === "clinicians" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>Clinicians</a>
                </li>
               
                <li className="nav-item">
                  <a className={this.props.category === "genetic-counselling" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/clinicians_genetic-counselling">Genetic Counselling</a>
                </li>
                <li className="nav-item">
                  <a className={this.props.category === "tests" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/clinicians_tests">Tests</a>
                </li>
                <li className="nav-item">
                  <a className={this.props.category === "work" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/clinicians_work">How does it work?</a>
                  <hr />
                </li>
              
         
                <li className="nav-item">
                  <a href={PayContants.blogUrl} className="nav-link m-2 menu-item">Blog</a>
                  <hr />
                </li>
                <li className="nav-item">
                <a href="/clinicians_help" className={this.props.category === "help" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>HELP</a>
                <hr />
                </li>
              </ul>
              <div className="book-button ">
                <button type="button" className=" btn btn-primary rounded-pill">
                <a href="/clinicians_login">ORDER Test NOW</a>
                </button>
              </div>
              <hr />
              {Cookies.get("username")?
              <ul className="main-navbar-nav navbar-nav">
             
             <li className="nav-item">
             <a onClick={this.logout} className="nav-link m-2 menu-item ">Log out</a>
                <hr />
              </li>
              <br></br>
  </ul>:""}
            </div>
            
           
           
        <div id="main" className="bg-white" style={bgwhit} >
        <div className="row">
          <div className="col-6">
            <div className="d-flex  justify-content-start">
              <a className="navbar-brand  " href="/clinicians"><img id="img-1" className="img-size-custom" src="./img/Logo.png" /></a>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end">
              <button className="openbtn" onClick={this.toggleMobileMenu}>☰ </button>
              {/* <h2>Collapsed Sidebar</h2> */}
            </div>
          </div>
        </div>
        
        {this.state.helpmenu ? <div>
          <hr id="hor-line"  style={{ marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)' }} />
          <div id="submenu" className="container"><div className="row  py-md-2">
            <div className="col-12 nav-tab-1 py-md-2">
              <a className="font-nav mr-md-3 mr-sm-1" data-placement="top" href={PayContants.truGenyMail} data-toggle="tooltip" data-original-title={PayContants.truGenyMail} style={{ color: 'grey' }} aria-describedby={this.state.hover ? "tooltip367779" : ""}><span>
                <i className="i-button mr-1 fas fa-at" /></span>Email</a><a className="font-nav active nav-active1 mr-md-3 mr-sm-1 " href={PayContants.truGenyConatct} data-toggle="tooltip" data-original-title={PayContants.truGenyConatct} style={{ color: 'rgb(79, 91, 231)' }}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>{PayContants.truGenyConatct} </a></div></div></div>
        </div> : ""
        }
      </div>


<header id="headbar" className={(this.state.visible&&this.state.desktop)?"fixed-top bg-white":""}>

<nav id="nav-a-tag" className="main-nav pb-0 navbar navbar-expand-lg nav-menu container">
  <a className="navbar-brand  " href="/clinicians"><img id="img-1" className="img-size-custom p-0" src="./img/Logo.png" /></a>
  <button className={show?"navbar-toggler nav-button change":"navbar-toggler nav-button collapsed"}  type="button" data-toggle="collapse" data-target="#myNavbar" aria-expanded="false" onClick={this.toggleMenu}>
    <div className=" line1" />
    <div className=" line2" />
    <div className=" line3" />
  </button>
  <div className={show?"main-navbar-collapse navbar-collapse text-center justify-content-lg-end text-uppercase collapse show":"main-navbar-collapse collapse navbar-collapse text-center justify-content-lg-end  text-uppercase"} id="myNavbar" style={{}}>
    <ul className="main-navbar-nav navbar-nav">
      <li className="nav-item">
        <a href="/clinicians_about-us" className={this.props.category === "AboutUs" ? "nav-link m-2 menu-item nav-active":"nav-link m-2 menu-item "}>About</a>
      </li>
      <li className="nav-item">
        <a href="/clinicians" className={this.props.category === "clinicians" ? "nav-link m-2 menu-item nav-active":"nav-link m-2 menu-item "}>For clinicians</a>
      </li>
     
      <li className="nav-item">
        <a  href={PayContants.blogUrl} className="nav-link m-2 menu-item ">Blog</a>
      </li>
      {/* <li class="nav-item">
        <a href="clinicians.html" class="nav-link m-2 menu-item">for clinicians</a>
      </li> */}
      <li className="nav-item">
        <a href="/clinicians_help" className={this.props.category === "help" ? "nav-link m-2 menu-item nav-active":"nav-link m-2 menu-item "}>HELP</a>
      </li>
    </ul>
    <div className="signin-register">
      <ul>
        <li><a style={{color: showheader==="signin"?'#4f5be7':'#2c333c',fontWeight:showheader==="signin"?'bold':''}} className="signinRegisterBtn" id="signin"  onClick={this.logout}>{Cookies.get("username")?"Logout":""}</a></li>
      </ul>
    </div>
    <div className="book-button ">
      <button type="button" className=" btn btn-primary rounded-pill">
        <a href="/clinicians">Order your test</a>
      </button>
    </div>
  </div>
</nav>

  { this.state.submenu?
  
  <div ><hr id="hor-line" className={this.state.visible?"":"d-none"} style={{marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)'}} />
  <div id="submenu" className={this.state.visible?"container":"container d-none"}>

<div className="row  py-md-2">
  <div className="col-12 nav-tab-1">
  <a className={this.props.category==="clinicians"?"font-nav nav-active1" : "font-nav "} href="/clinicians">Clinicians</a>
  <a className={this.props.category==="genetic-counselling"?"font-nav nav-active1" : "font-nav "} href={Cookies.get("username")?"/clinicians_genetic-counselling":"/clinicians_login"}>Genetic Counselling</a>
  <a className={this.props.category==="tests"?"font-nav nav-active1" : "font-nav "} href={Cookies.get("username")?"/clinicians_tests":"/clinicians_login"}>Tests</a>
  <a className={this.props.category==="work"?"font-nav nav-active1" : "font-nav "} href={Cookies.get("username")?"/clinicians_work":"/clinicians_login"}>How does it work?</a>
  </div>
</div>


</div>
</div>
:""
}
{this.state.helpmenu?<div>
<hr id="hor-line"  style={{marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)'}} />

<div id="submenu" className="container"><div className="row  py-md-2">
<div className="col-12 nav-tab-1 py-md-2">
<a className="font-nav mr-md-3 mr-sm-1" data-placement="top" href={PayContants.truGenyMail} data-toggle="tooltip" data-original-title={PayContants.truGenyMail} style={{color: 'grey'}}   aria-describedby={this.state.hover ?"tooltip367779":""}><span>
<i className="i-button mr-1 fas fa-at" /></span>Email</a><a className="font-nav active nav-active1 mr-md-3 mr-sm-1 " href={PayContants.truGenyConatct} data-toggle="tooltip" data-original-title={PayContants.truGenyConatct} style={{color: 'rgb(79, 91, 231)'}}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>{PayContants.truGenyConatct} </a></div></div></div>
</div>:""

}
 


</header>


</div>
        
       )
   }

}

export default withRouter(MainHeader);