import React from 'react'

class SubMainHeader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            modal: false,
           
          };  
          this.toggleMenu = this.toggleMenu.bind(this);
          this.changeTab2=this.changeTab2.bind(this);

   
    }

    toggleMenu() {
      this.setState({ menu: !this.state.menu })
    }
    changeTab2(value2) {

      localStorage.setItem("value2",value2)
    
    }
   render(){
  
    let tab2=localStorage.getItem("value2");


       return(
        <div>
        <hr id="hor-line" className style={{marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)'}} />
        <div id="submenu" className="container ">
          <div className="row  py-md-2">
            <div className="col-12 nav-tab-1">
              <a className="font-nav " onClick={() => this.changeTab2(2)} className={tab2 == 2 ? "font-nav nav-active1" : "font-nav " } href="/">For You</a>
              <a onClick={() => this.changeTab2(3)} className={tab2 ==3 ? "font-nav nav-active1" : "font-nav " } href="/genetic-counselling">Genetic Counselling</a>
              <a onClick={() => this.changeTab2(4)} className={tab2 == 4 ? "font-nav nav-active1" : "font-nav " } href="/cancer">Hereditary Cancer Test</a>
              <a onClick={() => this.changeTab2(5)} className={tab2 == 5 ? "font-nav nav-active1" : "font-nav " } href="/heart">Heart Health Test</a>
            </div>
          </div>
        </div>
      </div>
       )
   }

}
export default SubMainHeader;