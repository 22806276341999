import React from 'react';
import {withRouter,Link} from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Media
} from 'reactstrap';
import Cookies from 'js-cookie';

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.users = this.users.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  users(){
    this.props.history.push('/user');
  }
  logout()
   {
   
    Cookies.remove('username');
    Cookies.remove('jwt-token');
    Cookies.remove('email');
    this.props.history.push('/');
  }
  render() {
 
   const imgStyle = {
    maxHeight:80,
    maxWidth: 80
  }
    if(Cookies.get('jwt-token')!==undefined){
      return (
      
        <div>
          <Navbar light expand="md" >
            <NavbarBrand href="/">
            <Media object src="./img/Logo.png" style={imgStyle} alt="trugene" /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {/* <NavItem>
                  <Link to="#" onClick={this.users}>Users</Link>&nbsp;&nbsp;
                </NavItem> */}
               
                <NavItem>
                  <Link to="/">Home</Link>&nbsp;&nbsp;
                </NavItem>
                
                {/*<NavItem>
                      <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Options
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          Option 1
                        </DropdownItem>
                        <DropdownItem>
                          Option 2
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          Reset
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>*/}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      );
    }else{
      return (
      
        <div>
          <Navbar light expand="md">
            <NavbarBrand href="/">
            <Media object src="./img/Logo.png" style={imgStyle} alt="trugene" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
              <NavItem>
                  <Link to="/">Home</Link>&nbsp;&nbsp;
                </NavItem>
                 {/*<NavItem>
                  <NavLink href="#" onClick={this.logout}>Logout</NavLink>
                </NavItem>
               <NavItem>
                      <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Options
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          Option 1
                        </DropdownItem>
                        <DropdownItem>
                          Option 2
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                          Reset
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>*/}
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      );
    }
    
  }
}
export default withRouter(Header);
