import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class GeneticCounsellingCarouselCards3 extends Component {
  constructor(props) {
    super(props);
  }
  toBookSession(){
    window.location.href="/book-counselling"
  }
  toOrder(){
    window.location.href="/product-page"
  }
  render() {
    return (
      <div
        id="carouselExampleControls1"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner  new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-1 newcard2 gMobileCard">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5" id="cimg">
                          <img
                            className="img-fluid mx-auto cimg"
                            src="./img/geneticspage/planning-pregnancy.png"
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <h5 className="card-title">Planning a Pregnancy</h5>
                        <p className="card-text">
                          If you are planning for a pregnancy, our Genetic
                          counsellors can help you learn about what genetic
                          variations you could pass on to your children.
                        </p>
                        <div className="container1  gc-help-button-wrap text-center my-5 pt-5">
                          <a
                            className="mt-4 gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center"
                           onClick={this.toBookSession}
                            role="button"
                            style={{
                              backgroundColor: "#4f5be7",
                              color: "white"
                            }}
                          >
                            Schedule Your Appointment
                          </a>
                        </div>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-1  newcard2 gMobileCard">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5" id="cimg">
                          <img
                            className="img-fluid mx-auto cimg"
                            src="./img/geneticspage/pregnant.png"
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <h5 className="card-title">Pregnant</h5>
                        <p className="card-text">
                          Understanding all the options of prenatal testing
                          during a pregnancy can be overwhelming. Our genetic
                          experts can help identify and interpret risks for an
                          inherited disorder and suggest the best prenatal
                          testing options for you.
                        </p>
                        <div className="container1  gc-help-button-wrap text-center my-5 pt-5">
                          <a
                            className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center"
                            onClick={this.toBookSession}
                            role="button"
                            style={{
                              backgroundColor: "#4f5be7",
                              color: "white"
                            }}
                          >
                            Schedule Your Appointment
                          </a>
                        </div>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6 ">
                  <div className="card card-1 newcard3 gMobileCard">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5" id="cimg">
                          <img
                            className="img-fluid mx-auto cimg"
                            src="./img/geneticspage/genetics-cancer.png"
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <h5 className="card-title">Cancer</h5>
                        <p className="card-text">
                          At times genetic variations can put people at a higher
                          risk of developing certain cancers. Based on your
                          family history, our Genetic counsellors can help
                          assess whether genetic testing is appropriate for you.
                          The counsellors will will also help you interpret and
                          explain the results of a test, and based on your
                          report, guide you on next steps.
                        </p>
                        <div className="container1  gc-help-button-wrap  text-center my-5  ">
                          <a
                            className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center"
                            onClick={this.toBookSession}
                            role="button"
                            style={{
                              backgroundColor: "#4f5be7",
                              color: "white"
                            }}
                          >
                            Schedule Your Appointment
                          </a>
                        </div>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-1 newcard2 gMobileCard">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5" id="cimg">
                          <img
                            className="img-fluid mx-auto cimg"
                            src="./img/geneticspage/genetics-heartdisease.png"
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm ">
                        <h5 className="card-title">Heart Disease</h5>
                        <p className="card-text">
                          For people with a personal or family history of
                          cardiovascular conditions, Genetic Counselling can
                          help determine if genetic testing could be beneficial
                          and provide guidance on test results.
                        </p>
                        <div className="container1 gc-help-button-wrap  text-center my-5 pt-5">
                          <a
                            className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center"
                            onClick={this.toBookSession}
                            role="button"
                            style={{
                              backgroundColor: "#4f5be7",
                              color: "white"
                            }}
                          >
                            Schedule Your Appointment
                          </a>
                        </div>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default GeneticCounsellingCarouselCards3;
