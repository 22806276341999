import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class CancerCarouselCards3 extends Component {
  constructor(props) {
    super(props);
  }

  toBookSession() {
    window.location.href = "/book-counselling";
  }
  toOrder() {
    window.location.href = "/product-page";
  }

  render() {
    return (
      <div
        id="carouselExampleControls2"
        className="carousel slide"
        data-ride="pause"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className=" card-1 cancerOrderCard">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img
                            className="img-fluid mx-auto"
                            src="./img/cancerpage/learnIllustration-44.png"
                            style={{ height: "100px" }}
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text ">
                          <b>Schedule my genetic counselling session</b>
                        </p>
                        <div className="she-btn text-center mx-auto">
                          <a
                            className="btn btn-outline-primary btn-hover-white rounded-pill text-center "
                            onClick={this.toBookSession}
                            role="button"
                          >
                            {" "}
                            Schedule Now
                          </a>
                        </div>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className=" card-1 cancerOrderCard">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img
                            className="img-fluid mx-auto"
                            src="./img/cancerpage/Box-1.png"
                            style={{ height: "100px" }}
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text">
                          <b>Order my truGeny Test Kit</b>
                        </p>
                        <div className="she-btn  text-center mx-auto">
                          <a
                            className=" btn btn-outline-primary btn-hover-white rounded-pill text-center "
                            onClick={this.toOrder}
                            role="button"
                          >
                            {" "}
                            Order Now
                          </a>
                        </div>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default CancerCarouselCards3;
