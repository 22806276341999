import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class GeneticCounsellingCarouselCards extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard gCard">
                    <div className="card-body text-center ">
                      <div className="card-block circle">
                        <img
                          className="card-img img-fluid text-center p-2 "
                          src="./img/geneticspage/gs-icon1.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">
                        Understand your risk of developing a disease based on
                        your family health history or a genetic test report
                      </p>
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard gCard">
                    <div className="card-body text-center">
                      <div className="card-block circle">
                        <img
                          className="card-img text-center p-2"
                          src="./img/geneticspage/gs-icon2.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">
                        Determine if genetic testing is a good option for you
                      </p>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard gCard">
                    <div className="card-body text-center">
                      <div className="card-block circle ">
                        <img
                          className="card-img text-center  p-2"
                          src="./img/geneticspage/gs-icon3.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">
                        Choose the right genetic test for you
                      </p>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard gCard">
                    <div className="card-body text-center">
                      <div className="card-block circle">
                        <img
                          className="card-img text-center  p-2 "
                          src="./img/geneticspage/gs-icon4.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">
                        Understand your results and take actions accordingly
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default GeneticCounsellingCarouselCards;
