import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import AuthenticatedComponent from './components/AuthenticatedComponent';
import Users from './components/Users';
import Login from './components/Login';
import QuestionsWizard from './components/QuestionsWizard';
import App from './App'
import LandingPage from './components/LandingPage';
import Cancer from './components/Cancer';
import Heart from './components/Heart';
import ServerError from './components/ServerError';
import BookTest from './components/BookTest';
import RiskSummary from './components/RiskSummary';
import NextSteps from './components/NextSteps';
import FamilyHistory from './components/FamilyHistory';
import GeneticCounselling from './components/GeneticCounselling';
import ShareDetails from './components/ShareDetails';
import Faqs from './components/Faqs';

import Listen from './components/know-your-genes';
import ThanksListen from './components/ThanksListen'



import Details from './components/Details';
import BookCounselling from './components/BookCounselling';
import Register from './components/Register';
import ForgotPwd from './components/ForgotPwd';
import ChangePassword from './components/ChangePassword';
import YourReports from './components/YourReports';
import Calender from './components/Calender';
import OrderTrugene from './components/OrderTrugene';
import Loader from './components/Loader';
import ViewReports from './components/ViewReports';
import BasicCalender from './components/BasicCalender';
import HealthHistory from './components/HealthHistory';
import ProductPage from './components/ProductPage';
import AboutUs from './components/AboutUs';
import Help from './components/Help';
import GeneticCounsellingHelp from './components/GeneticCounsellingHelp';
import RiskWelcome from './components/RiskWelcome';
import RiskPage from './components/RiskPage';
import RiskLogin from './components/RiskLogin';
import RiskTq from './components/RiskTq';
import Settings from './components/Settings';
import TermsOfServices from './components/TermsOfServices';
import AllTermsOfServices from './components/AllTermsOfServices';
import AllPrivacyPolicy from './components/AlPrivacyPolicy';
import LoginSuccess from './components/LoginSuccess';
import Activatekit from './components/Activatekit';
import VerifyMobile from './components/VerifyMobile';
import ResetPwd from './components/ResetPwd';
import AllHelp from './components/AllHelp';
import HelpIndividual from './components/HelpIndivisual';
import HelpClinicians from './components/HelpClinicians';
import HelpCounselling from './components/HelpCounselling';
import HelpSecurity from './components/HelpSecurity';
import AllHelpIndivisual from './components/AllHelpIndivisual';
import AllHelpClinicians from './components/AllHelpClinicians';
import AllHelpCounselling from './components/AllHelpCounselling';
import AllGeneticCounsellingHelp from './components/AllGeneticCounsellingHelp';
import AllHelpSecurity from './components/AllHelpSecurity';
import ActivatekitSuccess from './components/ActivatekitSuccess';
import PaymentSuccess from './components/PaymentSuccess';
import Razorpay from './components/Razorpay';
import ThankYou from './components/ThankYou';
import PrivacyPolicy from './components/PrivacyPolicy';
import ActivateKitThankYou from './components/ActivateKitThankYou';
import BBPaymentSuccess from './components/BBPaymentSuccess';
import FamilyPlanning from './components/familyPlanning'

import CliniciansForgotPwd from './components/CliniciansForgotPwd';
import CliniciansVerifyMobile from './components/CliniciansVerifyMobile';
import CliniciansResetPwd from './components/CliniciansResetPwd';
import CliniciansRegister from './components/CliniciansRegister';
import CliniciansLogin from './components/CliniciansLogin';
import CliniciansAboutUs from './components/CliniciansAboutUs';
import CliniciansHelp from './components/CliniciansHelp';
import CliniciansHelpIndividual from './components/CliniciansHelpIndivisual';
import CliniciansHelpClinicians from './components/CliniciansHelpClinicians';
import CliniciansHelpCounselling from './components/CliniciansHelpCounselling';
import CliniciansGeneticCounsellingHelp from './components/CliniciansGeneticCounsellingHelp';
import CliniciansHelpSecurity from './components/CliniciansHelpSecurity';
import CliniciansTermsOfServices from './components/CliniciansTermsOfServices';
import CliniciansPrivacyPolicy from './components/CliniciansPrivacyPolicy';
import CliniciansTests from './components/CliniciansTests';
import CliniciansHeartTest from './components/CliniciansHeartTest';
import CliniciansCancerTest from './components/CliniciansCancerTest';
import CliniciansRefer2 from './components/CliniciansRefer2';
import CliniciansRefer1 from './components/CliniciansRefer1';
import CliniciansMedicalSpecialities  from './components/CliniciansMedicalSpecialities';
import CliniciansWork from './components/CliniciansWork';
import CliniciansGeneticCounselling  from './components/CliniciansGeneticCounselling';
import Clinicians from './components/Clinicians';
import CliniciansError from './components/CliniciansError';
import { getUser } from './components/UserType';
import ErrorIndividual from './components/ErrorIndividual';
import Redirect from 'react';




const user=getUser();

const Router = () => (
  
     <div>
          <BrowserRouter>
               <Switch>

                    <Route path="/" component={App} exact></Route>
                    <Route path="/landingpage" component={LandingPage} exact></Route>
                    <Route path="/cancer" component={Cancer}></Route>
                    <Route path="/heart" component={Heart}></Route>
                    <Route path="/error" component={ServerError}></Route>
                    <Route path="/know-your-genes" component={Listen}  exact  strict />
                    <Route path="/ThanksListen" component={ThanksListen}/>


                    <Route path="/risksummary" component={RiskSummary}></Route>
                    <Route path="/nextsteps" component={NextSteps}></Route>
                    <Route path="/familyhistory" component={FamilyHistory}></Route>
                    <Route path="/genetic-counselling" component={GeneticCounselling} exact  strict />
                    <Route path="/sharedetails" component={ShareDetails}></Route>
                    <Route path="/faqs" component={Faqs}></Route>
                    <Route path="/details" component={Details}></Route>
                    <Route path="/register" component={Register} ></Route>
                    <Route path="/forgotpwd" component={ForgotPwd} ></Route>
                    <Route path="/changepwd" component={ChangePassword} ></Route>
                    <Route path="/loader" component={Loader} ></Route>ErrorIndividual
                    
                   
                
               
              
                    <Route path="/about-us" component={AboutUs}/>
                    <Route path="/help" component={Help}/>
                    <Route path="/genetic-help" component={GeneticCounsellingHelp}/>
                    <Route path="/risk-welcome" component={RiskWelcome}/>
                    <Route path="/risk-page" component={RiskPage}/>
                    <Route path="/risk-login" component={RiskLogin}/>
                    <Route path="/risktq" component={RiskTq}/>
                    <Route path="/termsofservices" component={TermsOfServices}/>
                    <Route path="/al_termsofservices" component={AllTermsOfServices}/>
                    <Route path="/al_privacy_policy" component={AllPrivacyPolicy}/>
                    <Route path="/varify-mobile" component={VerifyMobile}/>
                    <Route path="/varify-mobile-reset-pwd" component={ResetPwd}/>
                    <Route path="/help_individual" component={HelpIndividual}/>
                    <Route path="/help_clinicians" component={HelpClinicians}/>
                    <Route path="/help_security" component={HelpSecurity}/>
                    <Route path="/help_counselling" component={HelpCounselling}/>
                    <Route path="/logout" component={ThankYou}></Route>
                    <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
                    <Route path="/family-planning" component={FamilyPlanning}></Route>

                   
                   
                   
                    {/*clinicians*/}
                    <Route path="/clinicians_forgot-pwd" component={CliniciansForgotPwd} ></Route>
                    <Route path="/clinicians_varify-mobile" component={CliniciansVerifyMobile}/>
                    <Route path="/clinicians_varify-mobile-reset-pwd" component={CliniciansResetPwd}/>
                    <Route path="/clinicians_register" component={CliniciansRegister} ></Route>
                    <Route path="/clinicians_login" component={CliniciansLogin} ></Route>
                    <Route path="/clinicians_about-us" component={CliniciansAboutUs}/>
                    <Route path="/clinicians_help" component={CliniciansHelp}/>
                    <Route path="/clinicians_help_individual" component={CliniciansHelpIndividual}/>
                    <Route path="/clinicians_help_clinicians" component={CliniciansHelpClinicians}/>
                    <Route path="/clinicians_help_counselling" component={CliniciansHelpCounselling}/>
                    <Route path="/clinicians_genetic-help" component={CliniciansGeneticCounsellingHelp}/>
                    <Route path="/clinicians_help_security" component={CliniciansHelpSecurity}/>
                    <Route path="/clinicians_termsofservices" component={CliniciansTermsOfServices}/>
                    <Route path="/clinicians_privacy-policy" component={CliniciansPrivacyPolicy}/>
                    <Route path="/clinicians_error" component={CliniciansError}/>
                    <Route path="/clinicians" component={Clinicians} />
                    <Route path="/login" component={Login} ></Route>
                    <Route path="/register" component={Register} ></Route>
                       



                    <AuthenticatedComponent>
                       {user==="CUSTOMER"? <Switch>
                         <Route path="/your-reports" component={YourReports} ></Route>
                         <Route path="/product-page" component={ProductPage}></Route>
                         <Route path="/activate-thankyou" component={ActivateKitThankYou}></Route>
                 

                          <Route path="/health-history" component={HealthHistory}></Route>

                       
                         <Route path="/questions" component={QuestionsWizard}></Route>
                         <Route path="/bookTest" component={BookTest}></Route>
                         <Route path="/loginSuccess" component={LoginSuccess}></Route>
                        
                         <Route path="/al_help" component={AllHelp}></Route>

                         <Route path="/user" component={Users}></Route>
                         <Route path="/dashboard" component={Dashboard}></Route>
                         <Route path="/book-counselling" component={BookCounselling}></Route>
                         <Route path="/calender" component={Calender}></Route>
                         <Route path="/ordertrugene" component={OrderTrugene}></Route>
                         <Route path="/view-reports" component={ViewReports}></Route>
                         <Route path="/activate-kit" component={Activatekit}></Route>
                         <Route path="/activate-success" component={ActivatekitSuccess}></Route>
                         <Route path="/payment-success" component={PaymentSuccess}></Route>
                         <Route path="/bb-payment-success" component={BBPaymentSuccess}></Route>

                         <Route path="/settings" component={Settings}/>

                         
                         <Route path="/basic" component={BasicCalender}></Route>
                         <Route path="/all_help_indivisual" component={AllHelpIndivisual}></Route>
                         <Route path="/al_help_clinicians" component={AllHelpClinicians}></Route>
                         <Route path="/al_help_counselling" component={AllHelpCounselling}></Route>
                         <Route path="/al_help_genetic_test" component={AllGeneticCounsellingHelp}></Route>
                         <Route path="/al_help_security" component={AllHelpSecurity}></Route>
                         <Route path="/razorpay" component={Razorpay}></Route>
                         </Switch>:""}
                         {/**Clinicians */}
                        {user==="CLINICIANS"?<Switch>
                        <Route path="/clinicians_tests" component={CliniciansTests}/>

                         <Route path="/clinicians_heart-test"  component={CliniciansHeartTest}/>
                         <Route path="/clinicians_cancer-test"    component={CliniciansCancerTest}/>
                         <Route path="/clinicians_refer1" component={CliniciansRefer1}/>
                         <Route path="/clinicians_refer2" component={CliniciansRefer2}/>
                         <Route path="/clinicians_medical" component={CliniciansMedicalSpecialities}/>
                         <Route path="/clinicians_work" component={CliniciansWork}/>

                         <Route path="/clinicians_genetic-counselling" component={CliniciansGeneticCounselling}/>


                        </Switch>:''}


                    </AuthenticatedComponent>
               </Switch>
          </BrowserRouter>

     </div>
);

export default Router;