import React from 'react';
import Cookies from 'js-cookie';
import CommonHeder from './CommonHeder';
import MobileFooter from './MobileFooter';

import Currency from 'react-currency-formatter';
import axios from '../axios/axios'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from './Logo.png'
import SubFooter from './SubFooter';
import { Link ,Redirect} from 'react-router-dom';

class PaymentSuccess extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            dropdownOpen: false,
            productId:'',
            lastorderHistory:[],
            userData: [],
            productDetails:[] ,
            redirect: false,
            sessionType:""         
          }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
        this.toPrintInvoice=this.toPrintInvoice.bind(this);
        this.orderHistory = this.orderHistory.bind(this);

    }
    toggleMenu() {
        this.setState({ menu: !this.state.menu })
    }
    toggle() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })

    }
    logout() {
        //localStorage.removeItem("username");
        //localStorage.removeItem("jwt-token");
        Cookies.remove('username');
        Cookies.remove('jwt-token');
        this.props.history.push('/');
    }

  componentDidMount(){
this.orderHistory()
        axios.get('cartservice/'+Cookies.get("userId")+'/orderHistoryForProduct', 
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
        ).then((response) => {
           if(response.data){this.setState({lastorderHistory:response.data},()=>{})}
           axios.get('productservice/findByProduct/'+response.data.products[0].productId, 
           { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
           ).then((response) => {
              if(response.data){this.setState({productDetails:response.data},()=>{})}
             }).catch(error => {});
          }).catch(error => {});

          axios.get('/users/user/' + Cookies.get('userId'), {
            headers: { Authorization: `Bearer ${Cookies.get('jwt-token')}` }
            , 'Accept': 'application/json',
            'Content-Type': 'application/json'
          })
            .then(response => {
              this.setState({
               
               userData:response.data
      
              })
      
            })
            .catch(function (error) {
             
            });

            setTimeout(() => this.setState({ redirect: true }), 12000)

  }

  orderHistory(){
    axios
    .get(
      "cartservice/" +
        Cookies.get("userId") +
        "/lastOrderHistory",
      { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
    )
    .then(response => {
       if (response.data.orderId) {
        this.setState(
          { sessionType: "pre"},
          () => {console.log("Check",response.data.orderId)}
        );}
    })
    .catch(error => {});
  }

  toBookSession(){
    window.location.href="/book-counselling"
  }
  toPrintInvoice(){
    var doc=new jsPDF("p", "mm", "a4");
    var img = new Image();
    img.src =Logo;
    doc.addImage(img, 'png', 90, 20, 25, 25)
    doc.setFontSize(12);
    doc.setFontStyle("bold")
    doc.text(100,60,"YOUR TEST ORDER SUMMARY",'center');
    doc.setFontSize(10);
    doc.setFontStyle("normal")
   
    doc.text(150,70,"Date: "+this.state.lastorderHistory.createdDate)

    doc.autoTable({
      styles: {},
      theme: 'grid',
      columnStyles: {0: {halign: 'left'}}, // Cells in first column centered and green
      margin: {top: 80},
      head: [['Shipping Method', 'Payment Type', 'Product Name','Quantity','GST Amount','Price','Net Amount']],
      body: [[this.state.lastorderHistory.shippingMethod,this.state.lastorderHistory.paymentType,this.state.productDetails.productName,this.state.lastorderHistory.products[0].quantity,this.state.lastorderHistory.products[0].gstAmt,this.state.lastorderHistory.products[0].price,this.state.lastorderHistory.netAmount]]
      })
      doc.text(15,128,"SHIPPING ADDRESS");
      doc.text(15,135,"--------------------------------");
      doc.text(15,140,"Name: "+this.state.userData.address[0].user);
      doc.text(15,145,"Address line: "+this.state.userData.address[0].address1);
      doc.text(15,150,"Address line 2: "+this.state.userData.address[0].address2);
      doc.text(15,155,"City: "+this.state.userData.address[0].city);
      doc.text(15,160,"Pincode: "+this.state.userData.address[0].pincode);
    doc.save("invoice.pdf");
    //this.props.history.push('/view-reports');
  }
  render() {

 
    return (
      this.state.redirect?<Redirect to="/your-reports"/>:
      <div>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
      <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
      <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
      {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
      <title>trueGene</title>
      <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
      <link rel="stylesheet" href="./css/fonts.css" />
      <link rel="stylesheet" href="stylesheet_custom.css" />

      <style dangerouslySetInnerHTML={{__html: "\n      .background-pay-card{\n        background: url(./img/registration/3-layers.png) no-repeat center;-webkit-background-size: cover;\n        -moz-background-size: cover;\n        -o-background-size: cover;\n        background-size: cover;\n        width:100%;\n        /* height:500px; */\n      }\n      .checked {\n  color: orange;}\n      " }} />
      {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
      <link rel="stylesheet" href="./styleinline.css" />
      <link rel="stylesheet" href="./style-mobile2.css" />
      <link rel="stylesheet" href="style-mobile.css" />

      <CommonHeder category="product-page"></CommonHeder>
      <div style={{paddingBottom: '76px'}} className="padding22" />

      <div style={{marginTop: '8rem'}} className="margin"></div>
      <div className="container " style={{boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', borderRadius: '40px', backgroundColor: '#eff7ff'}}>
        <div className="row">
          <div className="col-sm-6 col-lg-8 background-pay-card d-flex justify-content-between py-0 px-0" style={{backgroundColor: '#ffffff'}}>
            <div className="mx-auto" style={{paddingTop: '180px'}}>
              {/* <h1 style="font-size: 36px;color:#2c333c;font-weight:900">Thank you!</h1> */}
              <h2 className="  text-center align-self-center roboto-black-36px " style={{fontWeight: 900, maxWidth: '650px'}}>
                Thank you for your purchase of truGeny’s
                <span style={{fontWeight: 400}}>"{this.state.productDetails.productName}"</span></h2>
              <p className="text-center">You will receive a confirmatory email along with a 'what to expect'<br />
                guide document from our support team within the next 24 hours</p>
              <br />
              {/* <button class="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  "style="height:60px;width:210px;border-radius:30px; " type="submit"><i class="fas fa-print"> Print your invoice </i></button> */}
             {this.state.sessionType?"":
                         <Link to={{ pathname: '/book-counselling', state: { session:"free session"} }}> <button className="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  "  style={{height: 'auto', width: 'auto', borderRadius: '30px', background: '#4f5be7', color: 'white'}} type="submit">Schedule Your Free Genetic Counselling Session Now !</button></Link>

            }
              {/* <p class="text-center">Have Questions?</p> */}<br /><br />
              <br />
              <button className="center-block btn btn-hover-white my-2 my-sm-0 center btn-hover-white  " onClick={this.toPrintInvoice} style={{height: 'auto', width: 'auto', borderRadius: '30px', fontSize: '10px'}} type="submit"><i className="fas fa-print"> Print your invoice </i></button>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 p-5">
            <h2>Summary</h2>
            <div className="table-responsive-sm">
            <table className="table" style={{border: 'none'}}>
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>{this.state.productDetails.productName}</b></td>
                <td><b><Currency currency="INR" quantity={this.state.lastorderHistory?this.state.lastorderHistory.products?this.state.lastorderHistory.products[0].price:'':''} /></b></td>
              </tr>
              
              <tr>
                <td><b>GST @{this.state.productDetails.gstPercentage}%</b></td>
                <td><b><Currency currency="INR" quantity={this.state.lastorderHistory?this.state.lastorderHistory.products?this.state.lastorderHistory.products[0].gstAmt:'':''} /></b></td>
              </tr>
              <tr>
                <td><b>Quantity</b></td>
                <td><b>{this.state.lastorderHistory?this.state.lastorderHistory.products?this.state.lastorderHistory.products[0].quantity:'':''}</b></td>
              </tr>
              <tr>
                <td><b>Total</b></td>
                <td><b><Currency currency="INR" quantity={this.state.lastorderHistory.netAmount} /></b></td>
              </tr>
            </tbody>
          </table>
            </div>
            <div id="hearabout">
              <h4 style={{color: '#4f5be7', fontSize: '24px', fontWeight: 700}} /><h4>
                <br />
                <div className="gc-col px-0 col-md-5 mb-3 mt-3" style={{maxWidth: '334px'}}>
                  <select className="form-control roboto-reg-16px " id="exampleFormControlSelect1" type="text" onchange="changeIt()" name="interest" style={{border: 'none', height: '60px'}}>
                    <option className="dropdown-item roboto-reg-16px">How did you hear about us?</option>
                    <option className="dropdown-item roboto-reg-16px">Ad</option>
                    <option className="dropdown-item roboto-reg-16px">Website</option>
                    <option className="dropdown-item roboto-reg-16px">Internet</option>
                    <option className="dropdown-item roboto-reg-16px">Social Media</option>
                    <option className="dropdown-item roboto-reg-16px">Referred by Doctor's</option>
                    <option className="dropdown-item roboto-reg-16px">Referred by Other's</option>
                  </select>
                </div>
                <div className="d-flex mb-3">
                  <h6>  Rate your experience with truGeny</h6>
                  <span className="fa fa-star checked" />
                  <span className="fa fa-star checked" />
                  <span className="fa fa-star checked" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <button className="float-right btn btn-hover-white  my-sm-0 center  " id="con1" onclick="window.location.href = 'Activate-kit.html';" style={{borderRadius: '30px', background: '#4f5be7', color: 'white'}} type="submit"><b>Submit</b></button>
              </h4></div>
          </div>
        </div>
      </div>
      <div style={{paddingTop: '12.25rem'}}>
      </div>
      <SubFooter></SubFooter>
      <MobileFooter></MobileFooter>
      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}
    </div>

    );
  }
}
export default PaymentSuccess;