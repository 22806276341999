import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import axios from '../axios/axios';
import SubFooter from './SubFooter';
import MobileFooter from './MobileFooter';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import moment from 'moment';
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from './Logo.png'
import { Collapse, Button, CardBody, Card } from 'reactstrap';


import "react-datepicker/dist/react-datepicker.css";
class Activatekit extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      isActive: 1,
      activateKit:{},
      loginForm: {},
      errors: {},
      errorMsg:'',
      userData: [],
      name:'',
      email:'',
      phone:'',
      collectedDate:new Date(),
      accessionNumber:'',
      bloodPressure:'',
      bloodPressureMax:'',
      bloodPressureMin:'',
      activateKitStatus:'',
      orderId:'',
      activateKitStatus:'',
      accessNumberData:[],

      accessNumberValidate:false,
      q1: false,
      b1: true,

      q2: false,
      b2: true,

      q3:false,
      b3:true,

      q4:false,
      b4:true,

      q5:false,
      b5:true,

      q6:false,
      b6:true,

      q7:false,
      b7:true,

      q8:false,
      b8:true,

      q9:false,
      b9:true,


    }
      this.changeTab = this.changeTab.bind(this)
      this.handle = this.handle.bind(this)
      this.validateCheckBox = this.validateCheckBox.bind(this)
      this.formOneValidation = this.formOneValidation.bind(this)
      this.saveActivateKit=this.saveActivateKit.bind(this)
      this.formThreeValidation = this.formThreeValidation.bind(this)
      this.checkBoxValue = this.checkBoxValue.bind(this)
      this.toggleModal=this.toggleModal.bind(this)

      this.toggle1 = this.toggle1.bind(this);
      this.toggle2 = this.toggle2.bind(this);
      this.toggle3 = this.toggle3.bind(this);
      this.toggle4 = this.toggle4.bind(this);
      this.toggle5 = this.toggle5.bind(this);
      this.toggle6 = this.toggle6.bind(this);
      this.toggle7 = this.toggle7.bind(this);
      this.toggle8 = this.toggle8.bind(this);
      this.toggle9 = this.toggle9.bind(this);
    }

    toggle1() {
      this.setState(state => ({ q1: !state.q1 }));
      this.setState(state => ({ b1: !state.b1 }));
    
      
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    
    
    }
    
    toggle2() {
      this.setState(state => ({ q2: !state.q2 }));
      this.setState(state => ({ b2: !state.b2 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    
    toggle3() {
      this.setState(state => ({ q3: !state.q3 }));
      this.setState(state => ({ b3: !state.b3 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    
    toggle4() {
      this.setState(state => ({ q4: !state.q4 }));
      this.setState(state => ({ b4: !state.b4 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    toggle5() {
      this.setState(state => ({ q5: !state.q5 }));
      this.setState(state => ({ b5: !state.b5 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    
    toggle6() {
      this.setState(state => ({ q6: !state.q6 }));
      this.setState(state => ({ b6: !state.b6 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    
    toggle7() {
      this.setState(state => ({ q7: !state.q7 }));
      this.setState(state => ({ b7: !state.b7 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
     
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    
    toggle8() {
      this.setState(state => ({ q8: !state.q8 }));
      this.setState(state => ({ b8: !state.b8 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
     
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});

    }
    
    toggle9() {
      this.setState(state => ({ q9: !state.q9 }));
      this.setState(state => ({ b9: !state.b9 }));
    
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
     

    }

    toggleModal() {
      this.setState(prevState => ({
        modalIsOpen: !prevState.modalIsOpen
      }));
    }
  checkBoxValue = (e) => {
    const state = this.state
    if(e.target.checked){
      state[e.target.name] = true;
    }else{
      state[e.target.name] = false;
    }
    this.setState(state);
    // console.log(this.state.defaultCheck1)
  }

  validateCheckBox(){
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields["customCheck"]=== 'false' || fields["customCheck"]===undefined) {
      formIsValid = false;
      errors["customCheck"] = swal("*Please check this informed consent");
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  // handleChange = date => {
    
  //   this.setState({
  //     collectedDate: date
  //   });
  // };
  // changeTab(value) {
  //   if(value === 1) {
  //     this.setState({
  //       isActive: value
  //     })
  //   }
  //   if(value === 2) {
  //     if(this.formOneValidation()){
  //       this.setState({
  //         isActive: value
  //       })
  //     }
  //   }
  //   if(value === 3) {
  //     this.setState({
  //       isActive: value
  //     })
  //   }
  //   if(value === 4) {
  //     let fields = this.state;
  //     let formIsValid = true;
  //     if (!fields["willUse"]) {
  //       formIsValid = false;
  //       swal("Please select Who will use this test?");
  //       return formIsValid;
  //     }
  //     this.setState({
  //       isActive: value
  //     })
  //   }
  // }
  handleChange = date => {
    this.setState({
      accessNumberValidate :false,
    }, () => {})
    
    this.setState({
      collectedDate: date
    });
  };
  changeTab(value) {
    if(value === 1) {
      this.setState({
        isActive: value
      })

    }
    if(value === 2) {
      this.validateAccessNumber()
      if( this.state.accessNumberValidate) {
        if(this.formOneValidation()){
          this.setState({
            isActive: value
          })
        }
      }
    }
    if(value === 3) {
      this.setState({
        isActive: value
      })
    }
    if(value === 4) {
      let fields = this.state;
      let formIsValid = true;
      if (!fields["willUse"]) {
        formIsValid = false;
        swal("Please select Who will use this test?");
        return formIsValid;
      }
      this.setState({
        isActive: value
      })
    }
  }
  validateAccessNumber() {

    axios.get('/cartservice/validate/accessionNumber/'+Cookies.get('userId')+"/"+this.state.accessionNumber,{
    })
    .then(response => {
      // console.log("data----->",response.data)
      this.setState({
        accessNumberData: response.data,
        accessNumberValidate :true,
        
      }, () => {})
    })
    .catch(
      error => {
        if (error.response === undefined) {
          this.props.history.push("/error")
        } else {
          swal(error.response.data.message)
          
        }
      }
    );
  }


  getUserData() {
    axios.get('/users/user/' + Cookies.get('userId'), {
      headers: { Authorization: `Bearer ${Cookies.get('jwt-token')}` }
      , 'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
      .then(response => {
        this.setState({
          userData:response.data,
          name: response.data.firstName,
          email: response.data.email, 
          phone: response.data.phone
        })

      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  handle(event) {
    this.setState({
      accessNumberValidate :false,
    }, () => {})
    if( event.target.value === 'some'){
     
     this.setState({ name:'',
     email:'',
     phone:'',
     userData:'',},()=>{})
    }
    if( event.target.value === 'me') {
       this.getUserData()
    }
    
    const loginForm = this.state
    loginForm[event.target.name] = event.target.value;
    this.setState(loginForm);
  }

    componentDidMount(){

      localStorage.removeItem("name")
    }
    
     saveActivateKit(m){
      if(this.validateCheckBox()) {
      if (this.formThreeValidation()) {
        let data=this.state
        data["activateKitStatus"]=true
        data["collectedDate"]=moment(this.state.collectedDate).format("YYYY-MM-DD")
        let bloodPressure = this.state.bloodPressureMin + "/" + this.state.bloodPressureMax
        data["bloodPressure"]=bloodPressure;
        data["consent"]=m;
        data["orderId"]=this.state.accessNumberData.orderId;
        this.setState({activateKit:data})
        axios.post('users/activeKit', this.state)
      .then((response) => {
        localStorage.setItem("name",response.data.name)
        window.location.href="/activate-thankyou";
      }).catch(
        error => {
         // console.log("rrr", error.response)
          if (error.response === undefined) {
            this.props.history.push("/error")
          } else {
            this.setState({
              errorMsg: error.response.data.message
            })
          }
        }
      );
      }
    }
  }

    formOneValidation() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
      
      if (!fields["collectedDate"]) {
        formIsValid = false;
        errors["collectedDate"] = "*Please enter saliva collected date.";
      }

      if (!fields["accessionNumber"]) {
        formIsValid = false;
        errors["accessionNumber"] = "*Please enter your youtube access number.";
      }

      if (typeof fields["accessionNumber"] !== "undefined") {
        if (!fields["accessionNumber"].match(/^[0|[1-9][0-9]*$/)) {
          formIsValid = false;
          errors["accessionNumber"] = "*Please enter your valid youtube access number.";
        }
      }

      if(fields["bloodPressureMin"] > 500 || fields["bloodPressureMin"] < 0){
        formIsValid = false;
        errors["bloodPressureMin"] = "*Please enter your correct blood pressure minimum value.";
      }

      if(fields["bloodPressureMax"] > 500 || fields["bloodPressureMax"] < 0){
        formIsValid = false;
        errors["bloodPressureMin"] = "*Please enter your correct blood pressure maximum value.";
      }
     
      this.setState({
        errors: errors
      });
      return formIsValid;
    }

    formThreeValidation() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
    if(fields["willUse"] === "some") {
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "*Please enter your first name.";
      }
      if (fields["name"]==="") {
        formIsValid = false;
        errors["name"] = "*Please enter your first name.";
      }
      if (typeof fields["name"] !== "undefined") {
        if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["name"] = "*Please enter alphabet characters only.";
        }
        if (!fields["email"]) {
          formIsValid = false;
          errors["email"] = "*Please enter your email address";
        }
    
        if (typeof fields["email"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = "*Please enter valid email address";
          }
        }
  
        if (!fields["phone"]) {
          formIsValid = false;
          errors["phone"] = "*Please enter your mobile no.";
        }
    
        if (typeof fields["phone"] !== "undefined") {
          if (!fields["phone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["phone"] = "*Please enter valid mobile no.";
          }
        }
      }
    }

     if (this.state.customCheck === 'false' ||this.state.customCheck === undefined) {
       formIsValid = false;
      errors["customCheck"] = "*Please accept terms and conditions";
     }
    
      this.setState({
        errors: errors
      });
      return formIsValid;

    }



    render(){
      let styles;
      let contactstyles;
      if(this.state.modalIsOpen){
        styles = {
          display: "block",paddingRight:'17px'
        };
      }else{ styles={display: "none",}
  
      }
      return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link rel="stylesheet" href="./css/bootstrap/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <link rel="stylesheet" href="./css/bootstrap/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <link href="https://www.jqueryscript.net/css/jquerysctipttop.css" rel="stylesheet" type="text/css" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/jquery.calendar.css" />
        <style dangerouslySetInnerHTML={{__html: "\n  .hello {\n    /* margin-top: 6rem; */\n    padding:0px;\n    box-shadow: 0 17px 73px rgba(0, 0, 0, 0.57);\n    border-radius: 40px;\n    background-color: #eff7ff;\n    \"\n\n  }\n\n  .title-font-modal {\n    width: 514px;\n    height: 36px;\n    color: #2c333c;\n    font-family: \"Roboto - Black\";\n    font-size: 36px;\n    font-weight: 400;\n  }\n\n  .title-part-modal {\n    width: 890px;\n    height: 130px;\n    background-color: #eff7ff;\n  }\n\n  .resize-1 {\n    width: 396px;\n    height: 60px;\n    background-color: #ffffff;\n  }\n\n  .textr-box-size {\n    /* Style for \"Input\" */\n    width: 396px;\n    height: 60px;\n    border: 1px solid #ebebeb;\n    background-color: #ffffff;\n  }\n  " }} />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        
        
        
          {/* header */}
          <CommonHeder  category="activate-kit"></CommonHeder>
          {/* end of header */}
          <div style={{paddingBottom: '76px'}} className="padding22" />

          <div style={{marginTop: '8rem'}} className="margin">
            </div>
          {/* -----------------------------------------start book my session------------------------------------------------- */}
          <div className="container hello" style={{backgroundColor: '#ffffff'}}>
            <section className="reasons-sec">
              <div className="container reasons-sec-wrap gr-sec-wrap pr-0 pl-0 pb-0">
                <div className="reasons-main-header-wrap pl-4">
                  <div className= "abc bg-white">
                    <h2 className="mx-3 " style={{fontSize: '2.25rem',  marginTop: '2rem',fontWeight: '900px'}}>Activate your kit</h2>
                    <div style={{paddingBottom: '1rem'}}>
                    </div>
                    <ul className="nav" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active " id="home-tab" data-toggle="tab" href="#home" role="tab" style={{ color: this.state.isActive === 1 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(1)} aria-controls="home" aria-selected="true">BARCODE<i className="fas fa-caret-right" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" style={{ color: this.state.isActive === 2 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(2)}  aria-controls="profile" aria-selected="false">TEST INFO <i className="fas fa-caret-right" /></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" style={{ color: this.state.isActive === 3 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(3)}  aria-controls="messages" aria-selected="false">ACCOUNT DETAILS<i className="fas fa-caret-right" /></a>
                      </li>
                      <li className="nav-item " style={{display: 'none'}}>
                        <a className="nav-link d-none " id="settings-tab" data-toggle="tab" href="#settings" role="tab" style={{ color: this.state.isActive === 4 ? "#4f5be7" : "#2c333c" }} onClick={() => this.changeTab(4)}  aria-controls="settings" aria-selected="false"><i className="mr-2 fas fa-caret-left" />BACK</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div style={{paddingBottom: '2rem'}}>
                </div>
                <div className="tab-content ">
                  <div className={this.state.isActive === 1 ? "tab-pane active" : "tab-pane"} id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="first-sec-started  container d-flex align-items-center py-4" style={{backgroundColor: '#eff7ff'}}>
                      <h2 className="roboto-black-36px pl-4 pr-0 mx-0" style={{color: '#4f5be7'}}>To get started enter the following information from your saliva tube</h2>
                    </div>
                    <div className="row container pl-5" style={{marginTop: '3rem'}}>
                      <div className="col-sm-12 col-lg-6 col-md-6">
                        <h2 className="roboto-bold-30px">Saliva collected on</h2>
                        <div className="d-flex inline">
                        <DatePicker
                        selected={this.state.collectedDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={this.handleChange}
                        minDate={subDays(new Date(), 5)}
                        maxDate={addDays(new Date(), 0)}

                      />
                         
                          {/* <div class="input-group-prepend mb-3 mr-2"style="border:solid 1px #ebebeb;width:200px;height:60px;">
                            <input type="date" placeholder="date"  class="form-control roboto-reg-16px" style="height:57px;border:none" aria-label="Text input with dropdown button" >
                          </div> */}
                          {/* <div class="input-group-prepend mb-3 mr-2"style="border:solid 1px #ebebeb;width:200px;height:60px;">
                            <input type="text" placeholder="Year" class="form-control roboto-reg-16px" style="height:60px;border:none" aria-label="Text input with dropdown button" >
                          </div> */}
                        </div>
                        
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.collectedDate}</div>
                        <h2 className=" roboto-bold-30px mb-2">Enter your tube’s accession number?</h2>
                        <div className="d-flex inline  ">
                          <div className=" input-group-prepend mb-3 ml-0 mr-2 actKitout" >
                            <input type="text" name="accessionNumber" onChange={this.handle}  placeholder="Accession number" className="form-control roboto-reg-16px actKitin"  aria-label="Text input with dropdown button" />
                          </div>
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.accessionNumber}</div>
                        <h2 className="roboto-bold-30px  mb-2">Your most recent blood pressure reading.</h2>
                        <div className="d-flex inline  ">
                        <div className=" input-group-prepend mb-3 ml-0 mr-2 sizetextlbox">
                              <input type="number" name="bloodPressureMin" onChange={this.handle} placeholder className="form-control roboto-reg-16px sizelabelbox" aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.bloodPressureMin}</div>
                            <h1 className="smallme">/</h1><h1>
                              <div className="input-group-prepend mb-3 mr-2 sizetextlbox">
                                <input type="number" name="bloodPressureMax" onChange={this.handle} placeholder className="form-control roboto-reg-16px sizelabelbox" aria-label="Text input with dropdown button" />
                              </div>
                              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.bloodPressureMax}</div>
                            </h1>
                          {/* <div className=" input-group-prepend mb-3 ml-0 mr-2 actKitout" >
                            <input type="text" name="bloodPressure" onChange={this.handle} placeholder="Reading" className="form-control roboto-reg-16px actKitin"  aria-label="Text input with dropdown button" />
                          </div> */}
                        </div>
                        {/* <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.bloodPressure}</div> */}
                      </div>
                      <div className="col-sm-12 col-lg-6 col-md-6">
                        <img className="img-fluid" src="./img/Health-History/kit-1.png" alt="Logo" />
                      </div>
                    </div>
                    <div className="container">
                      <hr />
                    </div>
                    <div className="container d-flex justify-content-end">
                      <div className="input-group-prepend  mb-5 mt-3 mr-5">
                        <button className="btn btn-outline-primary  btn-hover-white " onClick={() => this.changeTab(2)} name="submit" style={{border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px', width: '130px'}}>Continue</button>
                      </div>
                    </div>
                  </div>
                  {/* ----------------------------------------------------schedulde sesseion section start----------------------------------------------------------------------- */}
                  <div className={this.state.isActive === 2 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="profile-tab">
                    <div className="first-sec-started  container d-flex align-items-center py-4 " style={{backgroundColor: '#eff7ff'}}>
                      <h2 className="roboto-black-36px pl-4" style={{color: '#4f5be7'}}>Test info</h2>
                    </div>
                    <div className="first-sec-started  container d-flex align-items-center py-3">
                    </div>
                    <h3 className="roboto-reg-24px pl-5">The sample will be processed for</h3>
                    <div className="container d-flex roboto-bold-30px pl-5">
                      <p className="m-0 roboto-bold-30px">truGeny CARDIO TEST</p>
                      <input className="roboto-bold-30px" style={{border: 'none'}} type="text" id="myText" readOnly />
                      {/* <a href="#" className="roboto-reg-16px d-block pl-1 pt-2">
                        <i className="ml-2 fas fa-edit">Edit</i> </a> */}
                    </div>
                    <div className="container pl-5">
                      <hr />
                      {/* <h2 className="roboto-bold-24px">truGeny Cardio test analyses</h2> */}
                      {/* <p className="roboto-reg-18px">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                      {/* <p className="roboto-reg-18px">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> */}
                    </div>
                    {/* <div className="container">
                      <hr />
                      
                    </div> */}
                    <div className="container d-flex justify-content-end">
                      <div className="input-group-prepend mb-5 mt-3 mr-5">
                        <button className="btn btn-outline-primary  btn-hover-white " name="submit" onClick={() => this.changeTab(3)} style={{border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px', width: '130px'}} type="submit">Continue</button>
                      </div>
                    </div>
                  </div>
                  {/* ----------------------------------------------------review section start----------------------------------------------------------------------- */}
                  <div className={this.state.isActive === 3 ? "tab-pane active" : "tab-pane"}id="messages" role="tabpanel" aria-labelledby="messages-tab">
                    {/* <div class="first-sec-started  container d-flex align-items-center py-4 d-none" style="background-color: #eff7ff;display:none">
                    <h2 class=" d-none roboto-black-36px px-4" style="color: #4f5be7;">Confirm your account details</h2>
              </div> */}
                    <div className="container  pl-5 d-none">
                      <h2 className="roboto-bold-30px  py-3">The sample has been provided by :</h2><br />
                      <div className="container  ">
                        <input type="checkbox" id="self" />
                        <label  htmlFor="customCheck1"><span><p style={{fontSize: '1rem'}}>Me</p></span></label>
                      </div>
                      <div className="container  ">
                        <input type="checkbox" id="fammember"  />
                        <label  htmlFor="customCheck1"><span><p style={{fontSize: '1rem'}}>My Family Member</p></span></label>
                      </div>
                      <div className="d-none form-group learn-text-box mb-3 ml-5" id="daut" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                        <select className="form-control roboto-reg-16px" id="daughters" type="text" placeholder=" Daughter" name="daughters" style={{border: 'none', height: '60px'}}>
                          <option className="dropdown-item roboto-reg-16px">Daughter</option>
                          <option className="dropdown-item roboto-reg-16px">Wife</option>
                          <option className="dropdown-item roboto-reg-16px">Son</option>
                          <option className="dropdown-item roboto-reg-16px">Father</option>
                          <option className="dropdown-item roboto-reg-16px">Mother</option>
                        </select>
                      </div>
                      <div className="container form-control-lg ">
                        <input type="checkbox"  id="customCheck1" />
                        <label  htmlFor="customCheck1"><span><p style={{fontSize: '1rem'}}>My Friend</p></span></label>
                      </div>
                    </div>
                    <div className="first-sec-started  container d-flex align-items-center py-4 d-none" style={{backgroundColor: '#eff7ff'}}>
                      <h2 className="roboto-black-36px px-4" style={{color: '#4f5be7'}}>Your account details</h2>
                    </div>
                    <div className="container  pl-5">
                      <h2 className="roboto-bold-30px  pt-3">The sample has been provided by :</h2>
                      {/* <div className="d-flex inline pt-2">
                        <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                          <input type="text" placeholder=" "  name="providedBy" onChange={this.handle} className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                        </div>
                      </div> */}
                       <div className="col-sm-6 col-lg-8 pt-5">
                       <input type="radio" name="willUse" value="me" onChange={this.handle}  /> This test is for me<br /><br />
                       <input type="radio" name="willUse" value="some" onChange={this.handle}  data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" />This test is for someone else

                       {this.state.willUse ? this.state.willUse === "some" ?
                            <div className="d-flex inline pt-2 pb-3">
                            <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                              <input type="text" name="name" onChange={this.handle}  placeholder="Name" className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.name}</div>
                            <div className="input-group-prepend mb-3 mr-2 d-flex" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                              <input type="email" name="email" onChange={this.handle} placeholder="E-mail Id" className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.email}</div>
                            {/* <a  class="pt-5 mr-3"href=""><i class="ml-2 fas fa-edit">Edit</i></a> */}
                            <div className="input-group-prepend mb-3 mr-2" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                              <input type="text" name="phone" onChange={this.handle} placeholder="Mobile number" className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.phone}</div>
                            {/* <a  class="pt-5 mr-3"href=""><i class="ml-2 fas fa-edit">Edit</i></a> */}
                          </div>
                        : '' : ''}

                        {this.state.willUse ? this.state.willUse === "me" ?

                            <div className="d-flex inline pt-2 pb-3">
                            <div className=" input-group-prepend mb-3 ml-0 mr-2" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                              <input type="text" name="name" onChange={this.handle}  value={this.state.name} readOnly placeholder="Name" className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.name}</div>
                            <div className="input-group-prepend mb-3 mr-2 d-flex" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                              <input type="email" name="email" onChange={this.handle}  value={this.state.email} readOnly placeholder="E-mail Id" className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.email}</div>
                            {/* <a  class="pt-5 mr-3"href=""><i class="ml-2 fas fa-edit">Edit</i></a> */}
                            <div className="input-group-prepend mb-3 mr-2" style={{border: 'solid 2px #ebebeb', width: '200px', height: '60px'}}>
                              <input type="text" name="phone" onChange={this.handle}  value={this.state.phone} readOnly placeholder="Mobile number" className="form-control roboto-reg-16px" style={{height: '56px', border: 'none'}} aria-label="Text input with dropdown button" />
                            </div>
                            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.phone}</div>
                            {/* <a  class="pt-5 mr-3"href=""><i class="ml-2 fas fa-edit">Edit</i></a> */}
                          </div>
                            
                        : '' : ''}
                       </div>
                    </div>
                    {/* <div className="first-sec-started  container d-flex align-items-center py-4 mb-5" style={{backgroundColor: '#eff7ff'}}>
                      <h2 className="roboto-black-36px px-4" style={{color: '#4f5be7'}}>Status of Self Reported information</h2>
                    </div> */}
                    {/* <div className="container  pad-l-2rem pb-0 mb-0"> */}
                      <form>
                        {/* <div className="row mb-2">
                          <div className="col-sm-12 col-md-4 col-lg-6">
                            <h1 className="roboto-black-30px">Health History</h1>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                          {this.state.healthHistoryStatus?<h1 style={{ color: '#4f5be7'}} className="roboto-bold-24px ">Complete</h1>:  <h1 className="roboto-bold-24px " style={{color: '#de7f52'}}>Pending</h1>}
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <button className="float-right btn btn-hover-white my-2 my-sm-0 text-uppercase " style={{height: '55px', width: '200px', borderRadius: '30px'}} type="button" onClick={this.toHealthHistory}><b>UPDATE NOW</b></button>
                          </div>
                        </div>
                        <div className="container  mt-2">
                          <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                        </div> */}
                        {/* <div className="row mb-2">
                          <div className="col-sm-12 col-md-4 col-lg-6">
                            <h1 className="roboto-black-30px">Health Records</h1>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                          {this.state.healthRecordsStatus?<h1 style={{ color: '#4f5be7'}} className="roboto-bold-24px ">Complete</h1>:  <h1 className="roboto-bold-24px " style={{color: '#de7f52'}}>Pending</h1>}
                           
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <button className="float-right btn btn-hover-white my-2 my-sm-0 text-uppercase " type="button" style={{height: '55px', width: '200px', borderRadius: '30px'}} onClick={this.toHealthHistory}><b>UPDATE NOW</b></button>
                          </div>
                          <div className="container  mt-2">
                            <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                          </div>
                        </div> */}
                        {/* <div className="row mb-2">
                          <div className="col-sm-12 col-md-4 col-lg-6">
                            <h1 className="roboto-black-30px">  PreTest Genetic Counselling</h1>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                          {this.state.appointmentDetails?<h1 style={{ color: '#4f5be7'}} className="roboto-bold-24px ">Complete</h1>:  <h1 className="roboto-bold-24px " style={{color: '#de7f52'}}>Pending</h1>}
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-3">
                            <button className="float-right btn btn-hover-white my-2 my-sm-0 text-uppercase " style={{height: '55px', width: '200px', borderRadius: '30px'}} type="button" onClick={this.toBookSession}><b>SCHEDULE NOW</b></button>
                          </div>
                        </div> */}
                        {/* <div class="container  mt-2">
        <hr class="mx-auto" style="height:1px;background-color: #cdcdcd;">
      </div> */}
                        {/* <div class="row mb-2">
        <div class="col-sm-12 col-md-4 col-lg-6">
          <h1 class="roboto-black-30px">Health Records Uploaded</h1>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <h1 class="float-sm-left roboto-bold-24px ">One File</h1>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 clearfix">
          <label class="float-right btn-outline-primary btn-hover-white text-center p-3 text-uppercase"
            style="height:55px;width: 200px;border-radius:30px; ">
            <b>Upload new</b>
            <input type="file" hidden/>
          </label>
        </div>
      </div> */}
                        {/* <input type="text" class="file_input_textbox" readonly="readonly" value='No File Selected'> */}
                        {/* <input type="button" value="Browse" class="file_input_button" /> */}
                        {/* <input type="file" name="uploaded_file" class="file_input_hidden"/> */}
                      </form>
                      {/* </div> */}
                    {/* <div className="container">
                      <hr />
                    </div> */}
                   

                    <div className="container d-flex justify-content-end">
                      <div className="input-group-prepend  mb-5 mt-3 ">
                        <a className="btn btn-primary pt-3" onClick={() => this.changeTab(4)}  style={{color: 'white', background: '#4f5be7', border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px', width: '200px'}}><b>Activate Kit</b></a>
                      </div>
                    </div>
                  </div>
                  {/* ------------------------------------------------------back tab---------------------------------------- */}
                  <div className={this.state.isActive === 4 ? "tab-pane active" : "tab-pane"} id="settings" role="tabpanel" aria-labelledby="settings-tab">
                  <div className="first-sec-started  container d-flex  py-4" style={{backgroundColor: '#eff7ff'}}>
                        <h2 className="roboto-black-36px px-4" style={{color: '#4f5be7'}}>Congratulations {this.state.name}.</h2>
                        {/* <img class="justify-content-end" src="img/Health-History/X_icon.png"/> */}
                      </div>
                      <div className="container pl-5">
                        <h2 className="roboto-bold-30px  pt-3 ">You have now successfully activated your truGeny DNA Kit</h2><br />
                        <ul className="roboto-reg-18px px-3">
                          <li> Please remember to post your DNAT kit</li>
                          <li> You can expect your reports in 6weeks. In the mean time if you have any queries,please feel free to call on 1800-1234-1234</li>
                          <li> If you have not yet filled in your health history or your family tree, we recommend you to do this at the earliest.</li>
                        </ul>
                      </div>
                      <div className="first-sec-started  container d-flex align-items-center py-4" style={{backgroundColor: '#eff7ff'}}>
                        <h2 className="roboto-black-36px px-4 mr-5 pr-5" style={{color: '#4f5be7'}}>Invitation to Participate in Research</h2>
                        <img className="img-fluid align-self-end ml-5 pl-5 d-none" src="img/login/X_icon.png" />
                      </div>
                      <div className="container px-5">
                        {/* <h2 class="roboto-bold-30px  pt-3">You have now successfully activated your truGeny DNA Kit</h2><br> */}
                        <h2 className="roboto-reg-18px pt-3"> We invite you to participate in research to further and advance the science of genetics and health in India.</h2>
                        <ul className="roboto-reg-18px px-3">
                          <li> Voluntary : Participation is voluntary. So whether you give consent or not, your sample will be analysed and you will receive your report.</li>
                          <li> To participate,You are not required provide any further informationand no further work is required from your side.</li>
                          <li> Your data is protected : truGeny does not share your name, contact information or other identifying information with any collaborator.</li>
                          <li> You can decide to withdraw your consent at any time. </li>
                        </ul>
                        <p className="roboto-reg-18px">Read about informed Consent here.<a href="#" data-toggle="modal" onClick={this.toggleModal} data-target=".bd-example-modal-lg">here</a></p>
                        <div className="container ml-3 custom-control form-control-lg custom-checkbox">
                          <input name="customCheck" onChange={this.checkBoxValue} type="checkbox" className="custom-control-input" id="customCheck" />
                          <label className="custom-control-label" htmlFor="customCheck"><span><p style={{fontSize: '1rem'}}>I have read the <a href="#" onClick={this.toggleModal} data-toggle="modal" data-target=".bd-example-modal-lg">informed consent</a></p></span></label>
                        </div>
                      </div>
                      <div className="container">
                        <hr />
                      </div>
                      <div className="container d-flex justify-content-end my-5  ">
                        <div className="input-group-prepend mb-5 mr-3">
                          <button className="btn btn-outline-primary  btn-hover-white " name="submit" style={{border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px'}} type="submit" onClick={()=>this.saveActivateKit(" I do not give Consent.")}> I do not give Consent.</button>
                        </div>
                        <div className="input-group-prepend mb-5 mr-3">
                          <button className="btn btn-outline-primary  btn-hover-white " name="submit" style={{border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px'}} type="submit" onClick={()=>this.saveActivateKit(" I  give Consent.")}> I give consent</button>
                        </div>
                      </div>
                  </div>
                  {/* eo gr-sec-wrap */}
                </div></div></section>

              <div  className={this.state.modalIsOpen?"modal fade bd-example-modal-lg show":"modal fade bd-example-modal-lg"} tabIndex={-1} role="dialog" style={styles} aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" style={{maxWidth: '1300px', margin: 'auto'}}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Data Sharing Consent</h5>
                      <button type="button" onClick={this.toggleModal.bind(this)} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body px-0">
                      <section className="container" id="myData">
                        <h2 className=" " style={{fontSize: '16px!important'}}>truGeny’s mission is to help people access, understand and benefit from the human genome. One of the ways we do this is through our research program. We are asking for an additional level of research participation and consent. We seek your permission to share your de-identified individual-level Genetic &amp; Self-Reported Information with research collaborators outside of truGeny. We collaborate with researchers outside truGeny to study many different topics including genetic factors behind diseases and traits, connections among diseases and traits, human migration and population history, and how people react to their personal genetic information.
                        </h2>
                        <div className=" mt-2">
                          <div className>
                            <div className="card-block  p-2 circle">
                              <div className="px-0 hc-accordian-wrap">
                                <div className="accordion" id="accordionIhc">
                                  <div className="card ">
                                    <div className="card-header" id="headingOne">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q1?'#2C333C':'#4F5BE7'}}>
                                        What am I agreeing to if I consent?
                                        {this.state.b1?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q1}>
                                    <div id="collapseOnesf" className="collapse show" aria-labelledby="headingOnes" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          <p className=" roboto-reg-18px">Giving consent by checking the appropriate box below means that you agree to let truGeny share your de-identified individual-level data with approved researchers outside of truGeny. That is, you agree that we may share your Individual- level Genetic &amp; Self-Reported Information. This includes:</p>
                                        </a>
                                        <p className="roboto-bold-24px pt-3 pb-1">Your genetic data</p>
                                        <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Information you enter into surveys, forms and other features with the truGeny</p>
                                        <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Data you separately authorize us to import for research
                                        </p><p>
                                        </p><p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Your age and ethnicity
                                        </p><p>
                                          {/* -------------------------------------------6th-------------------------------------- */}
                                        </p></div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card ">
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q2?'#2C333C':'#4F5BE7'}}>
                                        What does “de-identified” mean?
                                        {this.state.b2?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q2}>
                                    <div id="collapseOnsse" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          <p className=" roboto-reg-18px">De-identified means that we strip the data of information that could directly identify you (such as name, date of birth, and address) and connect any study information you provide by using a random code (for example a study ID). Any Genetic &amp; Self-Reported Information you allow us to share will be associated with that code, not with your name.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card ">
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q3?'#2C333C':'#4F5BE7'}}>
                                        What does “individual-level” mean?
                                        {this.state.b3?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle3} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle3} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q3}>
                                    <div id="collapseOned" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          <p className=" roboto-reg-18px">Individual-level means the data are related to a single person. This is different from the truGeny Main Research Consent you signed which allows us to share only information that has been combined or “pooled” with data from other research participants.
                                          </p>
                                          <p>Allowing researchers to have access to individual-level data allows them to analyze the data more thoroughly, which may help them make more meaningful scientific discoveries. </p>
                                        </a>
                                      </div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card ">
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q4?'#2C333C':'#4F5BE7'}}>
                                        What can the research collaborators do with my data?
                                        {this.state.b4?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle4} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle4} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q4}>
                                    <div id="collapseOnsew" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          <p className=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p>
                                          <p>We will not share your Genetic &amp; Self-Reported Information for marketing purposes with our collaborators. You can learn more about how we share information for marketing purposes in our Privacy Statement.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card">
                                    <div className="card-header" id="headingTwo">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q5?'#2C333C':'#4F5BE7'}}>
                                        Can I find out who my data is shared with?
                                        {this.state.b5?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle5} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle5} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q5}>
                                    <div id="collapsesTwof" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                                      <div className="card-body">
                                        <a style={{color: '#000'}}>
                                          <p className=" roboto-reg-18px">
                                            For the most part, we won’t be able to contact you every time we would like to share your data. We may ask you to volunteer to provide information or take a survey on a specific topic or for a specific collaboration. In that case we will provide you with that information. Collaborations may result in scientific publications, which will be updated on a participant’s “publications dashboard” (this is a feature available under the “research” section of your account). If your data are used in a publication, it will only be published in summary form (combined data from many individuals), or using very limited, non-identifying information (such comments or feedback participants may consent to release).
                                          </p>
                                          {/* ---------------------------------------------------------------5th--------------------------------------------------------------- */}
                                        </a></div><a style={{color: '#000'}}>
                                      </a></div>
                                      </Collapse><a style={{color: '#000'}}>
                                    </a></div><a style={{color: '#000'}}>
                                  </a><div className="card "><a style={{color: '#000'}}>
                                      <div className="card-header" id="headingOnee">
                                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q6?'#2C333C':'#4F5BE7'}}>
                                          Is my data protected?
                                          {this.state.b6?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle6} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle6} className="fa fa-minus-circle  rounded-circle " /></button>}
                                          </h5>
                                      </div>
                                    </a>
                                    <Collapse isOpen={this.state.q6}>
                                    <div id="sssssssss" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc"><a style={{color: '#000'}}>
                                      </a><div className="card-body border-0"><a style={{color: '#000'}}>
                                        </a><a style={{color: '#000'}}>
                                          {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                          <p>truGeny takes seriously the trust you place in us. We have very strong security measures in place to ensure your data are transferred safely. truGeny will also have agreements with all collaborators regarding the security and storage of data that we share. Although truGeny cannot provide a 100% guarantee that your data will be safe, truGeny policies and procedures minimize the chance that a breach could take place.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card ">
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q7?'#2C333C':'#4F5BE7'}}>
                                        What are the additional benefits?
                                        {this.state.b7?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle7} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle7} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q7}>
                                    <div id="collapseOne10" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                          <p>In addition to the benefits described in the truGeny Main Research Consent, sharing your individual-level data will allow more researchers to study and analyze the data more thoroughly. This may increase the chance that meaningful scientific discoveries are made such as greater understanding of human disease and biology, human populations, and possibly new ways to diagnose or treat diseases. Sometime in the future you, your family or friends may benefit directly or indirectly from the research discoveries made by truGeny or our research partners.</p>
                                          <p>Based on the information you provide and allow us to share, truGeny or our research collaborators may make new discoveries. In the future, those discoveries could lead to new commercial products or services (for example, new drugs, devices, or screening tests). If this happens, these products or services will be the property of the researchers who developed them and you will not receive any compensation.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card ">
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q8?'#2C333C':'#4F5BE7'}}>
                                        What are my options? Can I change my mind?
                                        {this.state.b8?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle8} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle8} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q8}>
                                    <div id="collapseOneaa" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                          <p>Your alternative is not to agree to share your individual data. You can still participate in our research program without sharing your individual-level data.</p>
                                          <p>Choosing not to give consent or withdrawing your consent will not affect your access to your Genetic Information or to the Personal Genetic Service.</p>
                                          <p>You can withdraw your consent to share individual-level data at any time by declining this consent through your account settings. If you want to withdraw from our entire research program, you can decline the truGeny Main Research Consent through your account settings as well. truGeny will prevent your data from being shared after 30 days from receipt of your request (it may take up to 30 days to withdraw your information after you withdraw consent). If you withdraw your consent, any data that has been shared prior to this date cannot be reversed or undone.</p>
                                          <p>Based on the information you provide and allow us to share, truGeny or our research collaborators may make new discoveries. In the future, those discoveries could lead to new commercial products or services (for example, new drugs, devices, or screening tests). If this happens, these products or services will be the property of the researchers who developed them and you will not receive any compensation.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                    </Collapse>
                                  </div>
                                  <div className="card ">
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q9?'#2C333C':'#4F5BE7'}}>
                                        Who do I contact if I have questions?
                                        {this.state.b9?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle9} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle9} className="fa fa-minus-circle  rounded-circle " /></button>}
                                        </h5>
                                    </div>
                                    <Collapse isOpen={this.state.q9}>
                                    <div id="collapseOnef" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                      <div className="card-body border-0">
                                        <a style={{color: '#000'}}>
                                          {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                          <p>If you have general questions and need help with truGeny's service, please go to the following web page:
                                          </p>
                                          <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;truGeny Customer Care
                                          </p><p>
                                          </p><p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Email: support@trugeny.com
                                          </p></a><p><a style={{color: '#000'}}>
                                          </a>
                                        </p></div>
                                    </div>
                                    </Collapse>
                                  </div>
                                </div>
                              </div>
                              {/* eo bs-example */}
                              {/* end of accordian code */}
                            </div>
                          </div>
                          {/* eo card-block */}
                        </div>
                        {/* eo card body */}
                      </section></div>
                    {/* eo card */}
                    {/* eo-heart-accordian-sec-wrap */}
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary" onClick={this.toggleModal.bind(this)}>Acknowledged</button>
                      <button type="button" className="btn btn-secondary" onClick={this.toggleModal.bind(this)} data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            {/*eo gr-sec*/}
          </div>
     
        <div style={{paddingTop: '12.25rem'}}>
        </div>
       <SubFooter></SubFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter></MobileFooter>
      </div>
        )
    }
}
export default Activatekit;