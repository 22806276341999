import React from 'react';
import {withRouter} from 'react-router-dom'
import axios from '../axios/axios';
import swal from 'sweetalert';


class ReferProspect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

        referProspect:{},

        errors:{}
      };
    this.prospectReg = this.prospectReg.bind(this);
    this.prospectReg2=this.prospectReg2.bind(this);
   
  }
  prospect = (e) => {
    const prospectForm = this.state.referProspect
    prospectForm[e.target.name] = e.target.value;
    this.setState(prospectForm);
    console.log("ref prospect....",this.state.referProspect)
  }
  
  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["pemail"]) {
        formIsValid = false;
        errors["pemail"] = "*Please enter your email address";
    }

    if (typeof fields["pemail"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["pemail"])) {
            formIsValid = false;
            errors["pemail"] = "*Please enter valid email address";
        }
    }

    if (!fields["pphone"]) {
        formIsValid = false;
        errors["pphone"] = "*Please enter your mobile no.";
        
    }

    if (typeof fields["pphone"] !== "undefined") {
        if (!fields["pphone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["pphone"] = "*Please enter valid mobile no.";
        }
    }
    this.setState({
        errors: errors
    });
    return formIsValid;

}
  prospectReg(e) {
    e.preventDefault();

  //  if (this.validateForm()) {

        axios.post('users/prospect', this.state.referProspect)
            .then((response) => {
                
                swal("Thank you for your interest. We will get back to you soon...")
                window.location.href="/clinicians_refer2";

                // this.setState({
                //     sucessMsg:"Thank you for your interest. We will get back you soon..."
                // })
               // alert("Thanks for your intersr. We will get back you soon")   
            
 
            }).catch(error => {
              if(error.response===undefined){
                this.props.history.push("/clinicians_error")
              }else{
                this.setState({
                    errorMsg: error.response.data.message
                })  
              }
               
            });
            e.target.reset();
            
            this.prospectReg2(e);
    //}
   
  
}
prospectReg2(e){
  e.preventDefault();
  e.target.reset();
}
  render() {
      return (
        <div>

<div style={{paddingBottom: '6rem'}}>
          <h2 className="text-center roboto-black-40px">Use this form to refer your patients</h2>
        </div>
        {/* Start of Genetic Counsell Help cards  */}
        <div className="gc-form-card container ">
          <div className="gc-form-card-wrap  shadow-lg">
            <div className=" gc-form-card-top">
              <h2 className="text-center">Referring Physician information</h2>
              {/* <p class=" gc-form-subtitle text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit,sed do eiusmod tempor incididunt ut labore et dolore magna </p> */}
              {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
            </div>{/* gc-form-card-top */}
            <div className="gc-form-card-btm">
            <form className="gc-form needs-validation" onSubmit={this.prospectReg2}>                <div className="form-row justify-content-center">
                                    
                </div>{/* end of form-row */}
                
                <div className="form-row justify-content-center">
                  
                  <div className="gc-col col-md-5 mb-3">
                    <div className="input-group">
                      <textarea className="form-control m-0 " name="pmessage"  onChange={this.prospect} id="validationTextarea" rows={1} placeholder="Message" maxLength="500" required defaultValue={""} />
                    </div>
                  </div>
                </div>{/* end of form-row */}
                <div className="wrapper pt-3 mt-4 mb-5">
                  {/* <button class="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center"style="height:60px;width:210px;border-radius:30px;" type="submit"><b>Schedule Now</b></button> */}
                </div>
              </form>
            </div>{/* end of gc-form-card-btm */}
            <h2 className="text-center roboto-black-40px p-5">Information about your patient</h2>
            <div className="pgc-form-card-btm">
              <form className="gc-form needs-validation" onSubmit={this.prospectReg}>
                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                    <div className="input-group">
                      <input type="text" className="form-control m-0" name="pname"  onChange={this.prospect} id="validationTooltipEmailPrepend" placeholder="Patient's Name" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                        Please enter a valid E-mail ID.
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i1.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" name="pemail"  onChange={this.prospect} placeholder="Email Id" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                      <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row justify-content-center">
                  <div className=" gc-col col-md-5 mb-3">
                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltipEmailPrepend" name="pphone"  onChange={this.prospect} placeholder="Contact number" aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                      Contact number
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/mob.png" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="gc-col col-md-5 mb-3">
                    <div className="input-group">
                      <input type="text" className="form-control m-0" id="validationTooltip03" onChange={this.prospect} name="condition" placeholder="Condition" aria-describedby="validationTooltip03" required />
                      <div className="valid-tooltip">
                        Looks good!
                      </div>
                      <div className="input-group-prepend">
                        <span style={{borderRight: '1px solid #ebebeb'}} className="input-group-text" id="validationTooltip03"><img src="img/clinicians/refer/1/doc.png" /></span>
                      </div>
                    </div>
                  </div>
                </div>
               
               
                
               
                <div className="wrapper pt-3 mt-4 mb-5 pb-5">
                  <button className="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} name="submit" type="submit"><b>SUBMIT</b></button>
                </div>
              </form></div>
              <div className="form-row justify-content-center mt-5">
              <div className=" gc-col col-md-5 mb-3">
                
              </div>
              <div className="gc-col col-md-5 mb-3 mt-4">
                <img src="img/clinicians/refer/1/error.png" />
              </div>
            </div>
          </div>
        </div>
        
        </div>

      
        
      );
  }
    }
    


export default withRouter(ReferProspect);
