import React from 'react';

import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import GeneticCounsellingCarouselCards from './GeneticCounsellingCarouselCards';
import GeneticCounsellingCarouselCards2 from './GeneticCounsellingCarouselCards2';
import GeneticCounsellingCarouselCards3 from './GeneticCounsellingCarouselCards3';
import StickyContactMenu from './StickyContactMenu';
import MetaTags from 'react-meta-tags';



class GeneticCounselling extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toLogin() {
        window.location.href="/login";
    }

    
    toGeneticHelp() {

       window.location.href="/genetic-help";
      }
      toBookSession(){
        window.location.href="/book-counselling"
      }
      toOrder(){
        window.location.href="/product-page"
      }
  render() {
    return (
      

      <div>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
       
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        <link rel="stylesheet" href="newsticky.css" />
        <MetaTags>
        <title>Genetic Testing For Cancer Risk | Hereditary Cancer Test - truGeny</title>
		<meta name="description" content="truGeny offers genetic counselling services to help people understand complex information about their DNA."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Genetic Testing For Cancer Risk | Hereditary Cancer Test - truGeny"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny offers genetic counselling services to help people understand complex information about their DNA."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
       
        

        <style dangerouslySetInnerHTML={{__html: "\nhtml{\n  scroll-behavior: [ auto | smooth ];\n}\n.font-nav{\n  padding-right:15px;\nfont-size:15px;\ncolor:gray;\nfont-weight:300;\n\n}\n.img-size-custom{\n   height:70px;\n   width:120px;\n}\n\n.img-11{\n  height:48px;\n  /* width:120px; */\n}\n.nav-tab-1{\n    padding-top:5px!important;\n    padding-bottom:5px!important;\n    color:#FF3C3C;\n    font-size:15px;\n\n}\n@media (max-width: 767.98px) {\n  .font-nav{\n    padding-right:4px;\n  font-size:10px;\n  color:gray;\n\n  }\n  .nav-tab-1{\n      padding-top:3px!important;\n      padding-bottom:3px!important;\n      color:#FF3C3C;\n\n  }\n   }\n   .nav-active1{\n     font-weight:700;\n     color:#4f5be7;\n   }\n   .roboto-black-28pxx{\n     font-size:28px!important;\n   }\n    color:#4f5be7;\n   }\n\n" }} />

        
        <NewMainHeader category="genetic-counselling">
        </NewMainHeader>
        <StickyContactMenu ></StickyContactMenu>


        {/* eo-container */}{/* eo-container */}
        
        <section className="genetic-Counseling-main-sec genetics-banner-sec-wrap">
          <div className=" genetics-banner-sec ">
            <div className="container pt-4 mt-2">
              <div className="d-flex" style={{maxWidth: '480px'}}>
                <h1 className="roboto-black-28pxx bTextCenterG">Begin your journey to good health with a <span className="spanify"> conversation</span></h1>
                {/* <p class="text-justify mt-3 mb-4  pb-0">Knowing about your genetic risks for developing heart conditions can help you to work with your Doctor to develop a personalised plan designed to prevent heart conditions.</p> */}
              </div>
              <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0 text-uppercase mx-auto px-auto" style={{border: '1px solid #4f5be7', width: '12.5rem', fontSize: '14px', color: 'white', backgroundColor: '#4f5be7'}} onClick={this.toBookSession}><b>Schedule Now</b></button>
              {/* eo-container*/}
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#sec1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        
        
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main" id="sec1">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
            <h2 className=" text-center genetics-h2 ">Get expert guidance to learn about your DNA and your Health Risks
            </h2>
            <p className="genetics-h2-p">Make well informed healthcare decisions</p>

            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center ">
                <img className="img-fluid mx-auto" src="./img/geneticspage/genetics-informed.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Be Informed</h3>
                <p className="heartdisease-content">Most diseases have a genetic cause.</p>
                <p className="heartdisease-content">Our genetic counsellors can help evaluate if you’re at risk, and if genetic testing may be right for you.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center ">
                <img className="img-fluid mx-auto" src="./img/geneticspage/genetics-proactive.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Be Proactive about your Health. Get Genetic Counselling.</h3>
                <p className="heartdisease-content">Our expert genetic counsellors will help you evaluate your risks and guide you to make the right choices for better health. The duration of a counselling session will be for 30 minutes. At the end of the session, you will receive an easy-to-understand summary and guidance on the next steps to follow.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center ">
                <img className="img-fluid mx-auto" src="./img/geneticspage/genetics-health-risks.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Learn about your genes and your health risks from the comfort of your home</h3>
                <p className="heartdisease-content">With truGeny’s flexible tele-genetics platform, you can select for a phone or an online video consultation and schedule a timing of your convenience.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* Start of Genetic Counsell Help cards  */}
        <div className="gc-help-main-sec">
          <div className="container gc-help-main-wrap">
          <div className="agsp">
            <h2 className="text-center">A Genetic Counselling session can help you:</h2>
            </div>
            <div className="container gc-help-card-main-wrap mx-auto mt-5  mb-5">
              <div className="card-deck" id="hide">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg  mb-4 r4  ">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center p-2 " src="./img/geneticspage/gs-icon1.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Understand your risk of developing a disease based on your family health history or a genetic test report

                      </p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg mb-4 r4 ">
                    <div className="card-body text-center">
                      <div className="card-block p-2 circle">
                        <img className="card-img text-center p-2" src="./img/geneticspage/gs-icon2.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Determine if genetic testing is a good option for you</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg  mb-4 r4 ">
                    <div className="card-body text-center">
                      <div className="card-block mt-2 p-2 circle ">
                        <img className="card-img text-center  p-2" src="./img/geneticspage/gs-icon3.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Choose the right genetic test for you</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg mb-4 r4  ">
                    <div className="card-body text-center">
                      <div className="card-block mt-2 p-2  circle">
                        <img className="card-img text-center  p-2 " src="./img/geneticspage/gs-icon4.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Understand your results and take actions accordingly</p>
                    </div>
                  </div>{/* eo card */}
                </div>{/* eo-card-deck-wrap */}
              </div>{/* eo-card-deck */}
              {/* ======================================================= */}
              <GeneticCounsellingCarouselCards></GeneticCounsellingCarouselCards>
              {/* ========================================================= */}
            </div>{/*eo-container */}
            <div className="gc-help-button-wrap text-center">
              <a className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" onClick={this.toBookSession} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Schedule Your Appointment</a>
            </div>{/* gc-help-button-wrap */}
          </div>{/* gc-help-main-wrap */}
        </div>{/* gc-help-main-sec */}
        {/* end of Genetic Counsell Help cards  */}
        {/* Genes can influence section starts here */}
        <section className="heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap  container">
            <div className="genetics-intro-text text-center">
              <h2 className=" text-center genetics-h2 ">What will you get?</h2>
            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-sm-2">
                <h3>Understanding of your genetic risks or to consider genetic testing</h3>
                <p className="heartdisease-content">You will receive a 30 to 60 minute phone or video consultation with our genetic counsellors. They will discuss your genetic risks, and various testing options as appropriate and help you understand the significance of testing.</p>
              </div>{/* eo-hd-text */}
              <div className="  hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center order-sm-3">
                <img className="img-fluid mx-auto" src="./img/geneticspage/illu123.png" />
              </div>{/* eo-hd-img */}
            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center ">
                <img className="img-fluid mx-auto" src="./img/geneticspage/illu1234.png" />
              </div>{/* eo-hd-img */}
              <div className="col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center ">
                <h3>Understanding of your genetic test reports</h3>
                <p className="heartdisease-content">You will receive a 30 minute phone or video consultation. Our Genetic Counselling experts will help you interpret and understand the genetic test report. They will explain about what these results mean for you and your family. The counsellors will answer your questions and discuss further options that you could consider.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************Our Process-sec************ */}
        <section className="our-process-sec">
          <div className="container our-process-sec-header ">
            <h2 className="text-center">Our Process</h2>
          </div>{/* eo what-you-sec-wrap */}
          <div className="container op-card-wrap text-center">
            <div className="card-deck" id="hide">
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-1 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-1">1</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap ">
                        <img className="card-img-top text-center" src="heart-img/consult-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Initial Consultation</h5>
                    <p>Our genetic counsellors will review your personal and family health history, answer your questions and explain disease risks if any.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-2 rounded shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-2">2</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/call-out-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title pb-xl-5">Discussion</h5>
                    <p>Based on your risk, your counsellor will help you select and order the right test for you and your family.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-3 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-3">3</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/sample-collection.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">sample collection</h5>
                    <p>If you decide to have testing done, we will ship the sample collection kit to your preferred address.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-4 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-4">4</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/results-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">report consultation</h5>
                    <p>Your genetic counsellor will review your test report, explain in detail on what it means, answer your questions, guide you on next steps.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
            </div>{/* eo wyg-card-deck */}
            {/* =========================================== */}
            <GeneticCounsellingCarouselCards2></GeneticCounsellingCarouselCards2>
            {/* =========================================== */}
          </div>{/* eo wyg-card-wrap */}
        </section>{/* eo of what-you-sec */}
        {/* ******************End of Our Process-sec************ */}
        {/* /************genetic Counselling Session starts here*************** / */}
        <section className="reasons-sec">
          <div className="container reasons-sec-wrap gr-sec-wrap">
            <div className="reasons-main-header-wrap">
            <p className="text-center p-0 m-0">Reasons for you to schedule a <b>Genetic Counselling Session</b></p>


            </div>{/* eo gr-header */}
            <div className="container reasons-card-main-wrap pt-5 mt-lg-5 mt-sm-0 mb-5">
              <div className="row" id="hide">
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-1 shadow-lg r1 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/geneticspage/planning-pregnancy.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <h5 className="card-title">Planning a Pregnancy</h5>
                        <p className="card-text">If you are planning for a pregnancy, our Genetic counsellors can help you learn about what genetic variations you could pass on to your children.</p>
                        <div className="container  gc-help-button-wrap text-center my-5">
                          <a className="mt-4 gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" onClick={this.toBookSession} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Schedule Your Appointment</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-1 shadow-lg r1 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/geneticspage/pregnant.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <h5 className="card-title">Pregnant</h5>
                        <p className="card-text">Understanding all the options of prenatal testing during a pregnancy can be overwhelming. Our genetic experts can help identify and interpret risks for an inherited disorder and suggest the best prenatal testing options for you.

                        </p>
                        <div className="container  gc-help-button-wrap text-center mt-5">
                          <a className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" onClick={this.toBookSession} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Schedule Your Appointment</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6 ">
                  <div className="card card-1 shadow-lg r1 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/geneticspage/genetics-cancer.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <h5 className="card-title">Cancer</h5>
                        <p className="card-text">At times genetic variations can put people at a higher risk of developing certain cancers. Based on your family history, our Genetic counsellors can help assess whether genetic testing is appropriate for you. The counsellors  will also help you interpret and explain the results of a test, and based on your report, guide you on next steps.</p>
                        <div className="container  gc-help-button-wrap  text-center mt-2 mb-5  ">
                          <a className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" onClick={this.toBookSession} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Schedule Your Appointment</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="reasons-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-1 shadow-lg r1 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/geneticspage/genetics-heartdisease.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm ">
                        <h5 className="card-title">Heart Disease</h5>
                        <p className="card-text">For people with a personal or family history of cardiovascular conditions, Genetic Counselling can help determine if genetic testing could be beneficial and provide guidance on test results.</p>
                        <div className="container gc-help-button-wrap  text-center my-5 pt-5 ">
                          <a className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" onClick={this.toBookSession} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Schedule Your Appointment</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="container gc-help-button-wrap text-center pt-5 d-none">
                  <a className="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" onClick={this.toBookSession} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Schedule Your Appointment</a>
                </div>
              </div>{/* eo-row */}
              {/* ========================== */}
              <div className="row" style={{display: 'block'}}>
               <GeneticCounsellingCarouselCards3></GeneticCounsellingCarouselCards3>
              </div>
              {/* ========================== */}
            </div>{/* gr-card-wrap */}
          </div>{/* eo gr-sec-wrap */}
        </section>{/*eo gr-sec*/}
        {/* end of genetic risks */}
        {/* end of Genetic Session section */}
        {/* end of Genetic Cause section */}
        <section className="genetic-cause-sec-main ">
          <div className="genetic-cause-sec-wrap">
            <div className="genetic-cause-header-wrap">
              <h2 className="genetic-cause-header genetics-h2 pt-0 text-center">
                Most diseases have a genetic cause.
              </h2>
              <p className="genetic-cause-subtitle genetics-h2-p text-center">Our genetic counsellors can help evaluate if you’re at risk, and if genetic testing may be right for you.</p>
            </div>{/* eo gr-header */}
            <div className="questions-btn text-center mx-auto">
              <p >Find answers to common questions.</p>
              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toGeneticHelp} role="button" style={{backgroundColor: '#4f5be7', color: 'white'}}>Frequently Asked Questions</a>
            </div>
          </div>{/* genetic-cause-sec-wrap */}
        </section>{/* end of genetic-cause */}
        {/* end of genetic-cause */}
        {/* ******************End of Genetic Session section************ */}
        {/* ******************are you ready section************ */}
        <section className="ayr-sec-main heart-disease-sec-main d-none">
          <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container">
            <div className="intro-text-wrap text-center">
              <h2 className="text-center ">Are you Ready?</h2>
            </div>{/* eo-intro-text-wrap*/}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center ">
                <img className="img-fluid mx-auto" src="./img/cancerpage/Illustrationg.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center ">
                <h3>Start your journey to better health</h3>
                <p className="heartdisease-content">This simple 3-minute self reported risk assessment test will enable you to learn and measure your risks for certain chronic conditions.</p>
                <button type="button" className="btn btn-outline-primary btn-hover-white rounded-pill" >What’s my Risk?<i className="fas fa-long-arrow-alt-right" />
                </button>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************end of are you ready section************ */}
        {/* **********Schedule your Genetic Counselling Session Starts Here************** */}
       <Prospect></Prospect>
     
        {/* **********Schedule your Genetic Counselling Session Ends Here************** */}
        {/* Footer Links */}
        
        {/* end of footer */}
        <Footer></Footer>
        {/* -------------------------------------start q and a------------------------------ */}
      
        {/* sticky form---------------- */}
        <MobileFooter></MobileFooter>
        
        {/* --------eo sticky form----------------- */}
        {/* ----------------------------------------------------eo Q and A--------------------------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default GeneticCounselling;
