import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class HeartCarouselCards2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleControls1"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="text-center  col-12 col-md-6 col-lg-4">
                  <div
                    className="card newcard"
                    style={{ height: "333px !important" }}
                  >
                    <div className="card-body p-2 ">
                      <div className="card-top ">
                        <img
                          className="img-fluid pt-5"
                          src="img/risk-page/tq/33heartcard.png"
                          style={{ height: "100px" }}
                        />
                        <p className="card-title roboto-black-18px pt-4">
                          Some Heart Conditions are caused by genes
                        </p>
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm pt-4">
                        <p className="card-text pb-3">
                          These heart conditions if left undetected can be
                          life-threatening.
                        </p>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="text-center  col-12 col-md-6 col-lg-4 bg-white">
                  <div
                    className="card card-2 newcard"
                    style={{ height: "333px !important" }}
                  >
                    <div className="card-body p-2 ">
                      <div className="card-top ">
                        <img
                          className="img-fluid pt-5"
                          src="img/risk-page/tq/33heartcard.png"
                          style={{ height: "100px" }}
                        />
                        <p className="card-title roboto-black-18px pt-4">
                          With early detection most inherited heart conditions
                          can be managed successfully
                        </p>
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm pt-2">
                        <p className="card-text pb-3">
                          Knowing your risk early can help you and your Doctor
                          to create a personalised plan with regular screenings
                          to prevent heart disease.
                        </p>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-2 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="text-center  col-12 col-md-6 col-lg-4">
                  <div
                    className="card card-3 newcard"
                    style={{ height: "333px !important" }}
                  >
                    <div className="card-body p-2">
                      <div className="card-top ">
                        <img
                          className="img-fluid pt-5"
                          src="img/risk-page/tq/33heartcard.png"
                          style={{ height: "100px" }}
                        />
                        <p className="card-title roboto-black-18px pt-4">
                          Learning about your heart can help your family
                        </p>
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm pt-4">
                        <p className="card-text pb-3">
                          Inherited heart conditions are passed from one
                          generation to the next and run in families. Learning
                          about your risk can help your family take proactive
                          actions to keep their own hearts healthy.
                        </p>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-3 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default HeartCarouselCards2;
