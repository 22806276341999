import React from 'react';
import MainHeader from "./MainHeader";
import Footer from './Footer';
import axios from '../axios/axios';
import MobileFooter from './MobileFooter';
import MetaTags from 'react-meta-tags';


class CliniciansAboutUs extends React.Component{
  constructor(props) {
    super(props);
    this.state = {

        errors:{}
      };
    this.prospectReg = this.prospectReg.bind(this);
   
  }
  prospect = (e) => {
    const prospectForm = this.state
    prospectForm[e.target.name] = e.target.value;
    this.setState(prospectForm);
  }
  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["pemail"]) {
        formIsValid = false;
        errors["pemail"] = "*Please enter your email address";
    }

    if (typeof fields["pemail"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["pemail"])) {
            formIsValid = false;
            errors["pemail"] = "*Please enter valid email address";
        }
    }

    if (!fields["pphone"]) {
        formIsValid = false;
        errors["pphone"] = "*Please enter your mobile no.";
    }

    if (typeof fields["pphone"] !== "undefined") {
        if (!fields["pphone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["pphone"] = "*Please enter valid mobile no.";
        }
    }
    this.setState({
        errors: errors
    });
    return formIsValid;

}
  prospectReg(e) {
    e.preventDefault();

    if (this.validateForm()) {

        const { pname, pphone, pemail,pmessage } = this.state;
        axios.post('users/prospect', { pname, pphone, pemail, pmessage })
            .then((response) => {
                
                alert("Thank you for your interest. We will get back you soon...")
                // this.setState({
                //     sucessMsg:"Thank you for your interest. We will get back you soon..."
                // })
               // alert("Thanks for your intersr. We will get back you soon")   
            
 
            }).catch(error => {
              if(error.response===undefined){
                this.props.history.push("/clinicians_error")
              }else{
                this.setState({
                    errorMsg: error.response.data.message
                })  
              }
               
            });
            e.target.reset();
    }
   
  
}

    render(){

        return(
            <div>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
            {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
            <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
            <title>truGeny</title>
            <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
            <link rel="stylesheet" href="./css/header_footer.css" />
            <link rel="stylesheet" href="./css/stylesheet_custom.css" />
            <link rel="stylesheet" href="stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/fonts.css" />
            <link rel="stylesheet" href="./css/sticky.css" />
            <link rel="stylesheet" href="./css/header_footer.css"/>
            <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>
        <MetaTags>
        <title>About - Most Trusted Genetic Counselling Company in India</title>
		<meta name="description" content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="About - Most Trusted Genetic Counselling Company in India"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content=""/>
        <meta property="og:description" content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>

                   


            <style dangerouslySetInnerHTML={{__html: "\n.background-index\n{\n  background:url('img/aboutpage/banner.png') no-repeat  center ;-webkit-background-size: cover;\n}\n}\n" }} />
            <div className="background-index">
              <MainHeader  category="AboutUs"></MainHeader>
              <h4 className="text-center banner-text-h4-1 mx-auto"><br /><br /></h4>
              <h4 className="text-center  text-black banner-text-h4-2 mx-auto" style={{fontWeight: 700}}><br /></h4>
              <h1 className="text-center  banner-text-h1 mx-auto text-uppercase" style={{color: '#4f5be7'}}>Our MOONSHOT</h1>
              <div style={{paddingBottom: '12rem'}} />
              <div id="section-1" className="d-flex justify-content-center">
                <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#section-1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
              </div>
              <div style={{paddingBottom: '8rem'}} />
            </div>
            <div className="container questions-btn  img-card-1 text-center mx-auto my-5 pt-5">
              <h3 className=" text-center genetics-h2 roboto-black-36px mt-5 " style={{color: '#000'}}>About Us</h3>
              <p className=" text-center   roboto-reg-24px" style={{color: '#000'}}>truGeny was founded with the passion to improve peoples access to a longer and healthier life.</p>
              <p className=" text-center   roboto-reg-24px" style={{color: '#000'}}>At truGeny we believe it’s time for people to take control of their health destiny. truGeny empowers people with personalised genetic insights that are accessible, affordable and actionable.</p>
              {/* <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " style="background-color: #4f5be7; color:white;"href="#" role="button">Order truGeny Now</a> */}
            </div>
            {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
            <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main pt-0">
              <div className="heart-disease-sec-main-wrap container">
                <div className="genetics-intro-text text-center">
                  <h2 className=" text-center genetics-h2 pb-0">the BELIEF - Shifting health from being</h2>
                  <h3 className=" text-center genetics-h2 pt-0" style={{color: '#4f5be7'}}> REACTIVE to PROACTIVE</h3>
                  <div className="questions-btn text-center mx-auto my-5">
                    {/* <p class="">Find answers to common questions.</p> */}
                    <img className="text-center img-fluid" src="img/aboutpage/Illustration1.png" />
                  </div>
                </div>{/* eo-intro-text-wrap*/}
              </div></section>
            {/* -------------------------------------------------------------------------------------------------------------------------------- */}
            <section className="genetic-cause-sec-main container-fluid">
              <div className="genetic-cause-sec-wrap">
                <div className="genetic-cause-header-wrap">
                  <h2 className="genetic-cause-header genetics-h2 pt-0 text-center text-uppercase" style={{color: '#4f5be7'}}>
                    truGeny for everyone
                  </h2>
                  <p className="genetic-cause-subtitle roboto-bold-24px text-center">
                    We are making it easy for people to learn about and take advantage of their individualised genetic information.
                  </p>
                </div>{/* eo gr-header */}
                <div className="questions-btn text-center mx-auto">
                  <img className="text-center img-fluid " src="img/aboutpage/Illustration2.png" />
                </div>
              </div>
            </section>
            {/* -------------------------------------------------------------------------------------------------------------------------------- */}
            <div className="heart-disease-sec-main-wrap container pt-3 ">
              <h2 className="roboto-black-48px text-center py-5 my-5">truGeny Provides</h2>
              <div className=" heart-disease-sec row container">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                  <img className="img-fluid mx-auto" src="./img/aboutpage/Illustration3.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                  <h3>Genetic Counselling</h3>
                  <p className="heartdisease-content pb-5">
                    With exponential growth of research and clinical information in genetics, it is imperative that the knowledge is translated in a personalised and effective manner for people to make informed decisions about their health.<br /><br />truGeny’s certified genetic counsellors help patients and individuals understand their genetic risks based on family or personal health history, and help them decide whether genetic testing is right for them, and recommend the appropriate genetic test. truGeny’s genetic counselling service is delivered through a proprietary tele genetics platform. Our partners include clinics, hospitals and employers.
                  </p>
                </div>{/* eo-hd-text */}
              </div>{/* eo-row */}
              <div className="families-sec heart-disease-sec row container pt-5">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                  <img className="img-fluid mx-auto" src="./img/aboutpage/Illustration4.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                  <h3>Genetic Testing - not a previlege, it’s a necessity.</h3>
                  <p className="heartdisease-content pb-5">
                    truGeny’s aim is to democratise peoples access to genetic testing. We will always provide better services and comprehensive reporting at a non-premium pricing.
                  </p>        {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
                </div>{/* eo-hd-text */}
              </div>{/* eo-row */}
              <div className=" heart-disease-sec row container pt-3">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                  <img className="img-fluid mx-auto" src="./img/aboutpage/Illustration5.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                  <h3>truGeny is built on trust.</h3>
                  <p className="heartdisease-content">
                    Through a network of partner labs, truGeny offers high-quality clinical grade genetic tests at a very reasonable cost.<br /><br />To ensure that all our test results meet and exceed industry standards, our tests are performed in CAP accredited and NABL certified laboratories.
                  </p>
                </div>{/* eo-hd-text */}
              </div>
              <div className="families-sec heart-disease-sec row container">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                  <img className="img-fluid mx-auto" src="./img/aboutpage/Illustration6.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                  <h3>Data</h3>
                  <p className="heartdisease-content">
                    We ensure that your data is safe in our comprehensive security framework.<br /><br />Our technology ensures compliance with the latest data protection standards, and additionally. we implement constant improvements to ensure that we follow the best practices of the industry.</p>        {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
                </div>{/* eo-hd-text */}
              </div>
              <div className=" heart-disease-sec row container py-5">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                  <img className="img-fluid mx-auto" src="./img/aboutpage/Illustration7.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                  <h3>Team</h3>
                  <p className="heartdisease-content">
                    We’re are a team that's driven by passion and curiosity. The simple question “what can we do today to improve peoples health?” drives us each day.<br /><br />We are a team of that thinks big and does big.<br /><br />We are a team that's inspired by science and propelled by the enthusiasm of our scientists, counsellors, artists, creators, entrepreneurs and engineers and marketers.
                  </p>          {/* <p class="heartdisease-content">Your patients can now receive genetic counselling from the comfort of their home and at a convenient time of their choosing.</p> */}
                </div>{/* eo-hd-text */}
              </div>{/* eo-row */}
              <div className="families-sec heart-disease-sec row container">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                  <img className="img-fluid mx-auto" src="./img/aboutpage/Illlustration8.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                  <h3>Scientific advisory board</h3>
                  <p className="heartdisease-content pb-5">
                    Our advisory board is composed of some of India’s leading experts in genomics. Every board member serves an important role in enhancing and upholding our high scientific standards. They are our strategic beacon for the continuous improvement of our solution to deliver improved outcomes to patients and individuals.
                  </p>
                  {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
                </div>{/* eo-hd-text */}
              </div>{/* eo-row */}
            </div>{/* eo-heart-disease-sec-main-wrap*/}
            {/* -------------------------------------------------------------------------------------------------------------------------------- */}
            {/* <div class="questions-btn text-center mx-auto my-5">
        <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " style="background-color: #4f5be7; color:white;"href="#" role="button">Order truGeny Now</a>
      </div> */}
            {/* -------------------------------------------------------------------------------------------------------------------------------- */}
            <div className="heart-disease-sec-main-wrap container d-none">
              <div className="genetics-intro-text text-center">
                <h2 className=" text-center genetics-h2 text-uppercase py-5" style={{color: '#4f5be7', maxWidth: '800px', margin: 'auto'}}>Have your patients order truGeny directly to their home</h2>
                {/* <p class="roboto-reg-18px " ><b>Convenience :</b><br><br>truGeny’s Provider Platform makes ordering a test easy and fast.<br>Save time and place your order online or request your patient to purchase while at home. Alternatively, collect the patient’s sample in the office using a Color kit. You can choose if results are released at the same to your patient, or after a delay.</p> */}
              </div>{/* eo-intro-text-wrap*/}
              <div className=" heart-disease-sec row container">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                  <img className="img-fluid mx-auto" src="./img/clinicians/works/Illustration5.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                  <h3>Step 1</h3>
                  <p className="heartdisease-content">Place order for truGeny<br /><br />Create your account on the Clinicians Platform and place your order. You can choose if you would want the report to be released at the same time to your patient, or after a delay. Once your order is placed, you’ll be able to track its status. Order processing will take 3 to 4 days.</p>
                  {/* <p class="heartdisease-content">Your patients can now receive genetic counselling from the comfort of their home and at a convenient time of their choosing.</p> */}
                </div>{/* eo-hd-text */}
              </div>{/* eo-row */}
              <div className="families-sec heart-disease-sec row container">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                  <img className="img-fluid mx-auto" src="./img/clinicians/works/Illustration6.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                  <h3>Step 2</h3>
                  <p className="heartdisease-content pb-5">Your patient pays for the test<br /><br />Your patient will be emailed a requisition number to purchase truGeny. Your patient will be first counselled by our genetic counsellors for a pre-test counselling session. The sample collection kit is shipped to their home for them to provide their saliva sample.</p>
                  {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
                </div>{/* eo-hd-text */}
              </div>{/* eo-row */}
              <div className=" heart-disease-sec row container pt-5">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                  <img className="img-fluid mx-auto" src="./img/clinicians/works/Illustration7.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                  <h3>Step 3</h3>
                  <p className="heartdisease-content">On an average, the report will be available in 3-4 weeks.<br /><br />You will receive an email notification from truGeny informing you that your patient’s report is ready. You can view and download the report from the truGeny clinician platform.<br /></p>
                </div>{/* eo-hd-text */}
              </div>
              <div className="families-sec heart-disease-sec row container ">
                <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                  <img className="img-fluid mx-auto" src="./img/clinicians/works/Illustration8.png" />
                </div>{/* eo-hd-img */}
                <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                  <h3>Step 4</h3>
                  <p className="heartdisease-content">Your patient will have access to our certified genetic counsellors at no additional cost.<br /><br />They will help your patient understand the report and answer any questions they might have.</p>
                  {/* <p class="heartdisease-content">Rely on our board certified genetic counsellors with deep subject matter expertise across medical specialties.</p> */}
                  {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
                </div>{/* eo-hd-text */}
              </div>
            </div>
            {/* eo-heart-disease-sec-main */}
            {/* -------------------------------------------------------------------------------------------------------------------------------- */}
            <div style={{paddingBottom: '6rem'}}>
            </div>
            <div id="learn" style={{paddingBottom: '2rem'}}>
            </div>
            <div className="gc-form-card container">
        <div className="gc-form-card-wrap  shadow-lg">
          <div className=" gc-form-card-top">
            <h2 className="text-center"> Take control of your health, NOW!</h2>
            <p className=" gc-form-subtitle text-center">All you need to do is, fill out the form below and one of our experts will reach out to you very soon. </p>
            {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
          </div>{/* gc-form-card-top */}
          <div className="gc-form-card-btm">
            <form className="gc-form needs-validation" onSubmit={this.prospectReg}>
              <div className="form-row justify-content-center">
                <div className="gc-col  col-md-5 mb-3" htmlFor="validationTooltip01">
                  <div className="input-group ">
                    <input type="text"  className="form-control m-0" id="validationTooltip01" name="pname" placeholder="Name:" onChange={this.prospect}  placeholder="Name" required />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend border-0">
                      <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="email" name="pemail" onChange={this.prospect}  className="form-control m-0" required id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend"  />
                    <div className="invalid-tooltip">
                      Please enter a valid E-mail ID.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                  <div className="input-group d-none">
                    <input type="text"  className="form-control m-0" id="validationTooltip02" placeholder="Reason" defaultValue="Reason" required/>
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i2.png" /></span>
                    </div>
                  </div>
                </div>
              </div>{/* end of form-row */}
              {/* <div className="form-row justify-content-center d-none">
                <div className=" gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="email" name="email" className="form-control m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" required />
                    <div className="invalid-tooltip">
                      Please enter a valid E-mail ID.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="text" name="refered" className="form-control m-0" id="validationTooltip03" placeholder="Referred by" defaultValue="Referred by" aria-describedby="validationTooltip03" required />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip03"><img src="img/homepage/i4.png" /></span>
                    </div>
                  </div>
                </div>
              </div>end of form-row */}
              <div className="form-row justify-content-center">
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input className="form-control m-0" type="tel" maxLength={10} onChange={this.prospect} id="validationTooltip05" required placeholder="Phone number" name="pphone" pattern="[0-9]{10}"  />
                    <div className="invalid-tooltip">
                      Please provide a valid Phone number.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip05"><img src="img/homepage/i5.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <select className="form-control roboto-reg-16px" required id="exampleFormControlSelect1" placeholder="Select an answer" name="pmessage" onChange={this.prospect}style={{border: 'none', height: '60px'}}>
                    <option className="dropdown-item roboto-reg-16px" defaultValue disabled>I’m interested to learn about.</option>
                    <option className="dropdown-item roboto-reg-16px" value="Geneticcounselling">Genetic counselling</option>
                    <option className="dropdown-item roboto-reg-16px" value="Risks for pregnancy / future family">Risks for pregnancy / future family</option>
                    <option className="dropdown-item roboto-reg-16px" value="Risks for cancer">Risks for cancer</option>
                    <option className="dropdown-item roboto-reg-16px" value="Risks for heart attack">Risks for heart attack</option>
                  </select>
                  {/* <div className="input-group d-none">
                    <textarea name="message" className="form-control m-0 " id="validationTextarea" rows={1} placeholder="Message"  defaultValue={""} />
                  </div> */}
                </div>
              </div>{/* end of form-row */}
              <div className="wrapper pt-3 mt-4 mb-5  pb-5">
                <button className="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} name="submit" type="submit"><b>SUBMIT</b></button>
              </div>
            </form>
          </div>{/* end of gc-form-card-btm */}
        </div>{/* end of gc-form-card-wrap */}
      </div>
            {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
            {/* Footer Links */}
           <Footer></Footer>
           <MobileFooter></MobileFooter>
            {/* -------------------------------------start q and a------------------------------ */}
            <div className="modal fade bd-example-modal-lg d-none" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{borderRadius: '40px'}}>
                  <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                    <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                      Start your journey in 3 easy steps</h5>
                    <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                      <img className="img-fluid" src=".\img\registration\X.png" />
                    </button>
                    {/* <p class="float-left">Question 1 of 3</p> */}
                  </div>
                  <div className="bg-blue border-bottom-1">
                    <p className="pl-3">Question 1 of 3</p>
                  </div>
                  <div className="modal-body pb-5 pt-0 m-0">
                    <div className="container ">
                      <br />
                      <div className="tab-content">
                        <div id="home" className="container tab-pane active"><br />
                          <h3>How do you indentify your gender?</h3>          <hr />
                          <div className="d-flex d-flex-inline">
                            <img className="mr-3" src="./img/q&a/female.png" alt="" />
                            <img className="mr-3" src="./img/q&a/female.png" alt="" />
                          </div>
                          <div className="float-right pt-2 mt-2">
                            <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                          </div>
                        </div>
                        <div id="menu1" className=" m-0 p-0 container tab-pane fade"><br />
                          <h3>Does anyone in your family have 2 diabetes?</h3><hr />
                          <h4>Certain ethnic groups have higher risk for diabetes,<br />
                            even at lower body weights.</h4>
                          <div className="col-xs-12 mt-2 ">
                            <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                              <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                              <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                            </div>
                          </div>
                          <div className="float-right pt-2 mt-2">
                            <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                          </div>
                        </div>
                        <div id="menu2" className=" m-0 p-0 container tab-pane fade"><br />
                          <h3>Does anyone in your family have 2 diabetes?</h3>
                          <hr />
                          <div className="radio">
                            <label><input type="radio" name="optradio" defaultChecked />  Parents</label>
                          </div>
                          <div className="radio">
                            <label><input type="radio" name="optradio" />  Siblings</label>
                          </div>
                          <div className="float-right pt-2 mt-2">
                            <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill"  href="#">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* sticky form---------------- */}
                  {/* <div class="modal-footer">
                      <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                      <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                    </div> */}
                </div>
              </div>
            </div>
            {/* sticky form---------------- */}
            <div className="row ">
              <div className="nb-form">
                {/* <p class="title d-none d-lg-block ">May I Help You</p> */}
                <img src="https://www.growyourbrand.com/wp-content/uploads/2018/11/chat.gif" alt="" className="user-icon img-fluid" />
                {/* <form>
                  <input type="text" name="cpname" placeholder="Name:" required />
                  <input type="email" name="cpemail" placeholder="Email:" required />
                  <input type="tel" name="cpphone" placeholder="Phone:" required />
                  <textarea name="cpmessage" placeholder="Message:" required defaultValue={""} />
                  <input type="submit" defaultValue="Send message" style={{background: '#4f5be7'}} />
                </form> */}
              </div>
            </div>
            {/* --------eo sticky form----------------- */}
            {/* ----------------------------------------------------eo Q and A--------------------------------- */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
          </div>
        )
    }






}
export default CliniciansAboutUs;