import React from 'react';
import {  Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody ,ModalFooter} from 'reactstrap';
import { FacebookLoginButton, GoogleLoginButton,  } from "react-social-login-buttons";
import {
  Card, CardBody,
  CardTitle, CardHeader
} from 'reactstrap';
import '../styles.css';
import axios from '../axios/axios';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import StickyHeader from 'react-sticky-header';
import NewMainHeader from './NewMainHeader';
import SubFooter from './SubFooter';
import swal from 'sweetalert';
import { FadeLoader} from 'react-spinners';
import { css } from '@emotion/core';
import SubMainFooter from './SubMainFooter';

import MobileFooter from './MobileFooter';
import constants from './constants';
import Badge from 'react-bootstrap/Badge'
import { MoonLoader} from 'react-spinners';
import { UncontrolledAlert } from 'reactstrap';


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Register extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailData:'',
      emailStatus:'',
      loading2:false,
      loading: false,
      isOpen: false,
      modalIsOpen: false,

      modal: false,
      createPwd:false,
      otp:'',
      otpstatus:false,

      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPwd: '',
      errorMsg: null,
      errors: {},
      loginForm: {},
      loginErrors: {},
      passwordType:'',otpmessage:'',mobUserId:null
    };


    // this.toggle = this.toggle.bind(this);
    this.baseState =this.state;
    this.registration = this.registration.bind(this);
    this.login = this.login.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.verifySocialLoginUser = this.verifySocialLoginUser.bind(this);
    this.createPassword=this.createPassword.bind(this)
    this.withOTP=this.withOTP.bind(this)
    this.optHandler =this.optHandler.bind(this)
    this.onChange=this.onChange.bind(this)
    this.handleOnChange=this.handleOnChange.bind(this)
    this.emailVerification=this.emailVerification.bind(this)


  }
  // toggle() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }


  toggleModal() {
   
    this.setState(prevState => ({
     
      modalIsOpen: !prevState.modalIsOpen

    },()=>{if(!this.state.modalIsOpen){this.setState({otpstatus:false})}}));
  }
  optHandler(){
    if(this.state.otp){
      const {  email, phone ,otp,mobUserId} = this.state;
      axios.post('users/otpValidation', { email, phone,otp,mobUserId })
      .then((result) => {
        this.props.history.push('/login');
        swal("Your SuccessFully Registered")
  
      }).catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error")
        } else {
          this.setState({
            errorMsg: error.response.data.message,modalIsOpen:true,otpmessage:"Entered Otp Expired or INCorrect"
          })
        }
      });
   
    }
    else{
      const {  email, phone} = this.state;
      axios.post('users/resendOtp', { email, phone })
      .then((result) => {
      if(result.data.otpSentStatus==='success'){
        this.setState({
          modalIsOpen:true,email:result.data.email,phone:result.data.phone,otpmessage:"Please Submit your new Otp"
      })
      }else{
        swal("Something Problem with your Mobile No Please try Again Or Use New Number").then((value)=>{this.unprocessedMobileregistration()})

      }
     
      }).catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error")
        } else {
          this.setState({
            errorMsg: error.response.data.message,modalIsOpen:true
          })
        }
      });
    }
  }

  closeModel() {
    this.setState({
      modal: false,
      errorMsg: null,
      errors: {}
    });
  }
  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  onChangeEmail= (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  handleChange = (e) => {
    const loginForm = this.state
    loginForm[e.target.name] = e.target.value;
    this.setState(loginForm);
  }
  handleOnChange(value) {
    this.setState({ phone: value })
    this.emailVerification()
  }
  checktermsAndConditions = (e) =>{
    const loginForm = this.state
  
    if(e.target.checked){
      loginForm[e.target.name] = 1;
    }else{
      loginForm[e.target.name] = 0;
    }
    this.setState(loginForm);
  }

  /* Login Form Validation */

  loginValidation() {
    let loginForm = this.state;
    let loginErrors = {};
    let formIsValid = true;

    if (!loginForm["email"]) {
      formIsValid = false;

      loginErrors["email"] = "*Please enter your email address";
    }

    if (typeof loginForm["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(loginForm["email"])) {
        formIsValid = false;
        loginErrors["email"] = "*Please enter valid email address";
      }
    }
    if (!loginForm["password"]) {

      formIsValid = false;
      loginErrors["password"] = "*Please enter your password.";

    }

    if (typeof loginForm["password"] !== "undefined" && loginForm["password"] !== "") {

      if (!loginForm["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        loginErrors["password"] = "*Please enter valid password.";
      }
    }
    this.setState({
      loginErrors: loginErrors
    });
    return formIsValid;

  }

  /* Registration Form Validation */

  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }





    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your mobile no.";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid mobile no.";
      }
    }





    

    if(this.state.createPwd){

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined" && fields["password"] !== "") {
      if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (!fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Please confirm password.";
    }
    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";
    }
      
    }
    if(this.state.otpstatus==false&&this.state.createPwd==false){

      swal("Please create password or Generate OTP...!");
      formIsValid = false;
    }
    if (fields["terms"]=== 0 || fields["terms"]===undefined) {
      formIsValid = false;
      errors["terms"] = "*Please accept terms and conditions";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  createPassword(){

    this.setState({  createPwd:true }, function () {
    });
    this.setState({  otpstatus:false }, function () {
    });
    this.setState({  passwordType:'password' }, function () {
    });
}

withOTP(){

  this.setState({  otpstatus:true }, function () {
  });
  this.setState({  createPwd:false }, function () {
  });
  this.setState({  passwordType:'otp' }, function () {
  });
}
loader2(){
  return (
    <div className='sweet-loading'>
      <MoonLoader
        css={override}
        sizeUnit={"px"}
        size={50}
        color={'#123abc'}
        loading={this.state.loading2}
      />
  </div> )


}

emailVerification(){
  let formIsValid = true;
  // this.setState({loading2:true},()=>{}
  // )
  let id=this.state.email;
  if(id){
    (async () => {
      const rawResponse = await fetch('https://api.zerobounce.net/v2/validate?api_key=87ba3889be724555ad29d62183ed6334&email='+id+'&ip_address=', {
        method: 'GET',
      });
      const content = await rawResponse.json();
      if(content){
        this.setState({loading2:false},()=>{})
      }
      if(content.status==="valid"){
       
        this.setState({
          emailData:content,
          emailStatus:content.status},()=>{})
      }else  if(content.status==="invalid"){
         
        this.setState({emailStatus:content.status},()=>{})
        formIsValid = false;
      }
      
    })();

    return formIsValid;
}
}


  /* saving user registration details*/
  registration(e) {
    e.preventDefault();

     if (this.validateForm()&&this.state.emailStatus==="valid") {
       let group = "native";
       let userType="CUSTOMER";
       const { firstName, lastName, email, phone, password, confirmPwd,passwordType } = this.state;
       this.setState({loading:true},()=>{})
       axios.post('users/user', { firstName, lastName, email, phone, password, confirmPwd, group,passwordType,userType })
         .then((result) => {
           if( result.data.passwordType==='password')
          {  swal("Your Registration was SuccesssFull")
            this.props.history.push("/login")}
          else{
           
            if(result.data.otpSentStatus==='success'){
              this.setState({modalIsOpen:true,email:result.data.email,phone:result.data.phone,loading:false,mobUserId:result.data.userId }, function () {});
            }
            else{
              this.setState({email:result.data.email,phone:result.data.phone,loading:false,mobUserId:result.data.userId }, function () {});
               swal("Something Problem with your Mobile No Please try Again Or Use New Number").then((value)=>{if(value){this.unprocessedMobileregistration()}})
            }
           }
         }).catch(error => {
           if (error.response === undefined) {
             this.setState({loader:false,loading:false},()=>{})
             this.props.history.push("/error")

           } else {
             
             this.setState({loader:false,loading:false,errorMsg: error.response.data.message},()=>{})
             swal(error.response.data.message).then((value)=>{if(value){this.unprocessedMobileregistration()}})

           }
           
         });
         e.target.reset();
       }
   }

   unprocessedMobileregistration(){
     console.log("cehcking",this.state.mobUserId)
    axios.get('users/'+this.state.mobUserId)
    .then((result) => {window.location.reload(false)}).catch(error => {window.location.reload(false)});

   }

  /* user login authentication*/

  login(e) {
    e.preventDefault();
    if (this.loginValidation()) {
      const { email, password } = this.state;
      axios.post('users/login', { email, password })
        .then((response) => {
          
          Cookies.set('jwt-token', response.data.token, { expires: 1 });
          Cookies.set('username', jwt_decode(response.data.token).firstName);
          Cookies.set('userId', jwt_decode(response.data.token).userId);

          this.props.history.push('/loginSuccess');
        }).catch(
          
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              })
            }
          }
        )
    }
  }

  /* saving user registration details through social login*/

  verifySocialLoginUser(firstName, lastName, email) {
    
    let group = "google";
    axios.post('users/social/user', { firstName, lastName, email, group })
      .then((response) => {
       
        Cookies.set('jwt-token', response.data.token, { expires: 1 });
        Cookies.set('username', jwt_decode(response.data.token).firstName);
        Cookies.set('userId', jwt_decode(response.data.token).userId);
        this.props.history.push('/loginSuccess');
        

      });
  }
  loader(){
    return (
      <div className='sweet-loading'>
        <FadeLoader
          css={override}
          sizeUnit={"px"}
          size={100}
          color={'#123abc'}
          loading={this.state.loading}
        />
    </div> )}
    alert() {
      return (
        <div>
  
          <UncontrolledAlert color="info">
            Email verified...!!
       </UncontrolledAlert>
        </div>
      )
    }

    alert2() {
      return (
        <div>
  
          <UncontrolledAlert color="danger">
           Invalid email id!
       </UncontrolledAlert>
        </div>
      )
    }
 
  render() {
    console.log("email status....",this.state.emailStatus)

    const loginTitle = {
      fontSize: "13px",
    }
    const formFileds = {
      fontSize: "12px",
      width:"200px"
    }
    const responseGoogle = (response) => {
     

      if (!response.error) {
        Cookies.set('username', response.profileObj.name)
        Cookies.set('jwt-token', response.accessToken)
        Cookies.set('email', response.profileObj.email)
        this.verifySocialLoginUser(response.profileObj.givenName, response.profileObj.familyName, response.profileObj.email);
       
      }
    }



    const responseFacebook = (response) => {
      localStorage.setItem("username", response.name)
       this.props.history.push('/loginSuccess');


    }

    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/payment.css" />
        <style dangerouslySetInnerHTML={{__html: "\n\ninput[type='radio'] {\n    -webkit-appearance:none;\n    width:15px;\n    height:15px;\n    border:1px solid darkgray;\n    border-radius:50%;\n    outline:none;\n    box-shadow:0 0 5px 0px gray inset;\n}\ninput[type='radio']:hover {\n    box-shadow:0 0 5px 0px lightblue inset;\n}\ninput[type='radio']:before {\n    content:'';\n    display:block;\n    width:60%;\n    height:60%;\n    margin: 20% auto;\n    border-radius:50%;\n}\ninput[type='radio']:checked:before {\n    background:blue;\n}\n\n    " }} />
                <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />

        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}

       <NewMainHeader category="register"></NewMainHeader>
        <div style={{ paddingTop: '7rem' }} />
        
        <Form onSubmit={this.optHandler}>
        <Modal isOpen={this.state.modalIsOpen}>
          <ModalHeader toggle={this.toggleModal.bind(this)}>OTP Validation</ModalHeader>
            <ModalBody>Please provide OTP  received  to your registed Mobile No
              <div class="form-group">
              <label for="email">Email:</label>
              <input type="text" class="form-control" id="email" value={this.state.email} disabled={true}/>
             
              </div>
              <div class="form-group">
                <label for="pphonewd">Registered Mobile No:</label>
                <input type="text" class="form-control" id="phone" value={this.state.phone} disabled={true}/>
              </div>
              <div class="form-group">
                <label for="otp">Enter Your Four Digit Pin Number:</label>
                <input type="text" class="form-control" id="otp" name="otp" value={this.state.otp} onChange={this.onChange}  maxLength="4" placeholder="Enter 4 digit's Here.."/>
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.otpmessage}</div>

               </div>
               <button type="submit" class="btn btn-primary" disabled={this.state.otp.length===4?false:true} onClick={this.optHandler}>Submit</button>
            </ModalBody>
          <ModalFooter>
          <div style={{ color: 'red', fontSize: '12px' }}>Otp field must be empty to resend Otp</div>

            <Button type="submit" color="primary"  disabled={this.state.otp.length>0?true:false} onClick={this.optHandler}>Resend Otp</Button>
          </ModalFooter>
        </Modal>
        </Form>
       



        <div className=" text-center mb-1 sec-1">
          {/* <a href="index.html"><img  class="logo-create-page text-center img-fluid"src="./img/registration/Logo.png"/></a> */}
          <div className="container c1 text-center">
            <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Create your truGeny account </h1>
              <p className="text-center img-fluid para roboto-reg-16px">Already have your truGeny account?<a href="/login" className="text-center img-fluid para roboto-reg-16px" style={{color: '#4f5be7'}}> Sign in</a></p>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="cr-sec-main container mt-5 pt-3  pb-5 mb-2 b1-cr ">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errorMsg}</div>
              <div className="form-check form-check-inline p-3 d-none">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                <label className="form-check-label" htmlFor="inlineRadio1">I am a Clinicians</label>
              </div>
              <div className="form-check form-check-inline p-3 d-none">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                <label className="form-check-label" htmlFor="inlineRadio2">I am an Individual</label>
              </div>
              <div className="gc-form-card-btm pt-5">
                <Form className="cr-form pt-0 gc-form needs-validation" onSubmit={this.registration}>
                  <div className="form-row justify-content-center">
                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3" htmlFor="validationTooltip01">
                      <div className="input-group ">
                        <input type="text" name="firstName" placeholder="First Name" onChange={this.onChange} className="form-control m-0" id="validationTooltip01" placeholder="First Name"  required/>
                        <div className="valid-tooltip">
                          Looks good!
                        </div>
                        <div className="input-group-prepend border-0">
                          <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                        </div>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.firstName}</div>

                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
                      <div className="input-group">
                        <input type="text" name="lastName" placeholder="Last Name" onChange={this.onChange}  className="form-control m-0" id="validationTooltip02" placeholder="Last Name" required />
                        <div className="valid-tooltip">
                          Looks good!
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i1.png" /></span>
                        </div>
                      </div>
                    </div>
                  </div>{/* end of form-row */}
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.firstName}</div>
                  <div className="form-row justify-content-center">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group">
                        <input type="text" name="email" className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" onBlur={this.emailVerification} onChange={this.onChangeEmail} required />
                        <div className="invalid-tooltip">
                          Please enter a valid E-mail ID.
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                        </div>
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.email}</div>
                      {this.state.emailStatus==="valid"? this.alert():this.state.emailStatus==="invalid"? this.alert2():''}

                    </div>
                  </div>
                  <div id="mobiledvar" className="form-row justify-content-center ">
                  {/* <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                    <div className="input-group">
                      <PhoneInput  autoFormat={false} defaultCountry={'in'} disableDropdown={true} disableCountryCode={false} countryCodeEditable={false} type="text" name="phone"  value={this.state.phone} onChange={this.handleOnChange}  className="input-group-text" id="validationTooltipEmailPrepend" placeholder="Mobile Number." aria-describedby="validationTooltipEmailPrepend" required></PhoneInput>
                     
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/mob.png" /></span>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.phone}</div>
                  </div> */}
                   <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                    <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltipEmailPrepend"><Badge  variant="light">+91</Badge></span>
                      </div>
                    <input type="text" name="phone" maxLength={10}  onChange={this.onChange} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="Mobile Number." aria-describedby="validationTooltipEmailPrepend" required />
                      <div className="invalid-tooltip">
                        Please enter a valid Mobile Number.
                      </div>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/mob.png" /></span>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.phone}</div>
                  </div>






                </div>


                  {/* end of form-row */}
                  {/* <div class="form-check form-check-inline p-3 ">
          <input class="form-check-input" type="radio" name="passd" id="passd" value="option1" onclick="randomNumber()">
          <label class="form-check-label" for="inlineRadio1">Generate Password</label>
        </div> */}
                  <div className="form-check form-check-inline p-3">
                    <input className="form-check-input" type="radio" name="passd" id="passd" value={true} onClick={this.createPassword} checked={this.state.createPwd == true ? "checked" : ""} readOnly  />
                    <label className="form-check-label" htmlFor="inlineRadio2">Create Password</label>
                  </div>
                  <div className="form-check form-check-inline p-3">
                    <input className="form-check-input" type="radio" name="otpstatus" onClick={this.withOTP} value={true} checked={this.state.otpstatus == true ? "checked" : ""} id="passd" readOnly />
                    <label className="form-check-label" htmlFor="inlineRadio2">OTP Verification</label>
                  </div>
                  
                  {this.state.createPwd?<div>
                    <p style={{ color: '#007bff', fontSize: '12px' }}>Password must contain at least 6 characters, including UPPER/lowercase and numbers.</p>

                    <div id="passwordvar" className="form-row justify-content-center">

                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">

                   
                      <div className="input-group">
                      
                        <input type="password" name="password" title="Password must contain at least 6 characters, including UPPER/lowercase and numbers." placeholder="Create Password" onChange={this.onChange} className="form-control m-0" id="validationTooltip05" placeholder="Create Password" required />
                        <div className="invalid-tooltip">
                          Looks Good!
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltip05"><img src="img/payment/pwd.png" /></span>
                        </div>
                      </div>
                    </div>
                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
                      <div className="input-group">

                        <input type="password"  name="confirmPwd" placeholder="Confirm Password" onChange={this.onChange} className="form-control m-0" id="validationTooltip05" placeholder="Confirm Password" required />
                        <div className="invalid-tooltip">
                          Looks Good!
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltip05"><img src="img/payment/pwd.png" /></span>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.password}</div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.confirmPwd}</div></div>

                  :''}
                  {/* end of form-row */}
                 
              <div className="after-form-sec">
                <div className="col-xs-12 mx-auto d-flex justify-content-center mt-1 ">
                  <div className="custom-control form-control-lg custom-checkbox">
                    <input type="checkbox" name="terms" defaultValue="0" onChange={this.checktermsAndConditions}  id="customCheck1" />
                    <label  htmlFor="customCheck1"><span><p style={{color: 'black', fontSize: '1rem'}}>I agree to the truGeny <a style={{color: '#4f5be7'}} href="/termsofservices">Terms of Service</a> &amp; <a style={{color: '#4f5be7'}} href="/al_privacy_policy">Privacy Policy</a></p></span></label>
                  </div>
                </div>
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.terms}</div>

                {/*Grid column*/}
                {this.loader()}
                <div className="row d-flex justify-content-center px-3 pt-3">
                  <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center regButton" style={{height: '60px', width: '26rem', borderRadius: '1.875rem'}} type="submit"disabled={this.state.emailStatus==="valid"?false:true}>
                    <b>CREATE ACCOUNT</b></button>


                </div>
                <div className="row d-flex justify-content-center px-3 pt-5">
                  <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center regButton1" style={{height: '60px', width: '26rem', borderRadius: '1.875rem'}} type="submit" disabled={this.state.emailStatus==="valid"?false:true}>
                    <b>CREATE ACCOUNT</b></button>


                </div>

                {/* <div className="col-xs-12 col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto d-flex justify-content-center ">
                  <span className="my-3 line-span align-self-center" />
                  <span className="my-3 text-uppercase px-2 or align-self-center">or</span>
                  <span className="my-3 line-span align-self-center"> </span>
                </div>
                <div className=" d-flex justify-content-center mx-auto">
                  <div className=" form-row d-flex justify-content-between ">
                   
                    <GoogleLogin
                  clientId="527321491147-9dl5u31g86dss5lpus7f8u5n6iqgeikn.apps.googleusercontent.com"
                  render={renderProps => (
                    <GoogleLoginButton style={formFileds} onClick={renderProps.onClick} disabled={renderProps.disabled}></GoogleLoginButton>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                >
                  <span>Sign In with Google</span>
                </GoogleLogin>
                  
                <FacebookLogin
                appId="1703894579721102"

                callback={responseFacebook}
                render={renderProps => (
                  <FacebookLoginButton style={formFileds} onClick={renderProps.onClick}></FacebookLoginButton>
                )}
              />
                  </div>
                </div> */}
            
              </div>
                  
                </Form>
              </div>{/* end of gc-form-card-btm */}
              {/* end of after-form-sec */}
            </div>{/* end of cr-sec-main */}
            <div className="container text-center mt-5 pb-5">
              <img className="plus-button img-fluid mb-3" src="./img/registration/Icon.png" />
              <p className=" text-center  " style={{color: '#84888e'}}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a className="pb-5 cr-1" href="/login"><strong>CLICK HERE</strong></a> to create your account</p>
            </div>
          </div>
        </div>
        <div style={{paddingTop: '12.25rem'}}>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        <SubMainFooter></SubMainFooter>
        <MobileFooter></MobileFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>

    );

  }
}

export default Register;
