import React from 'react';
import Header from './Header';
import {Button} from 'reactstrap';
class ServerError extends React.Component{
    constructor(props) {
        super(props);

    }
    onHandle(){
        window.location.href="/"
      }
    render(){
        return(
            <div>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
            <title>truGeny</title>
            <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
            <link rel="stylesheet"  href="./errors/stylesheet_error.css" />
    
    
            {/* <link href="http://trugeny.in/cdn/404page.css" rel="stylesheet">   */}
            <div className="  sec1-landing ">
              <div className="  container ">
                <h4 className="text-center" style={{}}>404. <strong style={{fontWeight: '700!important', fontSize: '42px'}}>That’s an error</strong></h4><strong style={{fontWeight: '700!important', fontSize: '42px'}}>
                  <h5 className="text-center">Oops! Sorry, the page requested was not found</h5>
                </strong></div><strong style={{fontWeight: '700!important', fontSize: '42px'}}>
                <div className="  container ">
                  <div className="row">
                    <div className="col-lg-3 col-sm-12 ">
                      <img className="img-fluid img-adjust displyno" style={{}} src="./errors/doc.png" />
                    </div>
                    <div className="col-lg-9 col-sm-12 mtop" style={{}}>
                      <a className="btn btn-primary" style={{color: 'white', fontWeight: 900}} onClick={this.onHandle.bind(this)}>Click to go Home Page</a>
                    </div>
                  </div>
                </div>
              </strong></div><strong style={{fontWeight: '700!important', fontSize: '42px'}}>
            </strong></div>
        );
    }

}
export default ServerError;