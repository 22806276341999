import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import axios from '../axios/axios';
import SubFooter from './SubFooter';
import MobileFooter from './MobileFooter';
import swal from 'sweetalert';
class ActivatekitSuccess extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      errorMsg:'',
      modalIsOpen:false,
      show:false
    }
    this.checkBoxValue = this.checkBoxValue.bind(this)
    this.toActivatekit = this.toActivatekit.bind(this)
    this.validateCheckBox = this.validateCheckBox.bind(this)
    this.toActivatekitSuccess=this.toActivatekitSuccess.bind(this)
    this.toggleModal=this.toggleModal.bind(this)
  }
  toggleModal() {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen
    }));
  }
  checkBoxValue = (e) =>{
    const state = this.state
    if(e.target.checked){
      state[e.target.name] = true;
    }else{
      state[e.target.name] = false;
    }
    this.setState(state);
  }
  
  validateCheckBox(){
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields["customCheck1"]=== 'false' || fields["customCheck1"]===undefined) {
      formIsValid = false;
      errors["customCheck1"] = swal("*Please check this informed consent");
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  toActivatekit(){
      window.location.href="/activate-kit";
    
   
}
toActivatekitSuccess(){
  if(this.validateCheckBox()) {
    window.location.href="/activate-thankyou";
  }
 
}

    render(){
      let styles;
    let contactstyles;
    if(this.state.modalIsOpen){
      styles = {
        display: "block",paddingRight:'17px'
      };
    }else{ styles={display: "none",}

    }
        return(
          <div>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
          <link rel="stylesheet" href="./css/bootstrap/w3.css" />
          <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
          <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
          <link rel="stylesheet" href="./css/bootstrap/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
          <link href="https://www.jqueryscript.net/css/jquerysctipttop.css" rel="stylesheet" type="text/css" />
          <title>truGeny</title>
          <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
          <link rel="stylesheet" href="./css/stylesheet_custom.css" />
          <link rel="stylesheet" href="stylesheet_custom.css" />
          <link rel="stylesheet" href="./css/fonts.css" />
          <link rel="stylesheet" href="./css/jquery.calendar.css" />
          <style dangerouslySetInnerHTML={{__html: "\n  .hello {\n    /* margin-top: 6rem; */\n    padding:0px;\n    box-shadow: 0 17px 73px rgba(0, 0, 0, 0.57);\n    border-radius: 40px;\n    background-color: #eff7ff;\n    \"\n\n  }\n\n  .title-font-modal {\n    width: 514px;\n    height: 36px;\n    color: #2c333c;\n    font-family: \"Roboto - Black\";\n    font-size: 36px;\n    font-weight: 400;\n  }\n\n  .title-part-modal {\n    width: 890px;\n    height: 130px;\n    background-color: #eff7ff;\n  }\n\n  .resize-1 {\n    width: 396px;\n    height: 60px;\n    background-color: #ffffff;\n  }\n\n  .textr-box-size {\n    /* Style for \"Input\" */\n    width: 396px;\n    height: 60px;\n    border: 1px solid #ebebeb;\n    background-color: #ffffff;\n  }\n  " }} />
          <link rel="stylesheet" href="style-mobile.css" />
          <link rel="stylesheet" href="style-mobile2.css" />
          <div className="main-container">
            {/* header */}
           
            <CommonHeder category="product-page"></CommonHeder>
            <div style={{paddingBottom: '76px'}} className="padding22" />
            <div style={{marginTop: '8rem'}} className="margin"></div>
            {/* -----------------------------------------start book my session------------------------------------------------- */}
            <div className="container hello" style={{backgroundColor: '#ffffff'}}>
              <section className="reasons-sec">
                <div className="container reasons-sec-wrap gr-sec-wrap pr-0 pl-0 pb-0">
                  <div className="reasons-main-header-wrap pl-4">
                    <div className ="abc bg-white">
                      <h2 className="mx-3 " style={{fontSize: '2.25rem', fontWeight: '900px', marginTop: '2rem'}}>Success!</h2>
                      <div style={{paddingBottom: '1rem'}}>
                      </div>
                      <ul className="nav" id="myTab" role="tablist">
                        <li className="nav-item " style={{display: 'block'}}>
                          <a className="nav-link " id="settings-tab" data-toggle="tab" href="#settings" role="tab" onClick={this.toActivatekit} aria-controls="settings" aria-selected="false"><i className="mr-2 fas fa-caret-left" />BACK</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div style={{paddingBottom: '2rem'}}>
                  </div>
                  <div className="tab-content ">
                    {/* ------------------------------------------------------back tab---------------------------------------- */}
                    <div className="tab-pane active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                      <div className="first-sec-started  container d-flex  py-4" style={{backgroundColor: '#eff7ff'}}>
                        <h2 className="roboto-black-36px px-4" style={{color: '#4f5be7'}}>Congratulations {localStorage.getItem("name")}.</h2>
                        {/* <img class="justify-content-end" src="img/Health-History/X_icon.png"/> */}
                      </div>
                      <div className="container pl-5">
                        <h2 className="roboto-bold-30px  pt-3 ">You have now successfully activated your truGeny DNA Kit</h2><br />
                        <ul className="roboto-reg-18px px-3">
                          <li> Please remember to post your DNAT kit</li>
                          <li> You can expect your reports in 6weeks. In the mean time if you have any queries,please feel free to call on 1800-1234-1234</li>
                          <li> If you have not yet filled in your health history or your family tree, we recommend you to do this at the earliest.</li>
                        </ul>
                      </div>
                      <div className="first-sec-started  container d-flex align-items-center py-4" style={{backgroundColor: '#eff7ff'}}>
                        <h2 className="roboto-black-36px px-4 mr-5 pr-5" style={{color: '#4f5be7'}}>Invitation to Participate in Research</h2>
                        <img className="img-fluid align-self-end ml-5 pl-5 d-none" src="img/login/X_icon.png" />
                      </div>
                      <div className="container px-5">
                        {/* <h2 class="roboto-bold-30px  pt-3">You have now successfully activated your truGeny DNA Kit</h2><br> */}
                        <h2 className="roboto-reg-18px pt-3"> We invite you to participate in research to further and advance the science of genetics and health in India.</h2>
                        <ul className="roboto-reg-18px px-3">
                          <li> Voluntary : Participation is voluntary. So whether you give consent or not, your sample will be analysed and you will receive your report.</li>
                          <li> To participate,You are not required provide any further informationand no further work is required from your side.</li>
                          <li> Your data is protected : truGeny does not share your name, contact information or other identifying information with any collaborator.</li>
                          <li> You can decide to withdraw your consent at any time. </li>
                        </ul>
                        <p className="roboto-reg-18px">Read about informed Consent here.<a href="#" data-toggle="modal" onClick={this.toggleModal} data-target=".bd-example-modal-lg">here</a></p>
                        <div className="container ml-3 custom-control form-control-lg custom-checkbox">
                          <input name="customCheck1" onChange={this.checkBoxValue} type="checkbox" className="custom-control-input" id="customCheck1" />
                          <label className="custom-control-label" htmlFor="customCheck1"><span><p style={{fontSize: '1rem'}}>I have read the <a  onClick={this.toggleModal} data-toggle="modal" data-target=".bd-example-modal-lg">informed consent</a></p></span></label>
                        </div>
                      </div>
                      <div className="container">
                        <hr />
                      </div>
                      <div className="container d-flex justify-content-end my-5  ">
                        <div className="input-group-prepend mb-5 mr-3">
                          <button className="btn btn-outline-primary  btn-hover-white " name="submit" style={{border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px'}} type="submit" onClick={this.toActivatekitSuccess}> I do not give Consent.</button>
                        </div>
                        <div className="input-group-prepend mb-5 mr-3">
                          <button className="btn btn-outline-primary  btn-hover-white " name="submit" style={{border: 'solid 1px #4f5be7', borderRadius: '30px', height: '60px'}} type="submit" onClick={this.toActivatekitSuccess}> I give consent</button>
                        </div>
                      </div>
                    </div>
                    {/* eo gr-sec-wrap */}
                  </div></div></section>
              {/*eo gr-sec*/}

              <div className={this.state.modalIsOpen?"modal fade bd-example-modal-lg show":"modal fade bd-example-modal-lg"} tabIndex={-1} role="dialog" style={styles} aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg" style={{maxWidth: '1300px', margin: 'auto'}}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Data Sharing Consent</h5>
                    <button type="button" onClick={this.toggleModal.bind(this)} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body px-0">
                    <section className="container" id="myData">
                      <h2 className=" " style={{fontSize: '16px!important'}}>truGeny’s mission is to help people access, understand and benefit from the human genome. One of the ways we do this is through our research program. We are asking for an additional level of research participation and consent. We seek your permission to share your de-identified individual-level Genetic &amp; Self-Reported Information with research collaborators outside of truGeny. We collaborate with researchers outside truGeny to study many different topics including genetic factors behind diseases and traits, connections among diseases and traits, human migration and population history, and how people react to their personal genetic information.
                      </h2>
                      <div className=" mt-2">
                        <div className>
                          <div className="card-block  p-2 circle">
                            <div className="px-0 hc-accordian-wrap">
                              <div className="accordion" id="accordionIhc">
                                <div className="card ">
                                  <div className="card-header" id="headingOne">
                                    <h5 className="acc-header collapse-color mb-0">
                                      What am I agreeing to if I consent?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOnesf"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOnesf" className="collapse show" aria-labelledby="headingOnes" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        <p className=" roboto-reg-18px">Giving consent by checking the appropriate box below means that you agree to let truGeny share your de-identified individual-level data with approved researchers outside of truGeny. That is, you agree that we may share your Individual- level Genetic &amp; Self-Reported Information. This includes:</p>
                                      </a>
                                      <p className="roboto-bold-24px pt-3 pb-1">Your genetic data</p>
                                      <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Information you enter into surveys, forms and other features with the truGeny</p>
                                      <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Data you separately authorize us to import for research
                                      </p><p>
                                      </p><p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Your age and ethnicity
                                      </p><p>
                                        {/* -------------------------------------------6th-------------------------------------- */}
                                      </p></div>
                                  </div>
                                </div>
                                <div className="card ">
                                  <div className="card-header" id="headingOnee">
                                    <h5 className="acc-header collapse-color mb-0">
                                      What does “de-identified” mean?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOnsse"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOnsse" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        <p className=" roboto-reg-18px">De-identified means that we strip the data of information that could directly identify you (such as name, date of birth, and address) and connect any study information you provide by using a random code (for example a study ID). Any Genetic &amp; Self-Reported Information you allow us to share will be associated with that code, not with your name.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card ">
                                  <div className="card-header" id="headingOnee">
                                    <h5 className="acc-header collapse-color mb-0">
                                      What does “individual-level” mean?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOned"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOned" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        <p className=" roboto-reg-18px">Individual-level means the data are related to a single person. This is different from the truGeny Main Research Consent you signed which allows us to share only information that has been combined or “pooled” with data from other research participants.
                                        </p>
                                        <p>Allowing researchers to have access to individual-level data allows them to analyze the data more thoroughly, which may help them make more meaningful scientific discoveries. </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card ">
                                  <div className="card-header" id="headingOnee">
                                    <h5 className="acc-header collapse-color mb-0">
                                      What can the research collaborators do with my data?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOnsew"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOnsew" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        <p className=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p>
                                        <p>We will not share your Genetic &amp; Self-Reported Information for marketing purposes with our collaborators. You can learn more about how we share information for marketing purposes in our Privacy Statement.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header" id="headingTwo">
                                    <h5 className="acc-header collapse-color mb-0">
                                      Can I find out who my data is shared with?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapsesTwof"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapsesTwof" className="collapse " aria-labelledby="headingTwo" data-parent="#accordionIhc">
                                    <div className="card-body">
                                      <a style={{color: '#000'}}>
                                        <p className=" roboto-reg-18px">
                                          For the most part, we won’t be able to contact you every time we would like to share your data. We may ask you to volunteer to provide information or take a survey on a specific topic or for a specific collaboration. In that case we will provide you with that information. Collaborations may result in scientific publications, which will be updated on a participant’s “publications dashboard” (this is a feature available under the “research” section of your account). If your data are used in a publication, it will only be published in summary form (combined data from many individuals), or using very limited, non-identifying information (such comments or feedback participants may consent to release).
                                        </p>
                                        {/* ---------------------------------------------------------------5th--------------------------------------------------------------- */}
                                      </a></div><a style={{color: '#000'}}>
                                    </a></div><a style={{color: '#000'}}>
                                  </a></div><a style={{color: '#000'}}>
                                </a><div className="card "><a style={{color: '#000'}}>
                                    <div className="card-header" id="headingOnee">
                                      <h5 className="acc-header collapse-color mb-0">
                                        Is my data protected?
                                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#sssssssss"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                      </h5>
                                    </div>
                                  </a><div id="sssssssss" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc"><a style={{color: '#000'}}>
                                    </a><div className="card-body border-0"><a style={{color: '#000'}}>
                                      </a><a style={{color: '#000'}}>
                                        {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                        <p>truGeny takes seriously the trust you place in us. We have very strong security measures in place to ensure your data are transferred safely. truGeny will also have agreements with all collaborators regarding the security and storage of data that we share. Although truGeny cannot provide a 100% guarantee that your data will be safe, truGeny policies and procedures minimize the chance that a breach could take place.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card ">
                                  <div className="card-header" id="headingOnee">
                                    <h5 className="acc-header collapse-color mb-0">
                                      What are the additional benefits?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne10"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOne10" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                        <p>In addition to the benefits described in the truGeny Main Research Consent, sharing your individual-level data will allow more researchers to study and analyze the data more thoroughly. This may increase the chance that meaningful scientific discoveries are made such as greater understanding of human disease and biology, human populations, and possibly new ways to diagnose or treat diseases. Sometime in the future you, your family or friends may benefit directly or indirectly from the research discoveries made by truGeny or our research partners.</p>
                                        <p>Based on the information you provide and allow us to share, truGeny or our research collaborators may make new discoveries. In the future, those discoveries could lead to new commercial products or services (for example, new drugs, devices, or screening tests). If this happens, these products or services will be the property of the researchers who developed them and you will not receive any compensation.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card ">
                                  <div className="card-header" id="headingOnee">
                                    <h5 className="acc-header collapse-color mb-0">
                                      What are my options? Can I change my mind?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOneaa"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOneaa" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                        <p>Your alternative is not to agree to share your individual data. You can still participate in our research program without sharing your individual-level data.</p>
                                        <p>Choosing not to give consent or withdrawing your consent will not affect your access to your Genetic Information or to the Personal Genetic Service.</p>
                                        <p>You can withdraw your consent to share individual-level data at any time by declining this consent through your account settings. If you want to withdraw from our entire research program, you can decline the truGeny Main Research Consent through your account settings as well. truGeny will prevent your data from being shared after 30 days from receipt of your request (it may take up to 30 days to withdraw your information after you withdraw consent). If you withdraw your consent, any data that has been shared prior to this date cannot be reversed or undone.</p>
                                        <p>Based on the information you provide and allow us to share, truGeny or our research collaborators may make new discoveries. In the future, those discoveries could lead to new commercial products or services (for example, new drugs, devices, or screening tests). If this happens, these products or services will be the property of the researchers who developed them and you will not receive any compensation.
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card ">
                                  <div className="card-header" id="headingOnee">
                                    <h5 className="acc-header collapse-color mb-0">
                                      Who do I contact if I have questions?
                                      <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOnef"><i className="fa fa-plus-circle  rounded-circle" /></button>
                                    </h5>
                                  </div>
                                  <div id="collapseOnef" className="collapse" aria-labelledby="headingOne" data-parent="#accordionIhc">
                                    <div className="card-body border-0">
                                      <a style={{color: '#000'}}>
                                        {/* <p class=" roboto-reg-18px">Our research partners must sign an agreement which describes what they can and can’t do with your data (for example, who can have access and what specific projects the data can be used for). We require strict data security measures which must be in place before we will release any data to them. The agreement also prohibits research collaborators from trying to identify you in any way or sharing your data outside the approved project. </p> */}
                                        <p>If you have general questions and need help with truGeny's service, please go to the following web page:
                                        </p>
                                        <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;truGeny Customer Care
                                        </p><p>
                                        </p><p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;&nbsp;Email: support@trugeny.com
                                        </p></a><p><a style={{color: '#000'}}>
                                        </a>
                                      </p></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* eo bs-example */}
                            {/* end of accordian code */}
                          </div>
                        </div>
                        {/* eo card-block */}
                      </div>
                      {/* eo card body */}
                    </section></div>
                  {/* eo card */}
                  {/* eo-heart-accordian-sec-wrap */}
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary">Acknowledged</button>
                    <button type="button" className="btn btn-secondary" onClick={()=>this.setState({show:false})} data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
          <div style={{paddingTop: '12.25rem'}}>
          </div>
          <footer className="footer-sec ">
            <div className="container footer-wrap text-center">
              <p>2019 truGeny</p>
              <div className="f-social-links">
                <ul className="social-links list-inline">
                  <li className="list-inline-item">
                    <a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="icon-google-plus"><i className="fab fa-google" /></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a>
                  </li>
                </ul>
                {/* eo .social-links */}
              </div>
              {/* eo-f-social-links */}
              <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">
                <a style={{color: 'gray'}} href="al_termsofservices.html">Terms of Service</a>&nbsp;|&nbsp;
                <a style={{color: 'gray'}} href="al_privacy_policy.html">Privacy Policy</a> &nbsp;|&nbsp;
                <a style={{color: 'gray'}} href="#">Consent for Research</a>&nbsp;|&nbsp;
                <a style={{color: 'gray'}} href="#">Mission</a>&nbsp;|&nbsp;
                <a style={{color: 'gray'}} href="#">Blog</a> &nbsp;|&nbsp;
                <a style={{color: 'gray'}} href="#">Customer Care</a>&nbsp;|&nbsp;
                <a style={{color: 'gray'}} href="#">Careers</a>&nbsp;|&nbsp;
              </div>
            </div>
            {/* eo-footer-wrap */}
          </footer>
          {/* Optional JavaScript */}
          {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </div>
        )
    }
}
export default ActivatekitSuccess;