import React from 'react';
import {  Form } from 'reactstrap';

import '../styles.css';
import axios from '../axios/axios';

import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import SubFooter from './SubFooter';
import swal from 'sweetalert';
import MainHeader from './MainHeader';


class CliniciansLogin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
     
      isOpen: false,
      modal: false,

      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPwd: '',
      errorMsg: null,
      errors: {},
      loginForm: {},
      loginErrors: {}
    };


    this.toggle = this.toggle.bind(this);
    this.login = this.login.bind(this);
    this.closeModel = this.closeModel.bind(this);

  }



  toggle() {
    this.props.history.push("/clinicians_register")
  }
  closeModel() {
    this.setState({
      modal: false,
      errorMsg: null,
      errors: {}
    });
  }
  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  handleChange = (e) => {
    const loginForm = this.state
    loginForm[e.target.name] = e.target.value;
    this.setState(loginForm);
  }




  /* Login Form Validation */

  loginValidation() {
    let loginForm = this.state;
    let loginErrors = {};
    let formIsValid = true;

    if (!loginForm["email"]) {
      formIsValid = false;

      loginErrors["email"] = "*Please enter your email address";
    }

    if (typeof loginForm["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(loginForm["email"])) {
        formIsValid = false;
        loginErrors["email"] = "*Please enter valid email address";
      }
    }
    if (!loginForm["password"]) {

      formIsValid = false;
      loginErrors["password"] = "*Please enter your password.";

    }

    if (typeof loginForm["password"] !== "undefined" && loginForm["password"] !== "") {

      if (!loginForm["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        loginErrors["password"] = "*Please enter valid password.";
      }
    }
    this.setState({
      loginErrors: loginErrors
    });
    return formIsValid;

  }

  /* Registration Form Validation */

  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your mobile no.";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid mobile no.";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined" && fields["password"] !== "") {
      if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (!fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Please confirm password.";
    }
    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";

    }
    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  /* saving user registration details*/


  /* user login authentication*/

  login(e) {
    e.preventDefault();
    if (this.loginValidation()) {
      const { email, password } = this.state;
      const loginType="password";
      axios.post('users/login', { email, password,loginType})
      .then((response) => {
        if(jwt_decode(response.data.token).scopes[0].authority==="CLINICIANS"){
          Cookies.set('jwt-token', response.data.token, { expires: 1 });
          Cookies.set('username', jwt_decode(response.data.token).firstName);
          Cookies.set('userId', jwt_decode(response.data.token).userId);
          Cookies.set('scope', 'CLINICIANS');
          this.props.history.push('/clinicians');
        }else{
          swal("You are not a doctor")
        }
        }).catch(
          error => {
           
            swal("Please login with valid credentials!")
            if (error.response === undefined) {
              this.props.history.push("/clinicians_error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              })
            }
          }
        )
    }
  }




  render() {

    return (

      <div>
 <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./css/payment.css" />
     
        <style dangerouslySetInnerHTML={{__html: "\n  /* form focus styles*/\n\n  .form-control:focus {\n    border-color: #ebebeb;\n    box-shadow: inset\n    0 0px 0px rgba(0, 0, 0, .01),\n    0 0 0px rgba(0, 0, 0, .01),\n    0 0 30px rgba(141, 141, 141, 0.5),\n    0 0 0px rgba(0, 0, 0, .01);\n  }\n\n  /*End of  form focus styles*/\n  " }} />
        <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>
       
       
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
       
<MainHeader category="signin"></MainHeader>




        <div style={{paddingBottom: '10rem'}} className="margin"/>
        <div className=" text-center mb-1 sec-1">
          {/* <a href="index.html"> <img  class="logo-create-page text-center img-fluid"src="./img/registration/Logo.png"/></a> */}
          <div className="container c1 text-center">
          <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Dear Doctor,<br /> Please SignIn to your truGeny account </h1>
              <a href="/clinicians_register" className="text-center img-fluid para roboto-reg-16px" style={{color: '#4f5be7'}}>Create your truGeny account? Sign Up</a>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="container mt-5 pt-5  pb-5 mb-2 b1-cr">
              {/* <div class="col-sm-12 d-flex d-inline-flex mx-auto justify-content-center"> */}
              <div className="col-xs-12">
                {/* <div class="row">

        <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-right: 10px !important;">
          <input type="text" placeholder="First Name" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
          <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/ship-Icon-1.png"/></button>
        </div>

      <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-left: 10px !important;">
        <input type="text" placeholder="Last Name" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
        <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/ship-Icon-1.png"/></button>
      </div>

  </div> */}
              </div>
              {/*Grid column*/}

              {/*Grid column*/}
              <Form onSubmit={this.login}>
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errorMsg}</div>
                <div className="col-xs-12 ">
                  <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                    <input type="email" name="email" placeholder="Email Id.." onChange={this.handleChange} className="form-control roboto-reg-16px" style={{ border: 'none', height: '60px' }} aria-label="Text input with dropdown button" />
                    <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/homepage/i3.png" /></span>                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.loginErrors.email}</div>
                </div>
                {/*Grid column*/}
                <div className="col-xs-12 ">
                {/* <div className="gc-col input-group-prepend mb-3 mx-auto" style={{maxWidth: '460px'}}>
                  <a  style={{color: '#4f5be7'}}>Click to get otp</a>
                </div> */}
              </div>
                <div className="col-xs-12 ">
                  <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                    <input type="password" name="password" placeholder="Password.." onChange={this.handleChange} className="form-control roboto-reg-16px" style={{ border: 'none', height: '60px' }} aria-label="Text input with dropdown button" />
                    <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/payment/pwd.png" /></span>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.loginErrors.password}</div>
                </div>
                {/* <div className="col-xs-12 ">
                  <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                    <input type="text" name="dId" placeholder="dId" onChange={this.handleChange2} className="form-control roboto-reg-16px" style={{ border: 'none', height: '60px' }} aria-label="Text input with dropdown button" />
                    <span className="input-group-text" id="validationTooltipEmailPrepend" style={{border: 'none', backgroundColor: 'white'}}><img className="img-fluid" src="img/payment/pwd.png" /></span>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.loginErrors.password}</div>
                </div> */}
               
               
                {/*Grid column*/}
                {/* <div class="col-xs-12">

  <div class="row">
      <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-right: 10px !important;">
        <input type="text" placeholder="Create Password" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
        <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/pwd.png"/></button>
      </div>

      <div class="input-group-prepend mb-3 mx-auto"style="border:solid 1px #ebebeb;max-width:220px;margin-left: 10px !important;">
        <input type="text" placeholder="Confirm Password" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" >
        <button class="btn btn-outline-secondary roboto-bold-14px " href="#"  style="border:none;background-color:white;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/payment/pwd.png"/></button>
      </div>

    </div>

</div> */}
                <div className="col-xs-12 mx-auto d-flex justify-content-center mt-1 ">
                  {/* <span><input type="checkbox" name="vehicle" value="Bike" style="color:#4f5be7">&nbsp;&nbsp;</span> */}
                </div>
                <div className="wrapper mt-3 pb-3">
                  <button className="btn btn-outline-primary btn-hover-white  my-sm-0 center" style={{ height: '60px', width: '210px', borderRadius: '30px' }} type="submit" >
                    <b>Sign in</b></button>
                </div>
                {/*Grid column*/}
                <div className="wrapper mt-3 pb-3 ">
                  <div className="wrapper mt-3 pb-3 ">
                    {/* <span><input type="checkbox" name="vehicle" value="Bike" style="color:#4f5be7">&nbsp;&nbsp;</span> */}
                    <a href="/clinicians_forgot-pwd" style={{ color: '#4f5be7', fontSize: '1rem' }}>Forgot Password</a>
                  </div>
                  <div className="col-xs-12">
                    <div className="row ">
                      {/* Form */}
                      <div className="input-group-prepend  mx-auto" style={{ maxWidth: '220px', marginRight: '10px !important' }}>
                      </div>

                    </div>
                    {/* <button  style="height:40px;width:220px;" class="mt-5 mb-3 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >Login</button> */}
                  </div>
                  <div className="col-xs-12 mx-auto d-flex justify-content-center pt-1 pb-3">
                    {/* <span class="line-span align-self-center"></span> */}
                    {/* <span class="text-uppercase px-2 or align-self-center">or</span> */}
                    {/* <span class="line-span align-self-center"> </span> */}
                  </div>
                  <div className="col-xs-12">
                    <div className="row">
                      {/* Form */}
                      <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '220px', marginRight: '10px !important' }}>
                        {/* <input type="text" placeholder="Email-id" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" > */}
                        {/* <a href=""><img src="img/payment/fb.png"/></a> */}
                      </div>
                      <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '220px', marginLeft: '10px !important' }}>
                        {/* <input type="text" placeholder="Email-id" class="form-control roboto-reg-16px" style="border:none;height:60px" aria-label="Text input with dropdown button" > */}
                        {/* <a href=""><img src="img/payment/gmail.png"/></a> */}
                      </div>
                      {/* Form */}
                    </div>
                  </div>

                </div>
              </Form>
            </div>
            {/* <div className="container text-center bg-white py-4 c333">
              <img className="plus-button img-fluid mb-3" src="./img/registration/Icon.png" />
              <p className=" text-center  " style={{ color: '#84888e' }}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a style={{ color: '#4f5be7', fontSize: '1rem' }} className="pb-5 cr-1" href><strong>CLICK HERE</strong></a> to create your account</p>
            </div> */}
          </div>
          <div style={{ marginTop: '6rem' }}>
          </div>
          {/* ------------------------------------------------------------------------------------------------- */}
          <SubFooter></SubFooter>
        </div>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    )

  }
}

export default CliniciansLogin;
