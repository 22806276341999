import React from 'react';
import Header from './Header';

class BookTest extends React.Component {

    render() {
        return (
            <div>
                <Header></Header>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>Book Your Test</div>
                </div>
                <p ></p>
            </div>
        );
    }
}
export default BookTest;