import React from 'react'
import HeaderLinks from './HeaderLinks';
import NewMainHeader from './NewMainHeader';
import axios from '../axios/axios';
import ProgressBar from './ProgressBar';
import SubMainFooter from './SubMainFooter';

class RiskPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

            noneOption:false,

            isActive: 1,
            mode: "Your Details",
            gender:'',
            progressval:0,
            errors: {},

                              
            basicdetails: {
              pname:'',
              pphone:'',
              pemail:''
            },

                      
            risk: {                       
             
              yourDetails:{
                age:[],
                gender:[],
                heightFeet:[],
                heightInches:[],
                weight:[]
              },
                healthHistory:{
                  riskForCancer:[],                                
                   diagnosed:[]                  
                },
                familyHealthHistory: {
                  diagnosed:[],
                  familyCancer:[],
                  immediateFamily:[]
                },
                myHabits: {
                  drink:[],                 
                  physicalActivity:[],
                  PhysicalHours:[],
                  smoke:[],
                  startedSmoking:[],
                  howmanyCiggratee:[],
                  startedDrinking:[],
                  howmanyDrinks:[],
                  quitSmoking: [],
                  quitDrinking: []
                },
                myDiet: {
                  OilBased: [],
                  eatFish: [],
                  eatbutter: [],
                  meat: [],
                  multivitamins12Years: [],
                  multivitaminsFourDays: [],
                  nuts: []
                },
                reproductiveHealth: {
                  givenBirth: [],
                  menopause: [],
                  estrogenAlone: [],
                  plusProgestin: [],
                  oldAtFirstChild:[]
                },
                medication: {
                  aspirin: [],
                  aspirin2: []
                }
              },  
            login:false  
      }
        
    this.changeTab = this.changeTab.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handle = this.handle.bind(this)
    this.handleChangeAddData=this.handleChangeAddData.bind(this)
    this.checkHandleChangeAddDataHealthHistory=this.checkHandleChangeAddDataHealthHistory.bind(this)
    this.prospectReg = this.prospectReg.bind(this);
    this.handleProspect=this.handleProspect.bind(this);
    this.tabonevalidation=this.tabonevalidation.bind(this);
    this.tabtwovalidation=this.tabtwovalidation.bind(this);
    this.tabthreevalidation=this.tabthreevalidation.bind(this);
    this.tabfourvalidation=this.tabfourvalidation.bind(this);
    this.tabfivevalidation=this.tabfivevalidation.bind(this);
    this.tabsixvalidation=this.tabsixvalidation.bind(this);
    this.tabsevenvalidation=this.tabsevenvalidation.bind(this);
      this.receiveyourriskvalidation=this.receiveyourriskvalidation.bind(this);
    
    }

    tabonevalidation(){
      let fields=this.state.risk.yourDetails;     
      let errors = {};
      let formIsValid = true;

      
      if(!fields["age"].length > 0 ){
        formIsValid = false;       
      errors["age"] = "*Please enter your age.";
      }else if(fields["age"] > 100 || fields["age"] < 0){
        formIsValid = false;       
      errors["age"] = "*Please enter your correct age.";
      }
        if(!fields["gender"].length > 0){
         formIsValid = false;
         errors["gender"] = "*Please select your gender.";
         }

        if(!fields["heightFeet"].length > 0){
           formIsValid = false;
          errors["heightFeet"] = "*Please enter feet's.";
         }else if(fields["heightFeet"] > 10 || fields["heightFeet"] < 0){
          formIsValid = false;
         errors["heightFeet"] = "*Please enter your correct height.";
        }

        if(!fields["heightInches"].length > 0){
          formIsValid = false;
         errors["heightInches"] = "*Please enter inches's.";
        }else if(fields["heightInches"] > 12 || fields["heightInches"] < 0){
          formIsValid = false;
      errors["heightInches"] = "*Please enter your correct height.";
        }
         if(!fields["weight"].length > 0){
           formIsValid = false;
           errors["weight"] = "*Please enter your weight.";
         }
         if(fields["weight"] < 0 || fields["weight"] > 200){
          formIsValid = false;
          errors["weight"] = "*Please enter a valid weight.";
        }
        this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        }
    );
      return formIsValid;
    }

    tabtwovalidation(){
      let fields=this.state.risk.healthHistory.diagnosed;   
      let fields2=this.state.risk.healthHistory;  
      let errors = {};
       let formIsValid = true;

      if(!fields.length > 0){   
        formIsValid = false;       
      errors["diagnosed"] = "*Please select your health history.";
	 }
	  
	  if(!fields2["riskForCancer"].length > 0){
           formIsValid = false;
           errors["option"] = "*Please pick your answer.";          
         }
        
      this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        }
    );  
   
      return formIsValid;
    }

    tabthreevalidation(){
      let fields=this.state.risk.familyHealthHistory;     
      let errors = {};
      let formIsValid = true;
	  
	    
	    if(!fields["diagnosed"].length > 0){
           formIsValid = false;
           errors["fhhdiagnosed"] = "*Please give the your diagnosed details.";
         }
		  if(!fields.familyCancer.length > 0){   
        formIsValid = false;       
      errors["familyCancer"] = "*Please give family health details.";
	 }
	  if(!fields.immediateFamily.length > 0){   
        formIsValid = false;       
      errors["immediateFamily"] = "*Please select your health history.";
	 }
		 
        this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        });
      return formIsValid;
    }

    tabfourvalidation(){
      let fields=this.state.risk.myHabits;
      let sd=this.state.risk.myHabits.startedDrinking;
      let ss=this.state.risk.myHabits.startedSmoking;
      let errors = {};
      let formIsValid = true;
      let ff=this.state.risk.yourDetails.age;
 if(!fields["drink"].length > 0){
  formIsValid = false;
  errors["drink"] = "*Please give the drinking details.";
}
if(fields["drink"] == "yes"){
 if(fields["startedDrinking"] === undefined || fields["startedDrinking"] === ""){
   formIsValid = false;
 errors["startedDrinking"] = "*Please enter your age.";
 }
 if(!fields["howmanyDrinks"].length > 0){
   formIsValid = false;
 errors["howmanyDrinks"] = "*Please enter the number of drinks.";
 }
}
if(fields["drink"] == "noq"){
 if(!fields["startedDrinking"].length > 0){
   formIsValid = false;
 errors["startedDrinking"] = "*Please enter your age.";
 }
 if(!fields["howmanyDrinks"].length > 0){
   formIsValid = false;
 errors["howmanyDrinks"] = "*Please enter the number of drinks.";
 }
}
if(fields["drink"] == "noq"){
 if(!fields["quitDrinking"].length > 0){
   formIsValid = false;
 errors["quitDrinking"] = "*Please enter the age you quit drinking.";
 }else if(fields["quitDrinking"] > ff){
   formIsValid = false;
 errors["quitDrinking"] = "*You are exceeding your age limit.";
 }else if(fields["quitDrinking"] < 0){
   formIsValid = false;
 errors["quitDrinking"] = "*You entered beneath your age.";
 }else if(fields["quitDrinking"] < sd){
   formIsValid = false;
 errors["quitDrinking"] = "*You entered beneath your start drinking age.";
}
}
if(!fields["physicalActivity"].length > 0){
  formIsValid = false;
  errors["physicalActivity"] = "*Please give the your physical activity details.";
}
if(fields["physicalActivity"] == "yes"){
  if(!fields["PhysicalHours"].length > 0){
   formIsValid = false;
   errors["PhysicalHours"] = "*Please select the duration of your physical activity.";
  }
}
if(!fields["smoke"].length > 0){
  formIsValid = false;
  errors["smoke"] = "*Please give the your smoking details.";
}
if(fields["smoke"] == "yes"){
 if(!fields["startedSmoking"].length > 0){
   formIsValid = false;
 errors["startedSmoking"] = "*Please enter your age.";
 }
 if(!fields["howmanyCiggratee"].length > 0){
   formIsValid = false;
 errors["howmanyCiggratee"] = "*Please enter the number of cigarettes.";
 }
}
if(fields["smoke"] == "noq"){
 if(!fields["startedSmoking"].length > 0){
   formIsValid = false;
 errors["startedSmoking"] = "*Please enter your age.";
 }
 if(!fields["howmanyCiggratee"].length > 0){
   formIsValid = false;
 errors["howmanyCiggratee"] = "*Please enter the number of cigarettes.";
 }
 if(!fields["quitSmoking"].length > 0){
   formIsValid = false;
 errors["quitSmoking"] = "*Please enter the age you quit smoking.";
 }else if(fields["quitSmoking"] > ff){
   formIsValid = false;
 errors["quitSmoking"] = "*You are exceeding your age limit.";
 }else if(fields["quitSmoking"] < 0){
   formIsValid = false;
 errors["quitSmoking"] = "*You entered beneath your age.";
}else if(fields["quitSmoking"] < ss){
 formIsValid = false;
errors["quitSmoking"] = "*You entered beneath your start smoke age.";
}
}
if(fields["startedSmoking"] > ff){
 formIsValid = false;
errors["startedSmoking"] = "*You are exceeding your age limit.";
}
if(fields["startedSmoking"] < 0){
 formIsValid = false;
errors["startedSmoking"] = "*You entered beneath your age.";
}
if(fields["startedDrinking"] > ff){
 formIsValid = false;
errors["startedDrinking"] = "*You are exceeding your age limit.";
}
if(fields["startedDrinking"] < 0){
 formIsValid = false;
errors["startedDrinking"] = "*You entered beneath your age.";
}
if(fields["howmanyCiggratee"] < 0){
 formIsValid = false;
errors["howmanyCiggratee"] = "*Please enter the correct number of cigarettes.";
}
if(fields["howmanyDrinks"] < 0){
 formIsValid = false;
errors["howmanyDrinks"] = "*Please enter the correct number of drinks.";
}
this.setState(
{ errors: errors },
() => {
   console.log(this.state.errors)
}
);
      return formIsValid;
    }
    tabfivevalidation(){
      let fields=this.state.risk.myDiet;     
      let errors = {};
      let formIsValid = true;
	  
	    
	    if(!fields["OilBased"].length > 0){
           formIsValid = false;
           errors["OilBased"] = "*Please give the details.";
         }
		   if(!fields["eatFish"].length > 0){
           formIsValid = false;
           errors["eatFish"] = "*Please give the details.";
         }
		   if(!fields["eatbutter"].length > 0){
           formIsValid = false;
           errors["eatbutter"] = "*Please give the details.";
         }
		  if(!fields["meat"].length > 0){
           formIsValid = false;
           errors["meat"] = "*Please give the details.";
         }
		  if(!fields["multivitamins12Years"].length > 0){
           formIsValid = false;
           errors["multivitamins12Years"] = "*Please give the details.";
         }
		  if(!fields["multivitaminsFourDays"].length > 0){
           formIsValid = false;
           errors["multivitaminsFourDays"] = "*Please give the details.";
         }
		  if(!fields["nuts"].length > 0){
           formIsValid = false;
           errors["nuts"] = "*Please give the details.";
         }
		
		 
        this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        }
    );
      return formIsValid;
    }
    
    tabsixvalidation(){
      let fields=this.state.risk.medication;     
      let errors = {};
      let formIsValid = true;
	  
	    
	    if(!fields["aspirin"].length > 0){
           formIsValid = false;
           errors["aspirin"] = "*Please give the medication details.";
         }
		   if(!fields["aspirin2"].length > 0){
           formIsValid = false;
           errors["aspirin2"] = "*Please give the medication details.";
         }
		  		 
        this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        }
    );
      return formIsValid;
    }
    
    tabsevenvalidation(){
      let fields=this.state.risk.reproductiveHealth;     
      let errors = {};
      let formIsValid = true;
      let ff=this.state.risk.yourDetails.age;     

      if(!fields["givenBirth"].length > 0){
        formIsValid = false;
        errors["givenBirth"] = "*Please give your children details.";
      }
      if(fields["givenBirth"] != "none"){
        if(!fields["oldAtFirstChild"].length > ff){
          formIsValid = false;
          errors["oldAtFirstChild"] = "*You are exceeding your age limit.";
        }
        if(fields["oldAtFirstChild"] < 0){          
          formIsValid = false;       
        errors["oldAtFirstChild"] = "*You entered beneath your age.";
        }
      }
      if(!fields["menopause"].length > 0){
        formIsValid = false;
        errors["menopause"] = "*Please give your menopause details.";
      }
      if(!fields["estrogenAlone"].length > 0){
        formIsValid = false;
        errors["estrogenAlone"] = "*Please give the details.";
      }
      if(!fields["plusProgestin"].length > 0){
        formIsValid = false;
        errors["plusProgestin"] = "*Please give the details.";
      }
      if(fields["oldAtFirstChild"] < 0){
        formIsValid = false;
        errors["oldAtFirstChild"] = "*Please give correct age at your first child.";
      }
      
      this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        }
    );
      return formIsValid;
    }
    changeTab(value) {
        if (value === 1) {
          this.setState({
            mode: "Your Details",
            progressval: 0,
            isActive: value
          })
        }
         if (value === 2) {
          if(this.tabonevalidation()){
               this.setState({
               mode: "Your Health History",
               progressval: 20,
               isActive: value
               })
              }
             }         

        if (value === 3) {
          if(this.tabtwovalidation()){
          this.setState({
            mode: "Your Family History",
            progressval: 40,
            isActive: value
          })
        }
        }
        if (value === 4) {
          if(this.tabthreevalidation()){
            this.setState({
              mode: "Your Habits",
              progressval: 50,
              isActive: value
            })
          }
          }
          if (value === 5) {
            if(this.tabfourvalidation()){
            this.setState({
              mode: "Your Diet",
              progressval: 70,
              isActive: value
            })
          }
          }
          if (value === 6) {
            let state = this.state
            if(state.gender === "male"){
            if(this.tabfivevalidation()){
            this.setState({
              mode: "Your Medication History",
              progressval: 100,
              isActive: value
            })
          }
        }else if(state.gender === "female"){
            if(this.tabsevenvalidation()){
            this.setState({
              mode: "Your Medication History",
              progressval: 100,
              isActive: value
            })
          }
        }
          } 
          if(value === 7){
            if(this.tabfivevalidation()){
            this.setState({             
              mode: "Your Reproductive Health",
              progressval: 85,
              isActive: value
            })
          }
          }  
      }
      toRisLogin() {
        // browserHistory.push("/ordertrugene");
         // window.location.href="/risk-login";
         if(this.tabsixvalidation()){
         this.setState({
           login:true
         })
        }
        }
      handle(event) {
        this.setState({ [event.target.name]: event.target.value });
      }
      handleChange(event, name) {

        let test = this.state.risk
        test[event.target.name] = { gender: event.target.value }
        this.setState({
          risk: test
        })
        
      
        
    }
    handleChangeAddData(event, name) {



      let data = this.state.risk[name]
      data[event.target.name] = event.target.value
  
      let healthhis = this.state.risk
      healthhis[name] = data
      this.setState({
          risk: healthhis
      })
      
      
      
  }
    handleChangeForMeat(event, name) {

        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ meat: [  event.target.value ] }
        }else{
          hh[name].meat= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
        
        
        
    }
    handleChangeForMeatQ2(event, name) {


        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ eatbutter: [  event.target.value ] }
        }else{
          hh[name].eatbutter= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
        
       
        
    }
    handleChangeForEatFish(event, name) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ eatFish: [  event.target.value ] }
        }else{
          hh[name].eatFish= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })  
    }
    handleChangeForOilBased(event, name) {

        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ OilBased: [  event.target.value ] }
        }else{
          hh[name].OilBased= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        })         
        
    }
    handleChangeForNuts(event, name) {

        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ nuts: [  event.target.value ] }
        }else{
          hh[name].nuts= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        })             
    }
    handleChangeForMultivitaminsFourDays (event, name) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ multivitaminsFourDays: [  event.target.value ] }
        }else{
          hh[name].multivitaminsFourDays= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        })        
        console.log("my diet details....",this.state.risk)        
    }

    handleChangeForphysicalActivity(event, name) {

        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ physicalActivity: [  event.target.value ] }
        }else{
          hh[name].physicalActivity= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        })        
        console.log("my physical details....",this.state.risk)        
    }

    handleChangePhysicalHours(event, name) {

        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ PhysicalHours: [  event.target.value ] }
        }else{
          hh[name].PhysicalHours= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        })        
        console.log("my physical details....",this.state.risk)        
    }

    handleChangeForSmoking (event, name) {

        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ smoke: [  event.target.value ] }
        }else{
          hh[name].smoke= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        }) 
    }
    handleChangeForDrink(event, name) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ drink: [  event.target.value ] }
        }else{
          hh[name].drink= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        }) 
    }
    checkHandleChangeStartedSmoking(event, name, subname) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ startedSmoking: [  event.target.value ] }
        }else{
          hh[name].startedSmoking= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
       
    
      }  

      checkHandleChangeStartedDrinking(event, name, subname) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ startedDrinking: [  event.target.value ] }
        }else{
          hh[name].startedDrinking= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
       
    
      }  
      
      checkHandleChangeQuitSmoking(event, name, subname) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ quitSmoking: [  event.target.value ] }
        }else{
          hh[name].quitSmoking= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
       
    
      }  
      checkHandleChangeQuitDrinking(event, name, subname) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ quitDrinking: [  event.target.value ] }
        }else{
          hh[name].quitDrinking= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
        
    
      }      

      checkHandleChangeHowMany(event, name, subname) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ howmanyCiggratee: [  event.target.value ] }
        }else{
          hh[name].howmanyCiggratee= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
       
    
      }  
      checkHandleChangeHowManyDrinks(event, name, subname) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ howmanyDrinks: [  event.target.value ] }
        }else{
          hh[name].howmanyDrinks= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
       
    
      }       


    handleChangeForMultivitamins12Years (event, name) {
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ multivitamins12Years: [  event.target.value ] }
        }else{
          hh[name].multivitamins12Years= [ event.target.value ]
        }    
        this.setState({
            risk: hh
        }) 
    }
    //functions for reproductive health
    handleChangeForGivenBirth(event, name) {
      let hh = this.state.risk
      if(hh[name]===undefined){
        hh[name]={ givenBirth : [  event.target.value ] }
      }else{
        hh[name].givenBirth= [ event.target.value ]
      }
  
      this.setState({
          risk: hh
      })
      
     
      
  }
  handleChangeForOld(event, name) {


    let hh = this.state.risk
    if(hh[name]===undefined){
      hh[name]={ oldAtFirstChild : [  event.target.value ] }
    }else{
      hh[name].oldAtFirstChild= [ event.target.value ]
    }

    this.setState({
        risk: hh
    })
    
   
    
}
handleChangeForMenopause(event, name) {


  let hh = this.state.risk
  if(hh[name]===undefined){
    hh[name]={ menopause : [  event.target.value ] }
  }else{
    hh[name].menopause= [ event.target.value ]
  }

  this.setState({
      risk: hh
  })
  
  
  
}
handleChangeForEstrogenAlone(event, name) {


  let hh = this.state.risk
  if(hh[name]===undefined){
    hh[name]={ estrogenAlone : [  event.target.value ] }
  }else{
    hh[name].estrogenAlone= [ event.target.value ]
  }

  this.setState({
      risk: hh
  })
  
  
  
}

handleChangeForEstrogenPlusProgestin(event, name) {


  let hh = this.state.risk
  if(hh[name]===undefined){
    hh[name]={ plusProgestin : [  event.target.value ] }
  }else{
    hh[name].plusProgestin= [ event.target.value ]
  }

  this.setState({
      risk: hh
  })
  
 
  
}

// functions for aspirin

    handleChangeForAspirin(event, name) {


        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ aspirin : [  event.target.value ] }
        }else{
          hh[name].aspirin= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
        
       
        
    }
    handleChangeForAspirin2(event, name) {


        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ aspirin2 : [  event.target.value ] }
        }else{
          hh[name].aspirin2= [ event.target.value ]
        }
    
        this.setState({
            risk: hh
        })
        
       
        
    }

    handleChangeAgeAddData(event, name) {

        
        let hh = this.state.risk
        if(hh[name]===undefined){
          hh[name]={ age: [  event.target.value ] }
        }else{
          hh[name].age= [ event.target.value ]
        }
    
        this.setState({
          risk: hh
        })
    
        
    }
    handleChangeGenderAddData(event, name) {

this.setState({gender:event.target.value})
      let hh = this.state.risk
      if(hh[name]===undefined){
        hh[name]={ gender: [  event.target.value ] }
      }else{
        hh[name].gender= [ event.target.value ]
      }
  
      this.setState({
        risk: hh
      })
  
      
  }
    handleChangeWeightAddData(event, name) {


      let hh = this.state.risk
      if(hh[name]===undefined){
        hh[name]={ weight: [  event.target.value ] }
      }else{
        hh[name].weight= [ event.target.value ]
      }
  
      this.setState({
        risk: hh
      })
  
      
  }
  handleChangeHeightInchesAddData(event, name) {


    
    let hh = this.state.risk
    if(hh[name]===undefined){
      hh[name]={ heightInches: [  event.target.value ] }
    }else{
      hh[name].heightInches= [ event.target.value ]
    }

    this.setState({
      risk: hh
    })

    
}
handleChangeHeightFeetAddData(event, name) {


  let hh = this.state.risk
  if(hh[name]===undefined){
    hh[name]={ heightFeet: [  event.target.value ] }
  }else{
    hh[name].heightFeet= [ event.target.value ]
  }

  this.setState({
    risk: hh
  })

  
}

    checkHandleChangeHealthHistory(event, name, subname) {
     
        let hh = this.state.risk
        if(event.target.value==="yes" ||event.target.value=== "DonNotknow"){
          if(hh[name]===undefined){
            hh[name]={ riskForCancer: [  event.target.value ] }
          }else{
            hh[name].riskForCancer= [ event.target.value ]
          }
      
          this.setState({
              risk: hh
          })
        }else{
          if(hh[name]===undefined){
            hh[name]={ riskForCancer: [  "No" ] }
          }else{
            hh[name].riskForCancer= [ "No" ]
          }
      
          this.setState({
              risk: hh
          })
        }

    
      }
      checkHandleChangeAddDataHealthHistory(e, names, subname,name) {
        this.checkHandleChangeHealthHistory(e, "healthHistory","option")
        let healthhis = this.state.risk
        let lists = healthhis[names];
        let value = e.target.value;
        let aneurysm = lists[subname]

        if(name==="None"){
         
          aneurysm.length =0
          this.setState({ risk: healthhis })
        }else{

        }
       
        if (aneurysm === undefined) {

         

          lists[subname] = [e.target.value];
          //healthhis[subname] = aneurysm
        } else {
         
          if (e.target.checked) {
            aneurysm.push(e.target.value)
           // healthhis[subname] = aneurysm
          } else {
            const index = aneurysm.findIndex((ch) => ch.id === value);
            aneurysm.splice(index, 1);
           //aneurysm.splice(aneurysm.findIndex(obj => obj.value ===  e.target.value), 1)
          //healthhis[subname] = aneurysm
           
          }
        }
    
        this.setState({ risk: healthhis })
    
       
      }
      checkHandleChangeFamilyHealthHistory(event, name, subname) {
    
        let hh = this.state.risk
    
        if(event.target.value==="yes"){
          if(hh[name]===undefined){
            hh[name]={ diagnosed: [  event.target.value ] }
          }else{
            hh[name].diagnosed= [ event.target.value ]
          }
      
          this.setState({
              risk: hh
          })
        }else{
          if(hh[name]===undefined){
            hh[name]={ diagnosed: [  "No" ] }
          }else{
            hh[name].diagnosed= [ "No" ]
          }
      
          this.setState({
              risk: hh
          })
        }
    
      }

      checkHandleChangeAddDataFamilyHealthHistory(e, names, subname,name) {
        this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")
        let healthhis = this.state.risk
        let lists = healthhis[names];
        let value = e.target.value;
        let aneurysm = lists[subname]
       
        if(name==="None"){
         
          aneurysm.length =0
          this.setState({ risk: healthhis })
        }else{

        }
        if (aneurysm === undefined) {
    
          lists[subname] = [e.target.value];
          //healthhis[subname] = aneurysm
        } else {
          if (e.target.checked) {
            aneurysm.push(e.target.value)
           // healthhis[subname] = aneurysm
          } else {
            const index = aneurysm.findIndex((ch) => ch.id === value);
            aneurysm.splice(index, 1);
           //aneurysm.splice(aneurysm.findIndex(obj => obj.value ===  e.target.value), 1)
          //healthhis[subname] = aneurysm
           
          }
        }
    
        this.setState({ risk: healthhis })
    
       
      }
      handleProspect(event) {
       
        let healthhis = this.state.basicdetails
        healthhis[event.target.name] = event.target.value;
        this.setState({ basicdetails: healthhis })
      }
      
    toRiskSucess() {
      // browserHistory.push("/ordertrugene");
        window.location.href="/risktq";
      }

      receiveyourriskvalidation(){
        let state= this.state.basicdetails;
        let errors = {};
         let formIsValid = true;
      
         
         if(state["pname"] === undefined|| state["pname"] === ""){
              formIsValid = false;
              errors["pname"] = "*Please enter your name.";
            }else if(!state["pname"].match(/^[a-zA-Z ]*$/)) {
              formIsValid = false;
              errors["pname"] = "*Please enter alphabet characters only.";
            }

            if(state["pphone"] === undefined|| state["pphone"] === ""){
              formIsValid = false;
              errors["pphone"] = "*Please enter your contact number.";
            }else if (typeof state["pphone"] !== "undefined") {
              if (!state["pphone"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["pphone"] = "*Please enter valid mobile no.";
              }
            }

            if(state["pemail"] === undefined|| state["pemail"] === ""){
              formIsValid = false;
              errors["pemail"] = "*Please enter your email address.";
            }else if (typeof state["pemail"] !== "undefined") {
              //regular expression for email validation
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(state["pemail"])) {
                formIsValid = false;
                errors["pemail"] = "*Please enter valid email address";
              }
            }        
		  		 
        this.setState(
        { errors: errors },
        () => {
            console.log(this.state.errors) 
        }
    );
      return formIsValid;
         
      }
      prospectReg() {
             if(this.receiveyourriskvalidation()){
            const riskpage=this.state.risk;
            const { pname, pphone, pemail } =this.state.basicdetails;
           
            axios.post('users/prospectRiskpage',{riskpage, pname, pphone, pemail})
                .then((response) => {
                   
                    this.props.history.push("/risktq");     
                }).catch(error => {
                  if(error.response===undefined){
                    this.props.history.push("/error")
                  }else{
                    this.setState({
                        errorMsg: error.response.data.message
                    })  
                  }
                   
                });
               
        
              }
      
    }
   render(){
     console.log("risk.....",this.state.risk);
         return(
        <div>
        <HeaderLinks></HeaderLinks>
        <style dangerouslySetInnerHTML={{__html: "\n.progress{\n  width:100%%;\nmargin:15px;\n/* margin-left:0px; */\n}\n.progress1{\nwidth:80%;\nmargin:15px;\n/* max-width: 1000px; */\n/* margin-left:0px; */\n}\n.percantage{\n  padding-left:20px;\n\n  width:13%\n}\n.bhhh{\n  border:1px solid white;max-width:900px;background:white;border-radius:2rem;\n}\n.bhhh1{\n  border:1px solid white;max-width:700px;background:white;border-radius:2rem;\n}\n    /* .container1 {\n      width: 70%;\n\n      border-radius: 2rem;\n      padding: 15px!important;\n\n    }\n\n    .progressbar {\n      counter-reset: step;\n    }\n\n    .progressbar li {\n      list-style-type: none;\n      float: left;\n      width: 14%;\n      position: relative;\n      text-align: center;\n    }\n\n    .progressbar li:before {\n      content: counter(step);\n      counter-increment: step;\n      width: 30px;\n      height: 30px;\n      line-height: 30px;\n      border: 1px solid #ddd;\n      display: block;\n      text-align: center;\n      margin: 0 auto 10px auto;\n      border-radius: 50%;\n      background-color: white;\n\n    }\n\n    .progressbar li:after {\n      content: '';\n      position: absolute;\n      width: 100%;\n      height: 1px;\n      background-color: #ddd;\n      top: 15px;\n      left: -50%;\n      z-index: -1;\n    }\n\n    .progressbar li:first-child:after {\n      content: none;\n    }\n\n    .progressbar li.active {\n      color: green!important;\n    }\n\n    .progressbar li.active:before {\n      border-color: green!important;\n    }\n\n    .progressbar li.active + li:after {\n      background-color: green!important;\n    } */\n    " }} />
        <NewMainHeader></NewMainHeader>
        {/* end of header */}
        <div style={{marginTop: '6rem'}} />
        {this.state.login?<div className="container  text-center shadow px-0 pt-5" style={{borderRadius: '2rem'}}>
          <div style={{backgroundColor: '#fff'}}>
            <h1 className="text-center roboto-black-36px pt-5" style={{color: '#4f5be7'}}>Your Risk Assessment Questionnaire is now COMPLETE.</h1>
            <p className="px-5 roboto-black-30px py-3">SIGN UP TO RECIEVE YOUR RISK ASSESSMENT REPORT FOR FREE</p>
          </div>
          <div style={{backgroundColor: '#eff7ff'}}>
              <div className=" text-center py-5">
                <div className=" hero-ravi justify-content-center px-2" style={{maxWidth: '336px', margin: '0px auto'}}>
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pname}</div>
                  <div className="learn-text-box input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                    <input type="text" placeholder="Name" name="pname" onChange={this.handleProspect} className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                    <button className="btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/risk-page/tq/Icon.png" /></button>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pemail}</div>
                  <div className="learn-text-box input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                    <input type="email" placeholder="E-mail Id" name="pemail" onChange={this.handleProspect} className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                    <button className="btn btn-outline-secondary" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/risk-page/tq/Icon1.png" /></button>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pphone}</div>
                  <div className="learn-text-box input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                    <input type="number" placeholder="Phone" name="pphone" onChange={this.handleProspect} className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                    <button className="btn btn-outline-secondary" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/risk-page/tq/icon2.png" /></button>
                  </div>
                  <div className="input-group-prepend mb-3 ">
                    <button className="btn btn-outline-primary  btn-hover-white py-3 " name="submit" style={{borderRadius: '30px', width: '400px', margin: 'auto', border: 'solid 1px #4f5be7', background: '#4f5be7', color: 'white'}} onClick={this.prospectReg}>Submit</button>
                  </div>
                </div>
              </div>
          </div>
        </div>:<section className="container shadow px-0" style={{borderRadius: '2.5rem'}}>
          <div className="container">
            <h1 id="head-tab1" className="roboto-black-36px py-5 px-4">{this.state.mode}</h1>
          </div>
          <div style={{backgroundColor: '#eff7ff', padding: '2rem'}}>
            <ul className="nav pr-5" id="myTab" role="tablist">
              <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link active pad-l-2rem pl-0" style={{ color: this.state.isActive === 1 ? "#0080ff" : "#000000", paddingRight: '40px' }} id="home-tab" data-toggle="tab" href="#pills-home" role="tab" aria-controls="home"  onClick={() => this.changeTab(1)}  aria-selected="true" >Your Details</a>
              </li>
              <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link" id="profile-tab" data-toggle="pill" style={{ color: this.state.isActive === 2 ? "#0080ff" : "#000000",  paddingRight: '40px' }} href="#pills-2" role="tab" aria-controls="pills-history" aria-selected="false" onClick={() => this.changeTab(2)} >Health History</a>
              </li>
              <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link" id="messages-tab" data-toggle="pill" style={{ color: this.state.isActive === 3 ? "#0080ff" : "#000000", paddingRight: '40px' }} onClick={() => this.changeTab(3)} href="#pills-3" role="tab" aria-controls="pills-fhistory" aria-selected="false" >Family History</a>
              </li>
              <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link" id="settings-tab" data-toggle="pill" href="#pills-4" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ color: this.state.isActive === 4 ? "#0080ff" : "#000000", paddingRight: '40px' }} onClick={() => this.changeTab(4)}>My Habits</a>
              </li>
              <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link" id="settings-tab" data-toggle="pill" href="#pills-5" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ color: this.state.isActive === 5 ? "#0080ff" : "#000000", paddingRight: '30px' }} onClick={() => this.changeTab(5)}>My Diet</a>
              </li>
              {this.state.gender==="female"? <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link" id="settings-tab" data-toggle="pill" href="#pills-5" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ color: this.state.isActive === 7 ? "#0080ff" : "#000000", paddingRight: '20px' }} onClick={() => this.changeTab(7)}>Reproductive Health</a>
              </li>:''}
              
              <li className="nav-item pr-md-3 pr-sm-2">
                <a className="nav-link" id="settings-tab" data-toggle="pill" href="#pills-7" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ color: this.state.isActive === 6 ? "#0080ff" : "#000000" }} onClick={() => this.changeTab(6)}>Medication</a>
              </li>
            </ul>
           {/* Progressbar space */}
          <ProgressBar  barvalue={this.state.progressval}/>

          </div>

          {this.state?this.state.gender==="female"? 
              <div className="tab-content">
              <div className="container tab-content py-5 px-4" id="pills-tabContent">
                <div className="tab-pane fade show active" className={this.state.isActive === 1 ? "tab-pane active" : "tab-pane"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.gender}</div>
                  <h2 className="roboto-bold-36px mx-2">Are you male or female?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="yourDetails" id="yourDetails"  value="male"  onChange={(e) => this.handleChangeGenderAddData(e, "yourDetails")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">Male</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="yourDetails" id="yourDetails" value="female"onChange={(e) => this.handleChangeGenderAddData(e, "yourDetails")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">Female</h6>
                      </label>
                    </div>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.age}</div>
                  <p className="roboto-bold-36px mx-2">Your age?</p>
                  <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder="Estimates are okay" name="age" onChange={(e) => this.handleChangeAgeAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.heightFeet} &nbsp;&nbsp;&nbsp; {this.state.errors.heightInches}</div>                
                  <p className="roboto-bold-36px mx-2">Your height?</p>
                  <div className="d-flex" style={{maxWidth: '400px'}}>
                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder="Feet" name="heightFeet" onChange={(e) => this.handleChangeHeightFeetAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                    </div>
                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder="Inches" name="heightInches" onChange={(e) => this.handleChangeHeightInchesAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                    </div>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.weight}</div>
                  <p className="roboto-bold-36px mx-2">Your Weight (in Kgs)?</p>
                  <div className="d-flex" style={{maxWidth: '400px'}}>
                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" name="weight" onChange={(e) => this.handleChangeWeightAddData(e, "yourDetails")} placeholder={0} className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                    </div>
                    <p className="pt-2">kg</p>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="container d-flex justify-content-end">
                    {/* <button type="submit" class="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"onclick="hideandshowhomesec1()" style="height:3.75rem;width:8.3rem;"/>Back</button> */}
                    <button  className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" data-toggle="pill" href="#pills-2" role="tab" aria-controls="pills-history" onClick={() => this.changeTab(2)} aria-selected="true" style={{height: '3.75rem', width: '8.3rem'}}>Next</button>
                  </div>
                </div>
                {/* <div class="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-history-tab"> */}
                <div className="tab-pane fade" className={this.state.isActive === 2 ? "tab-pane active" : "tab-pane"}  id="pills-2" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <h2 className="roboto-bold-36px mx-2">Have you ever been diagnosed with :</h2>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.diagnosed}</div>
                  <p className="roboto-reg-18px mx-2">Choose all that apply.</p>
                  <div className="  mx-2">
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="hypertension" value="hypertension"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","hypertension")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "hypertension") === "hypertension" ? "checked" : "":"":""} id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   High blood pressure (hypertension)
                        </h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="highBloodCholesterol" value="highBloodCholesterol"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","highBloodCholesterol")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "highBloodCholesterol") === "highBloodCholesterol" ? "checked" : "":"":""} defaultValue id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   High blood cholesterol</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="diabetes" value="diabetes"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","diabetes")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "diabetes") === "diabetes" ? "checked" : "":"":""} id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Diabetes or a problem with blood sugar</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="heartDisease" value="heartDisease"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","heartDisease")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "heartDisease") === "heartDisease" ? "checked" : "":"":""} id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Heart Disease
                        </h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Stroke" value="Stroke"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","Stroke")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "Stroke") === "Stroke" ? "checked" : "":"":""} id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Stroke</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Cancer" value="Cancer"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","Cancer")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "Cancer") === "Cancer" ? "checked" : "":"":""} id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Cancer - Any type other than basal or squamous cell skin cancer</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","None")} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None") === "None" ? "checked" : "":"":""} id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   None</h6>
                      </label>
                      </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.option}</div>
                  <h2 className="roboto-bold-36px mx-2">Have you been ever told that you have a genetic mutation that raises your risk for cancer?
                    Examples include mutations in the following genes:
                    BRCA1, BRCA2, TP53, PTEN and CDH1.</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="yes" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}  id="exampleRadios1" />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="no" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}   />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="DonNotknow" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="roboto-reg-18px">  Don’t know - I have not been tested.</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="d-none" id="onlyfemale">
                    <h2 className="roboto-bold-36px mx-2">Have you ever been informed by a doctor that you have a benign or non-cancerous
                      changes in your breasts?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  </div>
                  <div className="container d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onClick={() => this.changeTab(1)} style={{height: '3.75rem', width: '8.3rem'}}>Back</button>
                    <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(3)}>Next</button>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-3" className={this.state.isActive === 3 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.immediateFamily}</div>
                  <h2 className="roboto-bold-36px mx-2">Has anyone in your immediate family (mother, father, sister, brother) had?::</h2>
                  <p className="roboto-reg-18px mx-2">Choose all that apply.</p>
                  <div className="  mx-2">
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox"  name="heartDisease" value="heartDisease"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","heartDisease")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "heartDisease") === "heartDisease" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Heart Disease
                        </h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Stroke" value="Stroke"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","Stroke")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "Stroke") === "Stroke" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Stroke</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="diabetes" value="diabetes"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","diabetes")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "diabetes") === "diabetes" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Diabetes</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","None")} defaultChecked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None") === "None" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   None</h6>
                        </label>
                      </div>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.familyCancer}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you have a family history (on your mother's side or your father's side) of any of the
                    following?</h2>
                  {/* <p class="roboto-reg-18px mx-2">Choose all that apply.</p> */}
                  <div className="  mx-2">
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Breast/Ovarian" value="Breast/Ovarian"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Breast/Ovarian")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Breast/Ovarian") === "Breast/Ovarian" ? "checked" : "":"":""} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Breast and / or Ovarian Cancer
                      </h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Cervical" value="Cervical"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Cervical")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Cervical") === "Cervical" ? "checked" : "":"":""} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Cervical Cancer</h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Diabetes" value="Diabetes"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Diabetes")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Diabetes") === "Diabetes" ? "checked" : "":"":""} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Diabetes or a problem with blood sugar</h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Colorectal" value="Colorectal"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Colorectal")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Colorectal") === "Colorectal" ? "checked" : "":"":""}/>
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Colorectal Cancer</h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Lung" value="Lung"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Lung")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Lung") === "Lung" ? "checked" : "":"":""} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Lung Cancer</h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Uterine" value="Uterine"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Uterine")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Uterine") === "Uterine" ? "checked" : "":"":""} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  Uterine Cancer</h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Prostrate" value="Prostrate"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Prostrate")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Prostrate") === "Prostrate" ? "checked" : "":"":""}/>
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Prostrate Cancer</h6>
                    </label>
                  </div>
                  <div className="form-check  ">
                    <input className="form-check-input" type="checkbox" name="Stomach" value="Stomach"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Stomach")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Stomach") === "Stomach" ? "checked" : "":"":""} />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">   Stomach Cancer</h6>
                    </label>
                  </div>
  
                  <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","None")} defaultChecked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None") === "None" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">None</h6>
                        </label>
                      </div>
                </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.fhhdiagnosed}</div>
                  <p className="roboto-bold-36px mx-2">Do you have any brothers or sisters diagnosed with Cancer</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="diagnosed" value="yes" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")} id="exampleRadios1" defaultValue="option1"  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="diagnosed" value="no" defaultChecked onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="container d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(2)}>Back</button>
                    <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(4)}>Next</button>
                  </div>
                </div>
                {/* pills4 */}
                <div className="tab-pane fade" className={this.state.isActive === 4 ? "tab-pane active" : "tab-pane"} id="pills-4" role="tabpanel" aria-labelledby="pills-history-tab">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.smoke}</div>
                  <h2 className="roboto-bold-36px mx-2">Your Smoking History :</h2>
                  <p className="roboto-bold-18px mx-2">Do you smoke cigarettes?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                    <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No - I Never Smoked</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                    <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="noq" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  I have Quit Smoking</h6>
                      </label>
                    </div>
                  </div>
                  {this.state.risk.myHabits?this.state.risk.myHabits.smoke ? this.state.risk.myHabits.smoke[0] === "yes"?
                <div>
                    <hr  className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="row">
                      <div className="col-6">
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedSmoking}</div>
                        <p className="roboto-bold-18px mx-2">How old were you when you started smoking?</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" placeholder="Your age" name="startedSmoking" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedSmoking(e, "myHabits", "startAges")} />
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyCiggratee}</div>
                        <p className="roboto-bold-18px mx-2">How many cigarettes</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                          <input type="number"  name="howmanyCiggratee" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowMany(e, "myHabits", "howMany")} />
                        </div>
                        
                      </div>
                      
                    </div>
                    </div>
                    : '' : '':''}
                  {this.state.risk.myHabits?this.state.risk.myHabits.smoke ? this.state.risk.myHabits.smoke[0] === "noq"?
              <div>
                  <hr  className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="row">
                    <div className="col-6">
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedSmoking}</div>
                      <p className="roboto-bold-18px mx-2">How old were you when you started smoking?</p>
                      <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder="Your age" name="startedSmoking" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedSmoking(e, "myHabits", "startAges")} />
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyCiggratee}</div>
                      <p className="roboto-bold-18px mx-2">How many cigarettes</p>
                      <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number"  name="howmanyCiggratee" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowMany(e, "myHabits", "howMany")} />
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.quitSmoking}</div>
                      <p className="roboto-bold-18px mx-2">At what age did you quit smoking?</p>
                      <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" name="endAges" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeQuitSmoking(e, "myHabits", "endAges")} />
                      </div>
                    </div>
                    <div  className="col-6">
                      <img className="img-fluid" src="img/risk-page/tq/4-layers.png" />
                    </div>
                  </div>
                  </div>
                  : '' : '':''}
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.drink}</div>
                  <h2 className="roboto-bold-36px mx-2">Your Drinking History :</h2>
                  <p className="roboto-bold-18px mx-2">Do you drink alcohol?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3 ">
                      <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3 ">
                      <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No - I Never Drank alcohol</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3 ">
                      <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="noq" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18"> No - I have Quit Drinking Alcohol</h6>
                      </label>
                    </div>
                  </div>
                  {this.state.risk.myHabits?this.state.risk.myHabits.drink ? this.state.risk.myHabits.drink[0] === "yes"?
                  <div>
                  <hr id="drink44" className="mx-auto " style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedDrinking}</div>
                  <p id="drink1" className="roboto-bold-18px mx-2 ">How old were you when you started drinking?</p>
                  <div id="drink11" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} name="startedDrinking" aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedDrinking(e, "myHabits", "startAges")}/>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyDrinks}</div>
                  <p id="drink2" className="roboto-bold-18px mx-2 ">On average how many servings of alcohol did you have per day?</p>
                  <p id="drink22" className="roboto-reg-18px mx-2 ">One serving is equal to one peg of hard liquor / one can of beer / one glass of wine</p>
                  <div id="drink222" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder={0} className="form-control roboto-reg-16px" name="howmanyDrinks" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowManyDrinks(e, "myHabits", "howMany")}/>
                  </div>

                  </div>
                  : '' : '':''}
                  {this.state.risk.myHabits?this.state.risk.myHabits.drink ? this.state.risk.myHabits.drink[0] === "noq"?
                  <div>
                  <hr id="drink44" className="mx-auto " style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedDrinking}</div>
                  <p id="drink1" className="roboto-bold-18px mx-2 ">How old were you when you started drinking?</p>
                  <div id="drink11" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} name="startedDrinking" aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedDrinking(e, "myHabits", "startAges")}/>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyDrinks}</div>
                  <p id="drink2" className="roboto-bold-18px mx-2 ">On average how many servings of alcohol did you have per day?</p>
                  <p id="drink22" className="roboto-reg-18px mx-2 ">One serving is equal to one peg of hard liquor / one can of beer / one glass of wine</p>
                  <div id="drink222" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder={0} className="form-control roboto-reg-16px" name="howmanyDrinks" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowManyDrinks(e, "myHabits", "howMany")}/>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.quitDrinking}</div>
                  <p id="drink33" className="roboto-bold-18px mx-2 ">At what age did you quit Drinking?</p>
                  <div id="drink333" className="input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder={0} name="endAges" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button"  onChange={(e) => this.checkHandleChangeQuitDrinking(e, "myHabits", "endAges")}/>
                  </div>
                  </div>
                  : '' : '':''}
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.physicalActivity}</div>
                  <h2 className="roboto-bold-36px mx-2">Your Physical Activity</h2>
                  <p className="roboto-bold-18px mx-2">Do you walk (exercise or do other moderate physical activity) for at least 30 minutes on most days of the week, or at least do moderate physical
                    activity for 3 hours per week?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="physicalActivity" id="physicalActivity" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForphysicalActivity(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                    <input className="form-check-input" type="radio" name="physicalActivity" id="physicalActivity" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForphysicalActivity(e, "myHabits","option")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No </h6>
                      </label>
                    </div>
                  </div>
                 
                  {this.state.risk.myHabits?this.state.risk.myHabits.physicalActivity ? this.state.risk.myHabits.physicalActivity[0] === "yes"?
                  <div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.PhysicalHours}</div>
                  <p className="roboto-bold-18px mx-2">How many non-work hours per day do you spend watching television shows, playing video games, or using a computer/tablet?</p>
                  <div className=" d-flex mx-2">
                      <div className="col-lg-12 col-md-6 col-sm-3">
                      <div className="form-check   mr-3">
                          <input className="form-check-input" type="radio" name="exampleRadios" value="Less than 1 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Less than 1 hour</h6>
                          </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" value="1 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  1 hour </h6>
                          </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" value="2 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  2 hour </h6>
                          </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" value="3 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  3 hour </h6>
                          </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" value="4 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  4 hour </h6>
                          </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" value="More than 5 hours" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  More than 5 hours </h6>
                          </label>
                      </div>
                      </div>
                  </div>
                  </div>

                  : '' : '':''}
                  
                
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="container d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(3)}>Back</button>
                    <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}} onClick={() => this.changeTab(5)}>Next</button>
                  </div>
                </div>
                {/* pill5 */}
                <div className="tab-pane fade" id="pills-5" className={this.state.isActive === 5 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.meat}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you eat three or more servings of processed meat in a week?</h2>
                  <p className="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="meat" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForMeat(e, "myDiet")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                    <input className="form-check-input" type="radio" name="meat" id="exampleRadios1" value="no"  onChange={(e) => this.handleChangeForMeat(e, "myDiet")}/>
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.eatbutter}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you eat butter, lard / fat, red meat (Beef, Mutton, Lamb, Pork), cheese, or whole milk 2
                    or more times per day?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                    <input className="form-check-input" type="radio" name="eatbutter" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForMeatQ2(e, "myDiet")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                    <input className="form-check-input" type="radio" name="eatbutter" id="exampleRadios1" value="no"  onChange={(e) => this.handleChangeForMeatQ2(e, "myDiet")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.eatFish}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you eat fish two or more times in a week?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                    <input className="form-check-input" type="radio" name="eatFish" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForEatFish(e, "myDiet")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="eatFish" id="exampleRadios1" value="no" onChange={(e) => this.handleChangeForEatFish(e, "myDiet")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.OilBased}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you eat oil-based salad dressing or use liquid vegetable oil for cooking on most days?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="OilBased" value="yes"  onChange={(e) => this.handleChangeForOilBased(e, "myDiet")} id="exampleRadios1" defaultValue="option1"  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="OilBased" value="no"  onChange={(e) => this.handleChangeForOilBased(e, "myDiet")}  id="exampleRadios1" defaultValue="option1"  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.nuts}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you eat at least 3 servings of nuts per week?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="nuts" value="yes"  onChange={(e) => this.handleChangeForNuts(e, "myDiet")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="nuts" value="no"  onChange={(e) => this.handleChangeForNuts(e, "myDiet")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.multivitaminsFourDays}</div>
                  <h2 className="roboto-bold-36px mx-2">Do you take a multivitamins for more than four days in a week?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="multivitaminsFourDays" value="yes"  onChange={(e) => this.handleChangeForMultivitaminsFourDays(e, "myDiet")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="multivitaminsFourDays" value="no"  onChange={(e) => this.handleChangeForMultivitaminsFourDays(e, "myDiet")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.multivitamins12Years}</div>
                  <h2 className="roboto-bold-36px mx-2">Have you been taking multivitamins regularly for 12 years or more?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="multivitamins12Years" value="yes"  onChange={(e) => this.handleChangeForMultivitamins12Years(e, "myDiet")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="multivitamins12Years" value="no"  onChange={(e) => this.handleChangeForMultivitamins12Years(e, "myDiet")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="container d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(4)}>Back</button>
                    <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(7)}>Next</button>
                  </div>
                </div>
                <div className={this.state.isActive === 7 ? "tab-pane active" : "tab-pane"}  id="pills-6" role="tabpanel" aria-labelledby="pills-history-tab">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.givenBirth}</div>
                  <h2 className="roboto-bold-36px mx-2">How many children have you given birth to?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="birth" id="exampleRadios1" defaultValue="option1" value="none" onChange={(e) => this.handleChangeForGivenBirth(e, "reproductiveHealth")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  None</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="birth" id="exampleRadios1" defaultValue="option1" value="1" onChange={(e) => this.handleChangeForGivenBirth(e, "reproductiveHealth")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  1</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="birth" id="exampleRadios1" defaultValue="option1" value="2" onChange={(e) => this.handleChangeForGivenBirth(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  2</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="birth" id="exampleRadios1" defaultValue="option1" value="3 or more" onChange={(e) => this.handleChangeForGivenBirth(e, "reproductiveHealth")}  />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  3 or more</h6>
                      </label>
                    </div>
                  </div>
                   <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.oldAtFirstChild}</div>
                  <p className="roboto-bold-18px mx-2">How old were you when you had your first child?</p>
                  <div className="d-flex" style={{maxWidth: '400px'}}>
                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" name="oldAtFirstChild" onChange={(e) => this.handleChangeForOld(e, "reproductiveHealth")} placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                    </div>
                    {/* <p class="pt-2"></p> */}
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.menopause}</div>
                  <h2 className="roboto-bold-36px mx-2">Have you gone through menopause?</h2>
                  {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="menopause" id="exampleRadios1" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForMenopause(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="menopause" id="exampleRadios1" defaultValue="option1" value="Before the age of 55" onChange={(e) => this.handleChangeForMenopause(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes – Before the age of 55</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="menopause" id="exampleRadios1" defaultValue="option1" value="At age 55 or older" onChange={(e) => this.handleChangeForMenopause(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes – At age 55 or older</h6>
                      </label>
                    </div>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.estrogenAlone}</div>
                  <p className="roboto-bold-18px mx-2">Have you used estrogen-alone menopausal therapy (like Premarin) for 5 or more years?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="estrogen-alone" id="exampleRadios1" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForEstrogenAlone(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="estrogen-alone" id="exampleRadios1" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForEstrogenAlone(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="estrogen-alone" id="exampleRadios1" defaultValue="option1" value="Don't know" onChange={(e) => this.handleChangeForEstrogenAlone(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Don't know</h6>
                      </label>
                    </div>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.plusProgestin}</div>
                  <p className="roboto-bold-18px mx-2">Have you used estrogen-plus-progestin menopausal hormone therapy (like Prempro) for 5 or more years?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="estrogen-plus-progestin" id="exampleRadios1" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForEstrogenPlusProgestin(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="estrogen-plus-progestin" id="exampleRadios1" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForEstrogenPlusProgestin(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="estrogen-plus-progestin" id="exampleRadios1" defaultValue="option1" value="Don't know" onChange={(e) => this.handleChangeForEstrogenPlusProgestin(e, "reproductiveHealth")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Don't know</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="container d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}} onClick={() => this.changeTab(5)}>Back</button>
                    <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}} onClick={() => this.changeTab(6)}>Next</button>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-7" className={this.state.isActive === 6 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.aspirin}</div>
                  <p className="roboto-bold-36px mx-2">Do you take an aspirin every day?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="aspirin" value="yes"  onChange={(e) => this.handleChangeForAspirin(e, "medication")}/>
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="aspirin" value="no"  onChange={(e) => this.handleChangeForAspirin(e, "medication")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.aspirin2}</div>
                  <p className="roboto-bold-36px mx-2">Have you taken aspirin 2 or more times per week for 6 or more years?</p>
                  <div className=" d-flex mx-2">
                    <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="aspirin2" value="yes"  onChange={(e) => this.handleChangeForAspirin2(e, "medication")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Yes</h6>
                      </label>
                    </div>
                    <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="aspirin2" value="no"  onChange={(e) => this.handleChangeForAspirin2(e, "medication")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  No</h6>
                      </label>
                    </div>
                  </div>
                  <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div className="container d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(7)}>Back</button>
                    <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"  onClick={() => this.toRisLogin()} style={{height: '3.75rem', width: '8.3rem'}}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          :    <div className="tab-content">
          <div className="container tab-content py-5 px-4" id="pills-tabContent">
            <div className="tab-pane fade show active" className={this.state.isActive === 1 ? "tab-pane active" : "tab-pane"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.gender}</div>
              <h2 className="roboto-bold-36px mx-2">Are you male or female?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="yourDetails" id="yourDetails"   value="male"  onChange={(e) => this.handleChangeGenderAddData(e, "yourDetails")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Male</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="yourDetails" id="yourDetails" value="female"onChange={(e) => this.handleChangeGenderAddData(e, "yourDetails")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Female</h6>
                  </label>
                </div>
              </div>
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.age}</div>
              <p className="roboto-bold-36px mx-2">Your age?</p>
              <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                <input type="number" placeholder="Estimates are okay" name="age" onChange={(e) => this.handleChangeAgeAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
              </div>
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.heightFeet} &nbsp;&nbsp;&nbsp; {this.state.errors.heightInches}</div>        
              <p className="roboto-bold-36px mx-2">Your height?</p>
              <div className="d-flex" style={{maxWidth: '400px'}}>
                <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                  <input type="number" placeholder="Feet" name="heightFeet" onChange={(e) => this.handleChangeHeightFeetAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                </div>
                  <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                  <input type="number" placeholder="Inches" name="heightInches" onChange={(e) => this.handleChangeHeightInchesAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                </div>
              </div>
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.weight}</div>
              <p className="roboto-bold-36px mx-2">Your Weight (in Kgs)?</p>
              <div className="d-flex" style={{maxWidth: '400px'}}>
                <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                  <input type="number" name="weight" onChange={(e) => this.handleChangeWeightAddData(e, "yourDetails")} placeholder={0} className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                </div>
                <p className="pt-2">kg</p>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="container d-flex justify-content-end">
                {/* <button type="submit" class="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"onclick="hideandshowhomesec1()" style="height:3.75rem;width:8.3rem;"/>Back</button> */}
                <button  className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" data-toggle="pill" href="#pills-2" role="tab" aria-controls="pills-history" onClick={() => this.changeTab(2)} aria-selected="true" style={{height: '3.75rem', width: '8.3rem'}}>Next</button>
              </div>
            </div>
            {/* <div class="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-history-tab"> */}
            <div className="tab-pane fade" className={this.state.isActive === 2 ? "tab-pane active" : "tab-pane"}  id="pills-2" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.diagnosed}</div>
              <h2 className="roboto-bold-36px mx-2">Have you ever been diagnosed with :</h2>
              <p className="roboto-reg-18px mx-2">Choose all that apply.</p>
              <div className="  mx-2">
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="hypertension" value="hypertension"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","hypertension")}  disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "hypertension") === "hypertension" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   High blood pressure (hypertension)
                    </h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="highBloodCholesterol" value="highBloodCholesterol"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","highBloodCholesterol")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "highBloodCholesterol") === "highBloodCholesterol" ? "checked" : "":"":""} defaultValue id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   High blood cholesterol</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="diabetes" value="diabetes"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","diabetes")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "diabetes") === "diabetes" ? "checked" : "":"":""} id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Diabetes or a problem with blood sugar</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="heartDisease" value="heartDisease"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","heartDisease")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "heartDisease") === "heartDisease" ? "checked" : "":"":""} id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Heart Disease
                    </h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Stroke" value="Stroke"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","Stroke")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "Stroke") === "Stroke" ? "checked" : "":"":""} id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Stroke</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Cancer" value="Cancer"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","Cancer")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "Cancer") === "Cancer" ? "checked" : "":"":""} id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Cancer - Any type other than basal or squamous cell skin cancer</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","None")} defaultChecked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None") === "None" ? "checked" : "":"":""} id="defaultCheck1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   None</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.option}</div>
              <h2 className="roboto-bold-36px mx-2">Have you been ever told that you have a genetic mutation that raises your risk for cancer?
                Examples include mutations in the following genes:
                BRCA1, BRCA2, TP53, PTEN and CDH1.</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="yes" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}  id="exampleRadios1" />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  defaultChecked value="no" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}   />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="DonNotknow" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="roboto-reg-18px">  Don’t know - I have not been tested.</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="d-none" id="onlyfemale">
                <h2 className="roboto-bold-36px mx-2">Have you ever been informed by a doctor that you have a benign or non-cancerous
                  changes in your breasts?</h2>
                {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                <div className=" d-flex mx-2">
                  <div className="form-check   mr-3">
                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  Yes</h6>
                    </label>
                  </div>
                  <div className="form-check  mr-3">
                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                    <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  No</h6>
                    </label>
                  </div>
                </div>
                <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              </div>
              <div className="container d-flex justify-content-end">
                <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onClick={() => this.changeTab(1)} style={{height: '3.75rem', width: '8.3rem'}}>Back</button>
                <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(3)}>Next</button>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-3" className={this.state.isActive === 3 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.immediateFamily}</div>
              <h2 className="roboto-bold-36px mx-2">Has anyone in your immediate family (mother, father, sister, brother) had?:</h2>
              <p className="roboto-reg-18px mx-2">Choose all that apply.</p>
              <div className="  mx-2">
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox"  name="heartDisease" value="heartDisease"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","heartDisease")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "heartDisease") === "heartDisease" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Heart Disease
                    </h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Stroke" value="Stroke"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","Stroke")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "Stroke") === "Stroke" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Stroke</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="diabetes" value="diabetes"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","diabetes")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "diabetes") === "diabetes" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Diabetes</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","None")} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None") === "None" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   None</h6>
                  </label>
                </div>
              </div>
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.familyCancer}</div>
              <h2 className="roboto-bold-36px mx-2">Do you have a family history (on your mother's side or your father's side) of any of the
                following?:</h2>
              {/* <p class="roboto-reg-18px mx-2">Choose all that apply.</p> */}
              <div className="  mx-2">
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Breast/Ovarian" value="Breast/Ovarian"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Breast/Ovarian")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Breast/Ovarian") === "Breast/Ovarian" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Breast and / or Ovarian Cancer
                    </h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Cervical" value="Cervical"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Cervical")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Cervical") === "Cervical" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Cervical Cancer</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Diabetes" value="Diabetes"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Diabetes")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Diabetes") === "Diabetes" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Diabetes or a problem with blood sugar</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Colorectal" value="Colorectal"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Colorectal")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Colorectal") === "Colorectal" ? "checked" : "":"":""}/>
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Colorectal Cancer</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Lung" value="Lung"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Lung")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Lung") === "Lung" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Lung Cancer</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Uterine" value="Uterine"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Uterine")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Uterine") === "Uterine" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Uterine Cancer</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Prostrate" value="Prostrate"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Prostrate")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Prostrate") === "Prostrate" ? "checked" : "":"":""}/>
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Prostrate Cancer</h6>
                  </label>
                </div>
                <div className="form-check  ">
                  <input className="form-check-input" type="checkbox" name="Stomach" value="Stomach"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Stomach")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Stomach") === "Stomach" ? "checked" : "":"":""} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">   Stomach Cancer</h6>
                  </label>
                </div>

                <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","None")} defaultChecked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None") === "None" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">None</h6>
                      </label>
                    </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.fhhdiagnosed}</div>
              <p className="roboto-bold-36px mx-2">Do you have any brothers or sisters diagnosed with Cancer</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="diagnosed" value="yes" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")} id="exampleRadios1" defaultValue="option1"  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="diagnosed" defaultChecked value="no" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="container d-flex justify-content-end">
                <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(2)}>Back</button>
                <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(4)}>Next</button>
              </div>
            </div>
            {/* pills4 */}
            <div className="tab-pane fade" className={this.state.isActive === 4 ? "tab-pane active" : "tab-pane"} id="pills-4" role="tabpanel" aria-labelledby="pills-history-tab">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.smoke}</div>
              <h2 className="roboto-bold-36px mx-2">Your Smoking History :</h2>
              <p className="roboto-bold-18px mx-2">Do you smoke cigarettes?</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No - I Never Smoked</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="noq" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  I have Quit Smoking</h6>
                  </label>
                </div>
              </div>
              {this.state.risk.myHabits?this.state.risk.myHabits.smoke ? this.state.risk.myHabits.smoke[0] === "yes"?
                <div>
                    <hr  className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="row">
                      <div className="col-6">
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedSmoking}</div>
                        <p className="roboto-bold-18px mx-2">How old were you when you started smoking?</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" placeholder="Your age" name="startedSmoking" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedSmoking(e, "myHabits", "startAges")} />
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyCiggratee}</div>
                        <p className="roboto-bold-18px mx-2">How many cigarettes</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                          <input type="number"  name="howmanyCiggratee" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowMany(e, "myHabits", "howMany")} />
                        </div>
                        
                      </div>
                      
                    </div>
                    </div>
                    : '' : '':''}
              {this.state.risk.myHabits?this.state.risk.myHabits.smoke ? this.state.risk.myHabits.smoke[0] === "noq"?
          <div>
              <hr  className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="row">
                <div className="col-6">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedSmoking}</div>
                  <p className="roboto-bold-18px mx-2">How old were you when you started smoking?</p>
                  <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                  <input type="number" placeholder="Your age" name="startedSmoking" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedSmoking(e, "myHabits", "startAges")} />
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyCiggratee}</div>
                  <p className="roboto-bold-18px mx-2">How many cigarettes</p>
                  <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number"  name="howmanyCiggratee" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowMany(e, "myHabits", "howMany")} />
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.quitSmoking}</div>
                  <p className="roboto-bold-18px mx-2">At what age did you quit smoking?</p>
                  <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" name="endAges" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeQuitSmoking(e, "myHabits", "endAges")} />
                  </div>
                </div>
                <div  className="col-6">
                  <img className="img-fluid" src="img/risk-page/tq/4-layers.png" />
                </div>
              </div>
              </div>
              : '' : '':''}
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.drink}</div>
              <h2 className="roboto-bold-36px mx-2">Your Drinking History :</h2>
              <p className="roboto-bold-18px mx-2">Do you drink alcohol?</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3 ">
                  <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3 ">
                  <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No - I Never Drank alcohol</h6>
                  </label>
                </div>
                <div className="form-check  mr-3 ">
                  <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="noq" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18"> No - I have Quit Drinking Alcohol</h6>
                  </label>
                </div>
              </div>
              {this.state.risk.myHabits?this.state.risk.myHabits.drink ? this.state.risk.myHabits.drink[0] === "yes"?
                  <div>
                  <hr id="drink44" className="mx-auto " style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedDrinking}</div>
                  <p id="drink1" className="roboto-bold-18px mx-2 ">How old were you when you started drinking?</p>
                  <div id="drink11" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} name="startedDrinking" aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedDrinking(e, "myHabits", "startAges")}/>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyDrinks}</div>
                  <p id="drink2" className="roboto-bold-18px mx-2 ">On average how many servings of alcohol did you have per day?</p>
                  <p id="drink22" className="roboto-reg-18px mx-2 ">One serving is equal to one peg of hard liquor / one can of beer / one glass of wine</p>
                  <div id="drink222" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder={0} className="form-control roboto-reg-16px" name="howmanyDrinks" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowManyDrinks(e, "myHabits", "howMany")}/>
                  </div>

                  </div>
                  : '' : '':''}
              {this.state.risk.myHabits?this.state.risk.myHabits.drink ? this.state.risk.myHabits.drink[0] === "noq"?
              <div>
              <hr id="drink44" className="mx-auto " style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedDrinking}</div>
              <p id="drink1" className="roboto-bold-18px mx-2 ">How old were you when you started drinking?</p>
              <div id="drink11" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                <input type="number" placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} name="statedDrinking" aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedDrinking(e, "myHabits", "startAges")}/>
              </div>
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyDrinks}</div>
              <p id="drink2" className="roboto-bold-18px mx-2 ">On average how many servings of alcohol did you have per day?</p>
              <p id="drink22" className="roboto-reg-18px mx-2 ">One serving is equal to one peg of hard liquor / one can of beer / one glass of wine</p>
              <div id="drink222" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                <input type="number" placeholder={0} className="form-control roboto-reg-16px" name="howmanyDrinks" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowManyDrinks(e, "myHabits", "howMany")}/>
              </div>
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.quitDrinking}</div>
              <p id="drink33" className="roboto-bold-18px mx-2 ">At what age did you quit Drinking?</p>
              <div id="drink333" className="input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                <input type="number" placeholder={0} name="endAges" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button"  onChange={(e) => this.checkHandleChangeQuitDrinking(e, "myHabits", "endAges")}/>
              </div>
              </div>
              : '' : '':''}
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.physicalActivity}</div>
              <h2 className="roboto-bold-36px mx-2">Your Physical Activity</h2>
              <p className="roboto-bold-18px mx-2">Do you walk (exercise or do other moderate physical activity) for at least 30 minutes on most days of the week, or at least do moderate physical
                activity for 3 hours per week?</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="physicalActivity" id="physicalActivity" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForphysicalActivity(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                <input className="form-check-input" type="radio" name="physicalActivity" id="physicalActivity" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForphysicalActivity(e, "myHabits","option")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No </h6>
                  </label>
                </div>
              </div>
             
              {this.state.risk.myHabits?this.state.risk.myHabits.physicalActivity ? this.state.risk.myHabits.physicalActivity[0] === "yes"?
              <div>
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.PhysicalHours}</div>
              <p className="roboto-bold-18px mx-2">How many non-work hours per day do you spend watching television shows, playing video games, or using a computer/tablet?</p>
              <div className=" d-flex mx-2">
                  <div className="col-lg-12 col-md-6 col-sm-3">
                  <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="exampleRadios" value="Less than 1 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  Less than 1 hour</h6>
                      </label>
                  </div>
                  <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="exampleRadios" value="1 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  1 hour </h6>
                      </label>
                  </div>
                  <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="exampleRadios" value="2 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  2 hour </h6>
                      </label>
                  </div>
                  <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="exampleRadios" value="3 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  3 hour </h6>
                      </label>
                  </div>
                  <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="exampleRadios" value="4 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  4 hour </h6>
                      </label>
                  </div>
                  <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="exampleRadios" value="More than 5 hours" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                      <h6 className="reg-18">  More than 5 hours </h6>
                      </label>
                  </div>
                  </div>
              </div>
              </div>

              : '' : '':''}
              
            
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="container d-flex justify-content-end">
                <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(3)}>Back</button>
                <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}} onClick={() => this.changeTab(5)}>Next</button>
              </div>
            </div>
            {/* pill5 */}
            <div className="tab-pane fade" id="pills-5" className={this.state.isActive === 5 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.meat}</div>
              <h2 className="roboto-bold-36px mx-2">Do you eat three or more servings of processed meat in a week?</h2>
              <p className="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="meat" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForMeat(e, "myDiet")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                <input className="form-check-input" type="radio" name="meat" id="exampleRadios1" value="no"  onChange={(e) => this.handleChangeForMeat(e, "myDiet")}/>
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.eatbutter}</div>
              <h2 className="roboto-bold-36px mx-2">Do you eat butter, lard / fat, red meat (Beef, Mutton, Lamb, Pork), cheese, or whole milk 2
                or more times per day?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                <input className="form-check-input" type="radio" name="eatbutter" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForMeatQ2(e, "myDiet")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                <input className="form-check-input" type="radio" name="eatbutter" id="exampleRadios1" value="no"  onChange={(e) => this.handleChangeForMeatQ2(e, "myDiet")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.eatFish}</div>
              <h2 className="roboto-bold-36px mx-2">Do you eat fish two or more times in a week?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                <input className="form-check-input" type="radio" name="eatFish" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForEatFish(e, "myDiet")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="eatFish" id="exampleRadios1" value="no" onChange={(e) => this.handleChangeForEatFish(e, "myDiet")}  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.OilBased}</div>
              <h2 className="roboto-bold-36px mx-2">Do you eat oil-based salad dressing or use liquid vegetable oil for cooking on most days?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="OilBased" value="yes"  onChange={(e) => this.handleChangeForOilBased(e, "myDiet")} id="exampleRadios1" defaultValue="option1"  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="OilBased" value="no"  onChange={(e) => this.handleChangeForOilBased(e, "myDiet")}  id="exampleRadios1" defaultValue="option1"  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.nuts}</div>
              <h2 className="roboto-bold-36px mx-2">Do you eat at least 3 servings of nuts per week?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="nuts" value="yes"  onChange={(e) => this.handleChangeForNuts(e, "myDiet")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="nuts" value="no"  onChange={(e) => this.handleChangeForNuts(e, "myDiet")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.multivitaminsFourDays}</div>
              <h2 className="roboto-bold-36px mx-2">Do you take a multivitamins for more than four days in a week?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="multivitaminsFourDays" value="yes"  onChange={(e) => this.handleChangeForMultivitaminsFourDays(e, "myDiet")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="multivitaminsFourDays" value="no"  onChange={(e) => this.handleChangeForMultivitaminsFourDays(e, "myDiet")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.multivitamins12Years}</div>
              <h2 className="roboto-bold-36px mx-2">Have you been taking multivitamins regularly for 12 years or more?</h2>
              {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="multivitamins12Years" value="yes"  onChange={(e) => this.handleChangeForMultivitamins12Years(e, "myDiet")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="multivitamins12Years" value="no"  onChange={(e) => this.handleChangeForMultivitamins12Years(e, "myDiet")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="container d-flex justify-content-end">
                <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(4)}>Back</button>
                <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(6)}>Next</button>
              </div>
            </div>
            
            <div className="tab-pane fade" id="pills-7" className={this.state.isActive === 6 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.aspirin}</div>
              <p className="roboto-bold-36px mx-2">Do you take an aspirin every day?</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="aspirin" value="yes"  onChange={(e) => this.handleChangeForAspirin(e, "medication")}/>
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="aspirin" value="no"  onChange={(e) => this.handleChangeForAspirin(e, "medication")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.aspirin2}</div>
              <p className="roboto-bold-36px mx-2">Have you taken aspirin 2 or more times per week for 6 or more years?</p>
              <div className=" d-flex mx-2">
                <div className="form-check   mr-3">
                  <input className="form-check-input" type="radio" name="aspirin2" value="yes"  onChange={(e) => this.handleChangeForAspirin2(e, "medication")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  Yes</h6>
                  </label>
                </div>
                <div className="form-check  mr-3">
                  <input className="form-check-input" type="radio" name="aspirin2" value="no"  onChange={(e) => this.handleChangeForAspirin2(e, "medication")} />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    <h6 className="reg-18">  No</h6>
                  </label>
                </div>
              </div>
              <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
              <div className="container d-flex justify-content-end">
                <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(5)}>Back</button>
                <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"  onClick={() => this.toRisLogin()} style={{height: '3.75rem', width: '8.3rem'}}>Next</button>
              </div>
            </div>
          </div>
        </div>:    <div className="tab-content">
                <div className="container tab-content py-5 px-4" id="pills-tabContent">
                  <div className="tab-pane fade show active" className={this.state.isActive === 1 ? "tab-pane active" : "tab-pane"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.gender}</div>
                    <h2 className="roboto-bold-36px mx-2">Are you male or female?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="yourDetails" id="yourDetails" defaultChecked  value="male"  onChange={(e) => this.handleChangeGenderAddData(e, "yourDetails")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Male</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="yourDetails" id="yourDetails" value="female"onChange={(e) => this.handleChangeGenderAddData(e, "yourDetails")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Female</h6>
                        </label>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.age}</div>
                    <p className="roboto-bold-36px mx-2">Your age?</p>
                    <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder="Estimates are okay" name="age" onChange={(e) => this.handleChangeAgeAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.heightFeet} &nbsp;&nbsp;&nbsp; {this.state.errors.heightInches}</div>             
                    <p className="roboto-bold-36px mx-2">Your height?</p>
                    <div className="d-flex" style={{maxWidth: '400px'}}>
                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" placeholder="Feet" name="heightFeet" onChange={(e) => this.handleChangeHeightFeetAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                      </div>
                      
                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" placeholder="Inches" name="heightInches" onChange={(e) => this.handleChangeHeightInchesAddData(e, "yourDetails")}  className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.weight}</div>
                    <p className="roboto-bold-36px mx-2">Your Weight (in Kgs)?</p>
                    <div className="d-flex" style={{maxWidth: '400px'}}>
                      <div className=" input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" name="weight" onChange={(e) => this.handleChangeWeightAddData(e, "yourDetails")} placeholder={0} className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" />
                      </div>
                      <p className="pt-2">kg</p>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="container d-flex justify-content-end">
                      {/* <button type="submit" class="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"onclick="hideandshowhomesec1()" style="height:3.75rem;width:8.3rem;"/>Back</button> */}
                      <button  className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" data-toggle="pill" href="#pills-2" role="tab" aria-controls="pills-history" onClick={() => this.changeTab(2)} aria-selected="true" style={{height: '3.75rem', width: '8.3rem'}}>Next</button>
                    </div>
                  </div>
                  {/* <div class="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-history-tab"> */}
                  <div className="tab-pane fade" className={this.state.isActive === 2 ? "tab-pane active" : "tab-pane"}  id="pills-2" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.diagnosed}</div>
                    <h2 className="roboto-bold-36px mx-2">Have you ever been diagnosed with :</h2>
                    <p className="roboto-reg-18px mx-2">Choose all that apply.</p>
                    <div className="  mx-2">
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="hypertension" value="hypertension"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","hypertension")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "hypertension") === "hypertension" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   High blood pressure (hypertension)
                          </h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="highBloodCholesterol" value="highBloodCholesterol"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","highBloodCholesterol")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "highBloodCholesterol") === "highBloodCholesterol" ? "checked" : "":"":""} defaultValue id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   High blood cholesterol</h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="diabetes" value="diabetes"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","diabetes")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "diabetes") === "diabetes" ? "checked" : "":"":""} id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Diabetes or a problem with blood sugar</h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="heartDisease" value="heartDisease"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","heartDisease")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "heartDisease") === "heartDisease" ? "checked" : "":"":""} id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Heart Disease
                          </h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="Stroke" value="Stroke"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","Stroke")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "Stroke") === "Stroke" ? "checked" : "":"":""} id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Stroke</h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="Cancer" value="Cancer"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","Cancer")} disabled={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None"):false:false} checked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "Cancer") === "Cancer" ? "checked" : "":"":""} id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Cancer - Any type other than basal or squamous cell skin cancer</h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataHealthHistory(e, "healthHistory", "diagnosed","None")} defaultChecked={this.state.risk.healthHistory?this.state.risk.healthHistory.diagnosed?this.state.risk.healthHistory.diagnosed.find(x => x === "None") === "None" ? "checked" : "":"":""} id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   None</h6>
                        </label>
                       </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.option}</div>
                    <h2 className="roboto-bold-36px mx-2">Have you been ever told that you have a genetic mutation that raises your risk for cancer?
                      Examples include mutations in the following genes:
                      BRCA1, BRCA2, TP53, PTEN and CDH1.</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="yes" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}  id="exampleRadios1" />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="no" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}   />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="riskForCancer" id="riskForCancer"  value="DonNotknow" onChange={(e) => this.checkHandleChangeHealthHistory(e, "healthHistory","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="roboto-reg-18px">  Don’t know - I have not been tested.</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="d-none" id="onlyfemale">
                      <h2 className="roboto-bold-36px mx-2">Have you ever been informed by a doctor that you have a benign or non-cancerous
                        changes in your breasts?</h2>
                      {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                      <div className=" d-flex mx-2">
                        <div className="form-check   mr-3">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  Yes</h6>
                          </label>
                        </div>
                        <div className="form-check  mr-3">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  No</h6>
                          </label>
                        </div>
                      </div>
                      <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    </div>
                    <div className="container d-flex justify-content-end">
                      <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onClick={() => this.changeTab(1)} style={{height: '3.75rem', width: '8.3rem'}}>Back</button>
                      <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(3)}>Next</button>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="pills-3" className={this.state.isActive === 3 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.immediateFamily}</div>
                    <h2 className="roboto-bold-36px mx-2">Has anyone in your immediate family (mother, father, sister, brother) had?:</h2>
                    <p className="roboto-reg-18px mx-2">Choose all that apply.</p>
                    <div className="  mx-2">
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox"  name="heartDisease" value="heartDisease"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","heartDisease")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "heartDisease") === "heartDisease" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Heart Disease
                          </h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="Stroke" value="Stroke"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","Stroke")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "Stroke") === "Stroke" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Stroke</h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="diabetes" value="diabetes"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","diabetes")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "diabetes") === "diabetes" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   Diabetes</h6>
                        </label>
                      </div>
                      <div className="form-check  ">
                        <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "immediateFamily","None")} defaultChecked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.immediateFamily?this.state.risk.familyHealthHistory.immediateFamily.find(x => x === "None") === "None" ? "checked" : "":"":""} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">   None</h6>
                        </label>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.familyCancer}</div>
                    <h2 className="roboto-bold-36px mx-2">Do you have a family history (on your mother's side or your father's side) of any of the
                      following?::</h2>
                    {/* <p class="roboto-reg-18px mx-2">Choose all that apply.</p> */}
                    <div className="  mx-2">
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Breast/Ovarian" value="Breast/Ovarian"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Breast/Ovarian")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Breast/Ovarian") === "Breast/Ovarian" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Breast and / or Ovarian Cancer
                        </h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Cervical" value="Cervical"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Cervical")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Cervical") === "Cervical" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Cervical Cancer</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Diabetes" value="Diabetes"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Diabetes")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Diabetes") === "Diabetes" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Diabetes or a problem with blood sugar</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Colorectal" value="Colorectal"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Colorectal")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Colorectal") === "Colorectal" ? "checked" : "":"":""}/>
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Colorectal Cancer</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Lung" value="Lung"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Lung")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Lung") === "Lung" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Lung Cancer</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Uterine" value="Uterine"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Uterine")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Uterine") === "Uterine" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">  Uterine Cancer</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Prostrate" value="Prostrate"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Prostrate")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Prostrate") === "Prostrate" ? "checked" : "":"":""}/>
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Prostrate Cancer</h6>
                      </label>
                    </div>
                    <div className="form-check  ">
                      <input className="form-check-input" type="checkbox" name="Stomach" value="Stomach"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","Stomach")} disabled={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None"):false:false} checked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "Stomach") === "Stomach" ? "checked" : "":"":""} />
                      <label className="form-check-label" htmlFor="exampleRadios1">
                        <h6 className="reg-18">   Stomach Cancer</h6>
                      </label>
                    </div>
    
                    <div className="form-check  ">
                          <input className="form-check-input" type="checkbox" name="None" value="None"  onChange={(e) => this.checkHandleChangeAddDataFamilyHealthHistory(e, "familyHealthHistory", "familyCancer","None")} defaultChecked={this.state.risk.familyHealthHistory?this.state.risk.familyHealthHistory.familyCancer?this.state.risk.familyHealthHistory.familyCancer.find(x => x === "None") === "None" ? "checked" : "":"":""} />
                          <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">None</h6>
                          </label>
                        </div>
                  </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.fhhdiagnosed}</div>
                    <p className="roboto-bold-36px mx-2">Do you have any brothers or sisters diagnosed with Cancer</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="diagnosed" value="yes" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")} id="exampleRadios1" defaultValue="option1"  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="diagnosed" defaultChecked value="no" onChange={(e) => this.checkHandleChangeFamilyHealthHistory(e, "familyHealthHistory","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="container d-flex justify-content-end">
                      <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(2)}>Back</button>
                      <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(4)}>Next</button>
                    </div>
                  </div>
                  {/* pills4 */}
                  <div className="tab-pane fade" className={this.state.isActive === 4 ? "tab-pane active" : "tab-pane"} id="pills-4" role="tabpanel" aria-labelledby="pills-history-tab">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.smoke}</div>
                    <h2 className="roboto-bold-36px mx-2">Your Smoking History :</h2>
                    <p className="roboto-bold-18px mx-2">Do you smoke cigarettes?</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No - I Never Smoked</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="smoke" id="smoke" defaultValue="option1" value="noq" onChange={(e) => this.handleChangeForSmoking(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  I have Quit Smoking</h6>
                        </label>
                      </div>
                    </div>
                    {this.state.risk.myHabits?this.state.risk.myHabits.smoke ? this.state.risk.myHabits.smoke[0] === "yes"?
                <div>
                    <hr  className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="row">
                      <div className="col-6">
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedSmoking}</div>
                        <p className="roboto-bold-18px mx-2">How old were you when you started smoking?</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" placeholder="Your age" name="startedSmoking" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedSmoking(e, "myHabits", "startAges")} />
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyCiggratee}</div>
                        <p className="roboto-bold-18px mx-2">How many cigarettes</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                          <input type="number"  name="howmanyCiggratee" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowMany(e, "myHabits", "howMany")} />
                        </div>
                        
                      </div>
                      
                    </div>
                    </div>
                    : '' : '':''}
                    {this.state.risk.myHabits?this.state.risk.myHabits.smoke ? this.state.risk.myHabits.smoke[0] === "noq"?
                <div>
                    <hr  className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="row">
                      <div className="col-6">
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedSmoking}</div>
                        <p className="roboto-bold-18px mx-2">How old were you when you started smoking?</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                        <input type="number" placeholder="Your age" name="startedSmoking" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedSmoking(e, "myHabits", "startAges")} />
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyCiggratee}</div>
                        <p className="roboto-bold-18px mx-2">How many cigarettes</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                          <input type="number"  name="howmanyCiggratee" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowMany(e, "myHabits", "howMany")} />
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.quitSmoking}</div>
                        <p className="roboto-bold-18px mx-2">At what age did you quit smoking?</p>
                        <div  className="input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                          <input type="number" name="endAges" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeQuitSmoking(e, "myHabits", "endAges")} />
                        </div>
                      </div>
                      <div  className="col-6">
                        <img className="img-fluid" src="img/risk-page/tq/4-layers.png" />
                      </div>
                    </div>
                    </div>
                    : '' : '':''}
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.drink}</div>
                    <h2 className="roboto-bold-36px mx-2">Your Drinking History :</h2>
                    <p className="roboto-bold-18px mx-2">Do you drink alcohol?</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3 ">
                        <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3 ">
                        <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No - I Never Drank alcohol</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3 ">
                        <input className="form-check-input" type="radio" name="drink" id="drink" defaultValue="option1" value="noq" onChange={(e) => this.handleChangeForDrink(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18"> No - I have Quit Drinking Alcohol</h6>
                        </label>
                      </div>
                    </div>
                    {this.state.risk.myHabits?this.state.risk.myHabits.drink ? this.state.risk.myHabits.drink[0] === "yes"?
                  <div>
                  <hr id="drink44" className="mx-auto " style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedDrinking}</div>
                  <p id="drink1" className="roboto-bold-18px mx-2 ">How old were you when you started drinking?</p>
                  <div id="drink11" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} name="startedDrinking" aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedDrinking(e, "myHabits", "startAges")}/>
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyDrinks}</div>
                  <p id="drink2" className="roboto-bold-18px mx-2 ">On average how many servings of alcohol did you have per day?</p>
                  <p id="drink22" className="roboto-reg-18px mx-2 ">One serving is equal to one peg of hard liquor / one can of beer / one glass of wine</p>
                  <div id="drink222" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                    <input type="number" placeholder={0} className="form-control roboto-reg-16px" name="howmanyDrinks" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowManyDrinks(e, "myHabits", "howMany")}/>
                  </div>

                  </div>
                  : '' : '':''}
                    {this.state.risk.myHabits?this.state.risk.myHabits.drink ? this.state.risk.myHabits.drink[0] === "noq"?
                    <div>
                    <hr id="drink44" className="mx-auto " style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.startedDrinking}</div>
                    <p id="drink1" className="roboto-bold-18px mx-2 ">How old were you when you started drinking?</p>
                    <div id="drink11" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder="Your age" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} name="startAges" aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeStartedDrinking(e, "myHabits", "startAges")}/>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.howmanyDrinks}</div>
                    <p id="drink2" className="roboto-bold-18px mx-2 ">On average how many servings of alcohol did you have per day?</p>
                    <p id="drink22" className="roboto-reg-18px mx-2 ">One serving is equal to one peg of hard liquor / one can of beer / one glass of wine</p>
                    <div id="drink222" className=" input-group-prepend mb-3 ml-0 mr-4 " style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder={0} className="form-control roboto-reg-16px" name="howMany" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button" onChange={(e) => this.checkHandleChangeHowManyDrinks(e, "myHabits", "howMany")}/>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.quitDrinking}</div>
                    <p id="drink33" className="roboto-bold-18px mx-2 ">At what age did you quit Drinking?</p>
                    <div id="drink333" className="input-group-prepend mb-3 ml-0 mr-4" style={{border: 'solid 1px #ebebeb', height: '60px', width: '16.5rem'}}>
                      <input type="number" placeholder={0} name="endAges" className="form-control roboto-reg-16px" style={{height: '57px', width: '16.5rem', border: 'none'}} aria-label="Text input with dropdown button"  onChange={(e) => this.checkHandleChangeQuitDrinking(e, "myHabits", "endAges")}/>
                    </div>
                    </div>
                    : '' : '':''}
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.physicalActivity}</div>
                    <h2 className="roboto-bold-36px mx-2">Your Physical Activity</h2>
                    <p className="roboto-bold-18px mx-2">Do you walk (exercise or do other moderate physical activity) for at least 30 minutes on most days of the week, or at least do moderate physical
                      activity for 3 hours per week?</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="physicalActivity" id="physicalActivity" defaultValue="option1" value="yes" onChange={(e) => this.handleChangeForphysicalActivity(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="physicalActivity" id="physicalActivity" defaultValue="option1" value="no" onChange={(e) => this.handleChangeForphysicalActivity(e, "myHabits","option")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No </h6>
                        </label>
                      </div>
                    </div>
                   
                    {this.state.risk.myHabits?this.state.risk.myHabits.physicalActivity ? this.state.risk.myHabits.physicalActivity[0] === "yes"?
                    <div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.PhysicalHours}</div>
                    <p className="roboto-bold-18px mx-2">How many non-work hours per day do you spend watching television shows, playing video games, or using a computer/tablet?</p>
                    <div className=" d-flex mx-2">
                        <div className="col-lg-12 col-md-6 col-sm-3">
                        <div className="form-check   mr-3">
                            <input className="form-check-input" type="radio" name="exampleRadios" value="Less than 1 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  Less than 1 hour</h6>
                            </label>
                        </div>
                        <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" value="1 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  1 hour </h6>
                            </label>
                        </div>
                        <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" value="2 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "myHabits")} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  2 hour </h6>
                            </label>
                        </div>
                        <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" value="3 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  3 hour </h6>
                            </label>
                        </div>
                        <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" value="4 hour" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  4 hour </h6>
                            </label>
                        </div>
                        <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="exampleRadios" value="More than 5 hours" id="exampleRadios1" onChange={(e) => this.handleChangePhysicalHours(e, "physicalActivity")} />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                            <h6 className="reg-18">  More than 5 hours </h6>
                            </label>
                        </div>
                        </div>
                    </div>
                    </div>

                    : '' : '':''}
                    
                  
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="container d-flex justify-content-end">
                      <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}onClick={() => this.changeTab(3)}>Back</button>
                      <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}} onClick={() => this.changeTab(5)}>Next</button>
                    </div>
                  </div>
                  {/* pill5 */}
                  <div className="tab-pane fade" id="pills-5" className={this.state.isActive === 5 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.meat}</div>
                    <h2 className="roboto-bold-36px mx-2">Do you eat three or more servings of processed meat in a week?</h2>
                    <p className="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="meat" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForMeat(e, "myDiet")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="meat" id="exampleRadios1" value="no"  onChange={(e) => this.handleChangeForMeat(e, "myDiet")}/>
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.eatbutter}</div>
                    <h2 className="roboto-bold-36px mx-2">Do you eat butter, lard / fat, red meat (Beef, Mutton, Lamb, Pork), cheese, or whole milk 2
                      or more times per day?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="eatbutter" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForMeatQ2(e, "myDiet")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                      <input className="form-check-input" type="radio" name="eatbutter" id="exampleRadios1" value="no"  onChange={(e) => this.handleChangeForMeatQ2(e, "myDiet")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.eatFish}</div>
                    <h2 className="roboto-bold-36px mx-2">Do you eat fish two or more times in a week?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                      <input className="form-check-input" type="radio" name="eatFish" id="exampleRadios1" value="yes"  onChange={(e) => this.handleChangeForEatFish(e, "myDiet")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="eatFish" id="exampleRadios1" value="no" onChange={(e) => this.handleChangeForEatFish(e, "myDiet")}  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.OilBased}</div>
                    <h2 className="roboto-bold-36px mx-2">Do you eat oil-based salad dressing or use liquid vegetable oil for cooking on most days?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="OilBased" value="yes"  onChange={(e) => this.handleChangeForOilBased(e, "myDiet")} id="exampleRadios1" defaultValue="option1"  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="OilBased" value="no"  onChange={(e) => this.handleChangeForOilBased(e, "myDiet")}  id="exampleRadios1" defaultValue="option1"  />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.nuts}</div>
                    <h2 className="roboto-bold-36px mx-2">Do you eat at least 3 servings of nuts per week?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="nuts" value="yes"  onChange={(e) => this.handleChangeForNuts(e, "myDiet")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="nuts" value="no"  onChange={(e) => this.handleChangeForNuts(e, "myDiet")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.multivitaminsFourDays }</div>
                    <h2 className="roboto-bold-36px mx-2">Do you take a multivitamins for more than four days in a week?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="multivitaminsFourDays" value="yes"  onChange={(e) => this.handleChangeForMultivitaminsFourDays(e, "myDiet")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="multivitaminsFourDays" value="no"  onChange={(e) => this.handleChangeForMultivitaminsFourDays(e, "myDiet")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.multivitamins12Years}</div>
                    <h2 className="roboto-bold-36px mx-2">Have you been taking multivitamins regularly for 12 years or more?</h2>
                    {/* <p class="roboto-reg-18px mx-2">(Processed meats include foods like: ham, hot dogs, bacon, and sausage)</p> */}
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="multivitamins12Years" value="yes"  onChange={(e) => this.handleChangeForMultivitamins12Years(e, "myDiet")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="multivitamins12Years" value="no"  onChange={(e) => this.handleChangeForMultivitamins12Years(e, "myDiet")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="container d-flex justify-content-end">
                      <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" onclick="hideandshowhomesec1()" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(4)}>Back</button>
                      <button id="ctnbtn1" type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(6)}>Next</button>
                    </div>
                  </div>
                 
                  <div className="tab-pane fade" id="pills-7" className={this.state.isActive === 6 ? "tab-pane active" : "tab-pane"} role="tabpanel" aria-labelledby="pills-history-tab">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.aspirin}</div>
                    <p className="roboto-bold-36px mx-2">Do you take an aspirin every day?</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="aspirin" value="yes"  onChange={(e) => this.handleChangeForAspirin(e, "medication")}/>
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="aspirin" value="no"  onChange={(e) => this.handleChangeForAspirin(e, "medication")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.aspirin2}</div>
                    <p className="roboto-bold-36px mx-2">Have you taken aspirin 2 or more times per week for 6 or more years?</p>
                    <div className=" d-flex mx-2">
                      <div className="form-check   mr-3">
                        <input className="form-check-input" type="radio" name="aspirin2" value="yes"  onChange={(e) => this.handleChangeForAspirin2(e, "medication")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  Yes</h6>
                        </label>
                      </div>
                      <div className="form-check  mr-3">
                        <input className="form-check-input" type="radio" name="aspirin2" value="no"  onChange={(e) => this.handleChangeForAspirin2(e, "medication")} />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          <h6 className="reg-18">  No</h6>
                        </label>
                      </div>
                    </div>
                    <hr className="mx-auto" style={{height: '1px', backgroundColor: '#cdcdcd'}} />
                    <div className="container d-flex justify-content-end">
                      <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3" style={{height: '3.75rem', width: '8.3rem'}}  onClick={() => this.changeTab(5)}>Back</button>
                      <button type="submit" className="btn btn-outline-primary btn-hover-white rounded-pill mb-5 mr-3"  onClick={() => this.toRisLogin()} style={{height: '3.75rem', width: '8.3rem'}}>Next</button>
                    </div>
                  </div>
                </div>
              </div>}

          </section>}
       
        <div style={{marginTop: '6rem'}}>
        </div>
       <SubMainFooter></SubMainFooter>
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
        
       )
   }

}
export default RiskPage;