import React from 'react'

class NoTabMainHeader extends React.Component{
   


   render(){
  

       return(
        <header id="headbar">
        {/* navbar */}
        <nav id="nav-a-tag" className="main-nav pb-0  navbar navbar-expand-lg  nav-menu container">
          <a className="navbar-brand " href="/"><img id="img-1" className="mb-2  img-size-custom" src="./img/Logo.png" /></a>
          <button className="navbar-toggler nav-button" type="button" data-toggle="collapse" data-target="#myNavbar">
            <div className=" line1" />
            <div className=" line2" />
            <div className=" line3" />
          </button>
          <div className="main-navbar-collapse collapse navbar-collapse text-center justify-content-lg-end  text-uppercase" id="myNavbar">
            <ul className="main-navbar-nav navbar-nav">
              <li className="nav-item">
                <a href="/about-us" className="nav-link m-2 menu-item ">About</a>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link m-2 menu-item ">For you</a>
              </li>
              {/* <li class="nav-item">
          <a href="clinicians.html" class="nav-link m-2 menu-item">for clinicians</a>
        </li> */}
              <li className="nav-item">
                <a href="/help" className="nav-link m-2 menu-item ">HELP</a>
              </li>
            </ul>
            <div className="signin-register">
              <ul>
                <li>
                  <a className="signinRegisterBtn" id="signin" href="/login">Sign in</a>
                  <span>&nbsp;|&nbsp;</span>
                  <a className="signinRegisterBtn" id="register" href="/register">Register</a>
                </li>
              </ul>
            </div>
            {/* end of signin-register*/}
            <div className="book-button">
              <button type="button" className=" btn btn-primary rounded-pill">
                <a href="product-page.html">Order your test</a>
              </button>
            </div>
            {/* end of book-button */}
          </div>
        </nav>
        {/* end of navbar */}
        {/* <hr style="margin-top: .5rem;margin-bottom: 0rem; border: 0;border-top: 1px solid rgba(0,0,0,.1);"> */}
      </header>
        
       )
   }

}
export default NoTabMainHeader;