import React from 'react';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import CommonHeder from './CommonHeder';
import { Collapse} from 'reactstrap';

class Details extends React.Component{

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: [true,false,false,false,false,false ]};
  }

  toggle(tab) {
    const prevState = this.state.collapse;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState( { collapse: state });
  }


  componentDidMount() {
    window.scrollTo(0, 0)
  }

  
    render(){
        return(
            <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        {/* header */}
       <CommonHeder></CommonHeder>
        {/* end of header */}
        <section className="risk-summery-sec">
          <div className="rs-sec-wrap  p-0 container ">   
            <div className="card rs-main-card text-center rounded border-0 shadow-lg">
              <div className="card-header p-4 clearfix border-0 ">
                <div className="rs-header d-flex flex-column justify-content-start float-left">
                  <h2>{Cookies.get('username')}</h2>
                  <span className=" ">Hereditary Cancer Report</span> 
                </div>{/* eo-rs-header */}
                <ul className="rs-nav nav  card-header-tabs justify-content-end float-right">
                  <li className="nav-item">
                    <a className="nav-link" href="#">Genetic counselling appointment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Share report</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true">Download report</a>
                  </li>
                </ul>
              </div>{/* eo-card-header */}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-head px-4 py-5 container ">
                  <h2 className="card-title">Risk Summary</h2>
                  <p className="card-text">John, the results from your test do not indicate any increased genetic risks for inherited cancers</p>
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-genetic-mutation.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      No genetic mutations were identified.
                    </h5>
                    <p className="card-text">We analysed 90 genes that are associated with breast, colorectal, ovarian, pancreatic and stomach cancers. No mutations were identified in these genes.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">See your Detailed Report</a>
                  </div>{/* eo-cb-sec-one-text */}         
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="container seperatorline" />
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-health-history.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      Your health history is incomplete.
                    </h5>
                    <p className="card-text">You have not completed your health history, which may impact the risk and screening guidelines for you. Although your have tested negative, 85 - 90% of all cancers have a non-genetic cause. When cancer is detected early it improves the odds of survival.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Update your health history now</a>
                  </div>{/* eo-cb-sec-one-text */}         
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="dv-block-wrap d-flex flex-column justify-content-start">
                  <div className="dv-head cb-head px-4 pt-5 pb-0 container">
                    <h2 className="card-title">Detailed View</h2>
                    <ul className="dv-nav nav  card-header-tabs">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Your Risks</a> */}
                        <Link to="/risksummary" className="nav-link">Your Risks</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link nav-active" href="details.html">Details</a> */}
                        <Link to="/details" className="nav-link nav-active">Details</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="next-steps.html">Next Steps</a> */}
                        <Link to="/nextsteps" className="nav-link">Next Steps</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="family-history.html">Family History</a> */}
                        <Link to="/familyhistory" className="nav-link">Family History</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="genetic-counselling.html">Genetic Counselling</a> */}
                        <Link to="/genetic-counselling" className="nav-link">Genetic Counselling</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="share-details.html">Share Your Report</a> */}
                        <Link to="/sharedetails" className="nav-link">Share Your Report</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">faqs</a> */}
                        <Link to="/faqs" className="nav-link">faqs</Link>

                      </li>
                    </ul>
                  </div>{/* eo-dv-block-cb-head */}
                </div>{/* eo-dv-block-wrap */}
              </div>{/* eo-card-body*/}
              <div className="container dv-accordian-wrap px-4">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        We analysed these genes
                        <button className="float-right btn btn-link rounded-circle" data-toggle="collapse" onClick={()=>this.toggle(0)}>{this.state.collapse[0] ?<i className="fa fa-minus-circle" />:<i className="fa fa-plus-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse  className="collapse" aria-labelledby="headingOne" isOpen={this.state.collapse[0]} data-parent="#accordionExample">
                      <div className="analysed-body card-body">
                        <div className="float-right p-5"> 
                          <img className="img-fluid text-center" src="./img/risk-summary/analysed-genes.png" alt="Analysed Picture" />
                        </div>
                        <p>APC, ATM, BAP1, BARD1, BMPR1A, BRCA1, BRCA2, BRIP1, CDH1, CDK4, CDKN2A (p14ARF), CDKN2A (p16INK4a), CHEK2, EPCAM, GREM1, MITF, MLH1, MSH2, MSH6, MUTYH, NBN, PALB2, PMS2, POLD1, POLE, PTEN, RAD51C, RAD51D, SMAD4, STK11, TP53</p>
                        <p>In the genes analysed no pathogenic or likely pathogenic variants associated with an increased risk for hereditary cancers of hereditary breast, colorectal, melanoma, ovarian, pancreatic, stomach, or uterine cancers were identified. were identified.</p>
                        <p><strong>Note : Please see the test methodology and limitations section for</strong></p>
                      </div>
                    </Collapse >{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        Genetic testing
                        <button type="button" className="float-right btn btn-link" onClick={()=>this.toggle(1)}>{this.state.collapse[1] ?<i className="fa fa-minus-circle" />:<i className="fa fa-plus-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse className="collapse " aria-labelledby="headingTwo" isOpen={this.state.collapse[1]}>
                      <div className="gt-body card-body sec6 ">
                        <p>Please read below to understand the benefits and limitations of genetic testing.</p>
                        <div className="genetic-right">
                          <div className="genetic-right-header mb-3 d-flex flex-row align-items-center justify-content-start ">
                            <span className="d-block"><img className="img-fluid d-block text-center" src="./img/risk-summary/check-icon.png" alt="Check Icon" /></span>
                            <p className="mb-0">What can be learnt from genetic testing</p>
                          </div>
                          <ul className="gt-nav text-left">
                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                            <li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                            <li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</li>
                            <li>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                          </ul>
                        </div>{/* eo-genetic-right */}
                        <div className="genetic-right genetic-wrong">
                          <div className="genetic-right-header mb-3 d-flex flex-row align-items-center justify-content-start ">
                            <span className="d-block"><img className="img-fluid d-block text-center" src="./img/risk-summary/minus-icon.png" alt="Minus Icon" /></span>
                            <p className="mb-0">What cannot be learnt from genetic testing</p>
                          </div>
                          <ul className="gt-nav text-left">
                            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                            <li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                            <li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</li>
                            <li>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                          </ul>
                        </div>{/* eo-genetic-wrong */}
                      </div>
                    </Collapse>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        Reports of HIGHEST QUALITY
                        <button type="button" className="float-right btn btn-link" data-toggle="collapse" onClick={()=>this.toggle(2)}>{this.state.collapse[2] ?<i className="fa fa-minus-circle" />:<i className="fa fa-plus-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse  className="collapse" aria-labelledby="headingThree" isOpen={this.state.collapse[2]}>
                      <div className="dv-hq-card-body card-body">
                        <div className="float-right p-5"> 
                          <img className="img-fluid text-center" src="./img/risk-summary/highestquality.png" alt="High Quality Picture" />
                        </div>
                        <p>truGeny’s team, and processes generate results you can trust.</p>
                        <p>The truGeny’s tests are validated according to ACMG, AMP, and regulatory agencies' recommendations for molecular clinical tests.</p>
                        <p>Our CAP-accredited and CLIA-certified laboratory uses the newest technology, including 2D barcoded tubes and advanced liquid- handling robots, to ensure the integrity of every result. The quality of every sample is checked multiple times as it moves through the sequencing and interpretation process.</p>
                        <p>Our Ph.D. and M.D. scientists use state-of-the-art tools to classify variants according to ACMG guidelines.</p>
                        <p>A certified medical professional reviews every result before it is released.</p>
                        <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Learn More</a>
                      </div>
                    </Collapse>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h5 className="mb-0">
                        Methodology
                        <button type="button" className="float-right btn btn-link" onClick={()=>this.toggle(3)}>{this.state.collapse[3] ?<i className="fa fa-minus-circle" />:<i className="fa fa-plus-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse  className="collapse" aria-labelledby="headingFour" isOpen={this.state.collapse[3]}>
                      <div className="card-body">
                        <div className="float-right p-5"> 
                          <img className="img-fluid text-center" src="./img/risk-summary/methodology.png" alt="Methodology Picture" />
                        </div>
                        <p>Genomic DNA is extracted from the submitted sample, enriched for select regions using a hybridization protocol, and sequenced using Illumina Next Generation Sequencing. Sequence data is aligned to a reference genome, and variants are identified using a suite of bioinformatic tools designed to detect single nucleotide variants, small insertions/deletions, and structural variants such as copy number variants, insertions and inversions. Reported variants may be confirmed by alternate technologies, including Sanger sequencing, MLPA or aCGH. Analysis, variant calling and reporting focus on the complete coding sequence and adjacent intronic sequence of the primary transcript(s), unless otherwise indicated.</p>
                        <p>Variants are classified according to the standards and guidelines for sequence variant interpretation of the American College of Medical Genetics and Genomics (ACMG). Variant classification categories include pathogenic, likely pathogenic, variant of uncertain significance (VUS), likely benign, and benign. All variants are evaluated by a board certified medical geneticist or pathologist. Identified likely benign and benign variants are not reported. The presence of a VUS is always reported, and the details are available upon request. All VUS and likely pathogenic variants are reviewed bi-annually for updates in the scientific literature. As part of the Color service, we will attempt to recontact the provider and/or the person that was tested if any reported variant’s classification changes.</p>
                        <p>his test was developed and its performance characteristics determined by Color Genomics, a clinical laboratory accredited by the College of American Pathologists (CAP) and certified under the Clinical Laboratory Improvement Amendments (CLIA) to perform high-complexity testing (CAP #8975161 - CLIA #05D2081492). This test has not been cleared or approved by the United States Food and Drug Administration (FDA). The FDA does not require this test to go through premarket FDA review. This test is used for clinical purposes. It should not be regarded as investigational or for research. This test has received the European Conformity (CE) mark in compliance with the EU legislation.</p>
                        <p><strong>Genes</strong><br />
                          APC, ATM, BAP1, BARD1, BMPR1A, BRCA1, BRCA2, BRIP1, CDH1, CDK4*, CDKN2A(p14ARF), CDKN2A(p16INK4a), CHEK2, EPCAM*, GREM1*, MITF*, MLH1, MSH2, MSH6, MUTYH, NBN, PALB2, PMS2*, POLD1*, POLE*, PTEN, RAD51C, RAD51D, SMAD4, STK11, TP53
                          * These genes are only analyzed at specific locations (see Limitations).</p>
                      </div>
                    </Collapse>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h5 className="mb-0">
                        Limitations
                        <button type="button" className="float-right btn btn-link" onClick={()=>this.toggle(4)}>{this.state.collapse[4] ?<i className="fa fa-minus-circle" />:<i className="fa fa-plus-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse className="collapse" aria-labelledby="headingFive" isOpen={this.state.collapse[4]}>
                      <div className="card-body">
                        <div className="float-right p-5"> 
                          <img className="img-fluid text-center" src="./img/risk-summary/limitations.png" alt="Limitations Picture" />
                        </div>
                        <p>This test aims to detect all clinically relevant variants within the genes analyzed (defined above). The majority of these genes are assessed for variants within all coding exons (and adjacent intronic sequence). Exons 12-15 of PMS2 cannot be reliably assessed with standard target enrichment protocols. For the CDK4, MITF, POLD1 and POLE genes, the elevated risk of cancer is associated with distinct functional genomic regions; therefore, the complete coding sequences of these genes are not reported, but instead only the following regions: CDK4 - chr12:g.58145429-58145431 (codon 24), MITF - chr3:g.70014091 (including c.952G&gt;A), POLD1 - chr19:g. 50909713 (including c.1433G&gt;A) and POLE - chr12:g.133250250 (including c.1270C&gt;G). In EPCAM, only large deletions and duplications including the 3’ end of the gene are reported since these are the only variants known to silence the MSH2 gene and therefore increase risk of associated cancer. GREM1 is only analyzed for duplications in the upstream regulatory region.</p>
                        <p>This test is not designed to detect chromosomal aneuploidy or complex rearrangements such as translocations. It also does not reliably detect mosaicism. The sensitivity to detect deletions and duplications in the range of 40-250bp, as well as those which deletion/duplication do not overlap more than 250bp of contiguous coding sequence, may be reduced. The presence of a large insertion may interfere with the chemistry used to target the genes of interest, which could decrease the detection sensitivity. In addition, the sequence and identity of a large insertion may not be completely resolved. Inversions including at least one coding exon will be detected only if the breakpoints are covered by the Color test. The sensitivity to detect variants may be reduced in regions of low/high GC content, and in the vicinity of homopolymers and simple sequence repeats.</p>
                        <p>TruGeny only reports findings within the genes that are on the panel. It is important to understand that there may be variants in those genes that current technology is not able to detect. Additionally, there may be genes associated with hereditary cancer whose clinical association has not yet been definitively established. The test may therefore not detect all variants associated with hereditary cancer. Additionally, in the unlikely event a variant is detected that is associated with a disorder other than hereditary cancer, this information will not be included in the report. Genetic counseling and/or physician consultation may be warranted to ensure complete understanding of your test results.</p>
                        <p>In very rare cases, such as circulating hematolymphoid neoplasm, allogeneic bone marrow transplant, or recent blood transfusion (within 7 days of testing), the results of germline DNA analysis may be complicated by somatic and/or donor mutations. DNA quality may be affected if a participant has received chemotherapy within the last 120 days.</p>
                        <p><strong>Disclaimers</strong><br />
                          Color implements several safeguards to avoid technical errors, such as 2-dimensional barcoding and barcode scanning at several steps throughout the sequencing process. Color is not responsible for errors in specimen collection, transportation, and activation or other errors made prior to receipt at our laboratory. Due to the complexity of genetic testing, diagnostic errors, although rare, may occur due to sample mix-up, DNA contamination, or other laboratory operational errors. In addition, poor sample DNA quality and certain characteristics inherent to specific regions of an individual’s genomic DNA may limit the accuracy of results in those regions.</p>
                        <p>In the absence of an identified pathogenic or likely pathogenic mutation, standard risk models may be employed to determine potential risk of hereditary cancer and guidelines displayed on this report. All risk estimation is approximate, sometimes cannot be specifically calculated, and is based on previously analyzed cohorts. Additionally, risk estimation may be incorrect if inaccurate personal or family history is provided. An elevated risk for hereditary cancer is not a diagnosis and does not guarantee that a person will develop the disease.</p>
                      </div>
                    </Collapse>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingSix">
                      <h5 className="mb-0">
                        Refferences
                        <button type="button" className="float-right btn btn-link" onClick={()=>this.toggle(5)}>{this.state.collapse[5] ?<i className="fa fa-minus-circle" />:<i className="fa fa-plus-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse className="collapse" aria-labelledby="headingSix"  isOpen={this.state.collapse[5]}>
                      <div className="card-body">
                        <p>Tung N, Battelli C, Allen B, et al. Frequency of mutations in individuals with breast cancer referred for BRCA1 and BRCA2 testing using next-generation sequencing with a 25-gene panel. Cancer. January 2015;121(1):25-33. Pubmed Abstract</p>
                        <p>Pal T, Permuth-Wey J, Betts JA, et al. BRCA1 and BRCA2 mutations account for a large proportion of ovarian carcinoma cases. Cancer. December 2005;104(12):2807-16. Pubmed Abstract</p>
                        <p>Risch HA, McLaughlin JR, Cole DE, et al. Prevalence and penetrance of germline BRCA1 and BRCA2 mutations in a population series of 649 women with ovarian cancer. Am J Hum Genet. March 2001;68(3):700-10. Pubmed Abstract</p>
                        <p>Claus EB, Risch N, Thompson WD, et al. The calculation of breast cancer risk for women with a first degree family history of ovarian cancer. Breast Cancer Res Treat. November 1993;28(2):115-20. Pubmed Abstract</p>
                        <p>Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Breast Cancer Screening and Diagnosis V.2.2018, Colorectal Cancer Screening V.1.2018, Genetic/Familial High-Risk Assessment: Breast and Ovarian V.1.2018, Genetic/Familial High-Risk Assessment: Colorectal V.3.2017, Gastric Cancer V.1.2018 and Prostate Cancer Early Detection V.2.2018. © National Comprehensive Cancer Network, Inc 2018. All rights reserved. The NCCN Guidelines® and illustrations herein may not be reproduced in any form for any purpose without the express written permission of the NCCN. Accessed May 23, 2018. To view the most recent and complete version of the guideline, go online to NCCN.org. NATIONAL COMPREHENSIVE CANCER NETWORK®, NCCN®, NCCN GUIDELINES®, and all other NCCN Content are trademarks owned by the National Comprehensive Cancer Network, Inc..</p>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>{/* eo bs-example */}
              {/* end of details accordian code */}
            </div>{/* eo-rs-main-card*/}
          </div>{/* eo-rs-sec-wrap */}
        </section>{/* eo-risk-summery-sec */}
        <footer className="footer-sec">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
            </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
        )
    }
}
export default Details;