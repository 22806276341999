import React from 'react'

class Footer extends React.Component{

   render(){
  

       return(
        <footer className="footer-main-wrap  pt-5">
        <div className="footer-main container text-left ">
          <div className="d-flex d-flex-inline">
            <div className="col-lg-4 col-sm-0 d-none d-lg-block">
              <div className="logo-wrap pb-2">
                <a className="navbar-brand" href="/"><img  src="./img/Logo.png" width={150} height={90} /></a>
              </div>
              {/* end of logo-wrap */}
              {/* <p class=" pt-3"></p> */}
            </div>
            <div className="col-lg-2 col-sm-0 d-none d-lg-block">
            </div>
            <div className="col-lg-2 col-sm-3">
              <div className="logo-wrap pb-2">
                <h5 className="pb-3">About</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="#" className="footer-link ">Blog</a>
                  </li>
                  {/* <a href="#" class="footer-link ">Mission</a> */}
                  <li>
                  </li>
                  <li>
                    {/* <a href="#" class="footer-link ">Customer Care</a> */}
                  </li>
                  <li>
                    {/* <a href="#" class="footer-link ">Careers</a> */}
                  </li>
                </ul>
              </div>
              {/* end of logo-wrap */}
              {/* <p class=" pt-3"></p> */}
            </div>
            <div className="col-lg-2 col-sm-3">
              <div className="logo-wrap pb-2">
                <h5 className="pb-3">Connect</h5>
                <ul className="list-unstyled ">
                  <li>
                    <a href="#" className="footer-link ">Facebook</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link ">Linkedin</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link ">Twitter</a>
                  </li>
                  <li>
                    <a href="#" className="footer-link ">Instagram</a>
                  </li>
                </ul>
              </div>
              {/* end of logo-wrap */}
              {/* <p class=" pt-3"></p> */}
            </div>
            <div className="col-lg-2 col-sm-3">
              <div className="logo-wrap pb-2">
                <h5 className="pb-3">Legal</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/privacy-policy" className="footer-link ">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/termsofservices" className="footer-link ">Terms of Service</a>
                  </li>
                  <li>
                    {/* <a href="#" class="footer-link ">Consent for Research</a> */}
                  </li>
                </ul>
              </div>
              {/* end of logo-wrap */}
              {/* <p class=" pt-3"></p> */}
            </div>
          </div>
          <div className="row ">
            <div className="footer-copyright text-center col-sm-12 col-md-6 text-md-left border-top pt-3">
              <a>Copyright © 2019 truGeny, Inc. </a>
            </div>
            <div className=" text-center col-sm-12 col-md-6  text-md-right border-top  footer-legal-links">
              <ul>
                <li>
                  <a className="legalLinkBtn  d-none" id="signin" href="/termsofservices">Terms of Service</a>
                  {/* <span>|</span> */}
                  <a className="legalLinkBtn  d-none" id="register" href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
            {/* end of signin-register*/}
          </div>
        </div></footer>
       )
   }

}
export default Footer;