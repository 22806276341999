import React from 'react';




class constants extends React.Component {
    render() {
      
        const USERTYPE_CUSTOMER = "CUSTOMER";
        return (
         <div></div>
        )
      }
 
  }
  
  


  export default constants;