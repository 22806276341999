import React from 'react';
import {   Form, } from 'reactstrap';
import '../styles.css';
import axios from '../axios/axios';
import SubFooter from './SubFooter';
import swal from 'sweetalert';
import { css } from '@emotion/core';
// First way to import
import { FadeLoader} from 'react-spinners';
import { MoonLoader} from 'react-spinners';
import MainHeader from './MainHeader';
import { UncontrolledAlert } from 'reactstrap';
import Badge from 'react-bootstrap/Badge'


// Another way to import. This is recommended to reduce bundle size


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class CliniciansRegister extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      emailData:'',
      emailStatus:'',
      loading: false,
      loading2:false,
      data:'',
      doctorsData:[],
      emailData:'',
      hasErrors:'',
      isOpen: false,
      modal: false,
      createPwd:false,
      otp:false,

      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPwd: '',
      smcName:'',
      yearInfo:'',
      registrationNo:'',
      errorMsg: null,
      errors: {},
      loginForm: {},
      loginErrors: {},
      emailStatus:''
    };


    this.toggle = this.toggle.bind(this);
    this.registration = this.registration.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.createPassword=this.createPassword.bind(this)
    this.withOTP=this.withOTP.bind(this)
    this.doctorsDataRender=this.doctorsDataRender.bind(this);
    this.emailVerification=this.emailVerification.bind(this);


  }


  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  closeModel() {
    this.setState({
      modal: false,
      errorMsg: null,
      errors: {}
    });
  }
  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  onChangeEmail= (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }


  handleChange2= (e) => {

    this.setState({loading:true},()=>{}
    )
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    
    this.getDoctorsData()
  }
  handleChange3= (e) => {

    // this.setState({loading:true},()=>{}
    // )
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    
  }
  checktermsAndConditions = (e) =>{
    const loginForm = this.state
  
    if(e.target.checked){
      loginForm[e.target.name] = 1;
    }else{
      loginForm[e.target.name] = 0;
    }
    this.setState(loginForm);
  }



  /* Registration Form Validation */

  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined" && fields["password"] !== "") {
      if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (!fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Please confirm password.";
    }
    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";
    }

    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";
    }



    if (fields["terms"]=== 0 || fields["terms"]===undefined) {
      formIsValid = false;
      errors["terms"] = "*Please accept terms and conditions";
    }

    if(this.state.emailStatus==false){
        formIsValid = false;
        swal("Please provide valid email address...!")

        
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  createPassword(){
      this.setState({  createPwd:true }, function () {
      });
      this.setState({  otp:false }, function () {
      });
    }

  withOTP(){
    this.setState({  otp:true }, function () {});
    this.setState({  createPwd:false }, function () {});
  }

  /* saving user registration details*/
registration(e) {

e.preventDefault();
    if (this.validateForm()) {
      let group = "native";
      let userType="CLINICIANS";
      let passwordType="password";
      const { firstName, lastName, email, registrationNo,smcName,yearInfo,  password, confirmPwd } = this.state;
      axios.post('users/user', { firstName, lastName, email,registrationNo,smcName,yearInfo,  password, confirmPwd,userType,group,passwordType })
        .then((result) => {
         
          swal("Registration successfull...!");
          this.props.history.push("/clinicians_login")
        }).catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/clinicians_error")
          } else {
            this.setState({
              errorMsg: error.response.data.message
            })
          }
        });
        e.target.reset();
      }
  }

  loader(){
      return (
        <div className='sweet-loading'>
          <FadeLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={this.state.loading}
          />
      </div> )
  
    
  }
  loader2(){
    return (
      <div className='sweet-loading'>
        <MoonLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color={'#123abc'}
          loading={this.state.loading2}
        />
    </div> )

  
}
  getDoctorsData(){
    this.setState({loading:true},()=>{}
    )
    let id=this.state.registrationNo;
    if(id){
    (async () => {
      const rawResponse = await fetch('https://mciindia.org/MCIRest/open/getDataFromService?service=searchDoctor', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'registrationNo': id})
      });
      const content = await rawResponse.json();
      this.setState({

        doctorsData: content,
        data:"Invalid Registration Number...!",
        loading:false

      })
    
      // console.log(content[0]);
      // console.log("data...........",this.state.doctorsData[0].yearInfo);
      // console.log("data...........",this.state.doctorsData);
      // if(this.state.doctorsData.find(x => x.firstName === "YASHAVANTH")){
      //   swal("yes")
      //   console.log("coming..........")
      // }
      
    })();
  }
  }
  

  emailVerification(){
    let formIsValid = true;

    // this.setState({loading2:true},()=>{}
    // )
    let id=this.state.email;
    if(id){
      (async () => {
        const rawResponse = await fetch('https://api.zerobounce.net/v2/validate?api_key=87ba3889be724555ad29d62183ed6334&email='+id+'&ip_address=', {
          method: 'GET',
        });
        const content = await rawResponse.json();
        if(content){
          this.setState({loading2:false},()=>{})
        }
        if(content.status==="valid"){
         
          this.setState({
            emailData:content,
            emailStatus:content.status},()=>{})
        }else  if(content.status==="invalid"){
           
          this.setState({emailStatus:content.status},()=>{})
          formIsValid = false;
        }
        
      })();
  
  }
  }


  /* user login authentication*/


doctorsDataRender(){

  return(
    <div className="form-row justify-content-center">
      <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3" htmlFor="validationTooltip01">
        <div className="input-group ">
          <select type="text"  name="smcName" onChange={this.onChange} className="form-control m-0" id="validationTooltip01"  placeholder="Registration Counsil" required >
          <option  value="" hidden="hidden">Medical Counsellor Name</option>
          {this.state.doctorsData.map((e, key) => {
          return <option  key={key} selected={e == e.value} value={e.value}>{e.smcName}</option>;
      })}
          </select>
          <div className="valid-tooltip">
            Looks good!
          </div>
          <div className="input-group-prepend border-0">
            <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
          </div>
        </div>
      </div>
      <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
        <div className="input-group">
        <select type="text" name="yearInfo" onChange={this.onChange} className="form-control m-0" id="validationTooltip01"  placeholder="Registration Counsil" required >
        <option  value="" hidden="hidden">Registration Year</option>
          {this.state.doctorsData.map((e, key) => {
          return <option  key={key} value={e.value}>{e.yearInfo}</option>

      })}
          </select>          <div className="valid-tooltip">
            Looks good!
          </div>
          <div className="input-group-prepend">
            <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i1.png" /></span>
          </div>
        </div>
      </div>
    </div>
  )

//   return(
// <div>
//        <div className="form-row justify-content-center">
//       <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3" htmlFor="validationTooltip01">
//       <select className="input-group" type="text" name="" style={{border: 'none', height: '60px',width:'460px',marginLeft:'325px'}}>
//       {this.state.doctorsData.map((e, key) => {
//           return <option key={key} value={e.value}>{e.firstName}</option>;
//       })}
//     </select>
//     </div>
//     </div>
    

// </div>
//   )
}
alert() {
  return (
    <div>

      <UncontrolledAlert color="info">
        Email verified...!!
   </UncontrolledAlert>
    </div>
  )
}

alert2() {
  return (
    <div>

      <UncontrolledAlert color="danger">
       Invalid email id!
   </UncontrolledAlert>
    </div>
  )
}

 
  render() {


    return (
      <div>
 <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./css/payment.css" />
        <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>
        <style dangerouslySetInnerHTML={{__html: "\n  /* form focus styles*/\n\n  .form-control:focus {\n    border-color: #ebebeb;\n    box-shadow: inset\n    0 0px 0px rgba(0, 0, 0, .01),\n    0 0 0px rgba(0, 0, 0, .01),\n    0 0 30px rgba(141, 141, 141, 0.5),\n    0 0 0px rgba(0, 0, 0, .01);\n  }\n\n  /*End of  form focus styles*/\n  " }} />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
                <MainHeader category="register"></MainHeader>
        <div style={{paddingBottom: '12.5rem'}} className="padding2"/>
        <div className=" text-center mb-1 sec-1">
          {/* <a href="index.html"><img  class="logo-create-page text-center img-fluid"src="./img/registration/Logo.png"/></a> */}
          <div className="container c1 text-center">
          <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Dear Doctor,<br /> Create your truGeny account </h1>
              <a href="/clinicians_login" className="text-center img-fluid para roboto-reg-16px" style={{color: '#4f5be7'}}>Already have your truGeny account? Sign in</a>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="cr-sec-main container mt-5 pt-3  pb-5 mb-2 b1-cr ">
            <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errorMsg}</div>
              <div className="form-check form-check-inline p-3 d-none">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
                <label className="form-check-label" htmlFor="inlineRadio1">I am a Clinicians</label>
              </div>
              <div className="form-check form-check-inline p-3 d-none">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                <label className="form-check-label" htmlFor="inlineRadio2">I am an Individual</label>
              </div>
              <div className="gc-form-card-btm pt-5">
                <Form className="cr-form pt-0 gc-form needs-validation" onSubmit={this.registration}>
                <div data-custom-attribute="foo" />
                  <div className="form-row justify-content-center">
                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3" htmlFor="validationTooltip01">
                      <div className="input-group ">
                        <input type="text" name="firstName" placeholder="First Name" onChange={this.onChange} className="form-control m-0" id="validationTooltip01" placeholder="First Name"  required/>
                        <div className="valid-tooltip">
                          Looks good!
                        </div>
                        <div className="input-group-prepend border-0">
                          <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                        </div>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.firstName}</div>

                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
                      <div className="input-group">
                        <input type="text" name="lastName" placeholder="Last Name" onChange={this.onChange}  className="form-control m-0" id="validationTooltip02" placeholder="Last Name" required />
                        <div className="valid-tooltip">
                          Looks good!
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i1.png" /></span>
                        </div>
                      </div>
                    </div>
                  </div>{/* end of form-row */}
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.firstName}</div>
                  <div className="form-row justify-content-center">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group">
                        <input type="text" name="email"  className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" onChange={this.onChangeEmail} onBlur={this.emailVerification} />
                        <div className="invalid-tooltip">
                          Please enter a valid E-mail ID.
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                        </div>
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.email}</div>
                     
                      {/* {this.state.loading2?this.loader2():this.state.emailStatus==="okay"?this.succss()?this.state.emailStatus==="bad"?this.fail():'':'':''} */}
                      {this.state.emailStatus==="valid"? this.alert():this.state.emailStatus==="invalid"? this.alert2():''}





                    </div>
                  </div>
                  <div className="form-row justify-content-center">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group"> <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><Badge  variant="light">+91</Badge></span>
                    </div>
                        <input type="text" name="phone" maxLength={10} onChange={this.onChange} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="Mobile Number" aria-describedby="validationTooltipEmailPrepend" required />
                        <div className="invalid-tooltip">
                          Please enter a valid Phone Number.
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="./img/homepage/mob.png" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end of form-row */}
                  {/* <div class="form-check form-check-inline p-3 ">
          <input class="form-check-input" type="radio" name="passd" id="passd" value="option1" onclick="randomNumber()">
          <label class="form-check-label" for="inlineRadio1">Generate Password</label>
        </div> */}
                 
                <div className="form-row justify-content-center">
                <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                  <div className="input-group">
                    <input type="text" name="registrationNo" onChange={this.handleChange2} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="Medical Registration number" aria-describedby="validationTooltipEmailPrepend" required />
                    <div className="invalid-tooltip">
                      Medical Registration.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.loading?this.loader():this.state.doctorsData.length?this.doctorsDataRender():<div style={{ color: 'red', fontSize: '12px' }}>{this.state.data}</div>}
              
                  

                  <div><div id="passwordvar" className="form-row justify-content-center">
                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
                      <div className="input-group">
                        <input type="password" name="password" placeholder="Create Password" onChange={this.onChange} className="form-control m-0" id="validationTooltip05" placeholder="Create Password" required />
                        <div className="invalid-tooltip">
                          Looks Good!
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltip05"><img src="img/payment/pwd.png" /></span>
                        </div>
                      </div>
                    </div>
                    <div className="cr-col  col-sm-5 col-md-4 col-lg-3 mb-3">
                      <div className="input-group">
                        <input type="password" name="confirmPwd" placeholder="Confirm Password" onChange={this.onChange} className="form-control m-0" id="validationTooltip05" placeholder="Confirm Password" required />
                        <div className="invalid-tooltip">
                          Looks Good!
                        </div>
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltip05"><img src="img/payment/pwd.png" /></span>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.password}</div>
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.confirmPwd}</div></div>

                  
                  {/* {this.doctorsDataRender()} */}

                  
                  {/* end of form-row */}

              <div className="after-form-sec">
                <div className="col-xs-12 mx-auto d-flex justify-content-center mt-1 ">
                  <div className="custom-control form-control-lg custom-checkbox">
                    <input type="checkbox" name="terms" defaultValue="0" onChange={this.checktermsAndConditions}  id="customCheck1" />
                    <label  htmlFor="customCheck1"><span><p style={{color: 'black', fontSize: '1rem'}}>I agree to the truGeny <a style={{color: '#4f5be7'}} href="/clinicians_termsofservices">Terms of Service</a> &amp; <a style={{color: '#4f5be7'}} href="/al_privacy_policy">Privacy Policy</a></p></span></label>
                  </div>
                </div>
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.terms}</div>

                {/*Grid column*/}
                <div className="row d-flex justify-content-center px-3 pt-3 regButton">
                 <button disabled={this.state.emailStatus==="valid"?false:true} className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center" style={{height: '60px', width: '26rem', borderRadius: '1.875rem'}} type="submit"  >
                    <b>CREATE ACCOUNT</b></button>
                  {/* <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center" style={{height: '60px', width: '26rem', borderRadius: '1.875rem'}} type="submit" >
                    <b>CREATE ACCOUNT</b></button> */}
                </div>
                {/* <div className="col-xs-12 col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto d-flex justify-content-center ">
                  <span className="my-3 line-span align-self-center" />
                  <span className="my-3 text-uppercase px-2 or align-self-center">or</span>
                  <span className="my-3 line-span align-self-center"> </span>
                </div>
                <div className=" d-flex justify-content-center mx-auto">
                  <div className=" form-row d-flex justify-content-between ">
                   
                    <GoogleLogin
                  clientId="527321491147-9dl5u31g86dss5lpus7f8u5n6iqgeikn.apps.googleusercontent.com"
                  render={renderProps => (
                    <GoogleLoginButton style={formFileds} onClick={renderProps.onClick} disabled={renderProps.disabled}></GoogleLoginButton>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                    >
                  <span>Sign In with Google</span>
                </GoogleLogin>
                  
                <FacebookLogin
                appId="1703894579721102"

                callback={responseFacebook}
                render={renderProps => (
                  <FacebookLoginButton style={formFileds} onClick={renderProps.onClick}></FacebookLoginButton>
                )}
              />
                  </div>
                </div> */}
               
              </div>
                  
                </Form>
              </div>{/* end of gc-form-card-btm */}
              {/* end of after-form-sec */}
            </div>{/* end of cr-sec-main */}
            {/* <div className="container text-center mt-5 pb-5">
              <img className="plus-button img-fluid mb-3" src="./img/registration/Icon.png" />
              <p className=" text-center  " style={{color: '#84888e'}}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a className="pb-5 cr-1" href="/login"><strong>CLICK HERE</strong></a> to create your account</p>
            </div> */}
          </div>
        </div>
        <div style={{paddingTop: '12.25rem'}}>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        <SubFooter></SubFooter>
    </div>

    );

  }
}

export default CliniciansRegister;
