import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";

class FamilyHistory extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }
 


  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        {/* header */}
        <CommonHeder></CommonHeder>
        {/* end of header */}
        <section className="risk-summery-sec">
          <div className="rs-sec-wrap  p-0 container ">
            <div className="card rs-main-card text-center rounded border-0 shadow-lg">
              <div className="card-header p-4 clearfix border-0 ">
                <div className="rs-header d-flex flex-column justify-content-start float-left">
                  <h2>{Cookies.get('username')}</h2>
                  <span className=" ">Hereditary Cancer Report</span>
                </div>{/* eo-rs-header */}
                <ul className="rs-nav nav  card-header-tabs justify-content-end float-right">
                  <li className="nav-item">
                    <a className="nav-link" href="#">Genetic counselling appointment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Share report</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true">Download report</a>
                  </li>
                </ul>
              </div>{/* eo-card-header */}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-head px-4 py-5 container ">
                  <h2 className="card-title">Risk Summary</h2>
                  <p className="card-text">John, the results from your test do not indicate any increased genetic risks for inherited cancers</p>
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-genetic-mutation.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      No genetic mutations were identified.
                    </h5>
                    <p className="card-text">We analysed 90 genes that are associated with breast, colorectal, ovarian, pancreatic and stomach cancers. No mutations were identified in these genes.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">See your Detailed Report</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="container seperatorline" />
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-health-history.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      Your health history is incomplete.
                    </h5>
                    <p className="card-text">You have not completed your health history, which may impact the risk and screening guidelines for you. Although your have tested negative, 85 - 90% of all cancers have a non-genetic cause. When cancer is detected early it improves the odds of survival.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Update your health history now</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="dv-block-wrap d-flex flex-column justify-content-start">
                  <div className="dv-head cb-head px-4 pt-5 pb-0 container">
                    <h2 className="card-title">Detailed View</h2>
                    <ul className="dv-nav nav  card-header-tabs">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Your Risks</a> */}
                        <Link to="/risksummary" className="nav-link">Your Risks</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="details.html">Details</a> */}
                        <Link to="/details" className="nav-link">Details</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="next-steps.html nav-active">Next Steps</a> */}
                        <Link to="/nextsteps" className="nav-link">Next Steps</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link nav-active" href="family-history.html">Family History</a> */}
                        <Link to="/familyhistory" className="nav-link nav-active">Family History</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="genetic-counselling.html">Genetic Counselling</a> */}
                        <Link to="/genetic-counselling" className="nav-link">Genetic Counselling</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="share-details.html">Share Your Report</a> */}
                        <Link to="/sharedetails" className="nav-link">Share Your Report</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">faqs</a> */}
                        <Link to="/faqs" className="nav-link">faqs</Link>

                      </li>
                    </ul>
                  </div>{/* eo-dv-block-cb-head */}
                </div>{/* eo-dv-block-wrap */}
              </div>{/* eo-card-body*/}
              <section className="gc-sec-wrap">
                <div className="gc-card-body card-body p-0 container-fluid ">
                  <div className="gc-sec  px-4 py-5 container clearfix">
                    <div className="gc-img-wrap float-right mt-3 p-5">
                      <img className=" img-fluid text-center d-block " src="./img/risk-summary/family-history.png" alt="Card image cap" />
                    </div>
                    <div className="gc-text cb-sec-one-text float-right">
                      <h5>
                        Inheritance of mutations
                      </h5>
                      <p className="card-text">We recommend you to consider sharing your results with your relatives. Your relatives may benefit from learning their genetic status.
                        A few key points to remember: Your negative result shows that there’s a lower chance that you have an inherited mutation associated with hereditary cancer.</p>
                      <p>However, it is still possible for your relatives to have a hereditary cancer or a mutation that you did not inherit. They can benefit from getting a genetic test done. If any of your relatives have a mutation, there’s a 50% chance that their children or siblings have the same mutation. If any one of the parents have a mutation, their sons and daughters are equally likely to inherit a mutation.</p>
                      <p>You could recommend truGeny genetic counselling to your relatives so they understand their genetic risks better.</p>
                      <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">email my relatives<i className="fas fa-long-arrow-alt-right" /></a>
                    </div>{/* eo-gc-text */}
                  </div>{/* eo-gc-sec */}
                </div>{/* eo-gc-card-body*/}
              </section>{/* eo-gc-sec-wrap */}
              {/* end of genetic counselling card */}
            </div>{/* eo-rs-main-card*/}
          </div>{/* eo-rs-sec-wrap */}
        </section>{/* eo-risk-summery-sec */}
        <footer className="footer-sec">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
            </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default FamilyHistory;
