import React from 'react';
import { Form } from 'reactstrap';
import axios from '../axios/axios';
import Cookies from 'js-cookie';


class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          password: '',
          confirmPwd: '',
          errors: {},
        };

        this.changePwd = this.changePwd.bind(this)
        this.handleChange = this.handleChange.bind(this)
    
      }

      handleChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
      }

      validateForm() {

        let fields = this.state;
        let errors = {};
        let formIsValid = true;
    
      
        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = "*Please enter your password.";
        }
    
        if (typeof fields["password"] !== "undefined" && fields["password"] !== "") {
          if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
            formIsValid = false;
            errors["password"] = "*Please enter secure and strong password.";
          }
        }
        if (!fields["confirmPwd"]) {
          formIsValid = false;
          errors["confirmPwd"] = "*Please confirm password.";
        }
        if (fields["password"] !== fields["confirmPwd"]) {
          formIsValid = false;
          errors["confirmPwd"] = "*Password mismatched";
    
        }
    
        this.setState({
          errors: errors
        });
        return formIsValid;
    
    
      }

    changePwd(e) {
        e.preventDefault();
        if (this.validateForm()) {
          let email = Cookies.get('email')
         
          const { password   } = this.state;
          
          axios.post('users/changepwd', {email, password })
            .then((response) => {
    
              this.props.history.push('/dashboard');
            }).catch(
              error => {
                if (error.response === undefined) {
                  this.props.history.push("/error")
                } else {
                  this.setState({
                    errorMsg: error.response.data.message
                  })
                }
              }
            )
        }
      }
    render() {
        return (
            <div>
                {/* saved from url=(0045)https://www.abravelab.com/TruGene/signIn.html */}
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="stylesheet" href="./signIn_files/w3.css" />
                <link href="./signIn_files/css" rel="stylesheet" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
                <link rel="stylesheet" href="./signIn_files/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
                <title>trueGeny</title>
                <link rel="icon" href="https://www.abravelab.com/TruGene/img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
                <link rel="stylesheet" href="./signIn_files/fonts.css" />
                <link rel="stylesheet" href="./signIn_files/payment.css" />
                {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
                <div className=" text-center mb-1 sec-1">
                    <img className="logo-create-page text-center img-fluid" src="./signIn_files/Logo.png" />
                    <div className="container c1 text-center">
                        <div className="head-text">
                            <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Change Password to truGeny account </h1>
                        </div>
                        {/* ---------------------------------------------------section 2 started-----------*/}
                        <Form onSubmit={this.changePwd}>
                            <div className="container mt-5 pt-5  pb-5 mb-2 b1-cr">
                                {/* <div class="col-sm-12 d-flex d-inline-flex mx-auto justify-content-center"> */}
                                <div className="col-xs-12">
                                    <div>
                                        <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                                            <input type="text" placeholder="Create Password" name="password" onChange={this.handleChange} className="form-control roboto-reg-16px" style={{ border: 'none', height: '40px' }} aria-label="Text input with dropdown button" />
                                            <button className="btn btn-outline-secondary roboto-bold-14px " href="#" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="./signIn_files/pwd.png" /></button>
                                        </div>
                                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.password}</div>

                                        <div className="input-group-prepend mb-3 mx-auto" style={{ border: 'solid 1px #ebebeb', maxWidth: '460px' }}>
                                            <input type="text" placeholder="Confirm Password" name="confirmPwd" onChange={this.handleChange}  className="form-control roboto-reg-16px" style={{ border: 'none', height: '40px' }} aria-label="Text input with dropdown button" />
                                            <button className="btn btn-outline-secondary roboto-bold-14px " href="#" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="./signIn_files/pwd.png" /></button>
                                        </div>
                                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.confirmPwd}</div>
                                    </div>
                                    <div className="input-group-prepend mb-3 mx-auto" style={{ maxWidth: '220px', marginRight: '10px !important' }}>
                                        <button type="submit" style={{ height: '40px', width: '220px' }} className="mt-2 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" type="submit">Change Password</button>
                                    </div>

                                </div>
                                {/*Grid column*/}

                            </div>
                        </Form>
                    </div>
                </div>
                <div style={{ marginTop: '6rem' }}>
                </div>
                {/* ------------------------------------------------------------------------------------------------- */}
                <footer className="footer-sec ">
                    <div className="container footer-wrap text-center">
                        <p>2019 truGeny</p>
                        <div className="f-social-links">
                            <ul className="social-links list-inline">
                                <li className="list-inline-item"><a href="https://www.abravelab.com/TruGene/signIn.html#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                                <li className="list-inline-item"><a href="https://www.abravelab.com/TruGene/signIn.html#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                                <li className="list-inline-item"><a href="https://www.abravelab.com/TruGene/signIn.html#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                                <li className="list-inline-item"><a href="https://www.abravelab.com/TruGene/signIn.html#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
                            </ul>{/* eo .social-links */}
                        </div>{/* eo-f-social-links */}
                        <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
                </div>
                    </div>{/* eo-footer-wrap */}
                </footer>
                {/* Optional JavaScript */}
                {/* jQuery first, then Popper.js, then Bootstrap JS */}
            </div>
        )
    }
}
export default ChangePassword;