import React from 'react'

class SubFooter extends React.Component{

   render(){
  

       return(
       
        <footer className="footer-sec ">
        <div className="container footer-wrap text-center">
          <p>2019 truGeny</p>
          <div className="f-social-links">
            <ul className="social-links list-inline">
              <li className="list-inline-item">
                <a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="icon-google-plus"><i className="fab fa-google" /></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a>
              </li>
            </ul>
            {/* eo .social-links */}
          </div>
          {/* eo-f-social-links */}
          <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">
            <a style={{color: 'gray'}} href="/al_termsofservices">Terms of Service</a>&nbsp;|&nbsp;
            <a style={{color: 'gray'}} href="/al_privacy_policy">Privacy Policy</a> &nbsp;|&nbsp;
            {/* <a  style="color:gray" href="#">Consent for Research</a>&nbsp;|&nbsp; */}
            {/* <a  style="color:gray" href="#">Mission</a>&nbsp;|&nbsp; */}
            <a style={{color: 'gray'}} href="#">Blog</a>
            {/* &nbsp;|&nbsp; */}
            {/* <a  style="color:gray" href="#">Customer Care</a>&nbsp;|&nbsp; */}
            {/* <a  style="color:gray" href="#">Careers</a>&nbsp;|&nbsp; */}
          </div>
          {/* eo-footer-wrap */}
        </div></footer>
       )
   }

}
export default SubFooter;