export const baseUrl="http://localhost:8081";

export const trugenyUrl="https://trugeny.com";

export const blogUrl="https://blog.trugeny.com";

export const action="https://api.razorpay.com/v1/checkout/embedded";

export const key_id="rzp_test_3oWYKsj947PbQ0";
// export const key_id="rzp_live_4upJjqO83mExfq";
export const pay_name="Acme Corp";

export const pay_description="TruGeny Payment GateWay";


  
export const pay_profileName="Anil";

export const pay_contact=9123456780;

export const pay_email="it.support@trugeny.com";


export const pay_address="truGeny, Longevity Labs";

export const callback_url=baseUrl+"/cartservice/razorpay";


export const cancel_url=baseUrl+":3000";

export const truGenyConatct=18005726889;

export const truGenyMail="support@truGeny.com";





