import React from 'react'
import HeaderLinks from './HeaderLinks';
import NewMainHeader from './NewMainHeader';
import SubMainFooter from './SubMainFooter';

class RiskTq extends React.Component{
    constructor(props) {
        super(props);
       

    }

  
    
   render(){
  
       return(
        <div>
        <HeaderLinks></HeaderLinks>
        <style dangerouslySetInnerHTML={{__html: "\n.background-pay-card{\n  background: url(./img/risk-page/tq/background.png) no-repeat center;-webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n  width:auto;\n  height:630px;\n  margin:auto;\n  border-radius: 2.5rem;\n}\n\n" }} />
        <NewMainHeader></NewMainHeader>
        {/* end of header */}
        <div style={{marginTop: '6rem'}} />
        <div className="container img-flud background-pay-card ">
          <div className="text-center" style={{paddingTop: '200px'}}>
            <h1 className="roboto-black-36px"> Thank you!</h1>
            <p className="px-5 roboto-reg-24px py-3">Our team will review and you will receive your risk assessment in you inbox very soon. Meanwhile if you have any questions, please mail them to<a style={{color: '#4f5be7'}} href="mailto:support@trugeny.com"> support@trugeny.com</a></p>
          </div>
        </div>
        <div style={{marginTop: '6rem'}}>
        </div>
        <SubMainFooter></SubMainFooter>

        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
        
       )
   }

}
export default RiskTq;