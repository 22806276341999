import React from 'react'
import HeaderLinks from './HeaderLinks';
import NewMainHeader from './NewMainHeader';
import axios from '../axios/axios';

class RiskLogin extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

            details: {},
        }
        this.handle = this.handle.bind(this)
        this.prospectReg = this.prospectReg.bind(this);

    }

  
    toRiskSucess() {
      // browserHistory.push("/ordertrugene");
        window.location.href="/risktq";
      }



      handle(event) {
       
        let healthhis = this.state.details
        healthhis[event.target.name] = event.target.value;
        this.setState({ details: healthhis })
      }
      prospectReg(e) {
        e.preventDefault();
    
           
            axios.post('users/prospectRiskpage/'+"",this.state.details)
                .then((response) => {
                  
                    this.toRiskSucess()
    
                    alert("Thank you for your interest. We will get back you soon...")
                    // this.setState({
                    //     sucessMsg:"Thank you for your interest. We will get back you soon..."
                    // })
                   // alert("Thanks for your intersr. We will get back you soon")   
                
     
                }).catch(error => {
                  if(error.response===undefined){
                    this.props.history.push("/error")
                  }else{
                    this.setState({
                        errorMsg: error.response.data.message
                    })  
                  }
                   
                });
                e.target.reset();
        
       
      
    }
   render(){
  let state=localStorage.getItem("RiskPage");
  


       return(
        <div>
        <HeaderLinks></HeaderLinks>
        <style dangerouslySetInnerHTML={{__html: "\n.background-pay-card{\n  background: url(./img/risk-page/tq/background.png) no-repeat center;-webkit-background-size: cover;\n  -moz-background-size: cover;\n  -o-background-size: cover;\n  background-size: cover;\n  width:auto;\n  height:630px;\n  margin:auto;\n  border-radius: 2.5rem;\n}\n\n" }} />
        <NewMainHeader></NewMainHeader>
        {/* end of header */}
        <div style={{marginTop: '6rem'}} />
        <div className="container  text-center shadow px-0 pt-5" style={{borderRadius: '2rem'}}>
          <div style={{backgroundColor: '#fff'}}>
            <h1 className="text-center roboto-black-36px pt-5" style={{color: '#4f5be7'}}>Your Risk Assessment Questionnaire is now COMPLETE.</h1>
            <p className="px-5 roboto-black-30px py-3">SIGN UP TO RECIEVE YOUR RISK ASSESSMENT REPORT FOR FREE</p>
          </div>
          <div style={{backgroundColor: '#eff7ff'}}>
            <form className="needs-validation" onSubmit={this.prospectReg} validate>
              <div className=" text-center py-5">
                <div className=" hero-ravi justify-content-center px-2" style={{maxWidth: '336px', margin: '0px auto'}}>
                  <div className="learn-text-box input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                    <input type="text" placeholder="Name" name="pname" onChange={this.handle} className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                    <button className="btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/risk-page/tq/Icon.png" /></button>
                  </div>
                  <div className="learn-text-box input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                    <input type="email" placeholder="E-mail Id" name="pemail" onChange={this.handle} className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                    <button className="btn btn-outline-secondary" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/risk-page/tq/Icon1.png" /></button>
                  </div>
                  <div className="learn-text-box input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', maxWidth: '334px'}}>
                    <input type="number" placeholder="Phone" name="pphone" onChange={this.handle} className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                    <button className="btn btn-outline-secondary" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/risk-page/tq/icon2.png" /></button>
                  </div>
                  <div className="input-group-prepend mb-3 ">
                    <button className="btn btn-outline-primary  btn-hover-white py-3 " name="submit" style={{borderRadius: '30px', width: '400px', margin: 'auto', border: 'solid 1px #4f5be7', background: '#4f5be7', color: 'white'}} type="submit">Submit</button>
                  </div>
                </div>
              </div></form>
          </div>
        </div>
        <div style={{marginTop: '6rem'}}>
        </div>
        <footer className="footer-sec ">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">
              <a style={{color: 'gray'}} href="termsofservices.html">Terms of Service</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="privacy_policy.html">Privacy Policy</a> &nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Consent for Research</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Mission</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Blog</a> &nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Customer Care</a>&nbsp;|&nbsp;
              <a style={{color: 'gray'}} href="#">Careers</a>&nbsp;|&nbsp;
            </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
        
       )
   }

}
export default RiskLogin;