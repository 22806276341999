import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import HeaderLinks from './HeaderLinks';
import NewMainHeader from './NewMainHeader';
import SubMainHeader from './SubMainHeader';
import StickyHeader from 'react-sticky-header';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import SubFooter from './SubFooter';


class AllGeneticCounsellingHelp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            q1: false,
            b1: true,

            q2: false,
            b2: true,

            q3: false,
            b3: true,

            q4: false,
            b4: true,

            q5: false,
            b5: true,


        }
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggle5 = this.toggle5.bind(this);



    }

    toggle1() {
        this.setState(state => ({ q1: !state.q1 }));
        this.setState(state => ({ b1: !state.b1 }));


        this.setState({ q2: false }, function () { });
        this.setState({ b2: true }, function () { });
        this.setState({ q3: false }, function () { });
        this.setState({ b3: true }, function () { });
        this.setState({ q4: false }, function () { });
        this.setState({ b4: true }, function () { });
        this.setState({ q5: false }, function () { });
        this.setState({ b5: true }, function () { });


    }

    toggle2() {
        this.setState(state => ({ q2: !state.q2 }));
        this.setState(state => ({ b2: !state.b2 }));

        this.setState({ q1: false }, function () { });
        this.setState({ b1: true }, function () { });
        this.setState({ q3: false }, function () { });
        this.setState({ b3: true }, function () { });
        this.setState({ q4: false }, function () { });
        this.setState({ b4: true }, function () { });
        this.setState({ q5: false }, function () { });
        this.setState({ b5: true }, function () { });

    }

    toggle3() {
        this.setState(state => ({ q3: !state.q3 }));
        this.setState(state => ({ b3: !state.b3 }));

        this.setState({ q1: false }, function () { });
        this.setState({ b1: true }, function () { });
        this.setState({ q2: false }, function () { });
        this.setState({ b2: true }, function () { });
        this.setState({ q4: false }, function () { });
        this.setState({ b4: true }, function () { });
        this.setState({ q5: false }, function () { });
        this.setState({ b5: true }, function () { });

    }

    toggle4() {
        this.setState(state => ({ q4: !state.q4 }));
        this.setState(state => ({ b4: !state.b4 }));

        this.setState({ q1: false }, function () { });
        this.setState({ b1: true }, function () { });
        this.setState({ q2: false }, function () { });
        this.setState({ b2: true }, function () { });
        this.setState({ q3: false }, function () { });
        this.setState({ b3: true }, function () { });
        this.setState({ q5: false }, function () { });
        this.setState({ b5: true }, function () { });

    }
    toggle5() {
        this.setState(state => ({ q5: !state.q5 }));
        this.setState(state => ({ b5: !state.b5 }));

        this.setState({ q1: false }, function () { });
        this.setState({ b1: true }, function () { });
        this.setState({ q2: false }, function () { });
        this.setState({ b2: true }, function () { });
        this.setState({ q3: false }, function () { });
        this.setState({ b3: true }, function () { });
        this.setState({ q4: false }, function () { });
        this.setState({ b4: true }, function () { });

    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    toLogin() {
        // browserHistory.push("/ordertrugene");
        window.location.href = "/login";
    }

    render() {
        return (


            <div>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
                <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
                {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
                <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
                <title>truGeny</title>
                <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
                <link rel="stylesheet" href="./css/header_footer.css" />
                <link rel="stylesheet" href="./css/stylesheet_custom.css" />
                <link rel="stylesheet" href="stylesheet_custom.css" />
                <link rel="stylesheet" href="./css/fonts.css" />
                <link rel="stylesheet" href="./css/sticky.css" />
                <style dangerouslySetInnerHTML={{ __html: "\n/* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.activenavbar{\n  color:#4f5be7!important;\n}\n.card-body{\n  text-align: left!important;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/bg1.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.i-button{\n  display: inline-block;\nborder-radius: 60px;\nbox-shadow: 0px 0px 2px #888;\npadding: 0.5em 0.6em;\n}\n.heart-banner-sec-wrap .heart-banner-sec{\n  background: url(./img/help/banner-gc.png);\n  background-position:center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n" }} />
                <CommonHeder></CommonHeder>
                <div style={{ marginTop: '9rem' }}>
                </div>
                <hr id="hor-line" className style={{ marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)' }} />

                <div className="container pt-0 pb-2 mt-0">
                    <nav className="navbar container navbar-expand-xl  navbar-light d-none d-lg-block d-xl-block float-left py-0">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                            </span>
                        </button>
                        <ul className=" row navbar-nav mr-auto ">
                            <li className="nav-item ">
                                {/* <a class="nav-link float-right text-center activenavbar" href="index.html"><span class="activenavbar"><i  class=" i-button mr-1 fas fa-question-circle"></i></span>FAQs</a> */}
                            </li>
                            <li className="nav-item">
                                {/* <a class="nav-link" href="genetic-counseling.html">Generic Counseling</a> */}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:support@truGeny.com" data-toggle="tooltip" title="info@trugeny.com"><span><i className="i-button mr-1 fas fa-at" /></span>Email</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link " title="0870 128 82830" data-toggle="tooltip" href="tel:0870 128 82830" style={{ color: '#4f5be7' }}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>0870 128 82830</a>
                            </li>
                        </ul>
                    </nav>
                    <br />
                    <br />
                </div>
                <section className="heart-banner-sec-wrap banner-position">
                    <div className=" heart-banner-sec ">
                        <div className="container pt-4 mt-2">
                            <h1 className><strong>Genetic Counselling</strong></h1>
                            <div className="input-group " style={{ height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem' }}>
                                <input type="text" className="form-control m-0" id="validationTooltip01" placeholder="Lorem ipsum dolor" name="name" style={{ borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff' }} required />
                                <div className="valid-tooltip">
                                    Looks good!
                </div>
                                <div className="input-group-prepend border-0 d-flex justify-content-center">
                                    <span className="btn btn-outline-primary input-group-text border-0 justify-content-center" style={{ cursor: 'pointer', borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem', backgroundColor: '#4f5be7', width: '160px', color: 'white' }} id="validationTooltip01">SERACH</span>
                                </div>
                            </div>
                        </div>{/* eo-container*/}
                        <div className="d-flex justify-content-center" id="secc1">
                            <a style={{ scrollBehavior: '[ auto | smooth ]' }} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
                        </div>
                    </div>{/* eo-heart-banner-sec */}
                </section>{/* eo-heart-banner-sec-wrap */}
                {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
                <section className="container ">
                    <nav aria-label="breadcrumb mt-5 ">
                        <ol className="breadcrumb " style={{ backgroundColor: '#fff !important', paddingTop: '3rem' }}>
                            <li className="breadcrumb-item">
                                <a href="help.html" className="roboto-reg-18px" style={{ color: '#4f5be7' }}><b>Support</b></a>
                            </li>
                            {/* <li class="breadcrumb-item"><a href="#">Library</a></li> */}
                            <li className="breadcrumb-item active" aria-current="page">
                                <a className="roboto-reg-18px">truGeny Genetic Tests</a>
                            </li>
                        </ol>
                    </nav>
                    {/* Support / Genetic Counselling */}
                    <div className=" mt-5">
                        <div className="text-center">
                            <div className="card-block  p-2 circle">
                                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                                    <div className="accordion" id="accordionIhc">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                                                <h5 className="acc-header collapse-color mb-0">
                                                    What are trueGene tests ?
                          {this.state.b1 ? <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1} className="fa fa-plus-circle" /></a> : <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1} className="fa fa-minus-circle" /></a>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q1}>
                                                <div id="a1" className="collapse show " aria-labelledby="headingOne" data-parent="#accordionIhc">
                                                    <div className="card-body">
                                                        {/* <a href="help_indivisual.html" style="color:#000"> */}
                                                        <p>truGeny is mission driven to provide easy access to high quality genetic tests at an affordable cost. truGeny provides you with complimentary pre
                                                          and post genetic counselling services along with every genetic test. We want to ensure that every individual can understand their hereditary disease
                            risks based on their genetic makeup and how their genes can impact them or their future families.</p>
                                                    </div>
                                                </div>
                                            </Collapse>

                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="acc-header collapse-color mb-0">
                                                    {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                                                    What is the truGeny hereditary cancer test?
                          {this.state.b2 ? <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2} className="fa fa-plus-circle" /></a> : <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2} className="fa fa-minus-circle" /></a>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q2}>
                                                <div id="a2" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                                                    <div className="card-body">
                                                        {/* <a href="help_indivisual.html" style="color:#000"> */}
                                                        <p>Over 90 genes are analysed in the truGeny hereditary cancer test. Genes including BRCA1 &amp; BRCA2 are analysed to help men and women
                                                          understand their risks for hereditary cancers. They will learn about their genetic risks for cancers of the breast, colorectal, melanoma, ovarian,
                                                          pancreatic, prostate, stomach, uterine cancers. Every individual taking this test is offered a free pre and post genetic genetic counselling session
                            with certified genetic counsellors.</p>
                                                        <a href>Learn more</a>
                                                    </div>
                                                </div>
                                            </Collapse>

                                        </div>
                                        <div className="card ">
                                            <div className="card-header" id="headingThree">
                                                {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                                                <h5 className="acc-header collapse-color mb-0">
                                                    What is the truGeny heart test?
                          {this.state.b3 ? <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3} className="fa fa-plus-circle" /></a> : <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3} className="fa fa-minus-circle" /></a>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q3}>
                                                <div id="a3" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                                                    <div className="card-body">
                                                        {/* <a href="help_indivisual.html" style="color:#000"> */}
                                                        <p>Over 30 genes known to be associated with heart conditions are analysed in the truGeny hereditary heart health test. Some of the conditions
                                                          analysed for include arrhythmia, arteriopathies, cardiomyopathies, and familial hypercholestremia. Learning about your condition will enable you and
                                                          your Doctor to create a personalised plan to prevent heart disease. Individuals with a personal or family history of heart disease should consider
                            taking this test.</p>
                                                        <a href>Learn more</a>
                                                    </div>
                                                </div>
                                            </Collapse>

                                        </div>
                                        <div className="card ">
                                            <div className="card-header" id="headingThree">
                                                <h5 className="acc-header collapse-color mb-0">
                                                    {/* <a href="help_indivisual.html" style="color:#4f5be7"> */}
                                                    Who will help me understand more?
                          {this.state.b4 ? <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4} className="fa fa-plus-circle" /></a> : <a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{ color: '#4f5be7' }} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4} className="fa fa-minus-circle" /></a>}
                                                </h5>
                                            </div>
                                            <Collapse isOpen={this.state.q4}>
                                                <div id="a4" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                                                    <div className="card-body">
                                                        <p>When you purchase a truGeny test, you will receive a complimentary pre-test and post-test genetic counselling session with our certified genetic
                                                          counsellors. In the event that you would want to understand more before your purchase the test, you can always go to our website and book a
                            counselling appointment.</p>
                                                        <a href>Book my counselling appointment</a>
                                                    </div>
                                                </div>
                                            </Collapse>

                                        </div>
                                    </div>
                                </div>{/* eo bs-example */}
                                {/* end of accordian code */}
                            </div>
                        </div>{/* eo card-block */}
                    </div>{/* eo card body */}
                    {/* eo card */}
                    {/* eo-heart-accordian-sec-wrap */}
                </section>{/* eo-heart-accordian-sec */}
                {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
                {/* Footer Links */}
                <SubFooter></SubFooter>
                {/* end of footer */}
                {/* --------eo sticky form----------------- */}
                {/* Optional JavaScript */}
                {/* jQuery first, then Popper.js, then Bootstrap JS */}
            </div>
        );
    }
}
export default AllGeneticCounsellingHelp;
