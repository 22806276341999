import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import axios from '../axios/axios';
import SubFooter from './SubFooter';
import HeaderLinks from './HeaderLinks';
import moment from 'moment';

class YourReports extends React.Component{

  constructor(props) {
    super(props);
    this.state = {

      doctorId : '',
      appointmentDate : '',
      appointmentTime : '',
      
      counsellorfirstName:'',
      counsellorLastName:'',
      lastorderHistory:[],
      appointmentStatus:'',
    }
    
    this.viewReports = this.viewReports.bind(this)


  }

    componentDidMount(){
      axios.get('schedule/getAppointmentDetails/'+Cookies.get("userId"), 
    { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
    )
        .then((response) => {
          this.setState({
            doctorId : response.data.doctorId,
            appointmentDate : response.data.appointmentDate,
            appointmentTime : response.data.startTime,
            appointmentStatus : response.data.appointmentStatus
          })
          this.getCounsellorDetails();
        }).catch(
          error => {
        
          }
        )

        axios.get('cartservice/'+Cookies.get("userId")+'/orderHistoryForProduct', 
        { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
        )
            .then((response) => {
            if(response.data){
       
              this.setState({lastorderHistory:response.data},()=>{})
            }
            
            }).catch(
              error => {
            
              }
            )
        
    }

    

    getCounsellorDetails(){
      axios.get('users/user/'+this.state.doctorId, 
      { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
      ).then((response) => {
      this.setState(
        {
          counsellorfirstName:response.data.firstName,
          counsellorLastName:response.data.lastName
        }
        )}).catch(error => {})
    }

    viewReports(){
      this.props.history.push('/view-reports');
    }

    render(){

      let appointmentbooked;
      let orderTest;
      let activeKit;
      if(this.state.doctorId){
        appointmentbooked=
       
        <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
        <div className="card  rounded shadow-lg  r4  ">
          <div className="card-body text-center ">
            <div className="card-block mt-2 p-2 circle">
              <img className="card-img-top img-fluid text-center " src="./img/payment/comment.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
              <h3 className="text-highlight pt-3"><strong>truGeny Counseling service</strong></h3>
            </div>
            <h3 className="font-card pt-1"><b>Status:</b> {this.state.appointmentStatus}</h3>
            <h3 className="font-card pt-1 "><b>Date:</b> {moment(this.state.appointmentDate).format("DD-MM-YYYY")} </h3>
            <br />
            {/* <h3 class="font-card">Schedule your appointment with our genetic experts to start your genetic journey.</h3> */}
            {/* <a href="view-reports.html">     <img class="card-img-top text-center p-2 mb-1"  onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
            <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports} type="submit"><b>VIEW REPORT/ UPDATE</b></button>
            <br />
            <br />
          </div>
          {/* eo-card-body */}
        </div>
        {/* eo-card */}
      </div>;
      }
      else{
        
        appointmentbooked=
        
        <div className=" col-sm-12 col-md-12 col-lg-4 ">
        <div className="card  rounded shadow-lg  mb-5  r4 ">
          <div className="card-body text-center pb-5">
            <div className="card-block mt-2 p-2 circle ">
              <img className="card-img-top text-center  p-2" src="./img/login/1.png" style={{height: '160px', width: '160px'}} alt="Card image cap" />
            </div>
            <h3 className="font-card roboto-reg-18px py-4">Schedule your appointment with our genetic experts to start your genetic journey.</h3>
            {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
            {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
            <button style={{height: '40px', color: 'white', background: '#4f5be7'}} className="mx-auto px-5 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.toBookSession} type="submit"><b>COUNSELLING</b></button>
            {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
          </div>
        </div>
      </div>;
      }

      if(this.state.lastorderHistory.orderId){
        orderTest= 
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
          <div className="card rounded shadow-lg  r4 ">
            <div className="card-body  text-center">
              <div className="card-block  p-2 circle">
                <img className="card-img-top text-center  " src="./img/payment/cancer.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                <h3 className="text-highlight pt-4"><strong>truGeny cancer test service</strong></h3>
              </div>
              <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
              <h3 className="font-card pt-1 "><b>Date:</b>         {moment(this.state.lastorderHistory["created"]).format("DD-MM-YYYY")}
              </h3>
              <br />
              {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
              {/* <a href="view-reports.html">   <img class="card-img-top text-center p-2 mb-1 button-view-report"  src="./img/payment/view-report-button.png"/></a> */}
              <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports}  type="submit"><b>VIEW REPORT</b></button>
              <br />
              <br />
            </div>
          </div>
        </div>
   }else{

        orderTest= 
        <div className=" col-sm-12 col-md-12 col-lg-4 ">
        <div className="card  rounded shadow-lg  mb-5  r4 ">
          <div className="card-body text-center pb-5">
            <div className="card-block mt-2 p-2 circle ">
              <img className="card-img-top text-center  p-2" src="./img/login/2.png" style={{height: '160px', width: '160px'}} alt="Card image cap" />
            </div>
            <h3 className="font-card roboto-reg-18px py-4">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3>
            {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
            {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
            <button style={{height: '40px', color: 'white', background: '#4f5be7'}} className="mx-auto px-5 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.toProductPage}><b>BUY truGeny</b></button>
            {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
          </div>
        </div>
      </div>
      }
      if(this.state.lastorderHistory.orderId){
        activeKit=
        <div className=" col-sm-12 col-md-12 col-lg-4">
        <div className="card  rounded shadow-lg  mb-5  r4 ">
          <div className="card-body text-center pb-5">
            <div className="card-block mt-2 p-2 circle ">
              <img className="card-img-top text-center  p-2" src="./img/login/3.png" style={{height: '160px', width: '160px'}} alt="Card image cap" />
            </div>
            <h3 className="font-card roboto-reg-18px pt-4 pb-1">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3>
            {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
            {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
            <button style={{height: '40px', color: 'white', background: '#4f5be7'}} className="mx-auto px-5 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.toActiveKit} type="submit"><b>ACTIVE KIT</b></button>
            {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
          </div>
        </div>
      </div>



      }
      // else if(this.state.orderHistory){
      //   activeKit=
      //   <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
      //   <div className="card  rounded shadow-lg   r4 ">
      //     <div className="card-body text-center">
      //       <div className="card-block mt-2 p-2 circle ">
      //         <img className="card-img-top text-center " src="./img/payment/heart.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
      //         <h3 className="text-highlight  pt-3"><strong>truGeny cardio test service</strong></h3>
      //       </div>
      //       <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
      //       <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
      //       <br />
      //       {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
      //       {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
      //       <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports} type="submit"><b>VIEW REPORT</b></button>
      //       <br />
      //       <br />
      //     </div>
      //   </div>
      // </div>
      // }




        return(
            <div>
              <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
      
            <style dangerouslySetInnerHTML={{__html: "\n  .background-login-card{\n    background: url(./img/registration/3-layers.png) no-repeat center;-webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    width:100%;\n    /* height: 593px; */\n    /* background-position: top; */\n  }\n  body {\n    font-family: 'Roboto', sans-serif;\n    color:#2c333c;\n  }\n\n.header-font{\n  color: #2c333c;\n  font-family: Roboto - Black;\n  font-size: 36px;\n  font-weight: 400;\n}\n.font-card{\n\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 18px;\nfont-weight: 400;\n}\n.text-highlight{\n\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 24px;\nfont-weight: 700;\n}\n\n.blue-text\n{\nmargin-top:100px;\ncolor: #4f5be7;\nfont-family: \"Roboto - Black\";\nfont-size: 30px;\nfont-weight: 400;\n}\n.para{\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 16px;\nfont-weight: 400;\nmargin-top:24px;\n}\n\n.space-tp{\n  margin-top:31px;\n  margin-bottom:101px;\n\n}\n\n.card-round{\n\nbox-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);\nborder-radius: 40px;\nbackground-color: #ffffff;\n}\n.c2{\n    border-top-left-radius: 5rem;\n        border-top-right-radius: 2rem;\n        box-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);\n}\n\n" }} />
            {/* header */}
            <CommonHeder category="yourReports"></CommonHeder>
            {/* end of header */}
            <div style={{marginTop: '16rem'}}>
            </div>
            {/* <section class="id-1" > */}
            <div className=" container  text-center card-round " style={{padding: '0px', borderRadius: '40px'}}>
              <div className="card-header bg-white " style={{padding: '0px', borderTopRightRadius: '40px', borderTopLeftRadius: '40px', borderBottom: '0px'}}>
                <div className="rs-header d-flex flex-column justify-content-start ">
                  <h1 className="text-center header-font pt-5"><b>Your Reports</b></h1>
                  <span className="text-center pb-5"><p style={{color: '#2c333c', fontFamily: '"roboto" sans-serif', fontSize: '16px', fontWeight: 400}}>Hello {Cookies.get('username')}, welcome back.<br />
                      Check on the status of your test reports and purchases</p></span>
                </div>
              </div>
              {/* <div className="card-body p-0 container-fluid ">
                <div className="background-login-card p-r-5 pl-5 px-4 py-5 container ">
                  <div className="container mt-5  mb-5">
                    <div className="card-deck ">
                      <div className="card  rounded shadow-lg  r4  ">
                        <div className="card-body text-center ">
                          <div className="card-block mt-2 p-2 circle">
                            <img className="card-img-top img-fluid text-center " src="./img/payment/comment.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                            <h3 className="text-highlight pt-3"><strong>truGeny Counseling service</strong></h3>
                          </div>
                          <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                          <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                          <br />
                          <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onclick="window.location.href = 'view-reports.html';" type="submit"><b>VIEW REPORT</b></button>
                          <br />
                          <br />
                        </div>
                      </div>
                    {this.state.counsellorfirstName ?
                      <div className=" col-sm-12 col-md-4 col-lg-4 mb-2">

                        <div className="card  rounded shadow-lg  r4  ">
                        <div className="card-body text-center ">
                          <div className="card-block mt-2 p-2 circle">
                            <img className="card-img-top img-fluid text-center " src="./img/payment/comment.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                            <h3 className="text-highlight pt-3"><strong>truGeny Counseling service</strong></h3>
                          </div>
                          <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                          <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                          <br />
                          <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onclick="window.location.href = 'view-reports.html';" type="submit"><b>VIEW REPORT</b></button>
                          <br />
                          <br />
                        </div>
                       
                      </div>
                      </div> :''}
                      <div className=" col-sm-12 col-md-4 col-lg-4 mb-2">
                        <div className="card rounded shadow-lg  r4 ">
                          <div className="card-body  text-center">
                            <div className="card-block  p-2 circle">
                              <img className="card-img-top text-center  " src="./img/payment/cancer.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                              <h3 className="text-highlight pt-4"><strong>truGeny cancer test service</strong></h3>
                            
                            </div>
                            <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                            <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                            <br/>
                            <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports}  type="submit"><b>VIEW REPORT</b></button>
                            <br /><br />
                          </div>
                        </div>
                      </div>
                      <div className=" col-sm-12 col-md-4 col-lg-4 mb-2">
                        <div className="card  rounded shadow-lg   r4 ">
                          <div className="card-body text-center">
                            <div className="card-block mt-2 p-2 circle ">
                              <img className="card-img-top text-center " src="./img/payment/heart.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                              <h3 className="text-highlight  pt-3"><strong>truGeny cardio test service</strong></h3>
                            </div>
                            <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                            <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                            <br />
                            <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports}  type="submit"><b>VIEW REPORT</b></button>
                            <br /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}






        <div className="card-body p-0 container-fluid ">
        <div className="background-login-card p-r-5 pl-5 px-4 py-5 container " style={{borderBottomRightRadius: '40px', borderBottomLeftRadius: '40px'}}>
          <div className="container mt-5  mb-5">
          <div className="card-deck ">


        {appointmentbooked}
        {orderTest}
        {activeKit}



  {/* Saikiran */}




</div>
         
              {/* eo-card-deck-wrap */}
          
              {/* eo-card-deck-wrap */}
            {/* eo-card-deck */}
          </div>
        </div>
      </div>
            </div>
            {/* </section> */}
            <div className="container">
              <h2 className="text-center blue-text"><b>Gift a truGeny Test to your family</b></h2>
              <p className="para text-center">Give upto 5 family of your family members or friends upto Rs 2500 off a<br /> Color test by sharing your unique referral URL</p>
              <div className="text-center">
                <img className="img-fluid space-tp " src="./img/payment/gift-btn.png" />
              </div>
            </div>
            <SubFooter></SubFooter>
            {/* eo Modal */}
            {/* Optional JavaScript */}
            {/* jQuery first, then Popper.js, then Bootstrap JS */}
          </div>
        )
    }
}
export default YourReports;