import React from 'react';
import { Form } from 'reactstrap';
import axios from '../axios/axios';

class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reason: '',
            email: '',
            phone: '',
            sucessMsg:'',
            errors: {}

        }
        this.prospectReg = this.prospectReg.bind(this);
    }
   
    validateForm() {

        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Please enter your email address";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email address";
            }
        }

        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = "*Please enter your mobile no.";
        }

        if (typeof fields["phone"] !== "undefined") {
            if (!fields["phone"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["phone"] = "*Please enter valid mobile no.";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    prospectReg(e) {
        e.preventDefault();

        if (this.validateForm()) {

            const { reason, email, phone } = this.state;
            axios.post('users/prospect', { reason, email, phone })
                .then((response) => {
                  
                
     
                }).catch(error => {
                  if(error.response===undefined){
                    this.props.history.push("/error")
                  }else{
                    this.setState({
                        errorMsg: error.response.data.message
                    })  
                  }
                   
                });
                e.target.reset();
        }
       
      
    }
  
    render() {
        return (
            <div>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
            <title>trueGeny</title>
            <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
            {/* <link rel="stylesheet" href="./css/stylesheet_custom.css" >*/}
            <link rel="stylesheet" href="./css/stylesheet_custom2.css" />
            {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
            <div className="container mt-4 sec1-landing">
              <h1 className="text-center"> <img className="img-fluid roboto-reg-36px" src="./img/landingpage/Logo.png" style={{paddingTop: '128px', paddingBottom: '50px'}} /></h1>
              <h4 className="text-center img-fluid roboto-reg-36px pb-5 mb-5 l1">Finally, You can control your health <b>better<br /></b></h4><b>
              </b></div><b>
              {/* ---------------------------------------------------section 2 started----------------------------------------------------- ------------------------------------------------------------------------------*/}
              <div className="container-  mb-3 mt-2 " style={{backgroundColor: '#eff7ff'}}>
                <br />
                <br />
                <h1 className="text-center roboto-black-36px l2" style={{}}><b>Listen to your Genes</b></h1>
                <p className="text-center roboto-reg-24" style={{paddingTop: '22px', fontWeight: 'normal'}}>Most Diseases caused by hundreds of Genes. Based on your genetic makeup trueGene helps you to understand.</p>
                <p className="text-center roboto-reg-24 " style={{paddingBottom: '140px', fontWeight: 'normal'}}>your heath risk and guides you on actions to avoid or overcome certain diseases.</p>
                <div className="container" style={{paddingTop: '0px', paddingBottom: '115px'}}>
                  <div className="row">
                    <div className="col-sm">
                      <img className="mb-5 img-fluid img-responsive" src="img/landingpage/Illustration.png" />
                    </div>
                    <div className=" col-sm ">
                      <h3 className="roboto-black-36px" style={{color: '#4f5be7', paddingBottom: '20px'}}><b>Can't wait to learn more?</b></h3>
                      <p className="roboto-black-18px" style={{paddingBottom: '10px'}}>Get notified when we launch this amazing service.<br />
                      </p><p className="roboto-bold-18px" style={{paddingBottom: '20px'}}>Be amongst the first to know.</p>
                      <div className=" text-center ">
                      <Form onSubmit={this.prospectReg}>
                        <div className="input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', width: '334px'}}>
                          <input type="text" name="reason" placeholder=" I am interested to learn about" className="roboto-reg-16px form-control" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button"  onChange={this.onChange} required />
                          {/* <button className="btn btn-outline-secondary dropdown-toggle" style={{border: 'none', backgroundColor: '#ebebeb'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          <div className="dropdown-menu">
                            <a className="dropdown-item roboto-reg-16px" href="#">i'm interested to learn about:</a>
                            <a className="dropdown-item roboto-reg-16px" href="#">i'm interested to learn about:</a>
                            <a className="dropdown-item roboto-reg-16px" href="#">i'm interested to learn about:</a>
                            <div role="separator" className="dropdown-divider" />
                            <a className="dropdown-item roboto-reg-16px" href="#">Separated link</a>
                          </div> */}
                        </div>
                        <div className="input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', width: '334px'}}>
                          <input type="text" name="phone" placeholder="Phone number" className="roboto-reg-16px form-control" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button"  onChange={this.onChange} required/>
                          <button className="btn btn-outline-secondary " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="img/landingpage/Shape 1.png" /></button>
                        </div>
                        <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.phone}</div>
                        <div className="input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', width: '334px'}}>
                          <input type="text" name="email" placeholder="E-mail Id" className="roboto-reg-16px form-control" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button"  onChange={this.onChange} required />
                          <button className="btn btn-outline-secondary "  style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/landingpage/Forma 2.png" /></button>
                        </div>
                        <div style={{ color: 'red', fontSize: '12px',display:'flex',justifyContent:'center' }}>{this.state.errors.email}</div>
                        <div className="input-group-prepend mb-3 ">
                          <button className="btn btn-outline-secondary" type="submit" style={{border: 'none', borderRadius: '30px', height: '60px', width: '130px', backgroundColor: '#4f5be7', marginLeft: '200px', color: 'white'}}  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Submit</button>
                        </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------------------section 2 Ended----------------------------------------------------- */}
              {/* ---------------------------------------------------section 3 started----------------------------------------------------- */}
              <div className="container">
                <h1 className="text-center roboto-bold-48px" style={{color: '#4f5be7', paddingTop: '100px', paddingBottom: '110px'}}>live healthier, happier and longer</h1>
                <div className="container  pb-5 mb-5">
                  <div className=" text-center card-deck">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div className="card bg-default shadow-lg p-3 mb-5 bg-white rounded r2 r3 card-height-landing">
                        <div className="card-body text-center">
                          <div className="card-block mt-2 p-0 m-0">
                            <img className="card-img-top text-center mt-2 p-2 card-radius-circle" src="img/landingpage/Forma 1.png" alt="Card image cap" />
                          </div>
                          <p style={{paddingTop: '22px', fontWeight: 'normal'}} className="roboto-reg-18px card-text ">Access from anywhere and anytime. Even from the convenience of your home</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div className="card bg-default shadow-lg p-3 mb-5 bg-white rounded r2 r3 card-height-landing">
                        <div className="card-body text-center ">
                          <div className="card-block mt-2 p-2">
                            <img className="card-img-top text-center mt-2 p-2 card-radius-circle" src="img/landingpage/Shape 1 copy.png" alt="Card image cap" />
                          </div>
                          <p style={{paddingTop: '22px', fontWeight: 'normal'}} className="card-text roboto-reg-18px ">Personalised counselling by certified genetic counsellors </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      {/* style="padding-top:22px;font-weight:normal;border:none ;border-radius: 30px;height:360px" */}
                      <div className="card bg-default shadow-lg p-3 mb-5 bg-white rounded r2 r3 card-height-landing">
                        <div className="card-body text-center">
                          <div className="card-block mt-2 p-2">
                            <img className="card-img-top text-center mt-2 p-2 card-radius-circle " src="img/landingpage/Forma 3.png" alt="Card image cap" />
                          </div>
                          <p style={{paddingTop: '22px', fontWeight: 'normal', border: 'none', borderRadius: '30px', height: '360px'}} className="card-text roboto-reg-18px">Highly accurate reports with actionable insights</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      {/* style="padding-top:22px;font-weight:normal;border:none ;border-radius: 30px;height:360px" */}
                      <div className="card bg-default shadow-lg p-3 mb-5 bg-white rounded r2 r3 card-height-landing">
                        <div className="card-body text-center">
                          <div className="card-block mt-2 p-2">
                            <img className="card-img-top text-center mt-2 p-2 card-radius-circle" src="img/landingpage/Forma 4.png" alt="Card image cap" />
                          </div>
                          <p style={{paddingTop: '22px', fontWeight: 'normal', border: 'none', borderRadius: '30px', height: '360px'}} className="roboto-reg-18px card-text ">Affordable pricing</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------------------section 3 Ended----------------------------------------------------------------------------------------------------------------*/}
              {/* ---------------------------------------------------section 4 started----------------------------------------------------------------------------------------------------------------*/}
              <form action="/action_page.php">
                <div className="conatiner text-center">
                  <h1 className="text-center roboto-bold-48px" style={{color: '#4f5be7', fontSize: '3rem', paddingBottom: '90px', paddingTop: '152px'}}>Learn More.Get in touch with truGeny</h1>
                  <div className="container  mb-5" style={{fontSize: '1.5rem', paddingBottom: '37px'}}>
                    <h1 className="text-center mb-5 roboto-bold-24px"> I prefer to be counselled :
                    </h1>
                    <input type="radio" name="gender" className="roboto-reg-24px" defaultValue="male" /> Online&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="gender" className="roboto-reg-24px" defaultValue="female" /> Phone
                  </div>
                  {/* <div class="conatiner  ">
      <div class="mb-3 container d-flex justify-content-center " style="border:solid 1px #ebebeb; width:400px;" >
          <input type="text" placeholder=" I am interested to learn about:" style="border:none;width: 340px;height:60px" >
          <button class="btn btn-outline-secondary " href="#"  style="background-color:#ebebeb;;width:60px;hight:60px;" type="button">submit</button>
        </div>
    
    
        <div class="mb-3 container d-flex justify-content-center " style="border:solid 1px #ebebeb; width:400px;" >
            <input type="text" placeholder=" I am interested to learn about:" style="border:none;width: 340px;height:60px" >
            <button class="btn btn-outline-secondary " href="#"  style="background-color:#ebebeb;;width:60px;hight:60px;" type="button">submit</button>
          </div>
    
          <div class="mb-3 container d-flex justify-content-center " style="border:solid 1px #ebebeb; width:400px;" >
              <input type="text" placeholder=" I am interested to learn about:" style="border:none;width: 340px;height:60px" >
              <button class="justify-content-end btn btn-outline-secondary " href="#"  style="background-color:#ebebeb;;width:60px;hight:60px;" type="button">submit</button>
            </div>
    
    
    </div> */}
                  <div className=" justify-content-center " style={{width: '336px', margin: '0px auto'}}>
                    <div className=" input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', width: '334px'}}>
                      <input type="text" placeholder=" I am interested to learn about:" className="roboto-reg-16px form-control" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                      <button className="btn btn-outline-secondary dropdown-toggle" style={{border: 'none', backgroundColor: '#ebebeb'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      <div className="dropdown-menu">
                        <a className="dropdown-item roboto-reg-16px" href="#">i'm interested to learn about:</a>
                        <a className="dropdown-item roboto-reg-16px" href="#">i'm interested to learn about:</a>
                        <a className="dropdown-item roboto-reg-16px" href="#">i'm interested to learn about:</a>
                        <div role="separator roboto-reg-16px" className="dropdown-divider" />
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                    <div className="input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', width: '334px'}}>
                      <input type="text" placeholder="Phone number" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                      <button className="btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/landingpage/Shape 1.png" /></button>
                    </div>
                    <div className="input-group-prepend mb-3" style={{border: 'solid 1px #ebebeb', width: '334px'}}>
                      <input type="text" placeholder="E-mail Id" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                      <button className="btn btn-outline-secondary " style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/landingpage/Forma 2.png" /></button>
                    </div>
                    <div className="input-group-prepend mb-3 ">
                      <button className="btn btn-outline-secondary  " style={{border: 'none', borderRadius: '30px', height: '60px', width: '130px', backgroundColor: '#4f5be7', marginLeft: '200px', color: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Submit</button>
                    </div>
                  </div>
                  {/* ---------------------------------------------------section 4 Ended----------------------------------------------------------------------------------------------------------------*/}
                  {/* --------------------------------------------------------section 5 started--------------------------------------------------------- -----------------------------------------------*/}
                  <div className="footer" style={{paddingTop: '300px'}}>
                    <h1 className="text-center pt-3 " style={{fontSize: '5rem', paddingBottom: '270px'}}><strong> Coming soon</strong></h1>
                    <h1 className="text-center pt-3 "> <img className=" mt-2 img-fluid" height="150px" width="250px" src="./img/landingpage/Logo.png" /></h1>
                    <br />
                    <br />
                    <br />
                    <div className="text-center ">
                      <button className="roboto-bold-14px text-center text-white p-3 m-3" style={{fontSize: '14px', border: 'solid 1px #4f5be7', borderRadius: '30px', backgroundColor: '#4f5be7'}} type="submit">INFO@TRUGENY.COM</button>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  {/* -----------------------------------------------------section 5 Ended------------------------------------------------------------------------------------------------------------- */}
                  {/* Optional JavaScript */}
                  {/* jQuery first, then Popper.js, then Bootstrap JS */}
                </div></form></b></div>
    
        );
              
    }
}
export default LandingPage;