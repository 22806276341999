import React from "react";
import CommonHeder from "./CommonHeder";
import Cookies from "js-cookie";
import axios from "../axios/axios";
import SubFooter from "./SubFooter";
import HeaderLinks from "./HeaderLinks";
import moment from "moment";
import MobileFooter from "./MobileFooter";
import swal from "sweetalert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "./Logo.png";
import IdleTimer from 'react-idle-timer'


class YourReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: "",
      appointmentDate: "",
      appointmentId:"",
      appointmentTime: "",
      counsellorfirstName: "",
      counsellorLastName: "",
      lastorderHistory: [],
      appointmentStatus: "",
      productDetails: [],
      userData: [],
      prevScrollpos: window.pageYOffset,
      visible: false,
      desktop:false,
    };
    this.viewReports = this.viewReports.bind(this);
    this.resize = this.resize.bind(this);

    //inactive time
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }
  _onAction(e) {
    // console.log('user did something', e)
  }
 
  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {
    // console.log('user is idle', e)
    // console.log('last active', this.idleTimer.getLastActiveTime())
    this.props.history.push('/loginSuccess');
  }
  componentDidMount() {
    // console.log(Cookies.get("jwt-token"))
    
    // if(Cookies.get("jwt-token")===""||Cookies.get("jwt-token")===null||Cookies.get("jwt-token")===undefined){
    //   alert("james")
     
    //   this.props.history.push("/logout");

    // }else{
    //   alert("yes")
    // }
    this.getAppointmentDetails();
    this.lastOrderHistory();
    axios
      .get("/users/user/" + Cookies.get("userId"), {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` },
        Accept: "application/json",
        "Content-Type": "application/json"
      })
      .then(response => {
        this.setState({
          userData: response.data
        });
      })
      .catch(function(error) {}); 
   
  
   
    //this.getReportData()
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    const desktop= window.innerWidth>=576;
    this.setState({desktop},()=>{});
 }
 componentWillUnmount() {
  window.removeEventListener("scroll", this.handleScroll);
 
};
  getAppointmentDetails() {
    axios
      .get("schedule/getAppointmentDetails/" + Cookies.get("userId"), {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
      })
      .then(response => {
        this.setState(
          {
            doctorId: response.data.doctorId,
            appointmentId:response.data.appointmentId,
            appointmentDate: response.data.appointmentDate,
            appointmentTime: response.data.startTime,
            appointmentStatus: response.data.appointmentStatus
            
          },
          () => {}
        );
        this.getCounsellorDetails();
      })
      .catch(error => {});
  }

  lastOrderHistory() {
    axios
      .get("cartservice/" + Cookies.get("userId") + "/orderHistoryForProduct", {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
      })
      .then(response => {
        if (response.data) {
          this.setState({ lastorderHistory: response.data }, () => {});
          axios
            .get(
              "productservice/findByProduct/" +
                response.data.products[0].productId,
              {
                headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
              }
            )
            .then(response => {
              if (response.data) {
                this.setState({ productDetails: response.data }, () => {});
              }
            })
            .catch(error => {});
        }
      })
      .catch(error => {});
  }
  getCounsellorDetails() {
    axios
      .get("users/user/" + this.state.doctorId, {
        headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` }
      })
      .then(response => {
        this.setState(
          {
            counsellorfirstName: response.data.firstName,
            counsellorLastName: response.data.lastName
          },
          () => {}
        );
      })
      .catch(error => {});
  }

  onChangeTab(value) {
    if (value === 1) {
      this.props.history.push("/book-counselling");
    }
    if (value === 2) {
      this.props.history.push("/ordertrugene");
    }
    if (value === 3) {
      this.props.history.push("/activate-kit");
    }
    if (value === 4) {
      this.props.history.push("/product-page");
    }
  }

  viewReports() {
    axios
      .get("documents/get/patientData/" + this.state.appointmentId)
      .then(response => {
        if (response.data) {
          this.setState({ userData: response.data }, function() {});

          this.setState(
            {
              reportSubject: this.state.userData.reportSubject,
              reportBody: this.state.userData.reportBody,
              patientReportId: this.state.userData.patientReportId
            },
            function() {}
          );

          //var pdfConverter = require('jspdf');
          //var converter = new pdfConverter();
          //var doc = converter.jsPDF('p', 'pt');

          var doc = new jsPDF("p", "mm", "a4");
          var img = new Image();
          img.src = Logo;
          doc.addImage(img, "png", 90, 20, 25, 25);
          doc.setFontSize(12);
          doc.setFontStyle("bold");
          doc.text(
            100,
            60,
            "HEREDITARY CANCER PANEL TEST REPORT AND COUNSELLING SUMMARY",
            "center"
          );
          doc.setFontSize(10);
          doc.setFontStyle("normal");
          var lMargin = 15; //left margin in mm
          var rMargin = 15; //right margin in mm
          var pdfInMM = 210; // width of A4 in mm
          var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
          var ParaWidth = pageWidth * 0.85;

          var par = response.data.reportBody;

          doc.setFontStyle("bold");
          doc.text(10, 80, "Report");
          doc.setFontStyle("normal");
          var lines = doc.splitTextToSize(par, ParaWidth);
          for (var v = 0; v < lines.length; v++) {
            if (lines[v] == ".") {
              lines.push(v);
            }
            doc.text(10, 90, lines);
          }

          //   for (var i = 0; i < lines.length; i++) {

          //    console.log("in loop......",doc.text(10, 80,lines[i]))
          //     doc.text(10, 80,lines[i])

          // }

          //doc.text(10, 80,'Report: ' + response.data.reportBody);
          doc.setFontStyle("bold");
          doc.setFontSize(10);
          doc.text(10, 70, "Subject: " + response.data.reportSubject);

          doc.autoTable({
            styles: {},
            theme: "grid",

            columnStyles: { 0: { halign: "left" } }, // Cells in first column centered and green
            margin: { top: 120 },
            body: [
              ["Subject:" + response.data.reportSubject, "Japan", "Canada"],
              ["Norway", "China", "USA"],
              ["Denmark", "China", "Mexico"]
            ]
          });

          doc.setFontStyle("bold");
          doc.text(14, 160, "History");
          doc.setFontStyle("normal");
          var historyParaWidth = pageWidth * 0.85;
          var historyPar =
            "During your initial counselling session with truGeny, you wished to understand more about your own risk as well as your son’s risk for colon cancer because of your following concerns- your brother expired due to adenocarcinoma of the colon and your paternal uncle was undergoing treatment for IBS. Further, you had complaints of constipation, loose stools and indigestion and were being treated with XX medications. Your sedentary lifestyle and high meat intake also elevates your risk for colon cancers. ";
          var historyLines = doc.splitTextToSize(historyPar, historyParaWidth);
          for (var i = 0; i < historyLines.length; i++) {
            if (historyLines[i] == ".") {
              historyLines.push(i);
            }
            doc.text(14, 165, historyLines);
          }

          doc.setFontStyle("bold");
          doc.text(65, 190, "TruFIND. HereditaryCancer Panel Test Summary");
          doc.setFontStyle("normal");
          doc.setFontType("italic");
          var testSummaryParaWidth = pageWidth * 0.85;
          var testSummarayPar =
            "Using the advanced NGS technology, we analyzed your saliva sample for over 100 genes associated with 8 common hereditary cancers including colorectal, stomach, prostate and skin. We have identified a pathogenic variant ( genetic change) that increases your susceptibility to colorectal cancers. As your are already experiencing early clinical symptoms, which along with your current lifestyle places you at a higher risk for colon cancer, we recommend that you consult with a Gastroenterologist with our genetic test report. The Doctor can help to proactively reduce your risk by treating your early symptoms, prescribe prophylactic medicines and advise you on making healthy lifestyle changes. As this variant shows autosomal dominant inheritance and given your family history of colon cancers, we advise your son and other first degree relatives (siblings, parents) to undertake this genetic test.";
          var testSumaryLines = doc.splitTextToSize(
            testSummarayPar,
            testSummaryParaWidth
          );
          for (var j = 0; j < testSumaryLines.length; j++) {
            if (testSumaryLines[j] == ".") {
              testSumaryLines.push(j);
            }
            doc.text(14, 195, testSumaryLines);
          }

          doc.setFontStyle("bold");
          doc.text(
            14,
            240,
            "Details on Test Findings and their Clinical Significance"
          );
          doc.setFontStyle("normal");
          var clinicalSigniparaWidth = pageWidth * 0.85;
          var clinicalSummaryPar =
            "DNA was extracted from your saliva sample and analyzed for mutations in 107 cancer predisposing genes from the Hereditary Cancer test panel.";
          var clinicalSummaryLines = doc.splitTextToSize(
            clinicalSummaryPar,
            clinicalSigniparaWidth
          );
          for (var j = 0; j < clinicalSummaryLines.length; j++) {
            if (clinicalSummaryLines[j] == ".") {
              clinicalSummaryLines.push(j);
            }
            doc.text(14, 245, clinicalSummaryLines);
          }

          doc.addPage();
          doc.setFontStyle("bold");
          doc.text(
            25,
            30,
            "A pathogenic variant causative of the disease has been detected in your test sample"
          );

          doc.autoTable({
            styles: {},
            theme: "grid",
            columnStyles: { 0: { halign: "left" } }, // Cells in first column centered and green
            margin: { top: 35 },
            head: [
              [
                "Gene (Transcript)",
                "Location",
                "Variant",
                "Zygosity",
                "Disease (OMIM)",
                "Inheritance",
                "Classification "
              ]
            ],
            body: [
              [
                "CHEK2 (-) (ENST...)",
                "22q12.1 ",
                "CHEK2 1100del C",
                "Heterozygous",
                "{Hereditary breast and colorectal cancer } (HBCC)",
                "Autosomal Dominant",
                "Pathogenic "
              ]
            ]
          });

          doc.setFontStyle("normal");
          var pathogenicParaWidth = pageWidth * 0.85;
          var pathogenicPar =
            "The above table indicates that you carry a single copy of an abnormal variant (CHEK2 1100delC) in the CHEK2 gene. This mutation results in a deficient protein and is known to be associated with Hereditary Breast and Colorectal cancers (HBCC) and Hereditary nonpolyposis colorectal cancer (HNPCC). This variant shows autosomal dominant inheritance pattern.";
          var pathogenicLines = doc.splitTextToSize(
            pathogenicPar,
            pathogenicParaWidth
          );
          for (var j = 0; j < pathogenicLines.length; j++) {
            if (pathogenicLines[j] == ".") {
              pathogenicLines.push(j);
            }
            doc.text(14, 65, pathogenicLines);
          }

          doc.setFontStyle("bold");
          doc.text(14, 85, "Post Test Genetic Counselling Brief ");
          doc.setFontStyle("normal");
          var counsellingBriefParaWidth = pageWidth * 0.85;
          var counsellingBriefPar =
            "Your results show the presence of a pathogenic mutation, which means that you carry a relatively higher susceptibility for developing cancer than the general population. However, please note that a positive result doesn’t necessarily mean that you will develop cancer as much as a negative result doesn’t guarantee that you won’t develop cancer.";
          var counsellingBriefLines = doc.splitTextToSize(
            counsellingBriefPar,
            counsellingBriefParaWidth
          );
          for (var j = 0; j < counsellingBriefLines.length; j++) {
            if (counsellingBriefLines[j] == ".") {
              counsellingBriefLines.push(j);
            }
            doc.text(14, 90, counsellingBriefLines);
          }

          doc.text(14, 110, "How can your genetic test result help you? ");
          doc.setFontStyle("normal");
          var geneticTestParaWidth = pageWidth * 0.85;
          var geneticTestPar =
            "Knowing your genetic test result can certainly help you to make informed medical and lifestyle decisions in order to reduce your risk from environmental causes. We have earlier explained to you that cancer is a complex disease that develops due to interactions between your genes and environmental factors. You can now take proactive steps to mitigate the risk from the environmental factors Periodical cancer surveillance can help early detection, which increases the chances of a successful outcome and cure. Given that you are experiencing certain early symptoms, adopting healthy lifestyle choices can lower risks.";
          var geneticTestLines = doc.splitTextToSize(
            geneticTestPar,
            geneticTestParaWidth
          );
          for (var j = 0; j < geneticTestLines.length; j++) {
            if (geneticTestLines[j] == ".") {
              geneticTestLines.push(j);
            }
            doc.text(14, 115, geneticTestLines);
          }

          doc.setFontStyle("normal");
          var cancersLinkParaWidth = pageWidth * 0.85;
          var cancersLinkPar =
            "*Please refer to the American Cancer society guidelines for more information on how to reduce your risk of colon cancers, early clinical signs and diagnostic tests that are available for early cancer detection ( https://www.cancer.org ). ";
          var cancersLines = doc.splitTextToSize(
            cancersLinkPar,
            cancersLinkParaWidth
          );
          for (var j = 0; j < cancersLines.length; j++) {
            if (cancersLines[j] == ".") {
              cancersLines.push(j);
            }
            doc.text(14, 150, cancersLines);
          }

          doc.text(
            14,
            165,
            "How can the genetic test results help your family? "
          );
          doc.setFontStyle("normal");
          var familyGeneticParaWidth = pageWidth * 0.85;
          var familyGeneticLinkPar =
            "In addition, your genetic test result provides an opportunity to educate your family members about potential risk. As the CHEK2 1100delC mutation show an autosomal dominant inheritance pattern, your son has a 50% likelihood of carrying a similar gene mutation. We therefore recommend genetic testing for him as well as your first-degree relatives (parents, siblings). Since, your paternal uncle is undergoing treatment for IBS, genetic counselling and consideration for genetic testing is also recommended to him and his close family members.";
          var familyGeneticLines = doc.splitTextToSize(
            familyGeneticLinkPar,
            familyGeneticParaWidth
          );
          for (var j = 0; j < familyGeneticLines.length; j++) {
            if (familyGeneticLines[j] == ".") {
              familyGeneticLines.push(j);
            }
            doc.text(14, 170, familyGeneticLines);
          }

          doc.setFontStyle("normal");
          var proactiveParaWidth = pageWidth * 0.85;
          var proactiveLinkPar =
            "We recommend that you consult your Doctor/Gastroenterologist with our genetic test report for planning proactive management of your health and well being.";
          var proactiveLines = doc.splitTextToSize(
            proactiveLinkPar,
            proactiveParaWidth
          );
          for (var j = 0; j < proactiveLines.length; j++) {
            if (proactiveLines[j] == ".") {
              proactiveLines.push(j);
            }
            doc.text(14, 200, proactiveLines);
          }

          doc.text(14, 215, "ADDITIONAL INFORMATION");
          var additionalInfoParaWidth = pageWidth * 0.85;
          var additionalInfoPar =
            "* The American Cancer society has given clear guidelines on lowering colon cancer risk with healthy lifestyle like maintaining a healthy weight, exercising at least 150 minutes of physical activity per week, including strength-training exercises at least 2 days per week. You should eat a diet high in vegetables, fruits, and whole grains. (Follow the American Cancer Society Guidelines on Nutrition and Physical Activity for Cancer Prevention for more information)";
          var additionalInfoLines = doc.splitTextToSize(
            additionalInfoPar,
            additionalInfoParaWidth
          );
          for (var j = 0; j < additionalInfoLines.length; j++) {
            if (additionalInfoLines[j] == ".") {
              additionalInfoLines.push(j);
            }
            doc.text(14, 220, additionalInfoLines);
          }

          doc.addPage();
          var additionalParaWidth = pageWidth * 0.85;
          var additionalPar =
            "The following diagnostic tests are available for early cancer detection - a regular Stool based screening test before the age of 45 and Visual examination such as Colonoscopy screening every 5 years beginning at age 40. Details on early clinical symptoms like weakness or fatigue and unexplained weight loss and advance clinical symptoms like persistent abdominal discomfort, cramps, gas, pain, changes in bowel habits etc are also mentioned in these guidelines. ";
          var additionalLines = doc.splitTextToSize(
            additionalPar,
            additionalParaWidth
          );
          for (var j = 0; j < additionalLines.length; j++) {
            if (additionalLines[j] == ".") {
              additionalLines.push(j);
            }
            doc.text(14, 25, additionalLines);
          }

          doc.text(14, 50, "About your Test Results");
          var yourTestParaWidth = pageWidth * 0.85;
          var yourTestPar =
            "The CHEK2 gene in general is associated with an increased risk for autosomal dominant adult-onset cancers, including breast, colon, thyroid, prostate, and possibly others (PMID: 15492928, 18759107, 21807500, 21876083, 25431674). The risks of these cancers, particularly breast, have been determined to be both variant- and family history-dependent (PMID: 20597917, 21876083). Studies show that this pathogenic mutation, CHEK2 1100delC increases one’s risk for both colorectal and breast cancers.The protein expressed by the gene is responsible for the normal functioning and repair of body cells while the presence of the abnormal variant ( CHEK2 1100delC) lowers protein production and disrupts cellular function. Further, several studies have shown that presence of pathogenic variant, 1100delC confers a risk for colorectal cancers, particularly in families with Hereditary breast and colorectal cancer (HBCC) and Hereditary nonpolyposis colorectal cancer (HNPCC). Information regarding hereditary cancer susceptibility genes is constantly evolving, and more clinically relevant data regarding CHEK2 is likely to become available in the near future.";
          var yourTestLines = doc.splitTextToSize(
            yourTestPar,
            yourTestParaWidth
          );
          for (var j = 0; j < yourTestLines.length; j++) {
            if (yourTestLines[j] == ".") {
              yourTestLines.push(j);
            }
            doc.text(14, 55, yourTestLines);
          }

          doc.text(14, 110, "Test Methodology ");
          doc.setFontStyle("normal");
          var testMethParaWidth = pageWidth * 0.85;
          var testMethPar =
            "DNA extracted from your saliva sample was checked against genes in the panel and was sequenced on the Illumina NextSeq platform. Bioinformatic analysis carried out and the above pathogenic mutation was identified using relevant literature, public databases and in-silico prediction tools. llumina’s Cancer sequencing panel consisting of 107 genes was used for the NGS testing, by a CLIA and CAP accredited laboratory’s in-house protocol and bioinformatics pipeline. The libraries were sequenced to mean >80-100X coverage. GATK best practices were followed for identification of variants using Sentieon(v201808.01). The sequences were aligned to human reference genome (GrCh37.p13/hg19) Gene annotation was performed using VEP program against the Ensemble release 91 human gene model. Published variants in literature and a set of databases-ClinVar, OMIM, GWAS, HGMD and SwissVar were used to annotate clinically relevant mutations. Common variants are filtered based on minor allele frequency (MAF) in 1000 Genome Phase 3, ExAC, gnomAD, EVS, dbSNP141, 1000 Japanese Genome and our internal Indian population database [12-17]. The biological effect of non-synonymous variant is calculated using multiple algorithms such as PolyPhen, SIFT, Mutation Taster2, and LR.Only non-synonymous and splice site variants found in the coding regions of BRCA1 and BRCA2 genes are used for clinical interpretation.Silent variations that do not result in any amino acid change in the coding region are not reported. Variants annotated on incomplete and nonsense mediated decay transcripts are not reported. In addition to SNVs and small Indels, copy number variants (CNVs) are detected from targeted sequence data using the ExomeDepth (V1.1.10) methodGenetic test results are reported based on the variant classification recommendations of American College of Medical Genetics";
          var testMethLines = doc.splitTextToSize(
            testMethPar,
            testMethParaWidth
          );
          for (var j = 0; j < yourTestLines.length; j++) {
            if (testMethLines[j] == ".") {
              testMethLines.push(j);
            }
            doc.text(14, 115, testMethLines);
          }

          doc.setFontStyle("bold");
          doc.text(14, 200, "Disclaimers");
          doc.setFontStyle("normal");
          var disclaimersParaWidth = pageWidth * 0.85;
          var disclaimerPar =
            "1. The sensitivity of NGS based assays to detect large heterozygous deletions/duplications, complex rearrangements (>10 bp) and copy number variations (CNVs) is low (70-75%) as it uses short-read sequencing data. Therefore, an alternate method such as Multiplex Ligation-dependent Probe Amplification (MLPA) is recommended to detect such structural variants.";
          var disclamierLines = doc.splitTextToSize(
            disclaimerPar,
            disclaimersParaWidth
          );
          for (var j = 0; j < disclamierLines.length; j++) {
            if (disclamierLines[j] == ".") {
              disclamierLines.push(j);
            }
            doc.text(14, 205, disclamierLines);
          }

          doc.setFontStyle("normal");
          var disclaimersAddParaWidth = pageWidth * 0.85;
          var disclaimerAddPar =
            "2. The variants in this report are interpreted based on information available in scientific literature at the time of reporting, therefore, an impact and classification of gene variation might change over time with respect to clinical indication. MedGenome cannot be held responsible for this, the clinician can request reanalysis of data on an annual basis at an additional cost. ";
          var disclamierAddLines = doc.splitTextToSize(
            disclaimerAddPar,
            disclaimersAddParaWidth
          );
          for (var j = 0; j < disclamierAddLines.length; j++) {
            if (disclamierAddLines[j] == ".") {
              disclamierAddLines.push(j);
            }
            doc.text(14, 220, disclamierAddLines);
          }

          doc.setFontStyle("normal");
          var disclaimersAddOneParaWidth = pageWidth * 0.85;
          var disclaimerAddOnePar =
            "3. Variants in untranslated region, promoters and deep intronic regions are not analyzed in this test.";
          var disclamierAddOneLines = doc.splitTextToSize(
            disclaimerAddOnePar,
            disclaimersAddOneParaWidth
          );
          for (var j = 0; j < disclamierAddOneLines.length; j++) {
            if (disclamierAddOneLines[j] == ".") {
              disclamierAddOneLines.push(j);
            }
            doc.text(14, 235, disclamierAddOneLines);
          }

          doc.addPage();
          doc.setFontStyle("normal");
          var disclaimersAddTwoParaWidth = pageWidth * 0.85;
          var disclaimerAddTwoPar =
            "4. The reported gene variations have not been confirmed by Sanger sequencing.";
          var disclamierAddTwoLines = doc.splitTextToSize(
            disclaimerAddTwoPar,
            disclaimersAddTwoParaWidth
          );
          for (var j = 0; j < disclamierAddTwoLines.length; j++) {
            if (disclamierAddTwoLines[j] == ".") {
              disclamierAddTwoLines.push(j);
            }
            doc.text(14, 30, disclamierAddTwoLines);
          }

          doc.setFontStyle("normal");
          var disclaimersAddThreeParaWidth = pageWidth * 0.85;
          var disclaimerAddThreePar =
            "5. Incidental or secondary findings (if any) that meet the ACMG guidelines [20] can also be given upon request.";
          var disclamierAddThreeLines = doc.splitTextToSize(
            disclaimerAddThreePar,
            disclaimersAddThreeParaWidth
          );
          for (var j = 0; j < disclamierAddThreeLines.length; j++) {
            if (disclamierAddThreeLines[j] == ".") {
              disclamierAddThreeLines.push(j);
            }
            doc.text(14, 35, disclamierAddThreeLines);
          }

          doc.setFontStyle("normal");
          var disclaimersAddFourParaWidth = pageWidth * 0.85;
          var disclaimerAddFourPar =
            "6. Test results are interpreted in the context of clinical findings, family history and other laboratory data. In the absence of detailed and accurate information, truGeny cannot guarantee accuracy in the interpretation of results.";
          var disclamierAddFourLines = doc.splitTextToSize(
            disclaimerAddFourPar,
            disclaimersAddFourParaWidth
          );
          for (var j = 0; j < disclamierAddFourLines.length; j++) {
            if (disclamierAddFourLines[j] == ".") {
              disclamierAddFourLines.push(j);
            }
            doc.text(14, 45, disclamierAddFourLines);
          }

          doc.setFontStyle("normal");
          var disclaimersFifthParaWidth = pageWidth * 0.85;
          var disclaimersFifthPar =
            "7. The results may be inaccurate in rare circumstances if the individual tested has undergone bone marrow transplantation or blood transfusion.";
          var disclaimersFifthLines = doc.splitTextToSize(
            disclaimersFifthPar,
            disclaimersFifthParaWidth
          );
          for (var j = 0; j < disclaimersFifthLines.length; j++) {
            if (disclaimersFifthLines[j] == ".") {
              disclaimersFifthLines.push(j);
            }
            doc.text(14, 60, disclaimersFifthLines);
          }

          doc.setFontStyle("normal");
          var disclaimersSixthParaWidth = pageWidth * 0.85;
          var disclaimersSixthPar =
            "8. MedGenome is not liable to provide diagnosis, opinion or recommendation related to disease, in any manner.";
          var disclaimersSixthLines = doc.splitTextToSize(
            disclaimersSixthPar,
            disclaimersSixthParaWidth
          );
          for (var j = 0; j < disclaimersSixthLines.length; j++) {
            if (disclaimersSixthLines[j] == ".") {
              disclaimersSixthLines.push(j);
            }
            doc.text(14, 70, disclaimersSixthLines);
          }

          doc.setFontStyle("normal");
          var disclaimersSevenParaWidth = pageWidth * 0.85;
          var disclaimersSevenPar =
            "9. TruGeny recommends individuals to contact your treating medical oncologist, onco-surgeon for management or surveillance options.";
          var disclaimersSevenLines = doc.splitTextToSize(
            disclaimersSevenPar,
            disclaimersSevenParaWidth
          );
          for (var j = 0; j < disclaimersSevenLines.length; j++) {
            if (disclaimersSevenLines[j] == ".") {
              disclaimersSevenLines.push(j);
            }
            doc.text(14, 80, disclaimersSevenLines);
          }

          doc.setFontStyle("bold");
          doc.text(14, 100, "References");
          doc.setFontStyle("normal");
          var referencesParaWidth = pageWidth * 0.85;
          var referencesPar =
            "1. Online Mendelian Inheritance in Man (OMIM)., An Online Catalog of Human Genes and Genetic Disorders.,Updated November 21, 2018.";
          var referencesLines = doc.splitTextToSize(
            referencesPar,
            referencesParaWidth
          );
          for (var j = 0; j < referencesLines.length; j++) {
            if (referencesLines[j] == ".") {
              referencesLines.push(j);
            }
            doc.text(14, 105, referencesLines);
          }

          doc.setFontStyle("normal");
          var referencesOneParaWidth = pageWidth * 0.85;
          var referencesOnePar =
            "2.  Biotechnology Information. ClinVar; [VCV000055601.5], https://www.ncbi.nlm.nih.gov/clinvar/variation/VCV000055601.5 (accessed Sept. 27, 2019). A global reference for human genetic variation. Nature, 2015. 526(7571): p. 68-74.";
          var referencesOneLines = doc.splitTextToSize(
            referencesOnePar,
            referencesOneParaWidth
          );
          for (var j = 0; j < referencesOneLines.length; j++) {
            if (referencesOneLines[j] == ".") {
              referencesOneLines.push(j);
            }
            doc.text(14, 115, referencesOneLines);
          }

          doc.setFontStyle("normal");
          var referencesTwoParaWidth = pageWidth * 0.85;
          var referencesTwoPar =
            "3.  Karczewski, K., and L. Francioli. The genome Aggregation Database (gnomAD). MacArthur Lab (2017).";
          var referencesTwoLines = doc.splitTextToSize(
            referencesTwoPar,
            referencesTwoParaWidth
          );
          for (var j = 0; j < referencesTwoLines.length; j++) {
            if (referencesTwoLines[j] == ".") {
              referencesTwoLines.push(j);
            }
            doc.text(14, 130, referencesTwoLines);
          }

          doc.setFontStyle("normal");
          var referencesThreeParaWidth = pageWidth * 0.85;
          var referencesThreePar =
            "4.  Lek M., et al., Exome Aggregation Consortium., Analysis of protein-coding genetic variation in 60,706 humans. Nature. 2016 Aug 18;536(7616):285-91.";
          var referencesThreeLines = doc.splitTextToSize(
            referencesThreePar,
            referencesThreeParaWidth
          );
          for (var j = 0; j < referencesThreeLines.length; j++) {
            if (referencesThreeLines[j] == ".") {
              referencesThreeLines.push(j);
            }
            doc.text(14, 135, referencesThreeLines);
          }

          doc.setFontStyle("normal");
          var referencesFourthParaWidth = pageWidth * 0.85;
          var referencesFourthPar =
            "5. NHLBI Grand Opportunity Exome Sequencing Project (ESP)";
          var referencesFourthLines = doc.splitTextToSize(
            referencesFourthPar,
            referencesFourthParaWidth
          );
          for (var j = 0; j < referencesFourthLines.length; j++) {
            if (referencesFourthLines[j] == ".") {
              referencesFourthLines.push(j);
            }
            doc.text(14, 145, referencesFourthLines);
          }

          doc.setFontStyle("normal");
          var referencesFifthParaWidth = pageWidth * 0.85;
          var referencesFifthPar =
            "6.  Nagasaki, M., Rare variant discovery by deep whole-genome sequencing of 1,070 Japanese individuals. Nat. Commun. 2015. ";
          var referencesFifthLines = doc.splitTextToSize(
            referencesFifthPar,
            referencesFifthParaWidth
          );
          for (var j = 0; j < referencesFifthLines.length; j++) {
            if (referencesFifthLines[j] == ".") {
              referencesFifthLines.push(j);
            }
            doc.text(14, 150, referencesFifthLines);
          }

          doc.setFontStyle("normal");
          var referencesSixthParaWidth = pageWidth * 0.85;
          var referencesSixthPar =
            "7.  Sherry S. T., et al ., dbSNP: the NCBI database of genetic variation. Nucleic Acids Res. 2001 Jan 1;29(1):308-11.";
          var referencesSixthLines = doc.splitTextToSize(
            referencesSixthPar,
            referencesSixthParaWidth
          );
          for (var j = 0; j < referencesSixthLines.length; j++) {
            if (referencesSixthLines[j] == ".") {
              referencesSixthLines.push(j);
            }
            doc.text(14, 160, referencesSixthLines);
          }

          doc.setFontStyle("normal");
          var referencesSeventhParaWidth = pageWidth * 0.85;
          var referencesSeventhPar =
            "8.  Schwarz J. M., et al., MutationTaster2: mutation prediction for the deep-sequencing age. Nat Methods. 2014 Apr;11(4):361-2. ";
          var referencesSeventhLines = doc.splitTextToSize(
            referencesSeventhPar,
            referencesSeventhParaWidth
          );
          for (var j = 0; j < referencesSeventhLines.length; j++) {
            if (referencesSeventhLines[j] == ".") {
              referencesSeventhLines.push(j);
            }
            doc.text(14, 170, referencesSeventhLines);
          }

          doc.setFontStyle("normal");
          var referencesEigthParaWidth = pageWidth * 0.85;
          var referencesEigthPar =
            "9.  Cybulski, C, et al. Risk of breast cancer in women with a CHEK2 mutation with and without a family history of breast cancer. J. Clin. Oncol. 2011; 29(28):3747-52. doi: 10.1200/JCO.2010.34.0778. PMID: 21876083";
          var referencesEigthLines = doc.splitTextToSize(
            referencesEigthPar,
            referencesEigthParaWidth
          );
          for (var j = 0; j < referencesEigthLines.length; j++) {
            if (referencesEigthLines[j] == ".") {
              referencesEigthLines.push(j);
            }
            doc.text(14, 180, referencesEigthLines);
          }

          doc.setFontStyle("normal");
          var referencesNinthParaWidth = pageWidth * 0.85;
          var referencesNinthPar =
            "10. Narod, SA. Testing for CHEK2 in the cancer genetics clinic: ready for prime time?. Clin. Genet. 2010; 78(1):1-7. doi: 10.1111/j.1399-0004.2010.01402.x. PMID: 20597917";
          var referencesNinthLines = doc.splitTextToSize(
            referencesNinthPar,
            referencesNinthParaWidth
          );
          for (var j = 0; j < referencesNinthLines.length; j++) {
            if (referencesNinthLines[j] == ".") {
              referencesNinthLines.push(j);
            }
            doc.text(14, 195, referencesNinthLines);
          }
          doc.setFontStyle("normal");
          var referencesTenthParaWidth = pageWidth * 0.85;
          var referencesTenthPar =
            "11. CHEK2 1100delC Is a SusceptibilityAllele for HNPCC-Related Colorectal Cancer MarijkeWasielewski,1 Cancer Prevention and Susceptibility ";
          var referencesTenthLines = doc.splitTextToSize(
            referencesTenthPar,
            referencesTenthParaWidth
          );
          for (var j = 0; j < referencesTenthLines.length; j++) {
            if (referencesTenthLines[j] == ".") {
              referencesTenthLines.push(j);
            }
            doc.text(14, 205, referencesTenthLines);
          }

          doc.addPage();
          doc.setFontStyle("normal");
          doc.text(30, 40, "Date:");
          doc.text(150, 40, "Date:");
          doc.text(
            30,
            60,
            "For any further support please do not hesitate to reach out to us at any time."
          );
          doc.text(30, 70, "Email : support@trugeny.com");
          doc.text(30, 80, "Call : 1800 527 6889 (9:30 am to 9:30 pm)");
          doc.text(80, 100, "WE WISH YOU A HEALTHY & LONG LIFE ");

          doc.save("report.pdf");
        }
      });
  }

  render() {
    
    let appointmentbooked;
    let orderTest;
    let activeKit;
    let orderTestButton;
    let appointmentButton;
    let appointmentStatuspro;
    let orderTestImage;
    let styles;
    if(!this.state.lastorderHistory.orderId && this.state.desktop){
      styles = {
        sideBar1: {
          marginLeft: '180px',
        }};
    }else{
      styles = {
        sideBar1: {
          marginLeft: '0px',
        } };

    }
    const { sideBar1 } = styles;

   if(this.state.lastorderHistory){
      if(this.state.productDetails.productEnumName==="trucancer"){
       orderTestImage= <img
       className="card-img-top text-center  "
       src="./img/payment/cancer.png"
       style={{ height: "126px", width: "126px" }}
       alt="Card image cap"
     />;
      }
      else if(this.state.productDetails.productEnumName==="trufamily"){
        orderTestImage= <img
        className="card-img-top text-center  "
        src="./img/career/familyplanning.png"
        style={{ height: "126px", width: "126px" }}
        alt="Card image cap"
      />;
      }
      else if(this.state.productDetails.productEnumName==="truheart"){
        orderTestImage= <img
        className="card-img-top text-center  "
        src="./img/payment/heart.png"
        style={{ height: "126px", width: "126px" }}
        alt="Card image cap"
      />;
      }
   }
    /**Appointment Status with appointment button */
    if (
      this.state.appointmentStatus === "SCHEDULED" ||
      this.state.appointmentStatus === "RE-SCHEDULED"
    ) {
      appointmentStatuspro = "Confirmed";
      appointmentButton = (
        <button
          style={{ height: "40px", weight: "160px" }}
          className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
          type="button"
          onClick={() => this.onChangeTab(1)}
        >
          <b>View</b>
        </button>
      );
    } else if (this.state.appointmentStatus === "Completed") {
      appointmentStatuspro = "report pending";
      appointmentButton = (
        <button
          style={{ height: "40px", weight: "160px" }}
          className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
          type="button"
          onClick={() => this.onChangeTab(1)}
        >
          <b>View</b>
        </button>
      );
    } else if (this.state.appointmentStatus === "Your report is ready.") {
      appointmentStatuspro = this.state.appointmentStatus;
      appointmentButton = (<button
        style={{ height: "40px", weight: "160px" }}
        className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
        type="button"
        onClick={() => this.viewReports()}
      >
        <b>View Reports</b>
      </button>);
    }


    /**Appointment Status with appointment button */

    if (
      this.state.lastorderHistory.orderTestStatus === "Your report is ready."
    ) {
      orderTestButton = (
        <button
          style={{ height: "40px", weight: "160px" }}
          className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
          onClick={() => this.onChangeTab(2)}
          type="submit"
          onClick={this.viewReports}
        >
          <b>View Report</b>
        </button>
      );
    } else if (
      this.state.lastorderHistory.orderTestStatus === "Order Confirmed"
    ) {
      orderTestButton = (
        <button
          style={{ height: "40px", weight: "160px" }}
          className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
          onClick={() => this.onChangeTab(2)}
          type="submit"
        >
          <b>View</b>
        </button>
      );
    } else {
      orderTestButton = (
        <button
          style={{ height: "40px", weight: "160px" }}
          className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
          onClick={() => this.onChangeTab(2)}
          type="submit"
        >
          <b>View</b>
        </button>
      );
    }


    if (this.state.doctorId) {
      appointmentbooked = (
        <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
          <div className="card  rounded shadow-lg  r4 innerYourReportsCardLogs">
            <div className="card-body text-center ">
              <div className="card-block mt-2 p-2 circle">
                <img
                  className="card-img-top img-fluid text-center "
                  src="./img/payment/comment.png"
                  style={{ height: "126px", width: "126px" }}
                  alt="Card image cap"
                />
                <h3 className="text-highlight pt-3">
                  <strong>truGeny Counseling service</strong>
                </h3>
              </div>
              <h3 className="font-card pt-1">
                <b>Status:</b> {appointmentStatuspro}
              </h3>
              <h3 className="font-card pt-1 ">
                <b>Date: </b>{" "}
                {moment(this.state.appointmentDate).format("DD-MM-YYYY")}{" "}
              </h3>
              <h3 className="font-card pt-1 ">
                <b>Time: </b>
                {this.state.appointmentTime}
              </h3>
              <br />
              {/* <h3 class="font-card">Schedule your appointment with our genetic experts to start your genetic journey.</h3> */}
              {/* <a href="view-reports.html">     <img class="card-img-top text-center p-2 mb-1"  onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
            {appointmentButton}
              <br />
              <br />
            </div>
            {/* eo-card-body */}
          </div>
          {/* eo-card */}
        </div>
      );
    } else {
      appointmentbooked = (
        <div className=" col-sm-12 col-md-12 col-lg-4 ">
          <div className="card  rounded shadow-lg  mb-5  r4 innerYourReportsCardLogs bg-white">
            <div className="card-body text-center pb-5">
              <div className="card-block mt-2 p-2 circle ">
                <img
                  className="card-img-top text-center  p-2"
                  src="./img/login/1.png"
                  style={{ height: "160px", width: "160px" }}
                  alt="Card image cap"
                />
              </div>
              <h3 className="font-card roboto-reg-18px py-4">
                Schedule your appointment with our genetic experts to start your
                genetic journey.
              </h3>
              {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
              {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
              <button
                style={{
                  height: "40px",
                  color: "white",
                  background: "#4f5be7"
                }}
                className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                onClick={() => this.onChangeTab(1)}
                type="submit"
              >
                <b>COUNSELLING</b>
              </button>
              {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.lastorderHistory.orderId) {
      orderTest = (
        <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
          <div className="card rounded shadow-lg  r4 innerYourReportsCardLogs">
            <div className="card-body  text-center">
              <div className="card-block  p-2 circle">
             {orderTestImage}
                <h3 className="text-highlight pt-4">
                  <strong>{this.state.productDetails.productName} Test Service</strong>
                </h3>
              </div>
              <h3 className="font-card pt-1">
                <b>Status:</b> {this.state.lastorderHistory.orderTestStatus}
              </h3>
              
              <h3 className="font-card pt-1 ">
                <b>Date:</b>{" "}
                {moment(this.state.lastorderHistory.createdDate).format(
                  "DD-MM-YYYY"
                )}
              </h3>
              <br />
              {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
              {/* <a href="view-reports.html">   <img class="card-img-top text-center p-2 mb-1 button-view-report"  src="./img/payment/view-report-button.png"/></a> */}
              {orderTestButton}
              <br />
              <br />
            </div>
          </div>
        </div>
      );
    } else {
      orderTest = (
        <div className=" col-sm-12 col-md-12 col-lg-4 ">
          <div className="card  rounded shadow-lg  mb-5  r4 innerYourReportsCardLogs">
            <div className="card-body text-center pb-5">
              <div className="card-block mt-2 p-2 circle ">
                <img
                  className="card-img-top text-center  p-2"
                  src="./img/login/2.png"
                  style={{ height: "160px", width: "160px" }}
                  alt="Card image cap"
                />
              </div>
              <h3 className="font-card roboto-reg-18px py-4">
                Understand your genes and evaluate your risks for cancer and
                cardiac conditions.
              </h3>
              {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
              {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
              <button
                style={{
                  height: "40px",
                  color: "white",
                  background: "#4f5be7"
                }}
                className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                onClick={() => this.onChangeTab(4)}
              >
                <b>BUY truGeny</b>
              </button>
              {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
            </div>
          </div>
        </div>
      );
    }
    if (this.state.lastorderHistory.orderId) {
      activeKit = (
        <div className=" col-sm-12 col-md-12 col-lg-4">
          <div className="card  rounded shadow-lg  mb-5  r4 innerYourReportsCardLogs">
            <div className="card-body text-center pb-5">
              <div className="card-block mt-2 p-2 circle ">
                <img
                  className="card-img-top text-center  p-2"
                  src="./img/login/3.png"
                  style={{ height: "160px", width: "160px" }}
                  alt="Card image cap"
                />
              </div>
              <h3 className="font-card roboto-reg-18px pt-4 pb-1">
                Activating your kit will only take a few minutes. You will need
                the barcode found on your saliva tube.
              </h3>
              {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
              {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
              <button
                style={{
                  height: "40px",
                  color: "white",
                  background: "#4f5be7"
                }}
                className="mx-auto px-lg-5 px-sm-2 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                onClick={() => this.onChangeTab(3)}
                type="submit"
              >
                <b>ACTIVATE KIT</b>
              </button>
              {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
            </div>
          </div>
        </div>
      );
    }
    // else if(this.state.orderHistory){
    //   activeKit=
    //   <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
    //   <div className="card  rounded shadow-lg   r4 ">
    //     <div className="card-body text-center">
    //       <div className="card-block mt-2 p-2 circle ">
    //         <img className="card-img-top text-center " src="./img/payment/heart.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
    //         <h3 className="text-highlight  pt-3"><strong>truGeny cardio test service</strong></h3>
    //       </div>
    //       <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
    //       <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
    //       <br />
    //       {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
    //       {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
    //       <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports} type="submit"><b>VIEW REPORT</b></button>
    //       <br />
    //       <br />
    //     </div>
    //   </div>
    // </div>
    // }

    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://www.w3schools.com/w3css/4/w3.css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i"
          rel="stylesheet"
        />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
        <title>truGeny</title>
        <link
          rel="stylesheet"
          href="./fonts/fontawesome-free-5.8.2-web/css/all.css"
          type="text/css"
        />
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />

        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n  .background-login-card{\n    background: url(./img/registration/3-layers.png) no-repeat center;-webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    width:100%;\n    /* height: 593px; */\n    /* background-position: top; */\n  }\n  body {\n    font-family: 'Roboto', sans-serif;\n    color:#2c333c;\n  }\n\n.header-font{\n  color: #2c333c;\n  font-family: Roboto - Black;\n  font-size: 36px;\n  font-weight: 400;\n}\n.font-card{\n\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 18px;\nfont-weight: 400;\n}\n.text-highlight{\n\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 24px;\nfont-weight: 700;\n}\n\n.blue-text\n{\nmargin-top:100px;\ncolor: #4f5be7;\nfont-family: \"Roboto - Black\";\nfont-size: 30px;\nfont-weight: 400;\n}\n.para{\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 16px;\nfont-weight: 400;\nmargin-top:24px;\n}\n\n.space-tp{\n  margin-top:31px;\n  margin-bottom:101px;\n\n}\n\n.card-round{\n\nbox-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);\nborder-radius: 40px;\nbackground-color: #ffffff;\n}\n.c2{\n    border-top-left-radius: 5rem;\n        border-top-right-radius: 2rem;\n        box-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);\n}\n\n"
          }}
        />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />

        {/* header */}
        <CommonHeder category="yourReports"></CommonHeder>
        {/* end of header */}
        <div style={{ paddingBottom: "76px" }} className="padding22" />

        <div style={{ marginTop: "8rem" }} className="margin"></div>
        {/* <section class="id-1" > */}
        <div
          className=" container  text-center card-round "
          style={{ padding: "0px", borderRadius: "40px" }}
        >
          <div
            className="card-header bg-white "
            style={{
              padding: "0px",
              borderTopRightRadius: "40px",
              borderTopLeftRadius: "40px",
              borderBottom: "0px"
            }}
          >
            <div className="rs-header d-flex flex-column justify-content-start ">
              <h1 className="text-center header-font pt-5">
                <b>Your Reports</b>
              </h1>
              <span className="text-center pb-lg-5 pb-sm-2">
                <p
                  style={{
                    color: "#2c333c",
                    fontFamily: '"roboto" sans-serif',
                    fontSize: "16px",
                    fontWeight: 400
                  }}
                >
                  Hello {Cookies.get("username")}, welcome back.
                  <br />
                  Check on the status of your test reports and purchases
                </p>
              </span>
            </div>
          </div>
          {/* <div className="card-body p-0 container-fluid ">
                <div className="background-login-card p-r-5 pl-5 px-4 py-5 container ">
                  <div className="container mt-5  mb-5">
                    <div className="card-deck ">
                      <div className="card  rounded shadow-lg  r4  ">
                        <div className="card-body text-center ">
                          <div className="card-block mt-2 p-2 circle">
                            <img className="card-img-top img-fluid text-center " src="./img/payment/comment.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                            <h3 className="text-highlight pt-3"><strong>truGeny Counseling service</strong></h3>
                          </div>
                          <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                          <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                          <br />
                          <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onclick="window.location.href = 'view-reports.html';" type="submit"><b>VIEW REPORT</b></button>
                          <br />
                          <br />
                        </div>
                      </div>
                    {this.state.counsellorfirstName ?
                      <div className=" col-sm-12 col-md-4 col-lg-4 mb-2">

                        <div className="card  rounded shadow-lg  r4  ">
                        <div className="card-body text-center ">
                          <div className="card-block mt-2 p-2 circle">
                            <img className="card-img-top img-fluid text-center " src="./img/payment/comment.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                            <h3 className="text-highlight pt-3"><strong>truGeny Counseling service</strong></h3>
                          </div>
                          <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                          <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                          <br />
                          <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onclick="window.location.href = 'view-reports.html';" type="submit"><b>VIEW REPORT</b></button>
                          <br />
                          <br />
                        </div>
                       
                      </div>
                      </div> :''}
                      <div className=" col-sm-12 col-md-4 col-lg-4 mb-2">
                        <div className="card rounded shadow-lg  r4 ">
                          <div className="card-body  text-center">
                            <div className="card-block  p-2 circle">
                              <img className="card-img-top text-center  " src="./img/payment/cancer.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                              <h3 className="text-highlight pt-4"><strong>truGeny cancer test service</strong></h3>
                            
                            </div>
                            <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                            <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                            <br/>
                            <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports}  type="submit"><b>VIEW REPORT</b></button>
                            <br /><br />
                          </div>
                        </div>
                      </div>
                      <div className=" col-sm-12 col-md-4 col-lg-4 mb-2">
                        <div className="card  rounded shadow-lg   r4 ">
                          <div className="card-body text-center">
                            <div className="card-block mt-2 p-2 circle ">
                              <img className="card-img-top text-center " src="./img/payment/heart.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                              <h3 className="text-highlight  pt-3"><strong>truGeny cardio test service</strong></h3>
                            </div>
                            <h3 className="font-card pt-1"><b>Status:</b> Your report is ready.</h3>
                            <h3 className="font-card pt-1 "><b>Date:</b> 12th April,2019</h3>
                            <br />
                            <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2" onClick={this.viewReports}  type="submit"><b>VIEW REPORT</b></button>
                            <br /><br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

          <div className="card-body p-0 container-fluid ">
            <div
              className="background-login-card p-r-5 pl-5 px-4 py-5 container "
              style={{
                borderBottomRightRadius: "40px",
                borderBottomLeftRadius: "40px"
              }}
            >
              <div className="container mt-5  mb-5" style={sideBar1}>
                <div className="card-deck ">
                  {appointmentbooked}
                  {orderTest}
                  {activeKit}

                  {/* Saikiran */}
                </div>

                {/* eo-card-deck-wrap */}

                {/* eo-card-deck-wrap */}
                {/* eo-card-deck */}
              </div>
            </div>
          </div>
        </div>
        {/* </section> */}
        <div className="container giftB">
          <h2 className="text-center blue-text">
            <b>Gift a truGeny Test to your family</b>
          </h2>
          <p className="para text-center">
            Give upto 5 family of your family members or friends upto Rs 2500
            off a<br /> Color test by sharing your unique referral URL
          </p>
          <div className="text-center">
            <img
              className="img-fluid space-tp "
              src="./img/payment/gift-btn.png"
            />
          </div>
        </div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={10000} />
        <SubFooter></SubFooter>
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <MobileFooter></MobileFooter>
      </div>
    );
  }
}
export default YourReports;
