import React from 'react';
import Cookies from 'js-cookie';
import CommonHeader from './CommonHeder';

class Dashboard extends React.Component {


  render() {
  
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <style dangerouslySetInnerHTML={{ __html: "\n  .background-login-card{\n    background: url(./img/registration/3-layers.png) no-repeat center;-webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    width:100%;\n    /* height: 593px; */\n    /* background-position: top; */\n  }\n  body {\n    font-family: 'Roboto', sans-serif;\n    color:#2c333c;\n  }\n\n.header-font{\n  color: #2c333c;\n  font-family: Roboto - Black;\n  font-size: 36px;\n  font-weight: 400;\n}\n.font-card{\n\ncolor: #2c333c;\nfont-family: Roboto;\nfont-size: 18px;\nfont-weight: 400;\n}\n\n" }} />
        {/* header */}
        <CommonHeader></CommonHeader>
        {/* end of header */}
        <section className="id-1" style={{ paddingTop: '115px' }}>
          <div className="  p-0 container ">
            <div className="card rs-main-card text-center rounded border-0 shadow-lg r2 r3">
              <div className="rounded">
                <div className="card-header p-4 clearfix border-0 bg-white ">
                  <div className="rs-header d-flex flex-column justify-content-start ">
                    <h1 className="text-center header-font pt-5"><b>Welcome, {Cookies.get('username')}!</b></h1>
                    <span className="text-center pb-5"><p style={{ color: '#2c333c', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 400 }}>You don’t have any active tests or results to view. Here are some things you can do:</p></span>
                  </div>
                </div>
                <div className="card-body p-0 container-fluid ">
                  <div className="background-login-card p-r-5 pl-5 px-4 py-5 container ">
                    <div className="container mt-5  mb-5">
                      <div className="card-deck  ">
                        <div className=" col-sm-12 col-md-4 col-lg-4">
                          <div className="card  rounded shadow-lg  mb-5 r4  ">
                            <div className="card-body text-center ">
                              <div className="card-block mt-2 p-2 circle">
                                <img className="card-img-top img-fluid text-center p-2 " src="./img/login/card-1.png" style={{ height: '160px', width: '160px' }} alt="Card image cap" />
                              </div>
                              <h3 className="font-card">Schedule your appointment with our genetic experts to start your genetic journey.</h3>
                              {/* <h3 class="font-card">Schedule your appointment with our genetic experts to start your genetic journey.</h3> */}
                              <img className="card-img-top text-center p-2" src="./img/login/card-b1.png" />
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card */}
                        </div>{/* eo-card-deck-wrap */}
                        <div className=" col-sm-12 col-md-4 col-lg-4">
                          <div className="card rounded shadow-lg  mb-5 r4 ">
                            <div className="card-body  text-center">
                              <div className="card-block  p-2 circle">
                                <img className="card-img-top text-center p-2 " src="./img/login/card-2.png" style={{ height: '160px', width: '160px' }} alt="Card image cap" />
                              </div>
                              <h3 className="font-card pt-1">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3>
                              {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
                              <img className="card-img-top text-center p-2" src="./img/login/card-b2.png" />
                            </div>
                          </div>
                        </div>{/* eo-card-deck-wrap */}
                        <div className=" col-sm-12 col-md-4 col-lg-4">
                          <div className="card  rounded shadow-lg  mb-5  r4 ">
                            <div className="card-body text-center">
                              <div className="card-block mt-2 p-2 circle ">
                                <img className="card-img-top text-center  p-2" src="./img/login/card-3.png" style={{ height: '160px', width: '160px' }} alt="Card image cap" />
                              </div>
                              <h3 className="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3>
                              {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                              <img className="card-img-top text-center  p-2" src="./img/login/card-b3.png" />
                            </div>
                          </div>
                        </div>{/* eo-card-deck-wrap */}
                      </div>{/* eo-card-deck */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></section>
        <footer className="footer-sec " style={{ marginTop: '180px' }}>
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
                </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}

export default Dashboard;