import React from 'react'
import HeaderLinks from './HeaderLinks';
import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import StickyContactMenu from './StickyContactMenu';
import MetaTags from 'react-meta-tags';


class AboutUs extends React.Component{
    constructor(props) {
        super(props);
        
    }

  
   render(){
  

       return(
        <div>
       
        <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="newsticky.css" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        
            <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
            <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
            <title>TrueGeny</title>
            <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
            <link rel="stylesheet" href="stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/header_footer.css" />
            <link rel="stylesheet" href="./css/fonts.css"/>
            <link rel="stylesheet" href="./css/sticky.css"/>
            <link rel="stylesheet" href="./styleinline.css" />
            <link rel="stylesheet" href="./style-mobile2.css" />
            <link rel="stylesheet" href="./style-mobile.css" />
            <MetaTags>
        <title>About - Most Trusted Genetic Counselling Company in India</title>
		<meta name="description" content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="About - Most Trusted Genetic Counselling Company in India"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny is founded with a mission to help shift healthcare from reactive to proactive. We help people understand and benefit from their genes."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n.background-index\n{\n  background:url('img/aboutpage/banner.png') no-repeat  center ;-webkit-background-size: cover;\n}\n}\n" }} />
        <div className="background-index">
         
         <NewMainHeader category="about">
        
        </NewMainHeader>

        <StickyContactMenu ></StickyContactMenu>

          <h4 className="text-center banner-text-h4-1 mx-auto"><br /><br /></h4>
          <h4 className="text-center  text-black banner-text-h4-2 mx-auto" style={{fontWeight: 700}}><br /></h4>
          <h1 className="text-center  banner-text-h11 mx-auto" style={{color: '#4f5be7'}}>OUR MOONSHOT</h1>
          <div style={{paddingBottom: '12rem'}} />
          
          <div id="section-1" className="d-flex justify-content-center">
            <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#section-1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
          </div>
          <div style={{paddingBottom: '8rem'}} />
        </div>
      
      

        <div className="container questions-btn  img-card-1 text-center mx-auto my-5 pt-5">
          <h3 className=" text-center genetics-h2 roboto-black-36px mt-5 " style={{color: '#000'}}>About Us</h3>
          <p className=" text-center   roboto-reg-24px" style={{color: '#000'}}>truGeny was founded with the passion to improve people’s access to a longer and healthier life.</p>
          <p className=" text-center   roboto-reg-24px" style={{color: '#000'}}>At truGeny we believe it is time for people to take control of their health destiny. truGeny empowers people with personalised genetic insights that are accessible, actionable and affordable. </p>
          {/* <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " style="background-color: #4f5be7; color:white;"href="#" role="button">Order truGeny Now</a> */}
        </div>
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main pt-0">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
              <h2 className=" text-center genetics-h2 pb-0">the BELIEF - Shifting health from being</h2>
              <h3 className=" text-center genetics-h2 pt-0" > <span style={{color: '#B9B9B9'}}> REACTIVE</span> to <span style={{color: '#4f5be7'}}>PROACTIVE</span></h3>
              <div className="questions-btn text-center mx-auto my-5">
                {/* <p class="">Find answers to common questions.</p> */}
                <img className="text-center img-fluid imgfluidMobile" src="img/aboutpage/Illustration1.png" />
              </div>
            </div>{/* eo-intro-text-wrap*/}
          </div></section>
        {/* -------------------------------------------------------------------------------------------------------------------------------- */}
        <section className="genetic-cause-sec-main container-fluid">
          <div className="genetic-cause-sec-wrap">
            <div className="genetic-cause-header-wrap">
              <h2 className="genetic-cause-header genetics-h2 pt-0 text-center " style={{color: '#4f5be7'}}>
              truGeny for everyone
              </h2>
              <p className="genetic-cause-subtitle roboto-bold-24px text-center">
                We are making it easy for people to learn about and take advantage of their individualised genetic information.
              </p>
            </div>{/* eo gr-header */}
            <div className="questions-btn text-center mx-auto">
              <img className="text-center img-fluid imgfluidMobile" src="img/aboutpage/Illustration2.png" />
            </div>
          </div>
        </section>
        {/* -------------------------------------------------------------------------------------------------------------------------------- */}
        <div className="heart-disease-sec-main-wrap container pt-3 ">
          <h2 className="roboto-black-48px text-center py-5 my-5">truGeny Provides</h2>
          <div className=" heart-disease-sec row container">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/aboutpage/Illustration3.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
              <h3>Genetic Counselling</h3>
              <p className="heartdisease-content pb-5">
              With exponential growth of research and clinical information in Genetics, it is imperative that this knowledge is translated in a personalised and effective manner for people to make informed decisions about their health.<br /><br />truGeny’s certified genetic counsellors help patients and individuals understand their genetic risk based on family or personal health history, and help them to decide whether genetic testing is right for them, and recommend the appropriate genetic test. truGeny’s genetic counselling service is delivered through a proprietary telegenetics platform. Our partners include clinics, hospitals and employers.

              </p>
            </div>{/* eo-hd-text */}
          </div>{/* eo-row */}
          <div className="d-none d-sm-block" style={{paddingBottom: '4rem'}} />
          <div className="families-sec heart-disease-sec row container pt-5">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/aboutpage/Illustration4.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
              <h3>Genetic testing is not a privilege but a necessity</h3>
              <p className="heartdisease-content pb-lg-5 pb-sm-0">
              truGeny’s aim is to democratise people’s access to personalised and actionable genetic information by providing quality counselling services and comprehensive genetic tests at an affordable cost.
              </p>        {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
            </div>{/* eo-hd-text */}
          </div>{/* eo-row */}
          <div className="d-none d-sm-block" style={{paddingBottom: '4rem'}} />
          <div className=" heart-disease-sec row container pt-3">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/aboutpage/Illustration5.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
              <h3>truGeny is built on Trust.</h3>
              <p className="heartdisease-content">
                Through a network of partner labs, truGeny offers high quality clinical grade genetic tests at an affordable cost.<br /><br />To ensure that all our test results meet and exceed industry standards, our tests are performed in CAP accredited and NABL certified laboratories.
              </p>
            </div>{/* eo-hd-text */}
          </div>
          <div className="d-none d-sm-block" style={{paddingBottom: '4rem'}} />
          <div className="families-sec heart-disease-sec row container">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/aboutpage/Illustration6.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
              <h3>Data</h3>
              <p className="heartdisease-content">
                We ensure that your data is safe in our comprehensive security framework.<br /><br />Our technology ensures compliance with the latest data protection standards, and additionally we implement constant improvements to ensure that we follow the best practices.</p>        {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
            </div>{/* eo-hd-text */}
          </div>
          <div className="d-none d-sm-block" style={{paddingBottom: '4rem'}} />
          <div className=" heart-disease-sec row container py-5">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/aboutpage/Illustration7.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
              <h3>Team</h3>
              <p className="heartdisease-content">
              We are a team driven by passion and curiosity. The simple question that drives us each day is – What can we do to improve people’s health?<br /><br />We are a team that strive to think big and do big.<br /><br />We are a team that is inspired by science and propelled by the enthusiasm of our scientists, counsellors, healthcare professionals, artists, creators, engineers and marketers. 
              </p>          {/* <p class="heartdisease-content">Your patients can now receive genetic counselling from the comfort of their home and at a convenient time of their choosing.</p> */}
            </div>{/* eo-hd-text */}
          </div>{/* eo-row */}
          <div className="d-none d-sm-block" style={{paddingBottom: '4rem'}} />
          <div className="families-sec heart-disease-sec row container">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/aboutpage/Illlustration8.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
              <h3>Scientific advisory board</h3>
              <p className="heartdisease-content pb-5">
              Our Advisory board is composed of some of India’s leading experts in Genomics and Clinical Genetics. Every board member serves an important role in enhancing and upholding our high scientific standards. They are our strategic beacon for providing continuous improvement and innovative solutions to deliver improved outcomes to patients and individuals.
              </p>
              {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
            </div>{/* eo-hd-text */}
          </div>{/* eo-row */}
        </div>{/* eo-heart-disease-sec-main-wrap*/}
        {/* -------------------------------------------------------------------------------------------------------------------------------- */}
        {/* <div class="questions-btn text-center mx-auto my-5">
    <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " style="background-color: #4f5be7; color:white;"href="#" role="button">Order truGeny Now</a>
  </div> */}
        {/* -------------------------------------------------------------------------------------------------------------------------------- */}
        <div className="heart-disease-sec-main-wrap container d-none">
          <div className="genetics-intro-text text-center">
            <h2 className=" text-center genetics-h2 text-uppercase py-5" style={{color: '#4f5be7', maxWidth: '800px', margin: 'auto'}}>Have your patients order truGeny directly to their home</h2>
            {/* <p class="roboto-reg-18px " ><b>Convenience :</b><br><br>truGeny’s Provider Platform makes ordering a test easy and fast.<br>Save time and place your order online or request your patient to purchase while at home. Alternatively, collect the patient’s sample in the office using a Color kit. You can choose if results are released at the same to your patient, or after a delay.</p> */}
          </div>{/* eo-intro-text-wrap*/}
          <div className=" heart-disease-sec row container">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/clinicians/works/Illustration5.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
              <h3>Step 1</h3>
              <p className="heartdisease-content">Place order for truGeny<br /><br />Create your account on the Clinicians Platform and place your order. You can choose if you would want the report to be released at the same time to your patient, or after a delay. Once your order is placed, you’ll be able to track its status. Order processing will take 3 to 4 days.</p>
              {/* <p class="heartdisease-content">Your patients can now receive genetic counselling from the comfort of their home and at a convenient time of their choosing.</p> */}
            </div>{/* eo-hd-text */}
          </div>{/* eo-row */}
          <div className="families-sec heart-disease-sec row container">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/clinicians/works/Illustration6.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
              <h3>Step 2</h3>
              <p className="heartdisease-content pb-5">Your patient pays for the test<br /><br />Your patient will be emailed a requisition number to purchase truGeny. Your patient will be first counselled by our genetic counsellors for a pre-test counselling session. The sample collection kit is shipped to their home for them to provide their saliva sample.</p>
              {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
            </div>{/* eo-hd-text */}
          </div>{/* eo-row */}
          <div className=" heart-disease-sec row container pt-5">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/clinicians/works/Illustration7.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
              <h3>Step 3</h3>
              <p className="heartdisease-content">On an average, the report will be available in 3-4 weeks.<br /><br />You will receive an email notification from truGeny informing you that your patient’s report is ready. You can view and download the report from the truGeny clinician platform.<br /></p>
            </div>{/* eo-hd-text */}
          </div>
          <div className="families-sec heart-disease-sec row container ">
            <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
              <img className="img-fluid mx-auto imgfluidMobile" src="./img/clinicians/works/Illustration8.png" />
            </div>{/* eo-hd-img */}
            <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
              <h3>Step 4</h3>
              <p className="heartdisease-content">Your patient will have access to our certified genetic counsellors at no additional cost.<br /><br />They will help your patient understand the report and answer any questions they might have.</p>
              {/* <p class="heartdisease-content">Rely on our board certified genetic counsellors with deep subject matter expertise across medical specialties.</p> */}
              {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
            </div>{/* eo-hd-text */}
          </div>
        </div>
        {/* eo-heart-disease-sec-main */}
        {/* -------------------------------------------------------------------------------------------------------------------------------- */}
        <div className="abtBottom">
        </div>
        <div id="learn" className="abtBottomLearn">
        </div>
         <Prospect></Prospect>
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        {/* end of footer */}
        {/* -------------------------------------start q and a------------------------------ */}
        
        
        <MobileFooter></MobileFooter>
      </div>
        
       )
   }

}
export default AboutUs;