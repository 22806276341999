import React from 'react';

import $ from 'jquery';
import Calendar from 'react-calendar';

class Calender extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            date: new Date(),
          }
          this.onChange = this.onChange.bind(this)
    }

    componentDidMount(){
     
          
    }
    onChange = date => this.setState({ date })

    render(){
        return(
            <div>
            
            <link href="https://www.jqueryscript.net/css/jquerysctipttop.css" rel="stylesheet" type="text/css"/>
            <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
            <script src="./css/jquery.calendar.js"></script>
            <link rel="stylesheet" href="./css/jquery.calendar.css" />
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link href="https://www.jqueryscript.net/css/jquerysctipttop.css" rel="stylesheet" type="text/css" />
            <link rel="stylesheet" href="./css/jquery.calendar.css" />
            <style dangerouslySetInnerHTML={{__html: "\n\t\t/* * {font-family: 'Open Sans';}\n\t\tbody { background-color: #fafafa; }\n\t\tpre {font-family: Courier New;} */\n\t\t" }} />
            <div id="jquery-script-menu">
              <section style={{maxWidth: '400px', margin: '150px auto'}}>
                <h1>Select Event</h1>
                <p>calendar widget with assigned <i>onSelect</i> event</p>
                <div id="pnlEventCalendar" style={{width: '100%'}} />
                <p>selected date: <b><span id="lblEventCalendar">[date]</span></b></p>
                <div>
                <Calendar
                  onChange={this.onChange}
                  value={this.state.date}
                />
              </div>
              </section>
            </div></div>
        )
    }

}
export default Calender;