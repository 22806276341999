import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { Collapse} from 'reactstrap';


class Faqs extends React.Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: [true,false,false,false,false,false,false ]};
  }

  toggle(tab) {
    const prevState = this.state.collapse;
    const state = prevState.map((x, index) => tab === index ? !x : false);
    this.setState( { collapse: state });
  }


  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        {/* header */}
        <CommonHeder></CommonHeder>
        {/* end of header */}
        <section className="risk-summery-sec">
          <div className="rs-sec-wrap  p-0 container ">
            <div className="card rs-main-card text-center rounded border-0 shadow-lg">
              <div className="card-header p-4 clearfix border-0 ">
                <div className="rs-header d-flex flex-column justify-content-start float-left">
                  <h2>{Cookies.get('username')}</h2>
                  <span className=" ">Hereditary Cancer Report</span>
                </div>{/* eo-rs-header */}
                <ul className="rs-nav nav  card-header-tabs justify-content-end float-right">
                  <li className="nav-item">
                    <a className="nav-link" href="#">Genetic counselling appointment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Share report</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true">Download report</a>
                  </li>
                </ul>
              </div>{/* eo-card-header */}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-head px-4 py-5 container ">
                  <h2 className="card-title">Risk Summary</h2>
                  <p className="card-text">John, the results from your test do not indicate any increased genetic risks for inherited cancers</p>
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-genetic-mutation.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      No genetic mutations were identified.
                    </h5>
                    <p className="card-text">We analysed 90 genes that are associated with breast, colorectal, ovarian, pancreatic and stomach cancers. No mutations were identified in these genes.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">See your Detailed Report</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="container seperatorline" />
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-health-history.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      Your health history is incomplete.
                    </h5>
                    <p className="card-text">You have not completed your health history, which may impact the risk and screening guidelines for you. Although your have tested negative, 85 - 90% of all cancers have a non-genetic cause. When cancer is detected early it improves the odds of survival.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Update your health history now</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="dv-block-wrap d-flex flex-column justify-content-start">
                  <div className="dv-head cb-head px-4 pt-5 pb-0 container">
                    <h2 className="card-title">Detailed View</h2>
                    <ul className="dv-nav nav  card-header-tabs">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Your Risks</a> */}
                        <Link to="/risksummary" className="nav-link">Your Risks</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="details.html">Details</a> */}
                        <Link to="/details" className="nav-link">Details</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="next-steps.html nav-active">Next Steps</a> */}
                        <Link to="/nextsteps" className="nav-link">Next Steps</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="family-history.html">Family History</a> */}
                        <Link to="/familyhistory" className="nav-link">Family History</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="genetic-counselling.html">Genetic Counselling</a> */}
                        <Link to="/genetic-counselling" className="nav-link">Genetic Counselling</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="share-details.html">Share Your Report</a> */}
                        <Link to="/sharedetails" className="nav-link ">Share Your Report</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">faqs</a> */}
                        <Link to="/faqs" className="nav-link nav-active">faqs</Link>

                      </li>
                    </ul>
                  </div>{/* eo-dv-block-cb-head */}
                </div>{/* eo-dv-block-wrap */}
              </div>{/* eo-card-body*/}
              {/* faq accordian */}
              <div className="container faq-accordian-wrap dv-accordian-wrap px-4">
                <div className="faq-accordian accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="faq-title pb-3">Frequently asked questions</h5>
                      <h5 className="mb-0 change-color">
                        What does a negative result mean?
                        <button type="button" className="float-right btn btn-link rounded-circle" onClick={()=>this.toggle(0)}>{this.state.collapse[0] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse className="collapse" aria-labelledby="headingOne" isOpen={this.state.collapse[0]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="change-color mb-0">
                        Does this result mean I’m not at risk for cancer?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseTwo" onClick={()=>this.toggle(1)}>{this.state.collapse[1] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample" isOpen={this.state.collapse[1]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0 change-color">
                        Who will see these test reports?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseThree" onClick={()=>this.toggle(2)}>{this.state.collapse[2] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample" isOpen={this.state.collapse[2]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h5 className="mb-0 change-color">
                        Should I share my results with my Doctor?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseFour" onClick={()=>this.toggle(3)}>{this.state.collapse[3] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse id="collapseFour" className="collapse" aria-labelledby="headingfour" data-parent="#accordionExample" isOpen={this.state.collapse[3]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h5 className="mb-0 change-color">
                        What can cause cancer besides gene mutations?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseFive" onClick={()=>this.toggle(4)}>{this.state.collapse[4] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample" isOpen={this.state.collapse[4]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingSix">
                      <h5 className="mb-0 change-color">
                        What health history factors may influence my risk of breast cancer?
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseSix" onClick={()=>this.toggle(5)}>{this.state.collapse[5] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample" isOpen={this.state.collapse[5]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                  <div className="card">
                    <div className="card-header" id="headingSeven">
                      <h5 className="mb-0 change-color">
                        For breast cancer specifically, some factors known to influence risk include:
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseSeven" onClick={()=>this.toggle(6)}>{this.state.collapse[6] ?<i className="fa fa-minus-circle rounded-circle" />:<i className="fa fa-plus-circle rounded-circle" />}</button>
                      </h5>
                    </div>
                    <Collapse id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample" isOpen={this.state.collapse[6]}>
                      <div className="faq-card-body card-body">
                        <p>A negative result means that no mutations, or genetic changes associated with an increased risk of the most common hereditary cancers were identified in the genes that were analyzed. This result does not eliminate your risk of developing cancer. You may still be at increased risk of cancer due to other factors, mutations not detected by current technology, or mutations in other genes.</p>
                      </div>{/* eo-faq-card-body */}
                    </Collapse>{/* eo-#collapseOne */}
                  </div>{/* eo-card */}
                </div>{/* eo faq-accordian */}
              </div>{/* eo faq-accordian-wrap */}
              {/* end of faq accordian code */}
            </div>{/* eo-rs-main-card*/}
          </div>{/* eo-rs-sec-wrap */}
        </section>{/* eo-risk-summery-sec */}
        <footer className="footer-sec">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
            </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>

    );
  }
}
export default Faqs;