import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class HeartCarouselCards extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div
                    className="card rounded newcard "
                    style={{ height: "260px !important" }}
                  >
                    <div className="card-body text-center ">
                      <div className="card-block  p-2 ">
                        <img
                          className="card-img img-fluid text-center p-2 "
                          src="heart-img/obsity-01.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">Obesity</p>
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div
                    className="card rounded newcard"
                    style={{ height: "260px !important" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block p-2 ">
                        <img
                          className="card-img text-center p-2"
                          src=" heart-img/physical-activity-01.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">Physical Inactivity</p>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div
                    className="card rounded newcard "
                    style={{ height: "260px !important" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block  p-2  ">
                        <img
                          className="card-img text-center  p-2"
                          src="heart-img/smoking-drinking-01.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">Smoking &amp; Drinking</p>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div
                    className="card rounded newcard"
                    style={{ height: "260px !important" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block p-2 ">
                        <img
                          className="card-img text-center  p-2 "
                          src="heart-img/unhealthy-diet.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text">Unhealthy Diet</p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default HeartCarouselCards;
