import React from 'react';
import MainHeader from './MainHeader';
import Footer from './Footer';
import CliniciansProspect from './CliniciansProspect';
import MobileFooter  from './MobileFooter';
import MetaTags from 'react-meta-tags';

class CliniciansHeartTest extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toLogin() {
        window.location.href="/clinicians_login";
    }

    
    toGeneticHelp() {

       window.location.href="/clinicians_genetic-help";
      }
  render() {
    return (
      

        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>
        <MetaTags>
        <title>Patients Care: Genetic test for Cardiac Patients</title>
		<meta name="description" content="Choose truGeny’s genetic tests to detect cardiovascular disease risk of your patients and provide better care."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Patients Care: Genetic test for Cardiac Patients"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="Choose truGeny’s genetic tests to detect cardiovascular disease risk of your patients and provide better care."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        
        <style dangerouslySetInnerHTML={{__html: "\n/* @media only screen and (min-width: 1200px)\n{\n/* .custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n.demo {\n\t\twidth:100%;\n\t\tborder:1px solid #C0C0C0;\n    border-radius: 2rem;\n\t\tborder-collapse:collapse;\n\t\tpadding:5px;\n\n\t}\n\t.demo th {\n\t\tborder:1px solid #C0C0C0;\n\t\tpadding:5px;\n\t\tbackground:#fff;\n\t}\n  .demo tr:nth-child(odd)\n   {\n     background-color: #dcf4f4;\n   }\n\t.demo td {\n\t\tborder:1px solid #C0C0C0;\n\t\tpadding:5px;\n    /* background:#fff; */\n\n\t}\n\n  .genetics-banner-sec-wrap .genetics-banner-sec{\n    background: url(./img/clinicians/heart-test/bg.png) no-repeat center;-webkit-background-size: cover;\n    background-position:center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n\n  .gc-help-main-sec{\n    background: url(./img/clinicians/cancer-test/bg2.png) no-repeat center;-webkit-background-size: cover;\n    -moz-background-size: cover;\n    -o-background-size: cover;\n    background-size: cover;\n    background-position: top;\n  }\n\n\n\n\n" }} />
        <MainHeader  category="tests"></MainHeader>
        <div style={{marginTop:'12rem'}} className="margin"></div>
       
        <section className="genetic-Counseling-main-sec genetics-banner-sec-wrap" style={{width: '100%'}}>
          <div className=" genetics-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className>truHeart</h1>
              <p className=" mt-3 mb-4 pb-0">A tool to precisely help you identify high risk patients for Coronary Artery Disease(CAD) and initiate preventive therapies for them.</p>
              <a className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0 text-uppercase mx-auto px-auto"  style={{fontSize: '14px', color: 'white', backgroundColor: '#4f5be7'}}><b>Order truHeart Now</b></a>
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        <div style={{paddingBottom: '2rem'}} className="margin" />
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            {/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto" src="./img/clinicians/heart-test/Illustration1.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Very often People don’t know that they have an inherited heart condition</h3>
                <p className="heartdisease-content">As symptoms don’t show up very easily, most people don't know that they have inherited heart conditions and they could be at risk. If left undetected, inherited heart conditions can lead to coronary heart disease, heart attacks, sudden cardiac arrest, or even heart failure.</p>
                {/* <p class="heartdisease-content">Our telegenetics platform helps you to deliver a highly personalised service with deep medical expertise for genetic counselling to all your patients.</p>
          <p class="heartdisease-content">Your patients can now receive genetic counselling from the comfort of their home and at a convenient time of their choosing.</p> */}
                {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
              </div>{/* eo-hd-text */}
              <div className="genetics-intro-text text-center">
                <h2 className=" text-center genetics-h2 " style={{color: '#000'}}>The risk of CAD in Indians is nearly 3-4 times
                  higher than Caucasians</h2>
                {/* <p class="roboto-reg-18px " ><b>Convenience :</b><br><br>truGeny’s Provider Platform makes ordering a test easy and fast.<br>Save time and place your order online or request your patient to purchase while at home. Alternatively, collect the patient’s sample in the office using a Color kit. You can choose if results are released at the same to your patient, or after a delay.</p> */}
              </div>
            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container mb-5">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                <img className="img-fluid mx-auto" src="./img/clinicians/heart-test/Illustration2.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                <h3>TruHeart test?</h3>
                {/* <p class="heartdisease-content">Rely on our board certified genetic counsellors with deep subject matter expertise across medical specialties.</p> */}
                <p className="heartdisease-content">It is a blood/saliva test that helps estimate the genetic predisposition of an individual to develop Coronary Artery Disease.<br /><br />The risk is calculated from &gt;6 million genetic markers implicated in the disease and is given as a validated Polygenic Risk Score (PRS) The score indicates if the individual is at high, moderate or low genetic risk.<br /><br />Ethnicity of the tested individual is significant while estimating the Polygenic Risk Score. The test is validated for the South Asian population</p>
                {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill" data-toggle="modal" data-target="#exampleModalCenter" >LEARN MORE<i class="fas fa-long-arrow-alt-right"></i> */}
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
            <div className="padClinins" />
            <div className=" heart-disease-sec row container mt-5 pt-5">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto" src="./img/clinicians/heart-test/Illustration3.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Providing access for better Care</h3>
                <p className="heartdisease-content">
                  Based on the PRS generated from the test, a person is profiled as a low, moderate or high genetic risk individual.<br /><br />This risk level assignment is relative to the study cohort conducted by MedGenome. For example, an individual with high genetic risk may have &gt;3 times the increased genetic risk of CAD, compared to MedGenome’s study cohort, which comprises of CAD patients and healthy controls of<br />Indian ancestry.<br /><br />CAD manifestation is multifactorial and depends on genetic, as well as environmental factors, such as a person’s lifestyle. The PRS prediction refers to a person’s genetic predisposition for CAD.<br /><br />Although the score derived is fairly accurate, free from ambiguities of self-reported family history, and modifiable factors like a person’s health conditions, it does not consider the environmental risks an individual is exposed to.</p>
                {/* <p class="heartdisease-content">Our telegenetics platform helps you to deliver a highly personalised service with deep medical expertise for genetic counselling to all your patients.</p>
          <p class="heartdisease-content">Your patients can now receive genetic counselling from the comfort of their home and at a convenient time of their choosing.</p> */}
                <br/>
                <a className="btn btn-outline-primary mt-4 btn-hover-white rounded-pill">Order the truHeart test Now&nbsp;&nbsp;<i className="fas fa-long-arrow-alt-right">&nbsp;</i></a>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
          {/* <div class="questions-btn text-center mx-auto my-5">

    <a class="btn btn-outline-primary btn-hover-white rounded-pill text-center " style="background-color: #4f5be7; color:white;"href="#" role="button">Order truGeny Now</a>
  </div> */}
        </section>
        {/* Start of Genetic Counsell Help cards  */}
        <div className="gc-help-main-sec">
          <div className="container gc-help-main-wrap pr-0 pl-0">
            <h2 className="text-center">How does it work?</h2>
            <p className="roboto-reg-24px text-center pt-2">truGeny combines the efficiency of its telegenetics platform with clinical grade tests<br />to enable you deliver patient care that is unparalleled.</p>
            <div className="container gc-help-card-main-wrap mx-auto mt-5  mb-5">
              <div className="row text-center ">
                <div className="card-deck-wrap col-lg-1 col-sm-12 col-md-12 p-0">
                </div>
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH" >
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/heart-test/c1.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Schedule an Appointment</p>
                      <p className="roboto-reg-16px">It’s fast and easy to do online, and your personal information will always be kept confidental and protected.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/heart-test/c2.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Initial Consultation</p>
                      <p className="roboto-reg-16px">If the patient requires testing, we will ship the sample collection kit to the patients home.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/heart-test/c3.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Sample Collection</p>
                      <p className="roboto-reg-16px">Our expert genetic counsellors will review your patients personal and family history and answer their questions. They will also help patients select and order the right tests.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/heart-test/c4.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Results Consultation</p>
                      <p className="roboto-reg-16px">Our genetic counsellor with review the test results, walk your patient through the report and its implications and answer their questions.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-2 p-1">
                  <div className="card rounded shadow-lg  mb-4 r4  cardH">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center  " src="./img/clinicians/heart-test/c5.png" style={{height: '100px', width: '100px'}} alt="Card image cap" />
                      </div>
                      <p className="roboto-black-18px">Expert Follow Up</p>
                      <p className="roboto-reg-16px">You will receive a written summary of the counselling session with the patient and you can integrate the report insights into your patients healthcare plan.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
              </div>
              <div className="card-deck-wrap col-lg-1 col-sm-12 col-md-12 p-0">
              </div>
            </div>{/* eo-card-deck */}
          </div>{/*eo-container */}
          {/* <div class="gc-help-button-wrap text-center">
     <a class="gc-help-button btn btn-outline-primary btn-hover-white rounded-pill text-center" href="#" role="button">Schedule Your Appointment</a>
   </div> */}
        </div>{/* gc-help-main-wrap */}
        {/* gc-help-main-sec */}
        {/* end of Genetic Counsell Help cards  */}
        {/* Genes can influence section starts here */}
        {/* ******************Our Process-sec************ */}
        <div className="padClinins" />
        {/* end of genetic-cause */}
        <section className="genetic-cause-sec-main bg-white ">
          <div className="genetic-cause-sec-wrap">
            <div className="genetic-cause-header-wrap">
              <h2 className="genetic-cause-header genetics-h2 pt-0 text-center">
                Reports that are ACTIONABLE
              </h2>
              <p className="roboto-reg-18px text-center">truGeny’s clinical grade reports are clear and easy to use. The reports provide recommendations for managing patients based on well-recognized guidelines for genetic and familial risks.</p>
              <p className="roboto-reg-18px text-center">Screening guidelines created by nationally recognized boards, such as the ICMR, to help you and your patient create a healthcare plan that’s right for them.</p>
            </div>{/* eo gr-header */}
            <div className="questions-btn text-center mx-auto">
              <p className>Learn How to Partner With Us</p>
              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " style={{backgroundColor: '#4f5be7', color: 'white'}} href="#footer-c" role="button">Learn How to Partner With Us
              </a>
            </div>
          </div>{/* genetic-cause-sec-wrap */}
        </section>
        {/* ******************End of Genetic Session section************ */}
        {/* ******************are you ready section************ */}
        <section className="ayr-sec-main heart-disease-sec-main d-none">
          <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container">
            <div className="intro-text-wrap text-center">
              <h2 className="text-center d-none">Are you Ready?</h2>
            </div>{/* eo-intro-text-wrap*/}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************end of are you ready section************ */}
        {/* **********Schedule your Genetic Counseling Session Starts Here************** */}
        <CliniciansProspect></CliniciansProspect>
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        <MobileFooter></MobileFooter>
        {/* -------------------------------------start q and a------------------------------ */}
        <div className="modal fade bd-example-modal-lg d-none" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                      <h3>How do you indentify your gender?</h3>          <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3><hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label><input type="radio" name="optradio" defaultChecked />  Parents</label>
                      </div>
                      <div className="radio">
                        <label><input type="radio" name="optradio" />  Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill"  href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
        
        {/* --------eo sticky form----------------- */}
        {/* ----------------------------------------------------eo Q and A--------------------------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default CliniciansHeartTest;
