import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class CancerCarouselCards2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleControls1"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} interval={false} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="impact-card-wrap  col-12 col-md-12 col-lg-6 col-xl-6">
                  <div
                    className=" card-1 cancerCarocelCard1 "
                    style={{ height: "500px !important" }}
                  >
                    <div className="card-body p-0">
                      <div className="card-top impact-card-header">
                        <h3>The impact of mutations on women</h3>
                      </div>
                      <div className="card-middle">
                        <div className="middle-img-wrap text-center py-5">
                          <img
                            className="img-fluid mx-auto"
                            src="./img/cancerpage/Illustration-women-01.png"
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text-a card-text">
                          When there’s a mutation, a woman’s risk for developing
                          cancer increases significantly. For example, a BRCA1
                          mutation can increase the chances of breast cancer up
                          to 81% Similarly mutations in other genes can increase
                          risks for developing cancers such as Uterine,
                          Pancreatic, Melanoma, Colorectal and stomach cancers.
                        </p>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="impact-card-wrap  col-12 col-md-12 col-lg-6 col-xl-6">
                  <div
                    className=" card-1 cancerCarocelCard1"
                    style={{ height: "500px !important" }}
                  >
                    <div className="card-body p-0">
                      <div className="card-top impact-card-header">
                        <h3>The impact of mutations on man</h3>
                      </div>
                      <div className="card-middle">
                        <div className="middle-img-wrap text-center py-5">
                          <img
                            className="img-fluid mx-auto"
                            src="./img/cancerpage/Illustration-man-01.png"
                          />
                        </div>
                        {/* eo-hd-img */}
                      </div>
                      {/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text-b card-text">
                          A man’s risk for developing hereditary cancer goes up
                          significantly when there’s a mutation.The top five
                          cancers account for nearly 47.2% of all cancers; these
                          cancers can be prevented, screened for and/or detected
                          early and treated at an early stage [10] Prostate
                          cancer (PCa) is the second most common cause of cancer
                          and the sixth leading cause of cancer death among men
                          worldwide (11)
                        </p>
                      </div>
                      {/* eo-card-btm */}
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card-1 */}
                </div>
                {/* eo-ns-card-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default CancerCarouselCards2;
