import React from 'react';
import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import Footer from './Footer';
import MobileFooter from './MobileFooter'; 
import CancerCarouselCards from './CancerCarouselCards';
import CancerCarouselCards2 from './CancerCarouselCards2';
import CancerCarouselCards3 from './CancerCarouselCards3';
import StickyContactMenu from './StickyContactMenu';
import MetaTags from 'react-meta-tags';



class Cancer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }


      toLogin() {
         window.location.href="/login";
        }
        toBookSession(){
          window.location.href="/book-counselling"
        }
        toOrder(){
          window.location.href="/product-page"
        }
    render() {
        return (

          <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        {/* <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16">
    <link rel="stylesheet" href="./css/header_footer.css" >
    <link rel="stylesheet" href="./css/stylesheet_custom.css" >
    <link rel="stylesheet" href="./css/fonts.css" >
    <link rel="stylesheet" href="./css/sticky.css" >
    <link rel="stylesheet" href="stylesheet_custom.css"> */}
        {/* <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"> */}
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="newsticky.css" />
        <MetaTags>
        <title>Genetic Testing For Cancer Risk | Cardiovascular Genetic Testing - truGeny</title>
		<meta name="description" content="At truGeny, we provide genetic tests to help people learn about their risk for developing hereditary cancer. "/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Genetic Testing For Cancer Risk | Cardiovascular Genetic Testing - truGeny"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="At truGeny, we provide genetic tests to help people learn about their risk for developing hereditary cancer. "/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n.font-nav{\n  padding-right:15px;\nfont-size:15px;\ncolor:gray;\nfont-weight:300;\n\n}\n\n.img-11{\n  height:48px;\n  /* width:120px; */\n}\n.nav-tab-1{\n    padding-top:5px!important;\n    padding-bottom:5px!important;\n    color:#FF3C3C;\n    font-size:15px;\n\n}\n@media (max-width: 767.98px) {\n  .font-nav{\n    padding-right:4px;\n  font-size:10px;\n  color:gray;\n\n  }\n  .nav-tab-1{\n      padding-top:3px!important;\n      padding-bottom:3px!important;\n      color:#FF3C3C;\n\n  }\n   }\n   .nav-active1{\n     font-weight:700;\n     color:#4f5be7;\n   }\n  " }} />
        <link rel="stylesheet" href="style-mobile.css" />
        <NewMainHeader category="cancer"></NewMainHeader>
        <StickyContactMenu ></StickyContactMenu>

        {/* eo-container */}{/* eo-container */}

        {/* eo-container */}
        {/*  Cancer Page Hero Banner  */}
        <section className=" cancer-banner-sec-wrap banner-position">
          <div className=" cancer-banner-sec ">
            <div className="container pt-4 mt-2 cbannerp">
            <h1>Understand Cancer to Beat it.</h1>
                <p className="text-justify mt-3 mb-4">Knowledge about your genetic risks for developing cancer helps you to work with your doctor to create a personalised health plan that is designed to prevent or detect cancer at an early treatable stage
                 </p>
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        {/* End of  Cancer Page Hero Banner  */}
        {/* Sart of Cancer Page Section-1  */}
        <section id="secc1" className="cancerpage-sec-1-main genetics-informed-sec-main heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/cancerpage-sec-1-img.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                  <h3>Out of Control</h3>
                  <p className="heartdisease-content">Cancer is a disease in which cells grow out of control. This happens because of changes in some of the genes inside a cell. </p>
                  <p className="heartdisease-content">This abnormal change in a gene is known as a<strong> mutation.</strong></p>
                  <p className="heartdisease-content">Individuals having the mutation carry a greater risk for developing certain cancers.</p>

                </div>{/* eo-hd-text */}

            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/cancerpage-sec-1-img-02.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                  <p className="heartdisease-content"><strong style={{fontSize:'1.5rem'}}>Mutations when present can greatly increase your risk of developing cancer</strong></p>
                  <p className="heartdisease-content">For eg. certain mutations increase a woman’s risk of developing breast cancer by up to 70% and ovarian cancers by up to 44%</p>
                </div>{/* eo-hd-text */}

            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* End of  Cancer Page Section-1  */}
        {/* Start of Cancer Page Section-2  */}
        <section className="genetics-informed-sec-main heart-disease-sec-main cancer-common-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
              <h2 className=" text-center genetics-h2 m-0 ">Cancer has affected many, many families.
              </h2>
              {/* <p class="genetics-h2-p">and it has affected many, many families</p> */}
            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center  ">
                <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/cancerpage-sec-2-img.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Hereditary cancer</h3>
                <p className="heartdisease-content">In some cases, cancer is caused due to an abnormal gene mutation being passed on from one generation to the next. This form of cancer is referred to as <strong> hereditary cancer</strong></p>

                </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* End of Cancer Page Section-2  */}
        {/* Start of Cancer Page Section-3  */}
        <section className="genetics-informed-sec-main heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
              <h2 className=" text-center genetics-h2 pt-0">If you find out that you have a mutation
              </h2>
              <p className="genetics-h2-p">Your doctor may be able to create a personalised screening plan to detect or prevent cancer from happening to you</p>
            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 d-block text-center order-md-2 ">
                <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/Illustration4.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                <h3>Detect early</h3>
                <p className="heartdisease-content pb-3 ">The odds of survival go up significantly when cancer is detected early.</p>
                <div className="graph-img-box pt-3">
                  <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/Graph.png" />
                </div>{/* end of graph-img-box */}
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* End of Cancer Page Section-3  */}
        <div className="bp-main-sec">
          <div className="container bp-main-wrap">
            <h2 className="bp-main-heading text-center">Be proactive</h2>
            <h3 className="bp-main-sub-heading text-center">Know your risk to take ACTION</h3>
            <h3 className="bp-cards-header text-center">It’s important to KNOW.</h3>
            <p className="bp-cards-subtitle text-center ">Knowing about your risk for hereditary cancer can help you and your doctor to create a personalised plan and make informed decisions about prevention or treatment. If you are found to have a faulty gene, you can do the following to reduce your risk of developing cancer:</p>

            <div className="container gc-help-card-main-wrap mx-auto mt-5  mb-5">
              <div className="card-deck" id="hide">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg  mb-4 r4  ">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img className="card-img img-fluid text-center p-2 " src="./img/cancerpage/cancerpage-bp-icon-1.png" alt="Card image cap" />
                      </div>
                      <p className="card-text pt-4">You may be able to have regular screening to detect cancer early. For eg. if you have an increased risk for developing breast cancer your doctor may recommend mammograms more frequently.</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg mb-4 r4 ">
                    <div className="card-body text-center">
                      <div className="card-block p-2 circle">
                        <img className="card-img text-center p-2" src="./img/cancerpage/cancerpage-bp-icon-2.png" alt="Card image cap" />
                      </div>
                      <p className="card-text pt-4">For certain cancers, your doctor might prescribe medicines to lower the risk of developing cancer.</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg  mb-4 r4 ">
                    <div className="card-body text-center">
                      <div className="card-block mt-2 p-2 circle ">
                        <img className="card-img text-center  p-2" src="./img/cancerpage/cancerpage-bp-icon-3.png" alt="Card image cap" />
                      </div>
                      <p className="card-text pt-4">Your results can help your family to understand their risk for hereditary cancers.</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg mb-4 r4  ">
                    <div className="card-body text-center">
                      <div className="card-block mt-2 p-2  circle">
                        <img className="card-img text-center  p-2 " src="./img/cancerpage/cancerpage-bp-icon-4.png" alt="Card image cap" />
                      </div>
                      <p className="card-text pt-4">Knowing about risks and whether you have a faulty gene or not can reduce stress and worry.</p>
                    </div>
                  </div>{/* eo card */}
                </div>{/* eo-card-deck-wrap */}
              </div>{/* eo-card-deck */}
              {/* ==================================== */}
             <CancerCarouselCards></CancerCarouselCards>
              {/* ================================= */}
            </div>{/*eo-container */}
          </div>{/* gc-help-main-wrap */}
        </div>{/* gc-help-main-sec */}
        {/* End of Cancer Page Section-4 - be proactive cards  */}
        {/* impact of mutations section starts here */}
        <section className="impact-main-sec ">
          <div className="container impact-sec-wrap">
            <div className="impact-main-header-wrap">
              <h2 className="text-center">1 in 10 men and 1 in 8 women</h2>
              <h3 className="text-center">in India can expect to develop some form of cancer in their life span beyond 35 years.</h3>

              </div>{/* eo gr-header */}
            <div className="container impact-card-main-wrap pt-lg-5 pt-sm-3 mt-lg-5 mt-sm-0">
              <div className="row" id="hide">
                <div className="impact-card-wrap  col-12 col-md-12 col-lg-6 col-xl-6">
                  <div className=" card-1 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top impact-card-header">
                        <h3>The impact of mutations on women</h3>
                      </div>
                      <div className="card-middle">
                        <div className="middle-img-wrap text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/cancerpage/Illustration-women-01.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text-a card-text">When there’s a mutation, a woman’s risk for developing cancer increases significantly. For example, a BRCA1 mutation can increase the chances of breast cancer up to 81% Similarly mutations in other genes can increase risks for developing cancers such as Uterine, Pancreatic, Melanoma, Colorectal and stomach cancers.</p>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="impact-card-wrap  col-12 col-md-12 col-lg-6 col-xl-6">
                  <div className=" card-1 shadow-lg r4 border-0 ">
                    <div className="card-body p-0">
                      <div className="card-top impact-card-header">
                        <h3>The impact of mutations on man</h3>
                      </div>
                      <div className="card-middle">
                        <div className="middle-img-wrap text-center py-5">
                          <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/Illustration-man-01.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text-b card-text">A man’s risk for developing hereditary cancer goes up significantly when there’s a mutation. The top five cancers account for nearly 47.2% of all cancers; these cancers can be prevented, screened for and/or detected early and treated at an early stage [10] Prostate cancer (PCa) is the second most common cause of cancer and the sixth leading cause of cancer death among men worldwide (11)</p>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
              </div>{/* eo-row */}
              {/* ================================================= */}
              <CancerCarouselCards2></CancerCarouselCards2>
              {/* ================================================= */}
            </div>{/* gr-card-wrap */}
          </div>{/* eo gr-sec-wrap */}
        </section>{/*eo gr-sec*/}
        {/* end of impact of mutations section*/}
        {/*  Benefits of truGeny section starts here */}
        <section className="genetics-informed-sec-main heart-disease-sec-main heart-disease-sec-main ">
          <div className="heart-disease-sec-main-wrap container">
            <div className="genetics-intro-text text-center">
            <h2 className=" text-center genetics-h2 ">Benefits and reliability of our truFind(Hereditary Cancer Test)</h2>

            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 d-block text-center order-md-2 ">
                <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/benefitIllustration.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                <h3>Conversation FIRST</h3>
                <p className="heartdisease-content">Our expert genetic counsellors will assess your family history and risk factors, and determine if genetic testing is right for you and ensure that you are receiving the most appropriate test.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container ">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center ">
                <img className="img-fluid mx-auto" src="./img/cancerpage/highIllustration.png" />
              </div>{/* eo-hd-img */}
              <div className="col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center sec6 ">
                <h3>A highly reliable test</h3>
                <ul className="roboto-reg-18px">
                <li>Unlike most genetic tests available in the market, we don’t just look at the snippets of your DNA. We do a thorough analysis of your genes using the highly advanced NGS (Next Generation Sequencing) technology.</li>
                <li>We evaluate and analyse over 100 genes relevant to hereditary cancers.</li>
                <li>Our reports are actionable – they can be shared with your doctor for prevention and understanding of treatment options for better health.</li>
              </ul>

              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* End of Benefits of truGeny Section */}
        {/* Learn about your risks Section */}
        <section className="learn-video-sec d-none">
          <div className="learn-video-sec-wrap container">
            <div className="learn-video-sec-header">
              <h3>learn About Your Risks</h3>
            </div>{/* end of learn-video-sec-header */}
            <div className="embed-responsive embed-responsive-16by9 col-sm-12 col-md-12 shadow-lg r4 border-0">
              <iframe className="embed-responsive-item " src="https://www.youtube.com/embed/-68pyef4N2Y" frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                Your browser doesn't support HTML5 video tag.
              </iframe>
            </div>{/* end of embed-responsive */}
          </div>{/* end of learn video sec wrap */}
        </section>{/* end of learn video section  */}
        {/* End of Learn about your risks Section */}
        {/* Got to Know starts here */}
        <section className="gtk-sec ">
          <div className="container gtk-sec-wrap gr-sec-wrap">
            <div className="gtk-main-header-wrap">
              <h2 className="text-center">
              I’m interested to know about what my genes indicate on my risk for developing hereditary cancers

              </h2>
              <div className="questions-btn text-center mx-auto">
                <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toOrder} role="button">Order my truFind Kit</a>
              </div>
            </div>{/* eo gr-header */}
            <div className="container gtk-card-main-wrap pt-5 mt-5 ">
              <div className="row" id="hide">
                <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className=" card-1 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/cancerpage/learnIllustration-44.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text "><b>Schedule my Genetic Counselling session               </b></p>
                        <div className="she-btn text-center mx-auto">
                          <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toBookSession} role="button"> Schedule Now</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className=" card-1 shadow-lg r4 border-0">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto" src="./img/cancerpage/Box-1.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text"><b>Order my truFind Kit</b></p>
                        <div className="order-btn questions-btn text-center mx-auto mt-0">
                          <a className=" btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toOrder} role="button"> Order Now</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
              </div>{/* eo-row */}
              {/* ============================================ */}
              <div className="row" style={{display: 'block'}}>
                <CancerCarouselCards3></CancerCarouselCards3>
              </div>
              {/* ============================================ */}
            </div>{/* gr-card-wrap */}
          </div>{/* eo gr-sec-wrap */}
        </section>{/*eo gr-sec*/}
        {/* end of Got to Know */}
        {/* section-9 blue bg with text */}
        <section className="blue-bg-sec-main ">
          <div className=" row container blue-bg-sec-wrap mx-auto">
            <div className="blue-bg-content col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h3 className="blue-bg-header blue-bg-header-1 text-left">
                Cancer is a complex disease and has many causes.
              </h3>
              <p className=" text-left">Most cancers are “sporadic,” or seemingly due to random chance and without an identifiable cause. But about 10-15% of certain cancers are due to harmful genetic changes, called mutations, that are passed down through families.</p>
            </div>{/* eo gr-header */}
            <div className="blue-bg-content col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h3 className="blue-bg-header blue-bg-header-2 text-left">
                Increased risk doesn’t mean cancer will definitely develop
              </h3>
              <p className=" text-left">A positive result, or finding a mutation, is not a cancer diagnosis, and does not mean that you will develop cancer. For example, most women have a 10% chance of getting breast cancer by the time they are 80, while a woman with a mutation in the BRCA1 gene can have up to an 81% chance., Most men have a 2% chance of getting colorectal cancer by the time they are 70, while a man with a mutation in the MLH1 gene can have up to a 41% chance., The level of increased cancer risk differs from gene to gene.</p>
            </div>{/* eo gr-header */}
          </div>{/* genetic-cause-sec-wrap */}
        </section>{/* end of genetic-cause */}
        {/* end of section-9 blue bg with text*/}
        {/* ******************Useful resource buttons************ */}
        <section className=" useful-resources-sec d-none">
          <div className="container ur-header-wrap ">
            <h2 className="text-center">Useful Resources for you to learn more</h2>
          </div>{/* eo what-you-sec-wrap */}
          <div className="ur-pill-wrap container row ">
            <div className="  pill-1 col-sm col-md-6 col-lg-6 col-xl-3 mb-3 ur-pill">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            <div className="ur-pill pill-2  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            <div className="ur-pill pill-3 col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            <div className="ur-pill pill-4  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            {/*    </div> */}
          </div>{/* eo-ur-pill-wrap */}
        </section>{/* eo of what-you-sec */}
        {/* ******************Endof Useful resource buttons************ */}
        <section className="inherited-accordian-sec mt-0">
          <div className="inherited-accordian-sec-wrap container">
            <div className="inherited-accordian-header">
              <h2 className="mx-auto text-center">Knowledge of increased risk is actionable.</h2>
              <p className="ia-sub-title">Knowing that you have a mutation that increases your risk of developing a cancer allows you and your doctor to create a personalised action plan for better health.</p>
            </div>{/* eo heart-accordian-header */}
           
            <div className="card mt-5 rounded shadow-lg  mb-0 r4 d-none">
              <div className="card-body text-center">
                <div className="card-block  p-2 circle">
                  <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                    <div className="accordion" id="accordionIhc">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h5 className="acc-header collapse-color mb-0">
                            Additional evaluations may be recommended based on your health history
                            <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i className="fa fa-plus-circle  rounded-circle" /></button>
                          </h5>
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                          <div className="card-body">
                            <p>If you have a personal or family history of specific heart conditions, additional evaluations by a cardiologist may be recommended.</p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5 className="acc-header collapse-color mb-0">
                            Heart conditions can be affected by many factors
                            <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseTwo"><i className="fa fa-plus-circle" /></button>
                          </h5>
                        </div>
                        <div id="collapseTwo" className="collapse " aria-labelledby="headingTwo" data-parent="#accordionIhc">
                          <div className="card-body">
                            <p>If you have a personal or family history of specific heart conditions, additional evaluations by a cardiologist may be recommended.</p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="acc-header collapse-color mb-0">
                            A positive result does not mean that you will develop an inherited heart condition
                            <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseThree"><i className="fa fa-plus-circle" /></button>
                          </h5>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionIhc">
                          <div className="card-body">
                            <p>If you have a personal or family history of specific heart conditions, additional evaluations by a cardiologist may be recommended.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* eo bs-example */}
                  {/* end of accordian code */}
                </div>
              </div>{/* eo card-block */}
            </div>{/* eo card body */}
          </div>{/* eo card */}
          {/* eo-heart-accordian-sec-wrap */}
        </section>{/* eo-heart-accordian-sec */}
        {/* ******************End of Genetic Session section************ */}
        {/* ******************are you ready section************ */}
        {/* ******************are you ready section************ */}
        <section className="ayr-sec-main heart-disease-sec-main d-none">
          <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container d-none">
            <div className="intro-text-wrap text-center ">
              <h2 className="text-center ">Are you Ready?</h2>
            </div>{/* eo-intro-text-wrap*/}
            <div className="families-sec heart-disease-sec row container ">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center ">
                <img className="d-none img-fluid mx-auto" src="./img/cancerpage/Illustrationg.png" />
              </div>{/* eo-hd-img */}
              <div className="col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center ">
                <h3>Start your journey to better health</h3>
                <p className="heartdisease-content ">This simple 3-minute self reported risk assessment test will enable you to learn and measure your risks for certain chronic conditions.</p>
                <button type="button" className="btn btn-outline-primary btn-hover-white rounded-pill" >What’s my Risk?<i className="fas fa-long-arrow-alt-right" />
                </button>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************end of are you ready section************ */}
        {/* **********Schedule your Genetic Counseling Session Starts Here************** */}
        <Prospect></Prospect>
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
        <Footer></Footer>
        {/* end of footer */}
      
        {/* ------- sticky form----------------- */}
        
        {/* --------eo sticky form----------------- */}
<MobileFooter></MobileFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
            
        );
    }
}

export default Cancer;