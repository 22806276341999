import React from 'react'
import Footer from './Footer';
import MainHeader from './MainHeader';
import StickyHeader from 'react-sticky-header';
import SubFooter from './SubFooter';
import axios from '../axios/axios';

class VerifyMobile extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      otp:'',
      otpstatus:false,
      email: '',
      phone: '',
      password: '',
      confirmPwd: '',
      errorMsg: null,
      errors: {},
      loginForm: {},
      loginErrors: {},
      passwordType:'',otpmessage:''
    };
    //this.withOTP=this.withOTP.bind(this)
    this.optHandler =this.optHandler.bind(this)
    this.onChange=this.onChange.bind(this)
  }
  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    console.log("OTP",this.state.otp);
  }

  optHandler(){
    if(this.state.otp){
      let email = localStorage.getItem("email");
      console.log("email------>", email)
      let phone = localStorage.getItem("phone");
      console.log("email------>", phone)
      let otp = this.state.otp;
      console.log("email------>", otp)
      axios.post('users/counsellor/otpValidation', {email, phone ,otp})
      .then((result) => {
        localStorage.setItem("email",result.data.email);
        this.props.history.push('/varify-mobile-reset-pwd');
  
      }).catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error")
        } else {
          this.setState({
            errorMsg: error.response.data.message,modalIsOpen:true,otpmessage:"Entered Otp Expired or INCorrect"
          })
        }
      });
   
    }
    else{
      let email = localStorage.getItem("email");
      let phone = localStorage.getItem("phone");
      axios.post('users/resendOtp', { email, phone })
      .then((result) => {
        this.setState({
            modalIsOpen:true,email:result.data.email,phone:result.data.phone,otpmessage:"Please Submit your new Otp"
        })
     
      }).catch(error => {
        if (error.response === undefined) {
          this.props.history.push("/error")
        } else {
          this.setState({
            errorMsg: error.response.data.message,modalIsOpen:true
          })
        }
      });
    }
  }

    
    toResetPwd() {
        // browserHistory.push("/ordertrugene");
          window.location.href="/varify-mobile-reset-pwd";
        }

   render(){
  
        //console.log("email in bnext page.....",localStorage.getItem("email"))
        //console.log("mobile number for next.....",localStorage.getItem("phone"))
       return(
        <div>
       <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/payment.css" />
        <style dangerouslySetInnerHTML={{__html: "\n\ninput[type='radio'] {\n    -webkit-appearance:none;\n    width:15px;\n    height:15px;\n    border:1px solid darkgray;\n    border-radius:50%;\n    outline:none;\n    box-shadow:0 0 5px 0px gray inset;\n}\ninput[type='radio']:hover {\n    box-shadow:0 0 5px 0px lightblue inset;\n}\ninput[type='radio']:before {\n    content:'';\n    display:block;\n    width:60%;\n    height:60%;\n    margin: 20% auto;\n    border-radius:50%;\n}\ninput[type='radio']:checked:before {\n    background:blue;\n}\n\n    " }} />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <StickyHeader
    // This is the sticky part of the header.
    header={
      <div style={{ backgroundColor:"white" }}>
     
     <MainHeader></MainHeader>
        </div>
    }
  >
   
  </StickyHeader>
        <div style={{paddingTop: '8rem'}} />
        <div className=" text-center mb-1 sec-1">
          <div className="container c1 text-center">
            <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Verify Mobile </h1>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="cr-sec-main container mt-5 pt-3  pb-5 mb-2 b1-cr ">
              <div className="head-text">
                <h1 className="py-3 text-center img-fluid roboto-reg-24px">We have sent you an OTP on <br /> {localStorage.getItem("phone")} </h1>
              </div>
              <div className="gc-form-card-btm pt-2">
                <form className="cr-form pt-0 gc-form needs-validation" action method validate>
                  <div className="form-row justify-content-center">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group">
                        <input type="text" name="otp" value={this.state.otp} onChange={this.onChange} maxLength="4" className="form-control cr-email m-0" placeholder="Please enter 4 digit OTP here to verify " aria-describedby="validationTooltipEmailPrepend" required />
                        <div className="invalid-tooltip">
                          Please enter 4 digit OTP here to verify
                        </div>
                        
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="validationTooltipEmailPrepend"><img className="d-none" src="img/homepage/i3.png" /></span>
                        </div>
                        <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center px-5" style={{height: '60px', width: '210px', borderRadius: '1.875rem', color: 'white', background: '#4f5be7'}} type="button" disabled={this.state.otp.length>0?true:false} onClick={this.optHandler} >
                    <b>RESEND OTP</b></button>
                      </div>
                      <div style={{ color: 'red', fontSize: '12px' }}>Otp field must be empty to resend Otp</div>
                      <div style={{ color: 'red', fontSize: '12px' }}>{this.state.otpmessage}</div>
                    </div>
                  </div>
                  {/* end of form-row */}
                  <div className="row d-flex justify-content-center px-3 pt-3">
                  
                  
                </div>

                </form>
              </div>{/* end of gc-form-card-btm */}
              <div className="after-form-sec">
                {/*Grid column*/}
                <div className="row d-flex justify-content-center px-3 pt-3">
                  <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center px-5" style={{height: '60px', width: '210px', borderRadius: '1.875rem', color: 'white', background: '#4f5be7'}} type="submit" disabled={this.state.otp.length===4?false:true} onClick={this.optHandler} >
                    <b>Submit</b></button>
                </div>
              </div>{/* end of after-form-sec */}
            </div>{/* end of cr-sec-main */}
            <div className="container text-center mt-5 pb-5">
              <img className="plus-button img-fluid mb-3" src="./img/registration/Icon.png" />
              <p className=" text-center  " style={{color: '#84888e'}}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a className="pb-5 cr-1" href=""><strong>CLICK HERE</strong></a> to create your account</p>
            </div>
          </div>
        </div>
        <div style={{paddingTop: '12.25rem'}}>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        <SubFooter></SubFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
       )
   }

}
export default VerifyMobile;