import React from 'react';
import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import CarouselCards2 from './CarouselCards2';
import CancerCarouselCards3 from './CancerCarouselCards3';
import StickyContactMenu from './StickyContactMenu';



class FamilyPlanning extends React.Component {
    constructor(props) {
      super(props);
    }
    toBookSession(){
      window.location.href="/book-counselling"
    }
    toOrder(){
      window.location.href="/product-page"
    }
    render() {
return(<div>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
    <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
    {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
    <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
    <title>truGeny</title>
    <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
    <link rel="stylesheet" href="./css/header_footer.css" />
    <link rel="stylesheet" href="./css/stylesheet_custom.css" />
    <link rel="stylesheet" href="stylesheet_custom.css" />
    <link rel="stylesheet" href="./css/fonts.css" />
  
    
    <style dangerouslySetInnerHTML={{__html: "\n.newww{\n  font-weight:400;\n}\n.career-banner-sec-wrap .career-banner-sec .container p{\n  font-size: 1.5rem;\n    padding-bottom: 1px;\n    color: #2c333c;\n    font-weight: 300;\n    max-width: 640px;\n    line-height: 30px;\n}\n.custom-size {\n  height:450px!important;\n}\n.careerdisease-content{\n  font-family: 'Roboto', sans-serif;\n    font-size: 1.125rem;\n    font-weight: 400;\n    text-align: left;\n    padding-top: 15px;\n    margin: 0;\n}\n.carriercard .card-text\n{\nfont-size:1.125rem;\nfont-weight:400;\n}\n.font-nav{\n  padding-right:15px;\nfont-size:15px;\ncolor:gray;\n/* font-weight:300; */\n\n}\n\n.img-11{\n  height:48px;\n  /* width:120px; */\n}\n.order-btn, .she-btn{\n  padding-top: 5px;\n}.nav-tab-1{\n    padding-top:5px!important;\n    padding-bottom:5px!important;\n    color:#FF3C3C;\n    font-size:15px;\n\n}\n@media (max-width: 767.98px) {\n  .career-banner-sec-wrap .career-banner-sec .container p{\n    font-size: 14px;\n          padding-bottom: 1px;\n      color: #2c333c;\n      font-weight: 300;\n      max-width: 640px;\n      /* line-height: 14px; */\n  }\n  .font-nav{\n    padding-right:4px;\n  font-size:10px;\n  color:gray;\n\n  }\n  .nav-tab-1{\n    /* margin-bottom: :16rem; */\n      padding-top:3px!important;\n      padding-bottom:3px!important;\n      color:#FF3C3C;\n\n  }\n   }\n   .nav-active1{\n     font-weight:700;\n     color:#4f5be7;\n   }\n\n   @media only screen and (max-width: 576px){\n.career-banner-sec-wrap .career-banner-sec .container {\n    /* height: 40vh; */\n    /* margin-left: 0!important; */\n        /* padding-top: 0px!important; */\n}\n}\n" }} />
    <link rel="stylesheet" href="./css/sticky.css" />
    <link rel="stylesheet" href="./style-mobile2.css" />
    <link rel="stylesheet" href="./style-mobile.css" />
    <link rel="stylesheet" href="newsticky.css" />
   
    <NewMainHeader category="familyPlanning"></NewMainHeader>
    <StickyContactMenu ></StickyContactMenu>

    <div style={{paddingBottom: '76px'}} className="padding2" />
    {/* ========================== New header end ========================= */}
    <section className="career-banner-sec-wrap banner-position">
      <div className=" career-banner-sec ">
        <div className="container pt-4 mt-2">
        <div className="familyBanner">
          <h1 className="p"><strong>Planning for your future family’s health matters.</strong></h1>
        
          <p className="text-justify mt-3 mb-1">Knowledge about your risk of passing on a genetic condition to your Child can make a difference to the well being of your future family.</p>
          <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0 text-uppercase mx-auto px-auto" style={{border: '1px solid #4f5be7', width: '12.5rem', fontSize: '14px', color: 'white', backgroundColor: '#4f5be7'}} onClick={this.toBookSession}><b>Schedule Now</b></button>
        
          </div>
        </div>{/* eo-container*/}
        <div className="d-flex justify-content-center" id="sech1">
          <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#sech1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
        </div>
      </div>{/* eo-heart-banner-sec */}
    </section>{/* eo-heart-banner-sec-wrap */}
    {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
    <section className="career-disease-sec-main">
      <div className="career-disease-sec-main-wrap container">
        <div className="intro-text-wrap text-center">
          <h2 className="text-center py-5">Thinking of starting a family?</h2>
        </div>{/* eo-intro-text-wrap*/}
        <div className=" career-disease-sec row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 d-block text-center ">
            <img className="img-fluid mx-auto p-5" src="./img/career/Illustration1.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
            <h3>All of us inherit genes from our parents</h3>
            <p className="careerdisease-content">Our DNA contains two copies of every gene — one inherited from the mother and one from the father. Genes are responsible for passing along family characteristics like hair, eye color, height and so on.<br /><br />
              Sometimes they also pass on inherited conditions such as thalassemia, cystic fibrosis, sickle cell anaemia, spinal muscular atrophy and so on.</p>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
        <div className="families-sec career-disease-sec row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
            <h3>A carrier has a 50% chance of passing
              on the same mutation to the child.</h3>
            <img className="img-fluid mx-auto p-5" src="./img/career/Illustration22222.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
            <h3>CARRIER</h3>
            <p className="careerdisease-content">A carrier is an individual who carries a genetic mutation associated with a disease. While the individual may not display disease symptoms and is therefore unaware that they have a gene for a disorder, he or she may pass on this mutation to their children.</p>
            <p className="heartdisease-content pt-lg-5 pt-sm-1 "><strong>An abnormal change in a gene is known as a mutation.</strong></p>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
        <div className=" career-disease-sec row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 d-block text-center ">
            <img className="img-fluid mx-auto p-5" src="./img/career/Illustration3.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
            <h3>NO SYMPTOMS</h3>
            <p className="careerdisease-content">It can be worrisome for couples planning to have a baby, because a carrier doesn’t necessarily know if he or she carries a genetic condition, as there may be no symptoms of the disease.</p>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
      </div>{/* eo-heart-disease-sec-main-wrap*/}
    </section>{/* eo-heart-disease-sec-main */}
    {/* Start of lifestyle factors cards  */}
    <div className="life-factors-main-sec d-none">
      <div className="container life-factors-main-wrap">
        <div className="container life-factors-card-main-wrap mx-auto mt-5  mb-5">
          <div className="card-deck" id="hide">
            <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
              <div className="card rounded shadow-lg  mb-4 r4  ">
                <div className="card-body text-center ">
                  <div className="card-block  p-2 ">
                    <img className="card-img img-fluid text-center p-2 " src="heart-img/obsity-01.png" alt="Card image cap" />
                  </div>
                  <p className="card-text">Obesity</p>
                </div>{/* eo-card-body */}
              </div>{/* eo-card */}
            </div>{/* eo-card-deck-wrap */}
            <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
              <div className="card rounded shadow-lg mb-4 r4 ">
                <div className="card-body text-center">
                  <div className="card-block p-2 ">
                    <img className="card-img text-center p-2" src=" heart-img/physical-activity-01.png" alt="Card image cap" />
                  </div>
                  <p className="card-text">Physical Inactivity</p>
                </div>
              </div>
            </div>{/* eo-card-deck-wrap */}
            <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
              <div className="card rounded shadow-lg  mb-4 r4 ">
                <div className="card-body text-center">
                  <div className="card-block  p-2  ">
                    <img className="card-img text-center  p-2" src="heart-img/smoking-drinking-01.png" alt="Card image cap" />
                  </div>
                  <p className="card-text">Smoking &amp; Drinking</p>
                </div>
              </div>
            </div>{/* eo-card-deck-wrap */}
            <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
              <div className="card rounded shadow-lg mb-4 r4  ">
                <div className="card-body text-center">
                  <div className="card-block p-2 ">
                    <img className="card-img text-center  p-2 " src="heart-img/unhealthy-diet.png" alt="Card image cap" />
                  </div>
                  <p className="card-text">Unhealthy Diet</p>
                </div>
              </div>{/* eo card */}
            </div>{/* eo-card-deck-wrap */}
          </div>{/* eo-card-deck */}
          {/* =========================================================== */}
          <div id="carouselExampleControls" className="carousel slide" data-ride="false">
            <div className="carousel-inner new">
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard " style={{height: '260px !important'}}>
                    <div className="card-body text-center ">
                      <div className="card-block  p-2 ">
                        <img className="card-img img-fluid text-center p-2 " src="heart-img/obsity-01.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Obesity</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
              </div>
              <div className="carousel-item">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard" style={{height: '260px !important'}}>
                    <div className="card-body text-center">
                      <div className="card-block p-2 ">
                        <img className="card-img text-center p-2" src=" heart-img/physical-activity-01.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Physical Inactivity</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
              </div>
              <div className="carousel-item">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard " style={{height: '260px !important'}}>
                    <div className="card-body text-center">
                      <div className="card-block  p-2  ">
                        <img className="card-img text-center  p-2" src="heart-img/smoking-drinking-01.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Smoking &amp; Drinking</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
              </div>
              <div className="carousel-item">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded newcard" style={{height: '260px !important'}}>
                    <div className="card-body text-center">
                      <div className="card-block p-2 ">
                        <img className="card-img text-center  p-2 " src="heart-img/unhealthy-diet.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Unhealthy Diet</p>
                    </div>
                  </div>{/* eo card */}
                </div>{/* eo-card-deck-wrap */}
              </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
          {/* ======================================================== */}
        </div>{/*eo-container */}
      </div>{/* gc-help-main-wrap */}
    </div>{/* gc-help-main-sec */}
    {/* end of lifestyle factors cards  */}
    {/* Genes can influence section starts here */}
    <section className="heart-disease-sec-main d-none">
      <div className="heart-disease-sec-main-wrap container">
        <div className="families-sec heart-disease-sec p-0 row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center">
            <img className="img-fluid mx-auto" src="heart-img//Illustration-2.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
            <p className="heartdisease-content">Genes can influence your risks for heart disease.</p>
            <p className="heartdisease-content">Every aspect of your heart system is controlled by your genes. A single genetic variation in a single gene can affect your risks for developing a heart disease. Genetic variations are passed on from parents to children.</p>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
      </div>{/* eo-heart-disease-sec-main-wrap*/}
    </section>{/* eo-heart-disease-sec-main */}
    {/* Genes can influence section ends here */}
    {/* /************genetic risks starts here*************** / */}
    <section className=" gr-sec mb-5 pb-5">
      <div className="container gr-sec-wrap">
        <div className="gr-header">
          <h2 className="text-center">
            TAKE CONTROL.<br />
            As a couple, learning about your carrier status can help you
            plan better for your future family.
          </h2>
        </div>{/* eo gr-header */}
        <div className="container gr-card-main-wrap pt-5 mt-5  mb-5 d-none">
          <div className="row" id="hide">
            <div className="text-center  col-12 col-md-6 col-lg-4">
              <div className="card custom-size  card-1 py-5 px-4 shadow-lg r4 border-0 mb-5">
                <div className="card-body p-0 ">
                  <div className="card-top ">
                    <img className="img-fluid pt-1" src="img/risk-page/tq/33heartcard.png" />
                    <p className="card-title roboto-black-18px pt-4">Some Heart Conditions are caused due to genetics</p>
                  </div>{/* eo-card-top */}
                  <div className="card-btm pt-4">
                    <p className="card-text">Genetic factors impact every 1 in 200 people. These heart conditions, if left undetected can be life- threatening.</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-body */}
              </div>{/* eo-card-1 */}
            </div>{/* eo-ns-card-wrap */}
            <div className="text-center  col-12 col-md-6 col-lg-4 bg-white">
              <div className="card card-2 custom-size py-5 px-4 shadow-lg r4 border-0 mb-5">
                <div className="card-body p-0 ">
                  <div className="card-top ">
                    <img className="img-fluid py-1" src="img/risk-page/tq/33heartcard.png" />
                    <p className="card-title roboto-black-18px pt-4">With early detection most inherited heart conditions can be managed successfully</p>
                  </div>{/* eo-card-top */}
                  <div className="card-btm pt-2">
                    <p className="card-text">Knowing your risk early can help you and your Doctor, to create a personalized plan with regular screenings to prevent heart disease.</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-body */}
              </div>{/* eo-card-2 */}
            </div>{/* eo-ns-card-wrap */}
            <div className="text-center  col-12 col-md-6 col-lg-4">
              <div className="card card-3 custom-size  py-5 px-4 shadow-lg r4 border-0 mb-5">
                <div className="card-body p-0">
                  <div className="card-top ">
                    <img className="img-fluid py-1" src="img/risk-page/tq/33heartcard.png" />
                    <p className="card-title roboto-black-18px pt-4">Learning about your heart can help your family.</p>
                  </div>{/* eo-card-top */}
                  <div className="card-btm pt-4">
                    <p className="card-text">Inherited heart conditions are passed from one generation to the next and run in families. Learning about your risks can help your family take proactive actions to keep their own hearts healthier.</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-body */}
              </div>{/* eo-card-3 */}
            </div>{/* eo-ns-card-wrap */}
          </div>{/* eo-row */}
        </div>{/* gr-card-wrap */}
        {/* ==================================================== */}
        <div id="carouselExampleControls1" className="d-none" data-ride="false" >
          <div className="carousel-inner new">
            <div className="carousel-item active">
              <div className="text-center  col-12 col-md-6 col-lg-4">
                <div className="card newcard" style={{height: '333px !important'}}>
                  <div className="card-body p-2 ">
                    <div className="card-top ">
                      <img className="img-fluid pt-5" src="img/risk-page/tq/33heartcard.png" style={{height: '100px'}} />
                      <p className="card-title roboto-black-18px pt-4">Some Heart Conditions are caused due to genetics</p>
                    </div>{/* eo-card-top */}
                    <div className="card-btm pt-4">
                      <p className="card-text pb-3">Genetic factors impact every 1 in 200 people. These heart conditions, if left undetected can be life- threatening.</p>
                    </div>{/* eo-card-btm */}
                  </div>{/* eo-card-body */}
                </div>{/* eo-card-1 */}
              </div>{/* eo-ns-card-wrap */}
            </div>
            <div className="carousel-item">
              <div className="text-center  col-12 col-md-6 col-lg-4 bg-white">
                <div className="card card-2 newcard" style={{height: '333px !important'}}>
                  <div className="card-body p-2 ">
                    <div className="card-top ">
                      <img className="img-fluid pt-5" src="img/risk-page/tq/33heartcard.png" style={{height: '100px'}} />
                      <p className="card-title roboto-black-18px pt-4">With early detection most inherited heart conditions can be managed successfully</p>
                    </div>{/* eo-card-top */}
                    <div className="card-btm pt-2">
                      <p className="card-text pb-3">Knowing your risk early can help you and your Doctor, to create a personalized plan with regular screenings to prevent heart disease.</p>
                    </div>{/* eo-card-btm */}
                  </div>{/* eo-card-body */}
                </div>{/* eo-card-2 */}
              </div>{/* eo-ns-card-wrap */}
            </div>
            <div className="carousel-item">
              <div className="text-center  col-12 col-md-6 col-lg-4">
                <div className="card card-3 newcard" style={{height: '333px !important'}}>
                  <div className="card-body p-2">
                    <div className="card-top ">
                      <img className="img-fluid pt-5" src="img/risk-page/tq/33heartcard.png" style={{height: '100px'}} />
                      <p className="card-title roboto-black-18px pt-4">Learning about your heart can help your family.</p>
                    </div>{/* eo-card-top */}
                    <div className="card-btm pt-4">
                      <p className="card-text pb-3">Inherited heart conditions are passed from one generation to the next and run in families. Learning about your risks can help your family take proactive actions to keep their own hearts healthier.</p>
                    </div>{/* eo-card-btm */}
                  </div>{/* eo-card-body */}
                </div>{/* eo-card-3 */}
              </div>{/* eo-ns-card-wrap */}
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>
        {/* ==================================================== */}
        <div className="learn-btn">
          {/* <p class="gc-title" href="#gc-title">Schedule my appointment</p> */}
          {/* <a  class=" gc-button gc-link-bg" href="#gc-link">Schedule my appointment</a> */}
          <p className="gc-title roboto-reg-18px pt-5" href="#gc-title">I want to learn More</p>
          <div className="she-btn text-center mx-auto">
            <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toBookSession} role="button" style={{background: '#4f5be7', color: 'white'}}>Schedule my Genetic counselling appointment</a>
          </div>
        </div>
      </div>{/* eo gr-sec-wrap */}
    </section>{/*eo gr-sec */}
    {/* ******************end of genetic risks************ */}
    {/* ******************Heart conditions************ */}
    <section className="heart-disease-sec-main">
      <div className="heart-disease-sec-main-wrap container">
        <div className="families-sec heart-disease-sec p-0 row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center order-md-2">
            <img className="img-fluid mx-auto p-5" src="img/career/Illustration6.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
            <h3>80% of babies with genetic diseases are born to parents with no known family history of that disease.</h3>
            <p className="heartdisease-content">Undergoing early genetic screening is an important step in planning for a healthy family.</p>
            <p className="heartdisease-content">
              Genetic screening allows you to take control and make better choices about your future family.</p>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
      </div>{/* eo-heart-disease-sec-main-wrap*/}
    </section>{/* eo-heart-disease-sec-main */}
    <section className=" gr-sec my-lg-1 my-sm-5 py-5">
      <div className="container heart-disease-sec">
        <div className="gr-header">
          <h2 className="text-center new2">
            Be Proactive
          </h2>
          <p className="heartdisease-content text-center">Know your risk to take ACTION.</p>
          <h2 className="newww">It’s important to KNOW.</h2>
          <p className="heartdisease-content text-center">As a  prospective parent, learning about your risk of passing on a genetic disease to your future offspring before pregnancy will provide you more time with wider range of options to make an informed decision.</p>
        </div>{/* eo gr-header */}
      </div>
    </section>
    <div className="life-factors-main-sec2 py-5">
      <div className="container life-factors-main-wrap">
        <div className="container life-factors-card-main-wrap mx-auto  mb-5">
          <div className="box">
            <div className="row ">
              <div className="col-sm-6">
                <img src="./img/career/Box.png" className="img-fluid p-5" />
              </div>
              <div className="col-sm-6">
                <h3 className="pt-5 newww"><b>truFamily</b> <br />Carrier screening test</h3>
                <p>truFamily carrier screening test will help you to find out if you or your spouse are carrying genetic variants that can pose a risk of having a child with a genetic condition.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="learn-btn">
                  {/* <p class="gc-title" href="#gc-title">Schedule my appointment</p> */}
                  {/* <a  class=" gc-button gc-link-bg" href="#gc-link">Schedule my appointment</a> */}
                  <p className=" roboto-reg-18px " href="#gc-title">I want to learn More</p>
                  <div className=" text-center mx-auto">
                    <a className="mb-3 mt-2 btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toBookSession} role="button" style={{background: '#4f5be7', color: 'white'}}>Schedule my Genetic counselling appointment</a>
                  </div>
                </div></div>
            </div>
          </div>
        </div>{/*eo-container */}
      </div>{/* gc-help-main-wrap */}
    </div>{/* gc-help-main-sec */}
    <section className=" gr-sec mt-5 pt-5">
      <div className="container heart-disease-sec">
        <div className="gr-header">
          <h2 className=" new2">
            Who will benefit from truFamily Carrier Screening Test?
          </h2>
          <div className="container gr-card-main-wrap pt-5 mt-5  mb-5">
            <div className="row">
              <div className="text-center  col-12 col-md-6 col-lg-6">
                <div className="card custom-size  card-1 shadow-lg r4 border-0 mb-5">
                  <div className="card-top carriercard1">
                    <p className="card-title roboto-black-18px pt-4 pb-3">Starting a Family</p>
                  </div>{/* eo-card-top */}
                  <div className="card-body pt-5">
                    <div className="card-img-middle text-center">
                      <img src="img/career/Vector_Smart_Object.png" style={{width: '175px', height: '224px'}} />
                    </div>
                  </div>
                  <div className="card-btm py-4 px-4 carriercard">
                    <p className="card-text py-2">Couples currently planning a pregnancy</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-1 */}
              </div>{/* eo-ns-card-wrap */}
              <div className="text-center  col-12 col-md-6 col-lg-6 bg-white">
                <div className="card custom-size  card-1 shadow-lg r4 border-0 mb-5">
                  <div className="card-top carriercard1 ">
                    <p className="card-title roboto-black-18px pt-4 pb-3">Increased Risk</p>
                  </div>{/* eo-card-top */}
                  <div className="card-body pt-5">
                    <div className="card-img-middle text-center">
                      <img src="img/career/Vector_Smart_Object2.png" style={{width: '175px', height: '224px'}} />
                    </div>
                  </div>
                  <div className="card-btm py-4 carriercard">
                    <p className="card-text py-2">Individuals with a family history of
                      genetic disorders</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-1 */}
              </div>{/* eo-ns-card-wrap */}
            </div>{/* eo-row */}
            <div className="row">
              <div className="text-center  col-12 col-md-6 col-lg-6">
                <div className="card custom-size  card-1 shadow-lg r4 border-0 mb-5">
                  <div className="card-top carriercard1">
                    <p className="card-title roboto-black-18px pb-3 pt-4">Prior History</p>
                  </div>{/* eo-card-top */}
                  <div className="card-body pt-5">
                    <div className="card-img-middle text-center">
                      <img src="img/career/Vector_Smart_Object444.png" />
                    </div>
                  </div>
                  <div className="card-btm py-4 carriercard">
                    <p className="card-text py-2">Couples with history of
                      pregnancy losses</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-1 */}
              </div>{/* eo-ns-card-wrap */}
              <div className="text-center  col-12 col-md-6 col-lg-6">
                <div className="card custom-size  card-1 shadow-lg r4 border-0 mb-5">
                  <div className="card-top carriercard1 ">
                    <p className="card-title roboto-black-18px pt-4 pb-3">Consanguineous Marriage</p>
                  </div>{/* eo-card-top */}
                  <div className="card-body pt-5 mt-4">
                    <div className="card-img-middle text-center">
                      <img src="img/career/Vector_Smart_Object44.png" />
                    </div>
                  </div>
                  <div className="card-btm py-4 carriercard">
                    <p className="card-text py-2">Individuals married to
                      a close relative</p>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-1 */}
              </div>{/* eo-ns-card-wrap */}
            </div>
          </div>{/* gr-card-wrap */}
        </div>{/* eo gr-header */}
      </div>
    </section>
    <section className=" gr-sec mt-5 pt-5">
      <div className="container heart-disease-sec">
        <div className="gr-header">
          <h2 className="text-center mb-5 pb-5 new2">
            truFamily carrier screening helps screen your genes to see if
            you are a carrier for over 250 genetic conditions
          </h2>
          <h2 className="text-center mb-5 pb-5">
            <img src="./img/career/Vector_Smart_Objectnew.png" className="img-fluid " /></h2>
        </div>
      </div>
    </section>
    {/* ******************End of Heart conditions************ */}
    {/* ****************** Heart conditions accordian************ */}
    <section className="heart-accordian-sec d-none ">
      <div className="heart-accordian-sec-wrap container ">
        <div className="heart-accordian-header">
          <h2 className="mx-auto text-center">Some of the inherited heart conditions include</h2>
        </div>{/* eo heart-accordian-header */}
        <div className="card mt-5 rounded shadow-lg  mb-0 r4 ">
          <div className="card-body text-center">
            <div className="card-block  p-2 circle">
              <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                <div className="accordion" id="accordionHc">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="acc-header collapse-color mb-0 text-uppercase">
                        Arrythmia
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i className="fa fa-plus-circle  rounded-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionHc">
                      <div className="card-body">
                        <a style={{color: 'black'}} href="#">An arrhythmia is a disorder that affects the normal heart rate. With an arrhythmia, the heart tends to beat too slow or too fast or irregularly. These disorders can affect the amount of blood pumped by the heart.</a>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="acc-header collapse-color mb-0 text-uppercase">
                        Coronary Artery Diseases
                        <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseTwo"><i className="fa fa-plus-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse " aria-labelledby="headingTwo" data-parent="#accordionHc">
                      <div className="card-body">
                        <a style={{color: 'black'}} href="#">An arrhythmia is a disorder that affects the normal heart rate. With an arrhythmia, the heart tends to beat too slow or too fast or irregularly. These disorders can affect the amount of blood pumped by the heart.</a>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="acc-header collapse-color mb-0  text-uppercase">
                        Caridiomyopathy
                        <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseThree"><i className="fa fa-plus-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionHc">
                      <div className="card-body">
                        <a style={{color: 'black'}} href="#">Cardiomyopathy is a disease of the heart muscle. Cardiomyopathy makes it harder for your heart to pump blood to the rest of your body and could lead to heart failure. The two main types of cardiomyopathy are dilated, hypertrophic and restrictive cardiomyopathy.</a>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h5 className="acc-header collapse-color mb-0 text-uppercase">
                        FAMILIAL HYPER CHOLESTEROLEMIA (FH)
                        <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseFour"><i className="fa fa-plus-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionHc">
                      <div className="card-body">
                        <a style={{color: 'black'}} href="#">Familial hypercholesterolemia (FH) is a genetic disorder that is passed down through families. The condition begins at birth and can cause heart attacks at an early age. Familial hypercholesterolemia (FH) causes LDL (bad) cholesterol level to be very high.
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/* eo bs-example */}
              {/* end of accordian code */}
            </div>
          </div>{/* eo card-block */}
        </div>{/* eo card body */}
      </div>{/* eo card */}
      {/* eo-heart-accordian-sec-wrap */}
    </section>{/* eo-heart-accordian-sec */}
    {/* ******************End of Heart conditions accordian************ */}
    {/* ****************** Be proactive Sec************ */}
    <section className="heart-disease-sec-main d-none">
      <div className="heart-disease-sec-main-wrap container">
        <div className="families-sec p-0 heart-disease-sec row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center">
            <img className="img-fluid mx-auto" src="heart-img/Illustration-6.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center sec6">
            <h3><strong> BE PROACTIVE.<br />Know your risk to take ACTION</strong></h3>
            <p className="heartdisease-content font-weight-bold pb-4">It’s important to KNOW.<br />Learning about your inherited heart condition risks help you take actions.</p>
            <ul >
              <li>Allows you and your Doctor to create a personalised plan and make informed decisions about prevention.</li>
              <li>For certain conditions, it enables your Doctor to prescribe medicines to lower your risks for a heart condition.</li>
              <li>Your results can help your family members understand their inherited heart condition risks.</li>
              <li>Knowing about risks and whether you have a faulty gene or not can reduce stress and worry</li>
            </ul>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
      </div>{/* eo-heart-disease-sec-main-wrap*/}
    </section>{/* eo-heart-disease-sec-main */}
    {/* ******************End of Be proactive sec ************ */}
    {/* ******************Benifits-sec ************ */}
    <section className="heart-disease-sec-main">
      <div className="heart-disease-sec-main-wrap container">
        <div className="families-sec p-0 heart-disease-sec row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center order-md-2">
            <img className="img-fluid mx-auto" src="heart-img/Illustration-7.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1 sec6">
            <h3><strong> Benefits of truGeny &amp;<br />Reliability of our test</strong></h3>
            <p className="heartdisease-content">Conversation FIRST - Our expert genetic counsellor will assess your family history and risk factors, and determine if genetic testing is right for you and ensure that you are receiving the most appropriate test.</p>
            <p className="heartdisease-content font-weight-bold pb-4">A highly reliable test.</p>
            <ul >
              <li>We don’t just look at snippets of your DNA. We do a thorough analysis of your genes.</li>
              {/* <li>We look at over 90 Genes that are recognised by</li> */}
              <li>Our reports are actionable that can be shared with your Doctor for further planning.</li>
            </ul>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
      </div>{/* eo-heart-disease-sec-main-wrap*/}
    </section>{/* eo-heart-disease-sec-main */}
    {/* <div class="container be-proactive-sec benifits-sec sec6"  >
  <div class="row">

    <div class="container benifits-text be-proactive-text col-sm "  >
      <h3><strong> Benefits of truGeny &
      </br>Reliability of our test</strong></h3>

      <p class="imp-text-1" > Conversation FIRST - Our expert genetic counsellors will assess your family history and risk factors, and determine if genetic testing is right for you and ensure that you are receiving the most appropriate test.</p>

      <p class="imp-text-2 " > A highly reliable test.</p>
      <ul class="">
        <li >We don’t just look at snippets of your DNA. We do a thorough analysis of your genes.</li>
        <li>We look at over 90 Genes that are recognised by</li>
        <li>Our reports are actionable that can be shared with your Doctor for prevention and understanding of treatment options for better health.</li>
      </ul>
    </div> */}{/* eo be-proactive-text */}
    {/* <div class="container be-proactive-img-wrap benifits-img-wrap col-sm"   >
      <img class="center img-fluid "src="heart-img/Illustration-7.png"/>
    </div>
  </div>
</div> */}{/* eo be-proactive-sec */}
    {/* ******************end of Benifits-sec ************ */}
    {/* ******************what you get-sec************ */}
    <section className="our-process-sec mt-5 pt-5">
      <div className="container our-process-sec-header ">
        <h2 className="text-center">What do you GET?</h2>
        <p className="punchline">It starts with a conversation</p>
      </div>{/* eo what-you-sec-wrap */}
      <div className="container op-card-wrap text-center">
        <div className="card-deck" id="hide">
          <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
            <div className="card card-1 rounded  shadow-lg p-1 mx-0 mb-5 r4">
              <div className="card-body  text-center ">
                <span className="card-number op-no-1">1</span>
                <div className=" op-img-box  ">
                  <span className="op-img-wrap ">
                    <img className="card-img-top text-center" src="heart-img/calender-icon.png" alt="Card image cap" />
                  </span>
                </div>
                <h5 className="card-title">Schedule An Appointment</h5>
                <p>It’s fast and easy to do online, and your personal information will always be kept confidential and protected</p>
              </div>
            </div>{/* eo card */}
          </div>{/* eo splitter */}
          <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card card-2 rounded shadow-lg p-1 mx-0 mb-5 r4">
              <div className="card-body  text-center ">
                <span className="card-number op-no-2">2</span>
                <div className=" op-img-box">
                  <span className="op-img-wrap">
                    <img className="card-img-top text-center" src="heart-img/call-out-icon.png" alt="Card image cap" />
                  </span>
                </div>
                <h5 className="card-title">Initial consultation </h5>
                <p>We review your personal health history, answer your questions, and, if you are interested, help you select and order the right tests</p>
              </div>
            </div>{/* eo card */}
          </div>{/* eo splitter */}
          <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
            <div className="card card-3 rounded  shadow-lg p-1 mx-0 mb-5 r4">
              <div className="card-body  text-center">
                <span className="card-number op-no-3">3</span>
                <div className=" op-img-box">
                  <span className="op-img-wrap">
                    <img className="card-img-top text-center" src="heart-img/sample-collection.png" alt="Card image cap" />
                  </span>
                </div>
                <h5 className="card-title">sample collection</h5>
                <p>If you decide to have testing, we will ship you a collection kit or schedule a blood draw at a time and place convenient to you or your family</p>
              </div>
            </div>{/* eo card */}
          </div>{/* eo splitter */}
          <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card card-4 rounded  shadow-lg p-1 mx-0 mb-5 r4">
              <div className="card-body  text-center">
                <span className="card-number op-no-4">4</span>
                <div className=" op-img-box  ">
                  <span className="op-img-wrap">
                    <img className="card-img-top text-center" src="heart-img/results-icon.png" alt="Card image cap" />
                  </span>
                </div>
                <h5 className="card-title">results consultation</h5>
                <p>A genetic counselor reviews your test results, walks you through their implications in a telehealth session, and answers your questions</p>
              </div>
            </div>{/* eo card */}
          </div>{/* eo splitter */}
        </div>{/* eo wyg-card-deck */}
        {/* ============================= */}

        <CarouselCards2></CarouselCards2>
        </div>
    </section>{/* eo of what-you-sec */}
    {/* ******************End of what you get-sec************ */}
    {/* Got to Know starts here */}
    <section className="gtk-sec ">
      <div className="container gtk-sec-wrap pb-0 gr-sec-wrap">
        <div className="gtk-main-header-wrap">
          <h2 className="text-center">
            I want to learn about  my risks of passing on genetic disorders to my future children.
          </h2>
        </div>{/* eo gr-header */}
        <div className="container gtk-card-main-wrap pt-5 mt-5  mb-5">
          <div className="row" id="hide">
            <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
              <div className=" card-1 shadow-lg r4 border-0 mb-5">
                <div className="card-body p-0">
                  <div className="card-top">
                    <div className="text-center py-5">
                      <img className="img-fluid mx-auto" src="./img/cancerpage/learnIllustration-44.png" />
                    </div>{/* eo-hd-img */}
                  </div>{/* eo-card-top */}
                  <div className="card-btm">
                    <p className="card-text"><b>Schedule my Genetic Counselling Appointment</b></p>
                    <div className="she-btn text-center mx-auto">
                      <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toBookSession} role="button">Schedule Now</a>
                    </div>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-body */}
              </div>{/* eo-card-1 */}
            </div>{/* eo-ns-card-wrap */}
            <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
              <div className=" card-1 shadow-lg r4 border-0 mb-5">
                <div className="card-body p-0">
                  <div className="card-top">
                    <div className="text-center py-5">
                      <img className="img-fluid mx-auto" src="./img/cancerpage/Box-1.png" />
                    </div>{/* eo-hd-img */}
                  </div>{/* eo-card-top */}
                  <div className="card-btm">
                    <p className="card-text"><b>Order my truFamily Test Kit</b></p>
                    <div className="order-btn questions-btn text-center mx-auto mt-0">
                      <a className=" btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toOrder} role="button"> Order Now</a>
                    </div>
                  </div>{/* eo-card-btm */}
                </div>{/* eo-card-body */}
              </div>{/* eo-card-1 */}
            </div>{/* eo-ns-card-wrap */}
          </div>{/* eo-row */}
          {/* ============================================ */}
          <div className="row" style={{display: 'block'}}>
          <CancerCarouselCards3></CancerCarouselCards3>

         </div>
        </div>{/* gr-card-wrap */}
      </div>{/* eo gr-sec-wrap */}
    </section>{/*eo gr-sec*/}
    {/* end of Got to Know */}
    {/* ****************** Inherited Heart conditions accordian************ */}
    <section className="inherited-accordian-sec d-none">
      <div className="inherited-accordian-sec-wrap container">
        <div className="inherited-accordian-header">
          <h2 className="mx-auto text-center">Knowledge of increased risk is actionable.</h2>
          <p className="ia-sub-title">Knowing that you have a mutation that increases your risk of developing heart disease allows you and your Doctor to create a personalized action plan, to prevent the likelihood of a heart attack</p>
        </div>{/* eo heart-accordian-header */}
        <div className="heart-accordian-sub-header">
          <h4 className="mx-auto text-center">Inherited Heart Conditions</h4>
        </div>{/* eo heart-accordian-header */}
        <div className="card mt-5 rounded shadow-lg  mb-0 r4 ">
          <div className="card-body text-center">
            <div className="card-block  p-2 circle">
              <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                <div className="accordion" id="accordionIhc">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="acc-header collapse-color mb-0 text-uppercase">
                        Additional evaluations may be recommended based on your health history
                        <button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i className="fa fa-plus-circle  rounded-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>If you have a personal or family history of specific heart conditions, additional evaluations by a cardiologist may be recommended.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="acc-header collapse-color mb-0 text-uppercase">
                        Heart conditions can be affected by many factors
                        <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseTwo"><i className="fa fa-plus-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="collapse " aria-labelledby="headingTwo" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>Even if your report shows that there are no mutations, there are chances you may still develop an inherited heart condition.  Factors like lifestyle, environment, do influence and could increase the risk for cardiac events.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="acc-header collapse-color mb-0 text-uppercase">
                        Getting a positive result does not mean that you will necessarily develop an inherited heart condition.
                        <button type="button" className="float-right btn btn-link" data-toggle="collapse" data-target="#collapseThree"><i className="fa fa-plus-circle" /></button>
                      </h5>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>Having a mutation in one of the evaluated genes does not mean that you will develop  an inherited heart condition. However, by knowing that you have a mutation, will help you and your Doctor to work out a personalised plan to prevent a cardiac event.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/* eo bs-example */}
              {/* end of accordian code */}
            </div>
          </div>{/* eo card-block */}
        </div>{/* eo card body */}
      </div>{/* eo card */}
      {/* eo-heart-accordian-sec-wrap */}
    </section>{/* eo-heart-accordian-sec */}
    {/* ******************End of Heart conditions accordian************ */}
    {/* ******************Useful resource buttons************ */}
    <section className=" useful-resources-sec d-none">
      <div className="container ur-header-wrap ">
        <h2 className="text-center">Useful Resources for you to learn more</h2>
      </div>{/* eo what-you-sec-wrap */}
      <div className="ur-pill-wrap container row ">
        <div className="  pill-1 col-sm col-md-6 col-lg-6 col-xl-3 mb-3 ur-pill">
          <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
            <span className="ur-img-wrap float-left">
              <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
            </span>
            <span className="ur-link-title float-left ">Usefull <br />Resource</span>
          </a>
        </div>{/* eo-ur-pill*/}
        <div className="ur-pill pill-2  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
          <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
            <span className="ur-img-wrap float-left">
              <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
            </span>
            <span className="ur-link-title float-left ">Usefull <br />Resource</span>
          </a>
        </div>{/* eo-ur-pill*/}
        <div className="ur-pill pill-3 col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
          <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
            <span className="ur-img-wrap float-left">
              <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
            </span>
            <span className="ur-link-title float-left ">Usefull <br />Resource</span>
          </a>
        </div>{/* eo-ur-pill*/}
        <div className="ur-pill pill-4  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
          <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
            <span className="ur-img-wrap float-left">
              <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
            </span>
            <span className="ur-link-title float-left ">Usefull <br />Resource</span>
          </a>
        </div>{/* eo-ur-pill*/}
        {/*    </div> */}
      </div>{/* eo-ur-pill-wrap */}
    </section>{/* eo of what-you-sec */}
    {/* ******************Endof Useful resource buttons************ */}
    {/* ******************are you ready section************ */}
    <section className="ayr-sec-main heart-disease-sec-main d-none">
      <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container ">
        <div className="intro-text-wrap text-center">
          <h2 className="text-center ">Are you Ready?</h2>
        </div>{/* eo-intro-text-wrap*/}
        <div className="families-sec heart-disease-sec row container">
          <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center ">
            <img className="img-fluid mx-auto" src="./img/cancerpage/Illustrationg.png" />
          </div>{/* eo-hd-img */}
          <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center ">
            <h3>Start your journey to better health</h3>
            <p className="heartdisease-content">This simple 3-minute self reported risk assessment test will enable you to learn and measure your risks for certain chronic conditions.
            </p>
            <button type="button" className="btn btn-outline-primary btn-hover-white rounded-pill" >What’s my Risk?<i className="fas fa-long-arrow-alt-right" />
            </button>
          </div>{/* eo-hd-text */}
        </div>{/* eo-row */}
      </div>{/* eo-heart-disease-sec-main-wrap*/}
    </section>{/* eo-heart-disease-sec-main */}
    {/* ******************end of are you ready section************ */}
    {/* **********Schedule your Genetic Counseling Session Starts Here************** */}
  
    {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
    {/* Footer Links */}
    <Prospect></Prospect>
  <Footer></Footer>
    {/* end of footer */}
  
  
    {/* sticky form---------------- */}
    <MobileFooter></MobileFooter>

  </div>);

    }
}
export default FamilyPlanning;
