import React from 'react'
import Footer from './Footer';
import HeaderLinks from './HeaderLinks';
import NoTabMainHeader from './NoTabMainHeader';
import StickyHeader from 'react-sticky-header';
import SubFooter from './SubFooter';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import CommonHeder from './CommonHeder';
import $ from 'jquery';


class AllPrivacyPolicy extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

        q1: false,
        b1: true,

        q2: false,
        b2: true,

 

    }
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
   


}

toggle1() {
    this.setState(state => ({ q1: !state.q1 }));
    this.setState(state => ({ b1: !state.b1 }));


    this.setState({ q2: false }, function () { });
    this.setState({ b2: true }, function () { });


}

toggle2() {
    this.setState(state => ({ q2: !state.q2 }));
    this.setState(state => ({ b2: !state.b2 }));

    this.setState({ q1: false }, function () { });
    this.setState({ b1: true }, function () { });
   
}
componentDidMount(){
      
  $(document).ready(function(){
    $("#myInput").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#myData h5").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
  
    });
  });
}

   render(){
  

       return(
        <div>
        <HeaderLinks></HeaderLinks>
        <style dangerouslySetInnerHTML={{__html: "\n  /* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n  .activenavbar {\n    color: #4f5be7!important;\n  }\n\n  .card-body {\n    text-align: left!important;\n  }\n\n  .heart-banner-sec-wrap .heart-banner-sec {\n    background: url(./img/login/privacy_policy.png);\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n\n  .collapse .show {\n    /* background-color: red!important; */\n  }\n\n  .accordion>.card .card-header::after {\n    margin-bottom: -1px;\n    background: blue;\n  }\n\n  .accordion>.card .card-header::before {\n    margin-bottom: -1px;\n    background: blue;\n  }\n  " }} />
        {/* header */}
     <CommonHeder></CommonHeder>
        {/* end of header */}
        <div style={{marginTop: '6rem'}}>
        </div>
        <section className="heart-banner-sec-wrap banner-position">
          <div className=" heart-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className><strong>Privacy Policy</strong></h1>
              {/* <h2 class="roboto-reg-24px my-4">Get the right answers to your questions</h2> */}
              <div className="input-group " style={{height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem'}}>
              <input type="text" className="form-control m-0" placeholder="Search for help.." id="myInput"  style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />
                <div className="valid-tooltip">
                  Looks good!
                </div>
                <div className="input-group-prepend border-0 d-flex justify-content-center">
                  <span className="btn btn-outline-primary input-group-text border-0 justify-content-center" style={{cursor: 'pointer', borderBottomRightRadius: '2rem', borderTopRightRadius: '2rem', backgroundColor: '#4f5be7', width: '160px', color: 'white'}} id="validationTooltip01">SEARCH</span>
                </div>
              </div>
            </div>
            {/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1"> <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>
          {/* eo-heart-banner-sec */}
        </section>
        {/* eo-heart-banner-sec-wrap */}
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="container" id="myData">
          <div className=" mt-5">
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card ">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q1?'#2C333C':'#4F5BE7'}}>
                          Privacy Highlights
                          {this.state.b1?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q1}>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body border-0">
                          <a style={{color: '#000'}}>
                            <p className=" roboto-reg-18px">These "Privacy Highlights" provide an overview of some core
                              components of our data handling practices. Please be sure
                              to review the Full Privacy Statement.</p>
                          </a>
                          <p className="roboto-bold-24px pt-3 pb-1">Information We Collect</p>
                          <p className="roboto-reg-18px">We generally collect the following information:</p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;Information we receive
                            when you use our Services.
                          </p><p>
                          </p><p className="roboto-reg-18px pl-2 ">We collect Web-Behavior Information via cookies and other
                            similar tracking technologies when you use and access our
                            Services (our website, mobile apps, products, software
                            and other services). See our Cookie Policy <a style={{color: '#4f5be7'}} href="https://www.truGeny.com/about/cookies/">(https://www.truGeny.com/about/cookies/)</a>
                            for more information.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;Information you share
                            directly with us.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2"> We collect and process your information when you place an
                            order, create an account, register your truGeny kit, complete
                            research surveys, post on our Forums or use other messaging
                            features, and contact Customer Care. This information can
                            generally be categorized as Registration Information, Self-Reported
                            Information, and/or User Content as defined in our full
                            Privacy Statement.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;Information from
                            our DNA testing services.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2">With your consent, we extract your DNA from your saliva sample
                            and analyze it to produce your Genetic Information (the
                            As, Ts, Cs, and Gs at particular locations in your genome)
                            in order to provide you with truGeny reports.
                          </p>
                          {/* -----------------------------------------------------------2nd------------------------------------------- */}
                          <p className="roboto-bold-24px pt-3 pb-1">How We Use Informationc</p>
                          <p className="roboto-reg-18px">We generally process Personal Information for the following reasons:</p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;To provide our Services.
                          </p><p>
                          </p><p className="roboto-reg-18px pl-2 ">We process Personal Information in order to provide our Service, which includes processing payments,
                            shipping kits to customers, creating customer accounts and authenticating logins, analyzing saliva samples and DNA, and delivering
                            results and powering tools like DNA Relatives.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;To analyze and improve our Services.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2"> We constantly work to improve and provide new reports, tools, and Services. For example, we are constantly working to improve our ability to assign specific ancestries to your DNA segments and maximize the granularity of our results. We may also need to fix bugs or issues, analyze use of our website to improve the customer experience or assess our marketing campaigns.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;For truGeny Research, with your consent.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2">If you choose to consent to participate in truGeny Research, truGeny researchers can include your de-identified Genetic Information
                            and Self-Reported Information in a large pool of customer data for analyses aimed at making scientific discoveries.
                          </p>
                          {/* --------------------------------------------------3rd--------------------------------------- */}
                          <p className="roboto-bold-24px pt-3 pb-1">Control: Your Choices</p>
                          <p className="roboto-reg-18px">truGeny gives you the ability to share information in a variety of ways. You choose:</p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;To store or discard your saliva sample</p><p>
                          </p><p className="roboto-reg-18px pl-2 ">after it has been analyzed.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;Which health report(s)
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2"> you view and/or opt-in to view.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;When and with whom you share your information
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2">including friends, family members, health care professionals, or other individuals
                            outside our Services, including through third party services that accept truGeny data and social networks.
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;To give or decline consent for truGeny Research.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2"> By agreeing to the Research Consent Document, Individual Data Sharing Consent Document, or participating in a truGeny Research Community you can give consent for the use of your data for scientific research purposes.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;To delete your truGeny account and data
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2">
                            at any time.
                          </p>
                          {/* --------------------------------------------------4th--------------------------------------- */}
                          <p className="roboto-bold-24px pt-3 pb-1">How We Secure Information</p>
                          <p className="roboto-reg-18px">truGeny implements measures and systems to ensure confidentiality, integrity, and availability of truGeny data.</p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;De-identification/Pseudonymization, encryption, and data segmentation.</p><p>
                          </p><p className="roboto-reg-18px pl-2 ">Registration Information is stripped from Sensitive Information, including genetic and phenotypic data. This data is then assigned a random ID
                            so the person who provided the data cannot reasonably be identified. truGeny uses industry    standard security measures to encrypt sensitive
                            personal data both when it is stored (data-at-rest) and when it is being transmitted (data-in-flight). Additionally, data are segmented across
                            logical database systems to further prevent re-identifiability.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;Limiting access to essential personnel.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2"> We limit access of information to authorized personnel, based on job function and role. truGeny access controls include multi-factor authentication, single sign-on, and a strict least-privileged authorization policy.
                            <br />
                          </p>
                          <p className="roboto-bold-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;Detecting threats and managing vulnerabilities.
                            <br />
                          </p>
                          <p className="roboto-reg-18px pl-2">truGeny uses state of the art intrusion detection and prevention measures to stop any potential
                            attacks against its networks. We have integrated continuous vulnerability scanning in our build pipeline and regularly engage third party
                            security experts to conduct penetration tests.
                          </p>
                          {/* --------------------------------------------------5th--------------------------------------- */}
                          <p className="roboto-bold-24px pt-3 pb-1">Risks and Considerations</p>
                          <p className="roboto-reg-18px">There may be some consequences of using truGeny Services that you haven't considered.</p>
                          <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;You may discover things about yourself and/or your family members that may be upsetting or cause anxiety and that you may not have the ability to control or change.</p><p>
                          </p><p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;You may discover relatives who were previously unknown to you, or may learn that someone you thought you were related to is not your biological relative.
                            <br />
                          </p>
                          <p className="roboto-reg-18px"><i style={{color: '#eab623'}} className="fas fa-caret-right" />&nbsp;In the event of a data breach it is possible that your data could be associated with your identity, which could be used against your interests.
                            <br />
                          </p>
                          {/* -------------------------------------------6th-------------------------------------- */}
                        </div>
                      </div>
                  </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q2?'#2C333C':'#4F5BE7'}}>
                          Full Privacy Statement
                          {this.state.b2?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q2}>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <a style={{color: '#000'}}>
                            <p className=" roboto-reg-18px">
                              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                            </p>
                          </a>
                          {/* ---------------------------------------------------------------5th--------------------------------------------------------------- */}
                        </div>
                      </div>
                    </Collapse>
                      
                    </div>
                  </div>
                </div>
                {/* eo bs-example */}
                {/* end of accordian code */}
              </div>
            </div>
            {/* eo card-block */}
          </div>
          {/* eo card body */}
          {/* eo card */}
          {/* eo-heart-accordian-sec-wrap */}
        </section>
        {/* eo-heart-accordian-sec */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
       <SubFooter></SubFooter>
        <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active">
                      <br />
                      <h3>How do you indentify your gender?</h3>
                      <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" defaultChecked /> Parents</label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" /> Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" onclick="window.location.href = 'thankyou2.html';" href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
        <div className="row ">
          <div className="nb-form">
            {/* <p class="title d-none d-lg-block ">May I Help You</p> */}
            <img src="https://www.growyourbrand.com/wp-content/uploads/2018/11/chat.gif" alt="" className="user-icon img-fluid" />
            <form>
              <input type="text" name="cpname" placeholder="Name:" required />
              <input type="email" name="cpemail" placeholder="Email:" required />
              <input type="tel" name="cpphone" placeholder="Phone:" required />
              <textarea name="cpmessage" placeholder="Message:" required defaultValue={""} />
              <input type="submit" defaultValue="Send message" style={{background: '#4f5be7'}} />
            </form>
          </div>
        </div>
        {/* search */}
        {/* eo saerch */}
        {/* --------eo sticky form----------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
       )
   }

}
export default AllPrivacyPolicy;