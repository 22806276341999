import React from 'react'

class MobileFooter extends React.Component{

   render(){
  

       return(
        <div className="fixed-bottom bg-white stickme">
        <div className="container">
          <div className="row py-2">
            <div className="col-1 p-0 m-0">
              {/* <i style="color:#4f5be7"class="fas fa-home fa-2x"></i> */}
            </div>
            <div className="col-2 ">
              {/* <i class="fas fa-home fa-2x"></i>
 */}
              <a  href='/'><img style={{color: 'black'}} src="./img/icons/house.svg" className="img-fluid" /></a>
            </div>
            <div className="col-2">
              {/* <i class="fas fa-mobile-alt fa-2x"></i> */}
             <a href="tel:0870 128 82830"> <img   src="./img/icons/smartphone.svg" className="img-fluid" /></a>
            </div>
            <div className="col-2">
              <a href="mailto:support@truGeny.com"><img style={{color: 'black'}}   src="./img/icons/envelope.svg" className="img-fluid" /></a>
            </div>
            <div className="col-2">
              <img style={{color: 'black'}} src="./img/icons/share1.svg" className="img-fluid" />
            </div>
           
           
          </div>
        </div>
      </div>
       )
   }

}
export default MobileFooter;