import React from 'react';
import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import { Collapse } from 'reactstrap';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import CarouselCards2 from './CarouselCards2';
import CancerCarouselCards3 from './CancerCarouselCards3';
import HeartCarouselCards from './HeartCarouselCards';
import HeartCarouselCards2 from './HeartCarouselCards2';
import StickyContactMenu from './StickyContactMenu';
import MetaTags from 'react-meta-tags';




class Heart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {


      q1:false,
      b1:true,

      q2:false,
      b2:true,

      q3:false,
      b3:true,

      q12:false,
      b12:true,

      q22:false,
      b22:true,

      q32:false,
      b32:true,

      q42:false,
      b42:true,


    }
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);

    this.details1=this.details1.bind(this);
    this.details2=this.details2.bind(this);
    this.details3=this.details3.bind(this);
    this.details4=this.details4.bind(this);
  }
details1() {
    this.setState(state => ({ q12: !state.q12 }));
    this.setState(state => ({ b12: !state.b12 }));

    
    this.setState({q22: false}, function () {});
    this.setState({b22: true}, function () {});
    this.setState({q32: false}, function () {});
    this.setState({b32: true}, function () {});
    this.setState({q42: false}, function () {});
    this.setState({b42: true}, function () {});



  }

  details2(){

    this.setState(state => ({ q22: !state.q22 }));
    this.setState(state => ({ b22: !state.b22 }));

    
    this.setState({q12: false}, function () {});
    this.setState({b12: true}, function () {});
    this.setState({q32: false}, function () {});
    this.setState({b32: true}, function () {});
    this.setState({q42: false}, function () {});
    this.setState({b42: true}, function () {});


  }

  details3(){

    this.setState(state => ({ q32: !state.q32 }));
    this.setState(state => ({ b32: !state.b32 }));

    
    this.setState({q12: false}, function () {});
    this.setState({b12: true}, function () {});
    this.setState({q22: false}, function () {});
    this.setState({b22: true}, function () {});
    this.setState({q42: false}, function () {});
    this.setState({b42: true}, function () {});



  }

  details4(){

    this.setState(state => ({ q42: !state.q42 }));
    this.setState(state => ({ b42: !state.b42 }));

    
    this.setState({q12: false}, function () {});
    this.setState({b12: true}, function () {});
    this.setState({q22: false}, function () {});
    this.setState({b22: true}, function () {});
    this.setState({q32: false}, function () {});
    this.setState({b32: true}, function () {});



  }
  toggle1() {
    this.setState(state => ({ q1: !state.q1 }));
    this.setState(state => ({ b1: !state.b1 }));

    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});
  }

  toggle2() {
    
    this.setState(state => ({ q2: !state.q2 }));
    this.setState(state => ({ b2: !state.b2 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q3: false}, function () {});
    this.setState({b3: true}, function () {});

   
  }

  toggle3() {
    this.setState(state => ({ q3: !state.q3 }));
    this.setState(state => ({ b3: !state.b3 }));

    this.setState({q1: false}, function () {});
    this.setState({b1: true}, function () {});
    this.setState({q2: false}, function () {});
    this.setState({b2: true}, function () {});

  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  toLogin() {
    // browserHistory.push("/ordertrugene");
     window.location.href="/login";
    }
    toBookSession(){
      window.location.href="/book-counselling"
    }
    toOrder(){
      window.location.href="/product-page"
    }
  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="./style-mobile.css" />
        <link rel="stylesheet" href="newsticky.css" />
        <MetaTags>
        <title>Genetic Testing For Heart Risk | Cardiovascular Genetic Testing - truGeny</title>
		<meta name="description" content="Know your risk for developing cardiovascular diseases through a simple genetic test offered by truGeny. "/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Genetic Testing For Heart Risk | Cardiovascular Genetic Testing - truGeny"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="Know your risk for developing cardiovascular diseases through a simple genetic test offered by truGeny. "/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
</MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n.custom-size {\n  height:450px!important;\n}\n.font-nav{\n  padding-right:15px;\nfont-size:15px;\ncolor:gray;\n/* font-weight:300; */\n\n}\n\n.img-11{\n  height:48px;\n  /* width:120px; */\n}\n.nav-tab-1{\n    padding-top:5px!important;\n    padding-bottom:5px!important;\n    color:#FF3C3C;\n    font-size:15px;\n\n}\n@media (max-width: 767.98px) {\n  .font-nav{\n    padding-right:4px;\n  font-size:10px;\n  color:gray;\n\n  }\n  .nav-tab-1{\n    /* margin-bottom: :16rem; */\n      padding-top:3px!important;\n      padding-bottom:3px!important;\n      color:#FF3C3C;\n\n  }\n   }\n   .nav-active1{\n     font-weight:700;\n     color:#4f5be7;\n   }\n" }} />
        <NewMainHeader category="heart"></NewMainHeader>

        <StickyContactMenu ></StickyContactMenu>
        {/* eo-container */}
        <section className="heart-banner-sec-wrap banner-position">
          <div className=" heart-banner-sec ">
            <div className="container pt-4 mt-2 ">
              <h1 className="cbannerp"><strong>Listen to your heart</strong></h1>
              <p className="text-justify mt-1 mb-4">Knowing about your genetic risks for developing heart conditions can help you to work with your Doctor to develop a personalised plan designed to prevent heart conditions.</p>
            </div>{/* eo-container*/}
            <div className="d-flex justify-content-center" id="sech1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#sech1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>{/* eo-heart-banner-sec */}
        </section>{/* eo-heart-banner-sec-wrap */}
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="intro-text-wrap text-center">
              <h2 className="text-center ">Over 1.7 Million Indians pass away each year
                due to just one disease. <span className="hd">HEART DISEASE</span>
              </h2>
            </div>{/* eo-intro-text-wrap*/}
            <div className=" heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 d-block text-center ">
                <img className="img-fluid mx-auto imgfluidMobile" src="heart-img/heart-disease.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
                <h3>Heart disease</h3>
                <p className="heartdisease-content">Heart disease is complex and develops as a result of interaction between genes and lifestyle risk factors. Heart disease occurs when plaque develops in the arteries that supply blood to the heart. Plaque is a waxy substance made up of cholesterol, fatty molecules, and minerals. This blocks important nutrients and oxygen from reaching your heart.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-sm-5 mb-xs-5 text-center order-md-2">
                <img className="img-fluid mx-auto imgfluidMobile" src="heart-img/heriditary.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                <p className="heartdisease-content">Strong family history, distinctive disease pattern, higher prevalence of associated risk factors such as diabetes and hypertension, at a relatively younger age, are some of the noteworthy features of inherited heart diseases among Indians.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* Start of lifestyle factors cards  */}
        <div className="life-factors-main-sec">
          <div className="container life-factors-main-wrap">
            <h2 className="text-center">Lifestyle Factors that impact heart health</h2>
            <div className="container life-factors-card-main-wrap mx-auto mt-5  mb-5">
              <div className="card-deck" id="hide">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg  mb-4 r4  ">
                    <div className="card-body text-center ">
                      <div className="card-block  p-2 ">
                        <img className="card-img img-fluid text-center p-2 " src="heart-img/obsity-01.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Obesity</p>
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card */}
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg mb-4 r4 ">
                    <div className="card-body text-center">
                      <div className="card-block p-2 ">
                        <img className="card-img text-center p-2" src=" heart-img/physical-activity-01.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Physical Inactivity</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg  mb-4 r4 ">
                    <div className="card-body text-center">
                      <div className="card-block  p-2  ">
                        <img className="card-img text-center  p-2" src="heart-img/smoking-drinking-01.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Smoking &amp; Drinking</p>
                    </div>
                  </div>
                </div>{/* eo-card-deck-wrap */}
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded shadow-lg mb-4 r4  ">
                    <div className="card-body text-center">
                      <div className="card-block p-2 ">
                        <img className="card-img text-center  p-2 " src="heart-img/unhealthy-diet.png" alt="Card image cap" />
                      </div>
                      <p className="card-text">Unhealthy Diet</p>
                    </div>
                  </div>{/* eo card */}
                </div>{/* eo-card-deck-wrap */}
              </div>{/* eo-card-deck */}
              {/* =========================================================== */}
              <HeartCarouselCards></HeartCarouselCards>
              {/* ======================================================== */}
            </div>{/*eo-container */}
          </div>{/* gc-help-main-wrap */}
        </div>{/* gc-help-main-sec */}
        {/* end of lifestyle factors cards  */}
        {/* Genes can influence section starts here */}
        <section className="heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="families-sec heart-disease-sec p-0 row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center">
                <img className="img-fluid mx-auto imgfluidMobile" src="heart-img//Illustration-2.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center">
             
            <h2 className="text-center ">
              <span style={{color:'#4f5be7',fontWeight:'900',marginLeft:'-10px',fontFamily:'Roboto, sans-serif',paddingBottom:' 15px',fontSize:"1.875rem"}} className="hd">Role of genes in heart disease</span>
            </h2>
            <ul className="roboto-reg-18px" style={{marginLeft:'45px'}}>
              <li>Genes can influence your risk for heart disease. </li>
              <li>Genetic factors influence up to 50% of cases with Coronary artery disease.</li>
              <li>Familial Hypercholesterolemia (FH) is a genetic disorder where affected individuals show abnormal high cholesterol (LDL-c) levels in the blood. Such individuals have a 5 times higher risk for heart disease. </li>
              <li>Genetic variations are passed on from parents to children</li>
            </ul> 
            </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>
        {/* Genes can influence section ends here */}
        {/* /************genetic risks starts here*************** / */}
        <section className=" gr-sec">
          <div className="container gr-sec-wrap">
            <div className="gr-header">
              <h2 className="text-center">
                Learning about your genetic risks for a heart condition
              </h2>
              <p className="text-center p-0 m-0">can help you and your Doctor to create a personalised preventive heart
                health plan.
              </p>
            </div>{/* eo gr-header */}
            <div className="container gr-card-main-wrap pt-5 mt-lg-5 mt-sm-1  mb-5">
              <div className="row" id="hide">
                <div className="text-center  col-12 col-md-6 col-lg-4">
                  <div className="card custom-size  card-1 py-5 px-4 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0 ">
                      <div className="card-top ">
                        <img className="img-fluid pt-1 imgfluidMobile" src="img/risk-page/tq/33heartcard.png" />
                        <p className="card-title roboto-black-18px pt-4">Some Heart Conditions are caused by genes</p>
                        </div>{/* eo-card-top */}
                      <div className="card-btm pt-4">
                      <p className="card-text pb-3">These heart conditions if left undetected can be life-threatening.</p>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="text-center  col-12 col-md-6 col-lg-4 bg-white">
                  <div className="card card-2 custom-size py-5 px-4 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0 ">
                      <div className="card-top ">
                        <img className="img-fluid py-1" src="img/risk-page/tq/33heartcard.png" />
                        <p className="card-title roboto-black-18px pt-4">With early detection most inherited heart conditions can be managed successfully</p>
                      </div>{/* eo-card-top */}
                      <div className="card-btm pt-2">
                        <p className="card-text">Knowing your risk early can help you and your Doctor, to create a personalized plan with regular screenings to prevent heart disease.</p>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-2 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="text-center  col-12 col-md-6 col-lg-4">
                  <div className="card card-3 custom-size  py-5 px-4 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top ">
                        <img className="img-fluid py-1" src="img/risk-page/tq/33heartcard.png" />
                        <p className="card-title roboto-black-18px pt-4">Learning about your heart can help your family</p>
                        </div>{/* eo-card-top */}
                      <div className="card-btm pt-4">
                      <p className="card-text pb-3">Inherited heart conditions are passed from one generation to the next and run in families. Learning about your risk can help your family take proactive actions to keep their own hearts healthy.</p>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-3 */}
                </div>{/* eo-ns-card-wrap */}
              </div>{/* eo-row */}
            </div>{/* gr-card-wrap */}
            {/* ==================================================== */}
            <HeartCarouselCards2></HeartCarouselCards2>
            {/* ==================================================== */}
            <div className="learn-btn">
             
            <div className="she-btn text-center mx-auto">
            <p className="gc-title roboto-reg-18px pt-5" href="#gc-title">I want to learn More</p>

              <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toBookSession} role="button" style={{background: '#4f5be7', color: 'white'}}>Schedule my Genetic counselling appointment</a>
            </div>
          </div>
        </div>{/* eo gr-sec-wrap */}
        </section>{/*eo gr-sec */}
        {/* ******************end of genetic risks************ */}
        {/* ******************Heart conditions************ */}
        <section className="heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="families-sec heart-disease-sec p-0 row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center order-md-2">
                <img className="img-fluid mx-auto imgfluidMobile" src="heart-img/Illustration-5.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1">
                <h3>Most people are unaware that they have inherited a  HEART CONDITION</h3>
                <p className="heartdisease-content">People do not realise that they have an inherited heart condition as they don’t experience any symptoms.</p>
                <p className="heartdisease-content">When left undetected, inherited heart conditions can lead to heart attacks, sudden cardiac arrest and heart failure.</p>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************End of Heart conditions************ */}
        {/* ****************** Heart conditions accordian************ */}
        <section className="heart-accordian-sec ">
        <div className="heart-accordian-sec-wrap container ">
          <div className="heart-accordian-header">
            <h2 className="mx-auto text-center">Some of the common inherited Heart Conditions include</h2>
          </div>{/* eo heart-accordian-header */}
          <div className="card mt-5 rounded shadow-lg  mb-0 r4 ">
            <div className="card-body text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionHc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0 text-uppercase"  style={{color:this.state.q12?'#2C333C':'#4F5BE7'}}>
                          Arrhythmia
                          {this.state.b12?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details1} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q12}>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionHc">
                        <div className="card-body">
                          <a style={{color: 'black'}} >An arrhythmia is a disorder that affects the normal heart rate. With an arrhythmia, the heart tends to beat too slow or too fast or irregularly. These disorders can affect the amount of blood pumped by the heart.</a>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0 text-uppercase" style={{color:this.state.q22?'#2C333C':'#4F5BE7'}}>
                          Coronary Artery Diseases
                          {this.state.b22?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details2} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q22}>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionHc">
                        <div className="card-body">
                          <a style={{color: 'black'}} >In Coronary artery disease (CAD), the coronary blood vessels (arteries) become narrow or blocked due to cholesterol and fatty acids depositing on their inner walls (atherosclerotic plaque). This causes reduced flow and supply of oxygenated blood to the heart muscle which can eventually lead to chest pain (angina), heart attack (myocardial infarction) or sudden death (cardiac arrest).
                          </a>
                        </div>
                      </div>
                     
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0  text-uppercase" style={{color:this.state.q32?'#2C333C':'#4F5BE7'}}>
                          Cardiomyopathy
                          {this.state.b32?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details3} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details3} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q32}>
                      <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionHc">
                        <div className="card-body">
                          <a style={{color: 'black'}}>Cardiomyopathy is a disease of the heart muscle. Cardiomyopathy makes it harder for your heart to pump blood to the rest of your body and could lead to heart failure. The three main types of cardiomyopathy are dilated, hypertrophic and restrictive cardiomyopathy.</a>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingFour">
                        <h5 className="acc-header collapse-color mb-0 text-uppercase" style={{color:this.state.q42?'#2C333C':'#4F5BE7'}}>
                          FAMILIAL HYPERCHOLESTEROLEMIA (FH)
                          {this.state.b42?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details4} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.details4} className="fa fa-minus-circle  rounded-circle " /></button>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q42}>
                      <div id="collapseFour" className="collapse show" aria-labelledby="headingFour" data-parent="#accordionHc">
                        <div className="card-body">
                          <a style={{color: 'black'}} >Familial hypercholesterolemia (FH) is a genetic disorder that is passed down through families. The condition begins at birth and can cause heart attacks at an early age. Familial hypercholesterolemia (FH) causes LDL (bad) cholesterol level to be very high.
                          </a>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                  </div>
                </div>{/* eo bs-example */}
                {/* end of accordian code */}
              </div>
            </div>{/* eo card-block */}
          </div>{/* eo card body */}
        </div>{/* eo card */}
        {/* eo-heart-accordian-sec-wrap */}
      </section>{/* eo-heart-accordian-sec */}
        {/* ******************End of Heart conditions accordian************ */}
        {/* ****************** Be proactive Sec************ */}
        <section className="heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="families-sec p-0 heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center">
                <img className="img-fluid mx-auto imgfluidMobile" src="heart-img/Illustration-6.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center sec6">
              <h3><strong> Be Proactive.<br />Know your risk to take ACTION</strong></h3>
              <p className="heartdisease-content font-weight-bold pb-4">Learning about your risk for inherited heart condition (risks) can help you take timely actions.</p>
              <ul>
                <li>Allows you and your Doctor to create a personalised heart health plan and make informed decisions for prevention.</li>
                <li>It enables your Doctor to prescribe medicines to lower your risks for a heart condition.</li>
                <li>Your results can help your family members understand if they carry a risk for an inherited heart condition.</li>
                <li>Knowing about risks and whether you have a faulty gene or not can reduce stress and worry.</li>
              </ul>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************End of Be proactive sec ************ */}
        {/* ******************Benifits-sec ************ */}
        <section className="heart-disease-sec-main">
          <div className="heart-disease-sec-main-wrap container">
            <div className="families-sec p-0 heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center order-md-2">
                <img className="img-fluid mx-auto imgfluidMobile" src="heart-img/Illustration-7.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center order-md-1 sec6">
              <h3><strong> Benefits of truGeny &amp;<br />Reliability of our test</strong></h3>
              <p className="heartdisease-content">Conversation FIRST - Our expert genetic counsellor will assess your family history and risk factors, and determine if genetic testing is right for you and ensure that you are receiving the most appropriate test.</p>
              <p className="heartdisease-content font-weight-bold pb-4">A highly reliable test.</p>
              <ul>
                <li>truHeart is a PRS(Polygenic Risk Score) based test that analyses over 6 Million genetic markers.</li>
                <li>The report will provide you with a risk score to indicate whether you carry a low, moderate or high risk for developing heart disease.</li>
                <li>Our reports are actionable – they can be shared with your Doctor for prevention and understanding of treatment options for better health.</li>
              </ul>
            </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* <div class="container be-proactive-sec benifits-sec sec6"  >
      <div class="row">

        <div class="container benifits-text be-proactive-text col-sm "  >
          <h3><strong> Benefits of truGeny &
          </br>Reliability of our test</strong></h3>

          <p class="imp-text-1" > Conversation FIRST - Our expert genetic counsellors will assess your family history and risk factors, and determine if genetic testing is right for you and ensure that you are receiving the most appropriate test.</p>

          <p class="imp-text-2 " > A highly reliable test.</p>
          <ul class="">
            <li >We don’t just look at snippets of your DNA. We do a thorough analysis of your genes.</li>
            <li>We look at over 90 Genes that are recognised by</li>
            <li>Our reports are actionable that can be shared with your Doctor for prevention and understanding of treatment options for better health.</li>
          </ul>
        </div> */}{/* eo be-proactive-text */}
        {/* <div class="container be-proactive-img-wrap benifits-img-wrap col-sm"   >
          <img class="center img-fluid "src="heart-img/Illustration-7.png"/>
        </div>
      </div>
    </div> */}{/* eo be-proactive-sec */}
        {/* ******************end of Benifits-sec ************ */}
        {/* ******************what you get-sec************ */}
        <section className="our-process-sec">
          <div className="container our-process-sec-header ">
            <h2 className="text-center">What do you GET?</h2>
            <p className="punchline">It starts with a conversation</p>
          </div>{/* eo what-you-sec-wrap */}
          <div className="container op-card-wrap text-center">
            <div className="card-deck" id="hide">
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-1 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-1">1</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap ">
                        <img className="card-img-top text-center" src="heart-img/calender-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Schedule An Appointment</h5>
                    <p>It’s fast and easy to do online, and your personal information will always be kept confidential and protected</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-2 rounded shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-2">2</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/call-out-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Initial consultation </h5>
                    <p>We review your personal health history, answer your questions, and, if you are interested, help you select and order the right tests</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-3 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-3">3</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/sample-collection.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">sample collection</h5>
                    <p>If you decide to have testing done, we will ship the sample collection kit to your preferred address.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-4 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-4">4</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/results-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">results consultation</h5>
                    <p>Our certified genetic counsellors will review your test results, walk you through their implications, and answer your questions.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
            </div>{/* eo wyg-card-deck */}
            {/* ============================= */}
           <CarouselCards2></CarouselCards2>
            {/* ============================= */}
          </div>{/* eo wyg-card-wrap */}
        </section>{/* eo of what-you-sec */}
        {/* ******************End of what you get-sec************ */}
        {/* Got to Know starts here */}
        <section className="gtk-sec ">
          <div className="container gtk-sec-wrap pb-0 gr-sec-wrap">
            <div className="gtk-main-header-wrap">
              <h2 className="text-center">
              I want to learn about what my genes have to say about my risk for developing heart disease.

              </h2>
            </div>{/* eo gr-header */}
            <div className="container gtk-card-main-wrap pt-5 mt-5  mb-5">
              <div className="row" id="hide">
                <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className=" card-1 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/learnIllustration-44.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text"><b>Schedule my Genetic Counselling Appointment</b></p>
                        <div className="she-btn text-center mx-auto">
                          <a className="btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toBookSession} role="button">Schedule Now</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
                <div className="gtk-card-wrap  col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className=" card-1 shadow-lg r4 border-0 mb-5">
                    <div className="card-body p-0">
                      <div className="card-top">
                        <div className="text-center py-5">
                          <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/Box-1.png" />
                        </div>{/* eo-hd-img */}
                      </div>{/* eo-card-top */}
                      <div className="card-btm">
                        <p className="card-text"><b>Order my truHeart Test Kit</b></p>
                        <div className="order-btn questions-btn text-center mx-auto mt-0">
                          <a className=" btn btn-outline-primary btn-hover-white rounded-pill text-center " onClick={this.toOrder} role="button"> Order Now</a>
                        </div>
                      </div>{/* eo-card-btm */}
                    </div>{/* eo-card-body */}
                  </div>{/* eo-card-1 */}
                </div>{/* eo-ns-card-wrap */}
              </div>{/* eo-row */}
              {/* ============================================ */}
              <div className="row" style={{display: 'block'}}>
                <CancerCarouselCards3></CancerCarouselCards3>
              </div>
              {/* ============================================ */}
            </div>{/* gr-card-wrap */}
          </div>{/* eo gr-sec-wrap */}
        </section>{/*eo gr-sec*/}
        {/* end of Got to Know */}
        {/* ****************** Inherited Heart conditions accordian************ */}
        <section className="inherited-accordian-sec ">
          <div className="inherited-accordian-sec-wrap container">
            <div className="inherited-accordian-header">
              <h2 className="mx-auto text-center">Knowledge of increased risk is actionable.</h2>
              <p className="ia-sub-title">Knowing that you have a mutation that increases your risk of developing heart disease allows you and your Doctor to create a personalised action plan, to prevent the likelihood of a heart attack</p>
            </div>{/* eo heart-accordian-header */}
            <div className="heart-accordian-sub-header">
              {/* <h4 className="mx-auto text-center">Inherited Heart Conditions</h4> */}
            </div>{/* eo heart-accordian-header */}
            <div className="card mt-lg-5 mb-sm-1 rounded shadow-lg  mb-0 r4 ">
              <div className="card-body text-center">
                <div className="card-block  p-2 circle">
                  <div className="pr-5 pb-5 pl-5 hc-accordian-wrap">
                    <div className="accordion" id="accordionIhc">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h5 className="acc-header collapse-color mb-0 text-uppercase" style={{color:this.state.q1?'#2C333C':'#4F5BE7'}}>
                            Additional evaluations may be recommended based on your health history
                            {this.state.b1?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle1} className="fa fa-minus-circle  rounded-circle " /></button>}

                          </h5>
                        </div>
                        <Collapse isOpen={this.state.q1}>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                          <div className="card-body">
                            <p>If you have a personal or family history of specific heart conditions, additional evaluations by a cardiologist may be recommended.</p>
                          </div>
                        </div>
                        </Collapse>
                       
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5 className="acc-header collapse-color mb-0 text-uppercase" style={{color:this.state.q2?'#2C333C':'#4F5BE7'}}>
                            Heart conditions can be affected by many factors
                            {this.state.b2?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle2} className="fa fa-minus-circle  rounded-circle " /></button>}

                          </h5>
                        </div>
                        <Collapse isOpen={this.state.q2}>
                        <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                          <div className="card-body">
                            <p>Even if your report shows that there are no mutations, there are chances you may still develop  heart condition.  Factors like lifestyle, environment, do influence and could increase the risk for cardiac events.
                            </p>
                          </div>
                        </div>
                        </Collapse>
                        
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="acc-header collapse-color mb-0 text-uppercase" style={{color:this.state.q3?'#2C333C':'#4F5BE7'}}>
                          GETTING A POSITIVE RESULT DOES NOT MEAN THAT YOU WILL NECESSARILY
                          DEVELOP AN INHERITED HEART CONDITION
                          
                            {this.state.b3?<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle3} className="fa fa-plus-circle  rounded-circle " /></button>:<button type="button" className="float-right btn btn-link rounded-circle" data-toggle="collapse" data-target="#collapseOne"><i onClick={this.toggle3} className="fa fa-minus-circle  rounded-circle " /></button>}

                          </h5>
                        </div>
                        <Collapse isOpen={this.state.q3}>
                        <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                          <div className="card-body">
                            <p>Having a mutation in one of the evaluated genes does not mean that you will develop  an inherited heart condition. However, by knowing that you have a mutation, will help you and your Doctor to work out a personalised plan to prevent a cardiac event.</p>
                          </div>
                        </div>
                        
                        </Collapse>
                        
                      </div>
                    </div>
                  </div>{/* eo bs-example */}
                  {/* end of accordian code */}
                </div>
              </div>{/* eo card-block */}
            </div>{/* eo card body */}
          </div>{/* eo card */}
          {/* eo-heart-accordian-sec-wrap */}
        </section>{/* eo-heart-accordian-sec */}
        {/* ******************End of Heart conditions accordian************ */}
        {/* ******************Useful resource buttons************ */}
        <section className=" useful-resources-sec d-none">
          <div className="container ur-header-wrap ">
            <h2 className="text-center">Useful Resources for you to learn more</h2>
          </div>{/* eo what-you-sec-wrap */}
          <div className="ur-pill-wrap container row ">
            <div className="  pill-1 col-sm col-md-6 col-lg-6 col-xl-3 mb-3 ur-pill">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            <div className="ur-pill pill-2  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            <div className="ur-pill pill-3 col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            <div className="ur-pill pill-4  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
              <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                <span className="ur-img-wrap float-left">
                  <img className="card-img-top img-fluid text-center p-2 " src="heart-img/useful.png" alt="Card image cap" />
                </span>
                <span className="ur-link-title float-left ">Usefull <br />Resource</span>
              </a>
            </div>{/* eo-ur-pill*/}
            {/*    </div> */}
          </div>{/* eo-ur-pill-wrap */}
        </section>{/* eo of what-you-sec */}
        {/* ******************Endof Useful resource buttons************ */}
        {/* ******************are you ready section************ */}
        <section className="ayr-sec-main heart-disease-sec-main d-none">
          <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container ">
            <div className="intro-text-wrap text-center">
              <h2 className="text-center ">Are you Ready?</h2>
            </div>{/* eo-intro-text-wrap*/}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center ">
                <img className="img-fluid mx-auto imgfluidMobile" src="./img/cancerpage/Illustrationg.png" />
              </div>{/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center ">
                <h3>Start your journey to better health</h3>
                <p className="heartdisease-content">This simple 3-minute self reported risk assessment test will enable you to learn and measure your risks for certain chronic conditions.
                </p>
                <button type="button" className="btn btn-outline-primary btn-hover-white rounded-pill" >What’s my Risk?<i className="fas fa-long-arrow-alt-right" />
                </button>
              </div>{/* eo-hd-text */}
            </div>{/* eo-row */}
          </div>{/* eo-heart-disease-sec-main-wrap*/}
        </section>{/* eo-heart-disease-sec-main */}
        {/* ******************end of are you ready section************ */}
        {/* **********Schedule your Genetic Counseling Session Starts Here************** */}
       
        <Prospect></Prospect>
     
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
  <Footer></Footer>
        {/* end of footer */}
        <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-5 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active"><br />
                      <h3>How do you indentify your gender?</h3>          <hr />
                      <div className="d-flex d-flex-inline">
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                        <img className="mr-3" src="./img/q&a/female.png" alt="" />
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3><hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade"><br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label><input type="radio" name="optradio" defaultChecked />  Parents</label>
                      </div>
                      <div className="radio">
                        <label><input type="radio" name="optradio" />  Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} data-toggle="pill"  href="#">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
                  <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* sticky form---------------- */}
        
        {/* --------eo sticky form----------------- */}
        <MobileFooter></MobileFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );


  }
}

export default Heart;