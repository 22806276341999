import React from 'react';
import { Progress } from 'reactstrap';

const ProgressBar = (props) => {
  return (
    <div>
      <Progress value={props.barvalue} />
     
     
    </div>
  );
};

export default ProgressBar;