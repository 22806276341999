import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import axios from '../axios/axios';

import { UncontrolledAlert } from 'reactstrap';
import swal from 'sweetalert';

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: {

        userType: "customer",
        passwordType: "password"
      },
      resetPassword: false,
      update: "readOnly",
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPwd: '',
      errorMsg: null,
      errors: {},

    }

    this.changeHandler = this.changeHandler.bind(this);
    this.userUpdate = this.userUpdate.bind(this);
    this.logout = this.logout.bind(this);

  }


  componentDidMount() {
    axios.get('users/user/' + Cookies.get("userId"))
      .then((response) => {

        if (response.data) {
          this.setState({

            userData: response.data

          })

        }

      })
  }

 

  componentDidMount() {
    axios.get('users/user/' + Cookies.get("userId"))
      .then((response) => {

        if (response.data) {
          this.setState({

            userData: response.data

          })

        }

      })
  }

  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your mobile no.";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid mobile no.";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined" && fields["password"] !== "") {
      if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }
    if (!fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Please confirm password.";
    }
    if (fields["password"] !== fields["confirmPwd"]) {
      formIsValid = false;
      errors["confirmPwd"] = "*Password mismatched";

    }

    if (!fields["address1"]) {
      formIsValid = false;
      errors["address1"] = "*Please enter your address1 details.";
    }

    if (!fields["address2"]) {
      formIsValid = false;
      errors["address2"] = "*Please enter your address2 details.";
    }

    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "*Please enter your city.";
    }

    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "*Please enter your city.";
    }

    if (!fields["pinCode"]) {
      formIsValid = false;
      errors["pinCode"] = "*Please enter your pincode.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  validateBasicForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (typeof fields["firstName"] !== "undefined") {
      if (!fields["firstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["firstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your mobile no..";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid mobile no.";
      }
    }


    this.setState({
      errors: errors
    });
    return formIsValid;


  
}
  logout() {
    Cookies.remove('username');
   Cookies.remove('jwt-token');
   this.props.history.push('/login');
}

  userUpdate(e) {
    e.preventDefault();


    if (this.state.resetPassword) {
      axios.put('users/userUpdate/' + Cookies.get("userId"), this.state.userData)
        .then((response) => {
          swal("Your Profle has been updated....!")
          this.props.history.push("/settings")

        }).catch(
          error => {
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              },()=>{})
              swal("Please Try Again....!")

            }
          }
        )

    } else {
      //if(this.validateBasicForm()){
      axios.put('users/userUpdate/' + Cookies.get("userId"), this.state.userData)
        .then((response) => {
          swal("Your Profle has been updated....!")
          this.props.history.push("/settings")


          // this.changeTab(4);
        }).catch(
          error => {
       
            if (error.response === undefined) {
              this.props.history.push("/error")
            } else {
              this.setState({
                errorMsg: error.response.data.message
              },()=>{});
              swal("Please Try Again....!")

            }
          }
        )
      // }

    }


  }


  changeHandler(event) {
    let healthhis = this.state.userData
    healthhis[event.target.name] = event.target.value;
    this.setState({ userData: healthhis })


  }
  alert() {
    return (
      <div>

        <UncontrolledAlert color="info">
          Now you can edit!
     </UncontrolledAlert>
      </div>
    )
  }

  render() {
 
    if (this.state.userData.password === this.state.cpassword) {
    
    }

    return (
      <div>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
      <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
      <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
      {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous"> */}
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
      <title>truGeny</title>
      <link rel="icon" href="./img/landingpage/Logo.png" type="image/gif" sizes="16x16" />
      <link rel="stylesheet" href="./css/fonts.css" />
      <link rel="stylesheet" href="stylesheet_custom.css" />
      <link rel="stylesheet" href="./css/payment.css" />
      <style dangerouslySetInnerHTML={{__html: "\n.padding-h4{\n  padding-left: 160px;\n}\n.form-control[readonly] {\n    background-color: #fff;\n    opacity: 1;\n}\n.input-group{\nborder:2px solid gray;\n}\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 60px;\n  height: 34px;\n}\n\n\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #ccc;\n  -webkit-transition: .4s;\n  transition: .4s;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  height: 26px;\n  width: 26px;\n  left: 4px;\n  bottom: 4px;\n  background-color: white;\n  -webkit-transition: .4s;\n  transition: .4s;\n}\n\ninput:checked + .slider {\n  background-color: #2196F3;\n}\n\ninput:focus + .slider {\n  box-shadow: 0 0 1px #2196F3;\n}\n\ninput:checked + .slider:before {\n  -webkit-transform: translateX(26px);\n  -ms-transform: translateX(26px);\n  transform: translateX(26px);\n}\n\n/* Rounded sliders */\n.slider.round {\n  border-radius: 34px;\n}\n\n.slider.round:before {\n  border-radius: 50%;\n}\n"}}/>
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <CommonHeder></CommonHeder>
        <div style={{paddingBottom: '76px'}} className="padding22" />
        {/* ========================== New header end ========================= */}
        <div style={{marginTop: '13rem'}} className="margin">
        </div>
        {/* <a href="index.html"><img class="logo-create-page text-center img-fluid"src="./img/registration/Logo.png"/></a> */}
        {/* ./img/homepage/b-2.png */}
        <div className="container c1 mx-auto" style={{ background: 'url(./img/cancerpage/Back-new.png) no-repeat center', WebkitBackgroundSize: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <div className="head-text pb-5">
            <h1 className="head-text-h1 text-center img-fluid roboto-black-36px">Account Settings </h1>
            <p className="text-center img-fluid para roboto-reg-16px">Manage your profile, password and more.</p>
            {/* ---------------------------------------------------section 2 started-----------*/}
            {/* style="border:1px solid red" */}
            {/* style="max-width:860px;border-radius:1rem;margin:auto;box-shadow: 0 17px 40px rgba(92, 103, 108, 0.2);" */}
            <div className=" container mt-5 pt-3 b1-crr ">
              <div style={{ maxWidth: '460px', margin: 'auto' }}>
                <h4 id="text1" className="py-3 roboto-bold-24px"> Personal Details</h4>
                <section>
                  <div id="t11" className="form-row">
                    <div className="col-sm-2 mb-3">
                      {/* <img className="img-fluid" src="./img/setting/Layer 1.png" /> */}
                    </div>
                    <div className="col-sm-5 my-2">
                      <p className="mb-0 pt-1" style={{ fontSize: '18px', fontWeight: 700, color: '#4f5be7' }}>{this.state.userData.lastName}&nbsp;{this.state.userData.firstName}</p>
                      <p>{this.state.userData.email}</p>
                    </div>
                    {/* enableme() */}
                    <div className="col-sm-5 my-3">
                      <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center" style={{ fontSize: '14px', borderRadius: '1.875rem' }} type="submit" onClick={() => this.setState({ update: "" })}>
                        <i className="fas fa-pen" />
                        <b>Edit</b></button>
                    </div>

                  </div>
                  {this.state.update ? '' : this.alert()}
                  <p className="mb-0 pt-1" style={{ fontSize: '18px', fontWeight: 700, color: '#4f5be7' }}>Account Number&nbsp;{this.state.userData.accountNumber}</p>

                  <form className="cr-form pt-0 gc-form needs-validation" onSubmit={this.userUpdate} >
                    <div id="t22" className="form-row ">
                      <div className=" col-sm-6 mb-3" htmlFor="validationTooltip01">
                        <div className="input-group ">
                          <input type="text" name="lastName" className="form-control m-0" placeholder="First Name" value={this.state.userData.lastName} onChange={this.changeHandler} readOnly={this.state.update} required />
                          <div className="valid-tooltip">
                            Looks good!
 </div>
                          <div className="input-group-prepend border-0">
                            <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                          </div>
                        </div>
                      </div>
                      <div id="t33" className=" col-sm-6 mb-3">
                        <div className="input-group">
                          <input id="t2" type="text" name="firstName" className="form-control m-0" placeholder="Last Name" value={this.state.userData.firstName} onChange={this.changeHandler} readOnly={this.state.update} required />
                          <div className="valid-tooltip">
                            Looks good!
 </div>
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i1.png" /></span>
                          </div>
                        </div>
                      </div>
                    </div>{/* end of form-row */}
                    <div id="t44" className="form-row ">
                      <div className="col-sm-12 mb-3">
                        <div className="input-group">
                          <input id="t3" type="text" name="phone" value={this.state.userData.phone} onChange={this.changeHandler} readOnly className="form-control cr-email m-0" placeholder="Phone" aria-describedby="validationTooltipEmailPrepend" required />
                          <div className="invalid-tooltip">
                            Please enter a valid Phone number.
 </div>
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/mob.png" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="t44" className="form-row ">
                      <div className="col-sm-12 mb-3">
                        <div className="input-group mr-5">
                          <input id="t3" type="text" name="email" value={this.state.userData.email} className="form-control cr-email m-0" onChange={this.changeHandler} readOnly placeholder="Email Id"  aria-describedby="validationTooltipEmailPrepend" required />
                          <div className="invalid-tooltip">
                            Please enter a valid E-mail ID.
 </div>
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="t22" className="form-row ">
                      <div className=" col-sm-6 mb-3" htmlFor="validationTooltip01">
                        <div className="input-group ">
                          <input id="t1" type="text" name="address1" className="form-control m-0" value={this.state.userData.address1} onChange={this.changeHandler} placeholder="Address 1" readOnly={this.state.update} required />
                          <div className="valid-tooltip">
                            Looks good!
 </div>
                          <div className="input-group-prepend border-0">
                            <span className="input-group-text border-0" id="validationTooltip01"><img src="img/payment/ship-Icon-3.png" /></span>
                          </div>
                        </div>
                      </div>
                      <div id="t33" className=" col-sm-6 mb-3">
                        <div className="input-group">
                          <input id="t2" type="text" name="address2" className="form-control m-0" value={this.state.userData.address2} onChange={this.changeHandler} placeholder="Address 2" readOnly={this.state.update} required />
                          <div className="valid-tooltip">
                            Looks good!
 </div>
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="validationTooltip02"><img src="img/payment/ship-Icon-3.png" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="t22" className="form-row ">
                      <div className=" col-sm-6 mb-3" htmlFor="validationTooltip01">
                        <div className="input-group ">
                          <input id="t1" type="text" name="city" value={this.state.userData.city} className="form-control m-0" onChange={this.changeHandler} placeholder="City" readOnly={this.state.update} required />
                          <div className="valid-tooltip">
                            Looks good!
 </div>
                          <div className="input-group-prepend border-0">
                            <span className="input-group-text border-0" id="validationTooltip01"><img src="img/payment/ship-Icon-2.png" /></span>
                          </div>
                        </div>
                      </div>
                      <div id="t33" className=" col-sm-6 mb-3">
                        <div className="input-group">
                          <input id="t2" type="text" name="pinCode" value={this.state.userData.pinCode} className="form-control m-0" onChange={this.changeHandler} placeholder="Pincode" readOnly={this.state.update} required />
                          <div className="valid-tooltip">
                            Looks good!
 </div>
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="validationTooltip02"><img src="img/payment/ship-Icon-2.png" /></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col-sm-6 mb-3 d-flex justify-content-center">
                        {/* <button class="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center"style="border-radius:1.875rem;background:#4f5be7;color:white " type="submit" onclick="disaableme()" >
 <b>UPDATE</b></button> */}
                      </div>
                    </div>
                    {/* <hr> */}
                    <div className="d-flex">
                      <h4 id="text1" className="pb-4 roboto-bold-24px"> Reset Password</h4>
                      <input id="radio1" name="radio1" className="mr-2 ml-4" type="radio" onClick={() => this.setState({ resetPassword: true })} />Yes
 <input id="radio1" name="radio1" className="mr-2 ml-4" type="radio" onClick={() => this.setState({ resetPassword: false })} defaultChecked />No
 {/* <label class="switch">
 <input type="checkbox" >
 <span class="slider round"></span>
</label> */}
                    </div>
                    {this.state.resetPassword ?
                      ""
                      : <div className="col-sm-6 ">
                        <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center" disabled={this.state.update} style={{ borderRadius: '1.875rem', background: '#4f5be7', color: 'white' }} >
                          <b>UPDATE</b></button>
                      </div>}
                    {/* --------------change password */}
                    {this.state.resetPassword ?
                      <div className=" container pb-5 mb-2 b1-crr ">
                        <div className style={{ maxWidth: '460px', margin: 'auto' }}>
                          <div className=" form-row">
                            <div className="col-sm-12 mb-3">
                              {/* <div className="input-group">
 <input type="password" name="cpassword" onChange={this.changeHandler} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="Your current password" aria-describedby="validationTooltipEmailPrepend" required />
 <div className="invalid-tooltip">
 Your current password
 </div>
 <div className="input-group-prepend">
 <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/payment/pwd.png" /></span>
 </div>
 </div> */}
                            </div>
                          </div>
                          <div className="form-row ">
                            <div className="col-sm-12 mb-3">
                              <div className="input-group">
                                <input type="password" name="password" onChange={this.changeHandler} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="New password" aria-describedby="validationTooltipEmailPrepend" readOnly={this.state.update} required />
                                <div className="invalid-tooltip">
                                  Your New password
 </div>
                                <div className="input-group-prepend">
                                  <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/payment/pwd.png" /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="p3" className="form-row ">
                            <div className="col-sm-12 mb-3">
                              <div className="input-group">
                                <input type="password" name="confirmPwd" onChange={this.changeHandler} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="Confirm new password" aria-describedby="validationTooltipEmailPrepend" readOnly={this.state.update} required />
                                <div className="invalid-tooltip">
                                  Confirm New password
 </div>
                                <div className="input-group-prepend">
                                  <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/payment/pwd.png" /></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row mt-3">
                            <div className="col-sm-6 ">
                              <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center" disabled={this.state.update} style={{ borderRadius: '1.875rem', background: '#4f5be7', color: 'white' }} type="submit">
                                <b>UPDATE</b></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      : ""}
                  </form>
                </section></div>
            </div>
            {/* Form */}
          </div>{/* end of cr-sec-main */}
        </div>
        <div style={{ paddingTop: '12.25rem' }}>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        <footer className="footer-sec">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">
              <a style={{ color: 'gray' }} href="al_termsofservices.html">Terms of Service</a>&nbsp;|&nbsp;
 <a style={{ color: 'gray' }} href="al_privacy_policy.html">Privacy Policy</a> &nbsp;|&nbsp;
 {/* <a style="color:gray" href="#">Consent for Research</a>&nbsp;|&nbsp; */}
              {/* <a style="color:gray" href="#">Mission</a>&nbsp;|&nbsp; */}
              <a style={{ color: 'gray' }} href="#">Blog</a>
              {/* &nbsp;|&nbsp; */}
              {/* <a style="color:gray" href="#">Customer Care</a>&nbsp;|&nbsp; */}
              {/* <a style="color:gray" href="#">Careers</a>&nbsp;|&nbsp; */}
            </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    )
  }
}
export default Settings;