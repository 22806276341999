import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class CarouselCards2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} indicators={false} interval={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                  <div className="card card-1 rounded  newcard ">
                    <div className="card-body  text-center ">
                      <span className="card-number op-no-1">1</span>
                      <div className=" op-img-box  ">
                        <span className="op-img-wrap ">
                          <img
                            className="card-img-top"
                            src="heart-img/calender-icon.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">Schedule An Appointment</h5>
                      <p>
                        It’s fast and easy to schedule your appointment online.
                        You can do this from the comfort of your home.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card card-2 rounded newcard">
                    <div className="card-body  text-center ">
                      <span className="card-number op-no-2">2</span>
                      <div className=" op-img-box">
                        <span className="op-img-wrap">
                          <img
                            className="card-img-top"
                            src="heart-img/call-out-icon.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">Initial consultation </h5>
                      <p>
                        We review your personal health history, answer your
                        questions, and, if you are interested, help you select
                        and order the right tests.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                  <div className="card card-3 rounded  newcard">
                    <div className="card-body  text-center">
                      <span className="card-number op-no-3">3</span>
                      <div className=" op-img-box">
                        <span className="op-img-wrap">
                          <img
                            className="card-img-top"
                            src="heart-img/sample-collection.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">sample collection</h5>
                      <p>
                        If you decide to have testing done, we will ship the
                        sample collection kit to your preferred address.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card card-4 rounded  newcard">
                    <div className="card-body  text-center">
                      <span className="card-number op-no-4">4</span>
                      <div className=" op-img-box  ">
                        <span className="op-img-wrap">
                          <img
                            className="card-img-top"
                            src="heart-img/results-icon.png"
                            alt="Card image cap"
                          />
                        </span>
                      </div>
                      <h5 className="card-title">results consultation</h5>
                      <p>
                        Our certified genetic counsellors will review your test
                        results, walk you through their implications, and answer
                        your questions.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo splitter */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default CarouselCards2;
