import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import SubMainFooter from './SubMainFooter';


class RiskSummary extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        {/* header */}
        <CommonHeder></CommonHeder>
        {/* end of header */}
        <section className="risk-summery-sec">
          <div className="rs-sec-wrap  p-0 container ">
            <div className="card text-center rounded border-0 shadow-lg">
              <div className="card-header p-4 clearfix border-0 ">
                <div className="rs-header d-flex flex-column justify-content-start float-left">
                  <h2>{Cookies.get('username')}</h2>
                  <span className=" ">Hereditary Cancer Report</span>
                </div>{/* eo-rs-header */}
                <ul className="nav  card-header-tabs justify-content-end float-right">
                  <li className="nav-item">
                    <a className="nav-link" href="#">Genetic counselling appointment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Share report</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true">Download report</a>
                  </li>
                </ul>
              </div>{/* eo-card-header */}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-head px-4 py-5 container ">
                  <h2 className="card-title">Risk Summary</h2>
                  <p className="card-text">John, the results from your test do not indicate any increased genetic risks for inherited cancers</p>
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-genetic-mutation.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      No genetic mutations were identified.
                        </h5>
                    <p className="card-text">We analysed 90 genes that are associated with breast, colorectal, ovarian, pancreatic and stomach cancers. No mutations were identified in these genes.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">See your Detailed Report</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="container seperatorline" />
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-health-history.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      Your health history is incomplete.
                        </h5>
                    <p className="card-text">You have not completed your health history, which may impact the risk and screening guidelines for you. Although your have tested negative, 85 - 90% of all cancers have a non-genetic cause. When cancer is detected early it improves the odds of survival.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Update your health history now</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
            </div>{/* eo-card-body*/}
          </div>{/* eo-card */}
          {/* eo-rs-sec-wrap */}
        </section>{/* eo-risk-summery-sec */}
        <SubMainFooter></SubMainFooter>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default RiskSummary;