import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";

class NextSteps extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        {/* header */}
        <CommonHeder></CommonHeder>
        {/* end of header */}
        <section className="risk-summery-sec">
          <div className="rs-sec-wrap  p-0 container ">
            <div className="card rs-main-card text-center rounded border-0 shadow-lg">
              <div className="card-header p-4 clearfix border-0 ">
                <div className="rs-header d-flex flex-column justify-content-start float-left">
                  <h2>{Cookies.get('username')}</h2>
                  <span className=" ">Hereditary Cancer Report</span>
                </div>{/* eo-rs-header */}
                <ul className="rs-nav nav  card-header-tabs justify-content-end float-right">
                  <li className="nav-item">
                    <a className="nav-link" href="#">Genetic counselling appointment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">Share report</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true">Download report</a>
                  </li>
                </ul>
              </div>{/* eo-card-header */}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-head px-4 py-5 container ">
                  <h2 className="card-title">Risk Summary</h2>
                  <p className="card-text">John, the results from your test do not indicate any increased genetic risks for inherited cancers</p>
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-genetic-mutation.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      No genetic mutations were identified.
                        </h5>
                    <p className="card-text">We analysed 90 genes that are associated with breast, colorectal, ovarian, pancreatic and stomach cancers. No mutations were identified in these genes.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">See your Detailed Report</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="container seperatorline" />
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-health-history.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      Your health history is incomplete.
                        </h5>
                    <p className="card-text">You have not completed your health history, which may impact the risk and screening guidelines for you. Although your have tested negative, 85 - 90% of all cancers have a non-genetic cause. When cancer is detected early it improves the odds of survival.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Update your health history now</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="dv-block-wrap d-flex flex-column justify-content-start">
                  <div className="dv-head cb-head px-4 pt-5 pb-0 container">
                    <h2 className="card-title">Detailed View</h2>
                    <ul className="dv-nav nav  card-header-tabs">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Your Risks</a> */}
                        <Link to="/risksummary" className="nav-link">Your Risks</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link nav-active" href="details.html">Details</a> */}
                        <Link to="/details" className="nav-link">Details</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="next-steps.html nav-active">Next Steps</a> */}
                        <Link to="/nextsteps" className="nav-link nav-active">Next Steps</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="family-history.html">Family History</a> */}
                        <Link to="/familyhistory" className="nav-link">Family History</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="genetic-counselling.html">Genetic Counselling</a> */}
                        <Link to="/genetic-counselling" className="nav-link">Genetic Counselling</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="share-details.html">Share Your Report</a> */}
                        <Link to="/sharedetails" className="nav-link">Share Your Report</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">faqs</a> */}
                        <Link to="/faqs" className="nav-link">faqs</Link>

                      </li>
                    </ul>
                  </div>{/* eo-dv-block-cb-head */}
                </div>{/* eo-dv-block-wrap */}
              </div>{/* eo-card-body*/}
              <section className="next-steps-wrap">
                <div className="ns-sec-body container-fluid ">
                  <div className="ns-sec container ">
                    <div className="ns-header">
                      <h5>
                        Know your screening guidelines
                          </h5>
                      <p className="ns-text card-text">Below are guidelines for women who have the same cancer risk as the average US woman. Your healthcare provider may use these screening guidelines to help create a customized screening plan for you.</p>
                    </div>{/* eo-ns-header */}
                    <div className="ns-card-container container">
                      <div clas="ns-main-title">
                        <h2>Breast</h2>
                      </div>
                      <div className="row">
                        <div className="ns-card-wrap  col-12 col-md-6 col-lg-3">
                          <div className="card card-1 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">Starting at age</p>
                                <p className="card-number">25</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">Breast awareness - Women should be familiar with their breasts and promptly report changes to their healthcare provider.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-1 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-6 col-lg-3">
                          <div className="card card-2 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">Between ages</p>
                                <p className="card-number">25-39</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">Breast exam, risk assessment, and risk reduction counseling by your provider every 1-3 years.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-2 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-6 col-lg-3">
                          <div className="card card-3 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">Starting at age</p>
                                <p className="card-number">40</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">Breast exam, risk assessment, and risk reduction counseling by your provider and mammogram every year. Your provider may discuss screening with tomosynthesis
                                      (3D mammogram).</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-3 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-6 col-lg-3">
                          <div className="card card-4 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">After age</p>
                                <p className="card-number">75</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">Your provider may discuss an individualized management plan with you.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-4 */}
                        </div>{/*eo-ns-card-wrap */}
                      </div>{/* eo-row */}
                    </div>{/* eo-ns-card-container */}
                    <div className="ns-card-container container">
                      <div clas="ns-main-title">
                        <h2>Colorectal</h2>
                      </div>
                      <div className="row">
                        <div className="ns-card-wrap  col-12 col-md-6 col-lg-6">
                          <div className="card card-5 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">Between ages</p>
                                <p className="card-number">50-75</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">copy every 10 years, or Stool-based testing (high-sensitivity guaiac-based or immunochemical-based) every year, or Stool-based FIT-DNA testing every 3 years, or Flexible sigmoidoscopy every 5-10 years, or CT colonography every 5 years.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-1 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-6 col-lg-6">
                          <div className="card card-6 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">After age</p>
                                <p className="card-number">75</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">our provider may discuss an individualized management plan with you. These recommendations may change if you have polyps, colon cancer, inflammatory bowel disease (IBD), or family history of colorectal cancer.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-2 */}
                        </div>{/* eo-ns-card-wrap */}
                      </div>{/* eo-row */}
                    </div>{/* eo-ns-card-container */}
                    <div className="ns-card-container container">
                      <div className="row">
                        <div className="ns-card-wrap  col-12 col-md-12 col-lg-6">
                          <div clas="ns-main-title">
                            <h2>Uterine</h2>
                          </div>
                          <div className="card card-5 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">At the time of</p>
                                <p className="card-number">Menopause</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">All women should be told about the risks and symptoms of uterine cancer. Women should report any unexpected vaginal bleeding or spotting to their doctors. Some women, because of their history, may need to consider having a yearly uterine biopsy. Speak with a healthcare provider about your history.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-1 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-12 col-lg-6">
                          <div clas="ns-main-title">
                            <h2>Melanoma</h2>
                          </div>
                          <div className="card card-6 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="card-top">
                                <p className="card-title">To reduce the chance of developing</p>
                                <p className="card-number">Melanoma</p>
                              </div>{/* eo-card-top */}
                              <div className="card-btm">
                                <p className="card-text">The American Cancer Society recommends limiting exposure to UV light by avoiding excess sun exposure, wearing a hat, sunglasses and long protective clothing, applying sunscreen with SPF of 30 or higher and avoiding tanning beds and sun lamps. Any new, unusual, or changing moles should be reported to your provider or dermatologist.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-2 */}
                        </div>{/* eo-ns-card-wrap */}
                      </div>{/* eo-row */}
                    </div>{/* eo-ns-card-container */}
                    <div className="ns-card-container container">
                      <div className="row">
                        <div className="ns-card-wrap  col-12 col-md-6 col-lg-4">
                          <div className="card card-1 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="pos-header">
                                <h2>Pancreatic</h2>
                                {/* <p class= "card-number">25</p> */}
                              </div>{/* eo-card-top */}
                              <div className="card-btm pos-btm">
                                <p className="card-text">Currently, there are no standard screening guidelines for pancreatic cancer. Please discuss any family history of pancreatic cancer with your healthcare provider.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-1 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-6 col-lg-4">
                          <div className="card card-2 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="pos-header">
                                <h2>Ovarian</h2>
                                {/* <p class= "card-number">25-39</p> */}
                              </div>{/* eo-card-top */}
                              <div className="card-btm pos-btm">
                                <p className="card-text">Currently, there are no standard screening guidelines for ovarian cancer. Please discuss any family history of ovarian cancer with your healthcare provider.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-2 */}
                        </div>{/* eo-ns-card-wrap */}
                        <div className="ns-card-wrap col-12 col-md-6 col-lg-4">
                          <div className="card card-3 shadow-lg r1 border-0">
                            <div className="card-body p-0">
                              <div className="pos-header">
                                <h2>Stomach</h2>
                                {/* <p class= "card-number">40</p> */}
                              </div>{/* eo-card-top */}
                              <div className="card-btm pos-btm">
                                <p className="card-text">Currently, there are no standard screening guidelines for stomach cancer. Please discuss any family history of stomach cancer with your healthcare provider.</p>
                              </div>{/* eo-card-btm */}
                            </div>{/* eo-card-body */}
                          </div>{/* eo-card-3 */}
                        </div>{/* eo-ns-card-wrap */}
                      </div>{/* eo-row */}
                    </div>{/* eo-ns-card-container */}
                    <div className="gen-recommend-wrap container">
                      <div className="gen-rec-main-title">
                        <h2>General recommendations for all individuals</h2>
                      </div>
                      <div className="row px-3">
                        <div className="gr-column-1 gr-columns col-6 col-md-4 col-lg-2">
                          <div className="gr-column-img-wrap">
                            <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-tobacco.png" alt="Tobacco image cap" />
                          </div>{/* gr-column-img-wrap */}
                          <div className="gr-cloumn-text-wrap">
                            <p>Avoid all forms of tobacco.</p>
                          </div>{/* gr-cloumn-text-wrap */}
                        </div>{/* gr-column-1 */}
                        <div className="gr-column-2 gr-columns col-6 col-md-4 col-lg-2">
                          <div className="gr-column-img-wrap">
                            <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-alcohol.png" alt="Alcohol image cap" />
                          </div>{/* gr-column-img-wrap */}
                          <div className="gr-cloumn-text-wrap">
                            <p>Avoid or limit your alcohol intake.</p>
                          </div>{/* gr-cloumn-text-wrap */}
                        </div>{/* gr-column-1 */}
                        <div className="gr-column-3 gr-columns col-6 col-md-4 col-lg-2">
                          <div className="gr-column-img-wrap">
                            <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-weight.png" alt="Weight image cap" />
                          </div>{/* gr-column-img-wrap */}
                          <div className="gr-cloumn-text-wrap">
                            <p>Get to and stay at a healthy weight.</p>
                          </div>{/* gr-cloumn-text-wrap */}
                        </div>{/* gr-column-1 */}
                        <div className="gr-column-4 gr-columns col-6 col-md-4 col-lg-2">
                          <div className="gr-column-img-wrap">
                            <img className=" img-fluid text-center d-block " src="./img/risk-summary/Icon-physical.png" alt="Physical image cap" />
                          </div>{/* gr-column-img-wrap */}
                          <div className="gr-cloumn-text-wrap">
                            <p>Get moving with regular physical activity.</p>
                          </div>{/* gr-cloumn-text-wrap */}
                        </div>{/* gr-column-1 */}
                        <div className="gr-column-5 gr-columns col-6 col-md-4 col-lg-2">
                          <div className="gr-column-img-wrap">
                            <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-fruits.png" alt="Fruits image cap" />
                          </div>{/* gr-column-img-wrap */}
                          <div className="gr-cloumn-text-wrap">
                            <p>Eat healthy with plenty of fruits and vegetables.</p>
                          </div>{/* gr-cloumn-text-wrap */}
                        </div>{/* gr-column-1 */}
                        <div className="gr-column-6 gr-columns col-6 col-md-4 col-lg-2">
                          <div className="gr-column-img-wrap">
                            <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-skincare.png" alt="Skincare image cap" />
                          </div>{/* gr-column-img-wrap */}
                          <div className="gr-cloumn-text-wrap">
                            <p>Protect your skin.</p>
                          </div>{/* gr-cloumn-text-wrap */}
                        </div>{/* gr-column-1 */}
                      </div>{/* eo-row */}
                      <div className="gen-rec-footer-wrap">
                        <div className="gen-rec-footer-title">
                          <h5>Know yourself, your family history, and your risks</h5>
                        </div>{/* gen-rec-footer-title */}
                        <div className="gen-rec-footer-text">
                          <p>Get regular check-ups and cancer screening tests. A cancer-related check-up should include health counseling and, depending on a person’s age and gender, exams for cancers of the thyroid, oral cavity, skin, lymph nodes, testes, and ovaries, as well as for some other diseases besides cancer.</p>
                        </div>{/* gen-rec-footer-text */}
                      </div>{/* gen-rec-footer-wrap */}
                    </div>{/* eo-gen-recommend-wrap */}
                  </div>{/* eo-ns-sec */}
                </div>{/* eo-ns-sec-body*/}
              </section>{/* eo-next-steps-wrap */}
              {/* end of genetic counselling card */}
            </div>{/* eo-rs-main-card*/}
          </div>{/* eo-rs-sec-wrap */}
        </section>{/* eo-risk-summery-sec */}
        <footer className="footer-sec">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
                </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default NextSteps;