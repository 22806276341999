import React, { Component } from 'react';
import './App.css';
import { withRouter } from 'react-router-dom'
import './styles.css';
import HeaderLinks from './components/HeaderLinks';
import 'react-sticky-header/styles.css';
import Prospect from './components/Prospect';
import Footer from './components/Footer';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import NewMainHeader from './components/NewMainHeader';
import CarouselCards2 from './components/CarouselCards2';
import CarouselCards1 from './components/CarouselCards1';
import StickyContactMenu from './components/StickyContactMenu';
import MetaTags from 'react-meta-tags';

import * as constants from './components/paymentContants';






import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MobileFooter from './components/MobileFooter';
function initializeReactGA() {
  ReactGA.initialize('UA-148376759-1');
  ReactGA.pageview(constants.trugenyUrl);
  ReactGA.pageview(constants.trugenyUrl+'/about-us');
  ReactGA.pageview(constants.trugenyUrl+'/help');
  ReactGA.pageview(constants.trugenyUrl+'/login');
  ReactGA.pageview(constants.trugenyUrl+'/register');
  ReactGA.pageview(constants.trugenyUrl+'/ordertrugene');
  ReactGA.pageview(constants.trugenyUrl+'/book-counselling');
  ReactGA.pageview(constants.trugenyUrl+'/ThanksListen');
 
 }
 const tagManagerArgs = {
  gtmId: 'GTM-K27BQ7L'
 }
 TagManager.initialize(tagManagerArgs)

 const responsive = {

  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class App extends Component {
  constructor(props) {
    super(props);
  
 

  
  }


  
componentDidMount(){
   if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('sw.js').then(function (registration) {
          // console.log('Worker registration successful', registration.scope);
        }, function (err) {
          console.log('Worker registration failed', err);
        }).catch(function (err) {
          console.log(err);
        });
      });
    } else {
      console.log('Service Worker is not supported by browser.');
    }
}


  toGeneticCouncelling() {
      window.location.href="/genetic-counselling";
    }

  toCancer() {
      window.location.href="/cancer";
    }
  toHeart() {
      window.location.href="/heart";
    }
    toFamily() {
        window.location.href="/family-planning";
      }

    toRiskWelcome() {
        window.location.href="/risk-welcome";
      }

  render() {

    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"> */}
        <link rel="stylesheet" href="newsticky.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
    integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
    crossorigin="anonymous"> */}
        <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        <link rel="stylesheet" href="./styleinline.css" />
        <link rel="stylesheet" href="./style-mobile2.css" />
        <link rel="stylesheet" href="style-mobile.css" />
        <MetaTags>
        <title>TruGeny: DNA Test | Genetic Test Kit | Genetic Counselling</title>
		    <meta name="description" content="truGeny helps you to learn about your genes to understand your risks and take actions for preventing diseases and leading a healthier life."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="TruGeny: DNA Test | Genetic Test Kit | Genetic Counselling"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="truGeny helps you to learn about your genes to understand your risks and take actions for preventing diseases and leading a healthier life."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />

        </MetaTags>
        <style dangerouslySetInnerHTML={{__html: "\n .card-body {\n     -ms-flex: 1 1 auto;\n     flex: 1 1 auto;\n     padding: .75rem;\n }\n " }} />
        {/* <div id="slider" style={{right: '-342px'}}>
          <div id="sidebar" onclick="open_panel()">
            <p><i title="support@trugeny.com" className="py-2 fa fa-envelope " style={{color: 'white'}} /></p>
            <p><i title="1800 1234567" className="pb-2 fa fa-phone " style={{color: 'white'}} /></p>
          </div>
          <div id="header">
            <h3 className="text-center">Contact us</h3>
            <div className="signin-register">
              <ul>
                <li><a className="text-lowercase" style={{fontSize: '18px!important'}} href="mailto:support@trugeny.com"><i className="fas fa-envelope-open" /> support@trugeny.com</a></li>
                <li><a className="text-lowercase" style={{fontSize: '18px!important'}} id="register" href="tel:1800 1234455"><i className="fas fa-phone-volume" />
                    1800 1234455</a></li>
              </ul>
            </div>
          </div>
        </div> */}
        
        <a id="back2Top" title="Back to top" href="#" />
     
          
        <div className="background-index">
          <NewMainHeader category="foryou"></NewMainHeader>
         </div>
        <div className="vspacemain" />
        <StickyContactMenu ></StickyContactMenu>
        {/* ------------------------------------------------1st section over---------------------------------------------- */}
        <section>
          <div className="container homepage-sec2">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mt-sm-5 pt-sm-5">
                <img className="img-fluid imgfluidMobile" src="./img/homepage/Illu_1.png" />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h2 className="roboto-black-30 pt-3">You inherit a lot from your family, including your HEALTH</h2>
                <p className="roboto-reg-18px" style={{color: '#2c333c'}}>Genes are passed on from a parent to the child. A child gets one set of genes from the father and one set from the mother. Genes control the way you look, the way your body works and genes are what make you unique. Genes can also increase the risk in a family for developing certain diseases such as cancers, heart conditions amongst many others.
                </p>
                <p className="roboto-reg-18px" style={{color: '#2c333c'}}>Learning about your genes can help you understand your risks better, take actions for preventing diseases and lead a healthier life.</p>
                {/* <button type="button" class="btn btn-outline-primary mt-2 btn-hover-white rounded-pill my-3"  onclick="window.location.href = 'genetic-counseling.html#sec1';" href="#"   ><b>LEARN MORE</b> &nbsp;<i class="fas fa-long-arrow-alt-right"></i> */}
              </div>
            </div>
          </div>
        </section>
        {/* ------------------------------------2nd sec end--------------------------------------- */}
        <div className="paddingme" />
        {/* ------------------------------------3rd sec statted--------------------------------------- */}
        <div className="container  mb-5 ">
          <h2 className="text-center roboto-black-36px">There are over 7.7 billion people on this planet
          </h2>
          <h2 className="text-center roboto-reg-36px">but there is only ONE you!</h2>
        </div>
        <div className="container text-center ">
          <img className="img-fluid paddmar" src="./img/homepage/mapview.png" />
        </div>
        {/* ----------------------------------------------------3rd end----------------------------------- */}
        <h2 className="text-center roboto-black-36px my-5 spaceConMain">So how do you beat your odds of developing a hereditary disease?</h2>

        <div className="container  mb-5 my-5 pb-3" style={{boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', borderRadius: '2.5rem', backgroundColor: '#ffffff'}}>
          <h2 className="text-center roboto-black-36px pt-5">truGeny empowers you</h2>
          <h2 className="text-center roboto-reg-36px pb-3">with knowledge about your genes</h2>
          {/* --------------------------------------------------------------------------------------------------------------- */}
          <div className="container mt-4 sec6  px-4">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12  order-sm-0 ">
                <h2 className="roboto-black-30" style={{paddingBottom: '14px'}}><strong>Expert genetic guidance</strong></h2>
                <h3 className="roboto-bold-18px pb-3">A genetic Counselling session with our genetic counselor can help you:</h3>
                <ul className="roboto-reg-18px pl-2">
                <li>Understand your risk for developing a genetic disease based on your family history or a genetic test result</li>
                <li>Determine if genetic testing is a good option for you</li>
                <li>Choose the right genetic test</li>
                <li>Understand your results</li>
                <li className="pb-4"> Make the most informed decisions for a healthier, longer life.</li>
              </ul>

                <button type="button" className="btn btn-outline-primary mt-2 btn-hover-white rounded-pill my-3" onClick={this.toGeneticCouncelling} href="#"><b>LEARN MORE</b> &nbsp;<i className="fas fa-long-arrow-alt-right" />
                  {/* <button  class="custom-button btn btn-outline-primary my-2 my-sm-0 btn-hover-white" onclick="window.location.href = 'genetic-counseling.html';" type="submit" ><b>LEARN MORE</></button> */}
                </button></div>
              <div className="col-lg-6 col-md-6 col-sm-12  order-sm-1  rounded mx-auto d-block">
                <img className="center img-fluid rounded mx-auto d-block" src="./img/homepage/Illu_4.png" />
              </div>



            </div>
          </div>
        </div>
        <div className="pb3rem" />
        {/* --------------------------------------delete me--------------------------------
 */}




        <section className="id-1 main-background-2 ">
        <div className="container  pt-5">
        <h2 className="text-center roboto-black-40px pt-lg-3 pt-mb-3 pt-sm-0">Be PROACTIVE about your health</h2>
        <h2 className="text-center roboto-med-30px pt-3" style={{fontWeight: 500}}><b>truGeny brings to you new kind of DNA tests that empower you to understand and take actions for a longer healthier life </b></h2>
        <h3 className="text-center roboto-reg-18px pt-3">Discover DNA insights that will help you take action for a healthier life. Whether you are
          wanting to learn about your health risks or worried about what genetic conditions you might pass on to your kids,
          truGeny's unique DNA testing methodology helps you learn more about yourself than ever before.</h3>
      </div>

          <div className="container pb3rem1">
            <div className="card-deck" id="hide">
             {/* eo-card-deck-wrap */}
              
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2" >
                <div className="card rounded shadow-lg  r4 maincard">
                  <div className="card-body  text-center">
                    <div className="card-block  p-2 circle">
                      <img className="card-img-top text-center  " src="./img/payment/cancer.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                      <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFind</strong><br/><span className="textspancard" >Hereditary Cancer Test</span></h3>                    </div>
                    <p className="roboto-reg-18px pb-4">A detailed analysis of over 100 genes to help you learn your risks for developing cancers of the breast, ovarian, uterine, colorectal, prostate, pancreatic and stomach cancers.</p>
                    <button style={{height: '40px', width: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white " onClick={this.toCancer} type="submit"><b>Learn more</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button>
                    <br /><br />
                  </div>
                </div>
              </div>{/* eo-card-deck-wrap */}
              {/* <div class=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2"> */}
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-5 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
              <div className="card  rounded shadow-lg r4 maincard">
                  <div className="card-body text-center">
                    <div className="card-block p-2 circle ">
                      <img className="card-img-top text-center " src="./img/career/familyplanning.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                      <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFamily</strong><br/><span className="textspancard" >Career Screening Test</span></h3>                   
                    </div>
                    <p className="roboto-reg-18px  pb-1">Planning for your future family’s health matters. Knowledge about your risk Of passing on a genetic condition to your Child can make a difference to the well being of your future children.
                    </p><br/>
                    {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                    <button style={{height: '40px', width: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white " onClick={this.toFamily} type="submit"><b>Learn more</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button>
                    <br /><br />
                  </div>
                </div>
              </div>
              
              <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2 pb-5">
                <div className="card  rounded shadow-lg r4 maincard">
                  <div className="card-body text-center">
                    <div className="card-block p-2 circle ">
                      <img className="card-img-top text-center " src="./img/payment/heart.png" style={{height: '126px', width: '126px'}} alt="Card image cap" />
                      <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truHeart</strong><br/><span className="textspancard" >Coronary Artery Disease Test</span></h3>                   

                    </div>
                    <p className="roboto-reg-18px  pb-1">A next generation Polygenic Risk Score (PRS) based genetic test to you help you evaluate your risks for developing heart conditions such as Familial Hypercholesterolemia, Cardiomyopathy, Arrhythmia.
                    </p>
                    {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                    <button style={{height: '40px', width: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white " onClick={this.toHeart} type="submit"><b>Learn more</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button>
                    <br /><br />
                  </div>
                </div>
              </div>{/* eo-card-deck-wrap */}
           
            </div>
            {/* ============================= */}
            <CarouselCards1></CarouselCards1>
          </div>
          {/* ============================= */}
          {/* gr-card-wrap */}
          {/* eo gr-sec-wrap */}
        </section>
        {/* ------------------------------------5thth sec end--------------------------------------- */}
        
        {/* ---------------------------------- */}
        {/* ******************what you get-sec************ */}
        <section className="our-process-sec pb3rem1">
          <div className="container our-process-sec-header ">
            <h2 className="text-center pb3rem1">What do you GET?</h2>
            <p className="punchline">It starts with a conversation</p>
          </div>{/* eo what-you-sec-wrap */}
          <div className="container op-card-wrap text-center">
            <div className="card-deck" id="hide">
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-1 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-1">1</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap ">
                        <img className="card-img-top text-center" src="heart-img/calender-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Schedule An Appointment</h5>
                    <p>It’s fast and easy to schedule your appointment online. You can do this from the comfort of your home.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-2 rounded shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center ">
                    <span className="card-number op-no-2">2</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/call-out-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">Initial consultation </h5>
                    <p>We review your personal health history, answer your questions, and, if you are interested, help you select and order the right tests.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                <div className="card card-3 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-3">3</span>
                    <div className=" op-img-box">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/sample-collection.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">sample collection</h5>
                    <p>If you decide to have testing done, we will ship the sample collection kit to your preferred address.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
              <div className="splitter col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="card card-4 rounded  shadow-lg p-1 mx-0 mb-5 r4">
                  <div className="card-body  text-center">
                    <span className="card-number op-no-4">4</span>
                    <div className=" op-img-box  ">
                      <span className="op-img-wrap">
                        <img className="card-img-top text-center" src="heart-img/results-icon.png" alt="Card image cap" />
                      </span>
                    </div>
                    <h5 className="card-title">results consultation</h5>
                    <p>Our certified genetic counsellors will review your test results, walk you through their implications, and answer your questions.</p>
                  </div>
                </div>{/* eo card */}
              </div>{/* eo splitter */}
            </div>{/* eo wyg-card-deck */}
            {/* ============================= */}
           <CarouselCards2></CarouselCards2>
            {/* ============================= */}
          </div>
        </section>{/* eo of what-you-sec */}
        {/* ******************End of what you get-sec************ */}
        <section className="ayr-sec-main heart-disease-sec-main">
          <div className="ayr-sec-main-wrap heart-disease-sec-main-wrap container">
            <div className="intro-text-wrap text-center">
              <h2 className="text-center ">Help Us to help you</h2>
            </div>
            {/* eo-intro-text-wrap*/}
            <div className="families-sec heart-disease-sec row container">
              <div className=" hd-img col-xs-12 col-sm-12 col-md-6 mb-md-0 mb-sm-5 mb-xs-5 text-center align-self-center ">
                <img className="img-fluid mx-auto" src="./img/cancerpage/Illustrationg.png" />
              </div>
              {/* eo-hd-img */}
              <div className=" col-xs-12 col-sm-12 col-md-6 mt-md-0 mt-sm-5 mt-xs-5 hd-text align-self-center ">
                <h3>Begin your journey towards better health</h3>
                <p className="heartdisease-content pb-2">This simple 3-minute self reported risk assessment will enable you to learn and measure your risks for certain chronic conditions</p>
                <button type="button" className="btn btn-outline-primary btn-hover-white rounded-pill" onClick={this.toRiskWelcome}>What’s my Risk?<i className="fas fa-long-arrow-alt-right" />
                </button>
              </div>
              {/* eo-hd-text */}
            </div>
            {/* eo-row */}
          </div>
          {/* eo-heart-disease-sec-main-wrap*/}
        </section>
        <br />
        {/* ;border:solid 1px red; */}
        <Prospect></Prospect>
        {/* <div style="padding-bottom:6rem"></div> */}
        {/* end of footer */}
        {/* ---------------------triel----------------------------- */}
       <Footer></Footer>
        <div className="modal fade bd-example-modal-lg" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{borderRadius: '40px'}}>
              <div className="modal-header bg-blue clearfix border-0" style={{borderTopRightRadius: '40px', borderTopLeftRadius: '40px'}}>
                <h5 className="modal-title" id="exampleModalLongTitle" style={{paddingTop: '5px', paddingBottom: '25px', color: '#2c333c', fontSize: '30px', fontWeight: 700}}>
                  Start your journey in 3 easy steps</h5>
                <button type="button" className="text-center close" data-dismiss="modal" aria-label="Close" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                  <img className="img-fluid" src=".\img\registration\X.png" />
                </button>
                {/* <p class="float-left">Question 1 of 3</p> */}
              </div>
              <div className="bg-blue border-bottom-1">
                <p className="pl-3">Question 1 of 3</p>
              </div>
              <div className="modal-body pb-3 pt-0 m-0">
                <div className="container ">
                  <br />
                  <div className="tab-content">
                    <div id="home" className="container tab-pane active">
                      <br />
                      <h3>How do you indentify your gender?</h3>
                      <hr />
                      <div className="d-flex d-flex-inline">
                        <div className="float-right pt-2 mt-2 mr-5">
                          <img className="btn-hover-white" src="./img/male.png" alt="" style={{borderRadius: '50%', height: '68px', width: '88px'}} />
                          <img className="p-2 pl-3 btn-hover-white" src="./img/female.png" alt="" style={{borderRadius: '50%', height: '68px', width: '88px'}} />
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2 ml-5">
                        <button type="button" className=" btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu1">Next</button>
                      </div>
                    </div>
                    <div id="menu1" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <h4>Certain ethnic groups have higher risk for diabetes,<br />
                        even at lower body weights.</h4>
                      <div className="col-xs-12 mt-2 ">
                        <div className="input-group-prepend " style={{border: 'solid 1px #ebebeb', maxWidth: '312px'}}>
                          <input type="text" placeholder="Select your ethnicity" className="form-control roboto-reg-16px" style={{border: 'none', height: '60px'}} aria-label="Text input with dropdown button" />
                          <button className="m-0 p-0 btn btn-outline-secondary roboto-bold-14px " href="#" style={{border: 'none', backgroundColor: 'white'}} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img className="m-0 p-0" src="img/q&a/carot.png" /></button>
                        </div>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu2">Next</button>
                      </div>
                    </div>
                    <div id="menu2" className=" m-0 p-0 container tab-pane fade">
                      <br />
                      <h3>Does anyone in your family have 2 diabetes?</h3>
                      <hr />
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" defaultChecked /> Parents</label>
                      </div>
                      <div className="radio">
                        <label>
                          <input type="radio" name="optradio" /> Siblings</label>
                      </div>
                      <div className="float-right pt-2 mt-2">
                        <button type="button" className="btn btn-outline-primary text-center custom-button btn btn-outline-primary btn-hover-white my-2 my-sm-0" data-toggle="pill" href="#menu3">Next</button>
                      </div>
                    </div>
                    <div id="menu3" className=" m-0 p-0 container tab-pane fade">
                      <div className="gc-form-card container">
                        <div className="gc-form-card-wrap  ">
                          <div className=" gc-form-card-top">
                            <h2 className="text-center pt-0"> REACH OUT TO LEARN MORE</h2>
                            <p className=" gc-form-subtitle text-center">All you need to do is,fill out the form below and one of our experts will reach out to you very soon. </p>
                            {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
                          </div>{/* gc-form-card-top */}
                          <div className="gc-form-card-btm">
                            <form className="gc-form needs-validation" action="/office//trugene-final/insert2.php" method="post" >
                              <div className="form-row justify-content-center">
                                <div className="gc-col  col-md-5 mb-3" htmlFor="validationTooltip01">
                                  <div className="input-group ">
                                    <input type="text" name="name" className="form-control m-0" id="validationTooltip01" placeholder="Name" required />
                                    <div className="valid-tooltip">
                                      Looks good!
                                    </div>
                                    <div className="input-group-prepend border-0">
                                      <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input type="text" name="reason" className="form-control m-0" id="validationTooltip02" placeholder="Reason" defaultValue="Reason" required />
                                    <div className="valid-tooltip">
                                      Looks good!
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i2.png" /></span>
                                    </div>
                                  </div>
                                </div>
                              </div>{/* end of form-row */}
                              <div className="form-row justify-content-center">
                                <div className=" gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input type="email" name="email" className="form-control m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" required />
                                    <div className="invalid-tooltip">
                                      Please enter a valid E-mail ID.
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input type="text" name="refered" className="form-control m-0" id="validationTooltip03" placeholder="Referred by" defaultValue="Referred by" aria-describedby="validationTooltip03" required />
                                    <div className="valid-tooltip">
                                      Looks good!
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltip03"><img src="img/homepage/i4.png" /></span>
                                    </div>
                                  </div>
                                </div>
                              </div>{/* end of form-row */}
                              <div className="form-row justify-content-center">
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <input className="form-control m-0" type="tel" id="validationTooltip05" placeholder="Phone number" name="phone" pattern="[0-9]{10}" required />
                                    <div className="invalid-tooltip">
                                      Please provide a valid Phone number.
                                    </div>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="validationTooltip05"><img src="img/homepage/i5.png" /></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="gc-col col-md-5 mb-3">
                                  <div className="input-group">
                                    <textarea name="message" className="form-control m-0 " id="validationTextarea" rows={1} placeholder="Message" required defaultValue={""} />
                                  </div>
                                </div>
                              </div>{/* end of form-row */}
                              <div className=" pt-3 mt-4 pb-5">
                                <button className="center-block btn btn-outline-primary btn-hover-white  my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px'}} name="submit" type="submit"><b>SUBMIT</b></button>
                              </div>
                            </form>
                          </div>{/* end of gc-form-card-btm */}
                        </div>{/* end of gc-form-card-wrap */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sticky form---------------- */}
              {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary"  data-toggle="pill" href="#menu2">Close</button>
        <button type="button" class="btn btn-primary"  data-toggle="pill" href="#menu1">Next</button>
      </div> */}
            </div>
          </div>
        </div>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}

        <MobileFooter></MobileFooter>
    
      </div>

    );
  }
}

export default withRouter(App);
