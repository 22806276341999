import React from 'react';
import CommonHeder from './CommonHeder';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";

class ShareDetails extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>trueGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <style dangerouslySetInnerHTML={{ __html: "\n\n.hello{\n\n  width: 890px;\n  height: 560px;\n  box-shadow: 0 17px 73px rgba(0, 0, 0, 0.57);\n  border-radius: 40px;\n  background-color: #ffffff;\n}\n.title-font-modal{\nwidth: 514px;\nheight: 36px;\ncolor: #2c333c;\nfont-family: \"Roboto - Black\";\nfont-size: 36px;\nfont-weight: 400;\n}\n.title-part-modal{\nwidth: 890px;\nheight: 130px;\nbackground-color: #eff7ff;\n}\n.resize-1{\nwidth: 396px;\nheight: 60px;\nbackground-color: #ffffff;\n\n}\n" }} />
        {/* header */}
        <CommonHeder></CommonHeder>
        {/* end of header */}
        <section className="risk-summery-sec">
          <div className="rs-sec-wrap  p-0 container ">
            <div className="card rs-main-card text-center rounded border-0 shadow-lg">
              <div className="card-header p-4 clearfix border-0 ">
                <div className="rs-header d-flex flex-column justify-content-start float-left">
                  <h2>{Cookies.get('username')}</h2>
                  <span className=" ">Hereditary Cancer Report</span>
                </div>{/* eo-rs-header */}
                <ul className="rs-nav nav  card-header-tabs justify-content-end float-right">
                  <li className="nav-item">
                    <a className="nav-link" href="#">Genetic counselling appointment</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" data-toggle="modal" data-target="#exampleModalCenter">Share report</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" tabIndex={-1} aria-disabled="true">Download report</a>
                  </li>
                </ul>
              </div>{/* eo-card-header */}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-head px-4 py-5 container ">
                  <h2 className="card-title">Risk Summary</h2>
                  <p className="card-text">John, the results from your test do not indicate any increased genetic risks for inherited cancers</p>
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-genetic-mutation.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      No genetic mutations were identified.
                        </h5>
                    <p className="card-text">We analysed 90 genes that are associated with breast, colorectal, ovarian, pancreatic and stomach cancers. No mutations were identified in these genes.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">See your Detailed Report</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="container seperatorline" />
              <div className="card-body p-0 container-fluid ">
                <div className="cb-sec-one px-4 py-5 container clearfix ">
                  <div className="rs-img-wrap float-left">
                    <img className=" img-fluid text-center  " src="./img/risk-summary/Icon-health-history.png" alt="Card image cap" />
                  </div>
                  <div className="cb-sec-one-text float-left">
                    <h5>
                      Your health history is incomplete.
                        </h5>
                    <p className="card-text">You have not completed your health history, which may impact the risk and screening guidelines for you. Although your have tested negative, 85 - 90% of all cancers have a non-genetic cause. When cancer is detected early it improves the odds of survival.</p>
                    <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Update your health history now</a>
                  </div>{/* eo-cb-sec-one-text */}
                </div>{/* eo-cb-head */}
              </div>{/* eo-card-body*/}
              <div className="card-body p-0 container-fluid ">
                <div className="dv-block-wrap d-flex flex-column justify-content-start">
                  <div className="dv-head cb-head px-4 pt-5 pb-0 container">
                    <h2 className="card-title">Detailed View</h2>
                    <ul className="dv-nav nav  card-header-tabs">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">Your Risks</a> */}
                        <Link to="/risksummary" className="nav-link">Your Risks</Link>
                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="details.html">Details</a> */}
                        <Link to="/details" className="nav-link">Details</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="next-steps.html nav-active">Next Steps</a> */}
                        <Link to="/nextsteps" className="nav-link">Next Steps</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="family-history.html">Family History</a> */}
                        <Link to="/familyhistory" className="nav-link">Family History</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="genetic-counselling.html">Genetic Counselling</a> */}
                        <Link to="/genetic-counselling" className="nav-link">Genetic Counselling</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="share-details.html">Share Your Report</a> */}
                        <Link to="/sharedetails" className="nav-link nav-active">Share Your Report</Link>

                      </li>
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">faqs</a> */}
                        <Link to="/faqs" className="nav-link">faqs</Link>

                      </li>
                    </ul>
                  </div>{/* eo-dv-block-cb-head */}
                </div>{/* eo-dv-block-wrap */}
              </div>{/* eo-card-body*/}
              <section className="gc-sec-wrap">
                <div className="gc-card-body card-body p-0 container-fluid ">
                  <div className="gc-sec  px-4 py-5 container clearfix">
                    <div className="gc-img-wrap float-right p-3">
                      <img className=" img-fluid text-center d-block " src="./img/risk-summary/share-doctor.png" alt="Card image cap" />
                    </div>
                    <div className="gc-text cb-sec-one-text float-right">
                      <h5>
                        Share your report with your Doctor
                          </h5>
                      <p className="card-text">We encourage you to share your report with your Doctor. This will enable your Doctor to guide you on the appropriate next steps and design a personalised plan for your health.</p>
                      <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Share My Report<i className="fas fa-long-arrow-alt-right" /></a>
                    </div>{/* eo-gc-text */}
                  </div>{/* eo-gc-sec */}
                  <div className="seperatorline" />
                  <div className="gc-sec  px-4 py-5 container clearfix">
                    <div className="gc-img-wrap float-right p-3">
                      <img className=" img-fluid text-center d-block " src="./img/risk-summary/share-family.png" alt="Card image cap" />
                    </div>
                    <div className="gc-text cb-sec-one-text float-right">
                      <h5>
                        Share your report with a family member or friend
                          </h5>
                      <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehen</p>
                      <a className="btn btn-primary rounded-pill text-uppercase" href="#" role="button">Share My Report<i className="fas fa-long-arrow-alt-right" /></a>
                    </div>{/* eo-gc-text */}
                  </div>{/* eo-gc-sec */}
                </div>{/* eo-gc-card-body*/}
              </section>{/* eo-gc-sec-wrap */}
              {/* end of genetic counselling card */}
            </div>{/* eo-rs-main-card*/}
          </div>{/* eo-rs-sec-wrap */}
        </section>{/* eo-risk-summery-sec */}
        <footer className="footer-sec">
          <div className="container footer-wrap text-center">
            <p>2019 truGeny</p>
            <div className="f-social-links">
              <ul className="social-links list-inline">
                <li className="list-inline-item"><a href="#" className="icon-facebook"><i className="fab fa-facebook" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-twitter"><i className="fab fa-twitter" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-google-plus"><i className="fab fa-google" /></a></li>
                <li className="list-inline-item"><a href="#" className="icon-linkedin"><i className="fab fa-linkedin-in" /></a></li>
              </ul>{/* eo .social-links */}
            </div>{/* eo-f-social-links */}
            <div className="col-sm rs-footer-baseline footer-copyright text-center py-3 float-left">Terms of Service  &nbsp;|&nbsp;  Privacy Policy &nbsp;|&nbsp; Notice of Privacy Practices &nbsp;|&nbsp; Informed Consent &nbsp;|&nbsp; Accessability
                </div>
          </div>{/* eo-footer-wrap */}
        </footer>
        {/* Modal */}
        <div className="text-center modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content hello">
              <div className="modal-header title-part-modal">
                <h2 className="modal-title title-font-modal  pr-5 pb-5" id="exampleModalLongTitle">Share my report with my Doctor</h2>
                {/* <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> */}
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <img src=".\img\registration\x.png" />
                </button>
              </div>
              <div className="modal-body">
                <div className="container resize-1">
                </div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                <img src=".\img\registration\2-layers.png" onlick="#" href="#" />
              </div>
            </div>
          </div>
        </div>
        {/* eo Modal */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default ShareDetails;
