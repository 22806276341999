import React from 'react'

class HeaderLinks extends React.Component{

   render(){
  

       return(
        <div>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="stylesheet" href="newsticky.css" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese" rel="stylesheet" />
        
            <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
            <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
            <title>TrueGeny</title>
            <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
            

            <link rel="stylesheet" href="stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/stylesheet_custom.css" />
            <link rel="stylesheet" href="./css/header_footer.css" />
            <link rel="stylesheet" href="./css/fonts.css"/>
            <link rel="stylesheet" href="./css/sticky.css"/>
            <link rel="stylesheet" href="./styleinline.css" />
            <link rel="stylesheet" href="./style-mobile2.css" />
            <link rel="stylesheet" href="./style-mobile.css" />


        </div>
       )
   }

}
export default HeaderLinks;