import React, { Component } from "react";
import {Link,Redirect}  from 'react-router-dom';

import Carousel from "react-bootstrap/Carousel";
class ProductCarouselCards extends Component {
  constructor(props) {
    super(props);
    this.toOrderHeart = this.toOrderHeart.bind(this);
  }

  toOrderHeart() {
    // browserHistory.push("/ordertrugene");
    window.location.href = "/ordertrugene";
  }

  render() {
    return (
      <div
        id="carouselExampleControls"
        className="carousel slide mt-4"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} indicators={false}  interval={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div
                    className="card rounded newcard"
                    style={{ height: "375px" }}
                  >
                    <div className="card-body  text-center">
                      <div className="card-block circle">
                        <img
                          className="card-img-top text-center  "
                          src="./img/payment/cancer.png"
                          style={{ height: "90px", width: "90px" }}
                          alt="Card image cap"
                        />
                                            <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFind</strong><br/><span className="textspancard" >Hereditary Cancer Test</span></h3>

                      </div>
                      <p>
                        A detailed analysis of over 90 genes your genes to help
                        you learn your risks for developing cancers of the
                        breast, ovarian, uterine, colorectal, prostrate,
                        pancreatic and stomach cancers.
                      </p>
                      {/* <h3 class="font-card">Understand your genes and evaluate your risks for cancer and cardiac conditions.</h3> */}
                      {/* <a href="view-reports.html">   <img class="card-img-top text-center p-2 mb-1 button-view-report"  src="./img/payment/view-report-button.png"/></a> */}
                      <Link to={{ pathname: '/ordertrugene', state: { orderedProduct:"trucancer"} }}>      <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3" onClick={this.toOrderHeart}><b>ORDER NOW</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button></Link>

                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div
                    className="card  rounded newcard "
                    style={{ height: "375px" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block circle ">
                        <img
                          className="card-img-top text-center "
                          src="./img/career/familyplanning.png"
                          style={{ height: "90px", width: "90px" }}
                          alt="Card image cap"
                        />
                                             <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truFamily</strong><br/><span className="textspancard" >Career Screening Test</span></h3>                   

                      </div>
                      <p className="mb-4">
                      Planning for your future family’s health matters. Knowledge about your risk Of passing on a genetic condition to your Child can make a difference to the well being of your future children.
                      </p>{" "}
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                      <Link to={{ pathname: '/ordertrugene', state: {orderedProduct:"trufamily" } }}>  <button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3" onClick={this.toOrderHeart} ><b>ORDER NOW</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button></Link>

                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div
                    className="card  rounded newcard "
                    style={{ height: "375px" }}
                  >
                    <div className="card-body text-center">
                      <div className="card-block circle ">
                        <img
                          className="card-img-top text-center "
                          src="./img/payment/heart.png"
                          style={{ height: "90px", width: "90px" }}
                          alt="Card image cap"
                        />
                                            <h3 className="text-highlight pt-4 " style={{fontWeight: 900}}><strong>truHeart</strong><br/><span className="textspancard" >Coronary Artery Disease Test</span></h3>                   

                      </div>
                      <p className="mb-4">
                        A next generation Polygenic Risk Score (PRS) based
                        genetic test to you help you evaluate your risks for
                        developing heart conditions such as familial
                        Hypercholestrolemia, cardiomyopathy, Arrythmia.
                      </p>{" "}
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <a href="view-reports.html">  <img class="card-img-top text-center mb-1 p-2" onclick="view-reports.html" src="./img/payment/view-report-button.png"/></a> */}
                      <Link to={{ pathname: '/ordertrugene', state: { orderedProduct:"truheart"} }}><button style={{height: '40px', weight: '160px'}} className="mx-auto px-3 rounded-pill btn btn-outline-primary btn-hover-white mb-3" onClick={this.toOrderHeart} ><b>ORDER NOW</b>  &nbsp;<i className="fas fa-long-arrow-alt-right" />&nbsp;</button></Link>

                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default ProductCarouselCards;
