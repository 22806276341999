import React, { Component } from "react";

import Carousel from "react-bootstrap/Carousel";
class CancerCarouselCards extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} indicators={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded   newcard cCard">
                    <div className="card-body text-center ">
                      <div className="card-block mt-2 p-2 circle">
                        <img
                          className="card-img img-fluid text-center p-2 "
                          src="./img/cancerpage/cancerpage-bp-icon-1.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text pt-4">
                        You may be able to have regular screening to detect
                        cancer early. For eg. if you have an increased risk for
                        developing breast cancer your Doctor may recommend
                        mammograms more frequently.
                      </p>
                    </div>
                    {/* eo-card-body */}
                  </div>
                  {/* eo-card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded  newcard cCard">
                    <div className="card-body text-center">
                      <div className="card-block p-2 circle">
                        <img
                          className="card-img text-center p-2"
                          src="./img/cancerpage/cancerpage-bp-icon-2.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text pt-4">
                        For certain cancers, your Doctor might prescribe
                        medicines to lower the risk of developing cancer.
                      </p>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded  newcard cCard">
                    <div className="card-body text-center">
                      <div className="card-block mt-2 p-2 circle ">
                        <img
                          className="card-img text-center  p-2"
                          src="./img/cancerpage/cancerpage-bp-icon-3.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text pt-4">
                        Your results can help your family to understand their
                        risk for hereditary cancers.
                      </p>
                    </div>
                  </div>
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="card-deck-wrap col-sm-12 col-md-6 col-lg-3">
                  <div className="card rounded  newcard cCard">
                    <div className="card-body text-center">
                      <div className="card-block mt-2 p-2  circle">
                        <img
                          className="card-img text-center  p-2 "
                          src="./img/cancerpage/cancerpage-bp-icon-4.png"
                          alt="Card image cap"
                        />
                      </div>
                      <p className="card-text pt-4">
                        Knowing about risks and whether you have a faulty gene
                        or not can reduce stress and worry.
                      </p>
                    </div>
                  </div>
                  {/* eo card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
export default CancerCarouselCards;
