import React from 'react'
import Footer from './Footer';
import { Collapse,} from 'reactstrap';
import MainHeader from './MainHeader';
import $ from 'jquery';
import MobileFooter from './MobileFooter';

class CliniciansHelpIndividual extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
         
          q1:false,
          b1:true,
    
          q2:false,
          b2:true,
    
          q3:false,
          b3:true,
    
          q4:false,
          b4:true,
    
          q5:false,
          b5:true,
    
          q6:false,
          b6:true,
    
          q7:false,
          b7:true,

          q8:false,
          b8:true,

          q9:false,
          b9:true,

          q10:false,
          b10:true,

          q11:false,
          b11:true,

          q12:false,
          b12:true,

          q13:false,
          b13:true,

          q21:false,
          b21:true,
    
          q22:false,
          b22:true,
    
          q32:false,
          b32:true,
    
          q42:false,
          b42:true,
    
          q52:false,
          b52:true,


          q111:false,
          b111:true,
    
          q222:false,
          b222:true,
    
          q333:false,
          b333:true,
    
          q444:false,
          b444:true,
    
          q555:false,
          b555:true,
    
          q666:false,
          b666:true,
    
          q777:false,
          b777:true,

          q888:false,
          b888:true,


          q1111:false,
          b1111:true,
    
          q2222:false,
          b2222:true,
    
          q3333:false,
          b3333:true,
    
          q4444:false,
          b4444:true,
    
          q5555:false,
          b5555:true,
    


    
        }

        this.toggle1111=this.toggle1111.bind(this)
        this.toggle2222=this.toggle2222.bind(this)
        this.toggle3333=this.toggle3333.bind(this)
        this.toggle4444=this.toggle4444.bind(this)
        this.toggle5555=this.toggle5555.bind(this)

        this.toggle111=this.toggle111.bind(this)
        this.toggle222=this.toggle222.bind(this)
        this.toggle333=this.toggle333.bind(this)
        this.toggle444=this.toggle444.bind(this)
        this.toggle555=this.toggle555.bind(this)
        this.toggle666=this.toggle666.bind(this)
        this.toggle777=this.toggle777.bind(this)
        this.toggle888=this.toggle888.bind(this)

        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggle5 = this.toggle5.bind(this);
        this.toggle6 = this.toggle6.bind(this);
        this.toggle7 = this.toggle7.bind(this);
        this.toggle8 = this.toggle8.bind(this);
        this.toggle9 = this.toggle9.bind(this);
        this.toggle10 = this.toggle10.bind(this);
        this.toggle11 = this.toggle11.bind(this);
        this.toggle12 = this.toggle12.bind(this);
        this.toggle13 = this.toggle13.bind(this);

        this.details1=this.details1.bind(this);
        this.details2=this.details2.bind(this);
        this.details3=this.details3.bind(this);
        this.details4=this.details4.bind(this);
        this.details5=this.details5.bind(this);

        
    }

    toggle1111() {
      this.setState(state => ({ q1111: !state.q1111 }));
      this.setState(state => ({ b1111: !state.b1111 }));
  
      
      this.setState({q2222: false}, function () {});
      this.setState({b2222: true}, function () {});
      this.setState({q3333: false}, function () {});
      this.setState({b3333: true}, function () {});
      this.setState({q4444: false}, function () {});
      this.setState({b4444: true}, function () {});
      this.setState({q5555: false}, function () {});
      this.setState({b5555: true}, function () {});


  
    }

    toggle2222() {
      this.setState(state => ({ q2222: !state.q2222 }));
      this.setState(state => ({ b2222: !state.b2222 }));
  
      
      this.setState({q1111: false}, function () {});
      this.setState({b1111: true}, function () {});
      this.setState({q3333: false}, function () {});
      this.setState({b3333: true}, function () {});
      this.setState({q4444: false}, function () {});
      this.setState({b4444: true}, function () {});
      this.setState({q5555: false}, function () {});
      this.setState({b5555: true}, function () {});



  
    }

    toggle3333() {
      this.setState(state => ({ q3333: !state.q3333 }));
      this.setState(state => ({ b3333: !state.b3333 }));
  
      
      
      this.setState({q2222: false}, function () {});
      this.setState({b2222: true}, function () {});
      this.setState({q1111: false}, function () {});
      this.setState({b1111: true}, function () {});
      this.setState({q4444: false}, function () {});
      this.setState({b4444: true}, function () {});
      this.setState({q5555: false}, function () {});
      this.setState({b5555: true}, function () {});



  
    }


    toggle4444() {
      this.setState(state => ({ q4444: !state.q4444 }));
      this.setState(state => ({ b4444: !state.b4444 }));
  
      
      
      this.setState({q1111: false}, function () {});
      this.setState({b1111: true}, function () {});
      this.setState({q3333: false}, function () {});
      this.setState({b3333: true}, function () {});
      this.setState({q2222: false}, function () {});
      this.setState({b2222: true}, function () {});
      this.setState({q5555: false}, function () {});
      this.setState({b5555: true}, function () {});


  
    }

    toggle5555() {
      this.setState(state => ({ q5555: !state.q5555 }));
      this.setState(state => ({ b5555: !state.b5555 }));
  
      
      this.setState({q1111: false}, function () {});
      this.setState({b1111: true}, function () {});
      this.setState({q3333: false}, function () {});
      this.setState({b3333: true}, function () {});
      this.setState({q2222: false}, function () {});
      this.setState({b2222: true}, function () {});
      this.setState({q4444: false}, function () {});
      this.setState({b4444: true}, function () {});


  
    }


    toggle111() {
      this.setState(state => ({ q111: !state.q111 }));
      this.setState(state => ({ b111: !state.b111 }));
  
      
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }

    toggle222() {
      this.setState(state => ({ q222: !state.q222 }));
      this.setState(state => ({ b222: !state.b222 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }

    toggle333() {
      this.setState(state => ({ q333: !state.q333 }));
      this.setState(state => ({ b333: !state.b333 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }


    toggle444() {
      this.setState(state => ({ q444: !state.q444 }));
      this.setState(state => ({ b444: !state.b444 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }

    toggle555() {
      this.setState(state => ({ q555: !state.q555 }));
      this.setState(state => ({ b555: !state.b555 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }

    toggle666() {
      this.setState(state => ({ q666: !state.q666 }));
      this.setState(state => ({ b666: !state.b666 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }

    toggle777() {
      this.setState(state => ({ q777: !state.q777 }));
      this.setState(state => ({ b777: !state.b777 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q888: false}, function () {});
      this.setState({b888: true}, function () {});


  
    }

    toggle888() {
      this.setState(state => ({ q888: !state.q888 }));
      this.setState(state => ({ b888: !state.b888 }));
  
      
      this.setState({q111: false}, function () {});
      this.setState({b111: true}, function () {});
      this.setState({q222: false}, function () {});
      this.setState({b222: true}, function () {});
      this.setState({q333: false}, function () {});
      this.setState({b333: true}, function () {});
      this.setState({q444: false}, function () {});
      this.setState({b444: true}, function () {});
      this.setState({q555: false}, function () {});
      this.setState({b555: true}, function () {});
      this.setState({q666: false}, function () {});
      this.setState({b666: true}, function () {});
      this.setState({q777: false}, function () {});
      this.setState({b777: true}, function () {});


  
    }

    details1() {
      this.setState(state => ({ q21: !state.q21 }));
      this.setState(state => ({ b21: !state.b21 }));
  
      
      this.setState({q22: false}, function () {});
      this.setState({b22: true}, function () {});
      this.setState({q32: false}, function () {});
      this.setState({b32: true}, function () {});
      this.setState({q42: false}, function () {});
      this.setState({b42: true}, function () {});
      this.setState({q52: false}, function () {});
      this.setState({b52: true}, function () {});

  
  
    }
  
    details2(){
  
      this.setState(state => ({ q22: !state.q22 }));
      this.setState(state => ({ b22: !state.b22 }));
  
      
      this.setState({q21: false}, function () {});
      this.setState({b21: true}, function () {});
      this.setState({q32: false}, function () {});
      this.setState({b32: true}, function () {});
      this.setState({q42: false}, function () {});
      this.setState({b42: true}, function () {});
      this.setState({q52: false}, function () {});
      this.setState({b52: true}, function () {});

  
    }
  
    details3(){
  
      this.setState(state => ({ q32: !state.q32 }));
      this.setState(state => ({ b32: !state.b32 }));
  
      
      this.setState({q21: false}, function () {});
      this.setState({b21: true}, function () {});
      this.setState({q22: false}, function () {});
      this.setState({b22: true}, function () {});
      this.setState({q42: false}, function () {});
      this.setState({b42: true}, function () {});
      this.setState({q52: false}, function () {});
      this.setState({b52: true}, function () {});

  
  
    }
  
    details4(){
  
      this.setState(state => ({ q42: !state.q42 }));
      this.setState(state => ({ b42: !state.b42 }));
  
      
      this.setState({q21: false}, function () {});
      this.setState({b21: true}, function () {});
      this.setState({q22: false}, function () {});
      this.setState({b22: true}, function () {});
      this.setState({q32: false}, function () {});
      this.setState({b32: true}, function () {});
      this.setState({q52: false}, function () {});
      this.setState({b52: true}, function () {});

  
  
    }
  
    details5(){
  
      this.setState(state => ({ q52: !state.q52 }));
      this.setState(state => ({ b52: !state.b52 }));
  
      
      this.setState({q21: false}, function () {});
      this.setState({b21: true}, function () {});
      this.setState({q22: false}, function () {});
      this.setState({b22: true}, function () {});
      this.setState({q32: false}, function () {});
      this.setState({b32: true}, function () {});
      this.setState({q42: false}, function () {});
      this.setState({b42: true}, function () {});

  
  
    }

    toggle1() {
      this.setState(state => ({ q1: !state.q1 }));
      this.setState(state => ({ b1: !state.b1 }));
  
      
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});

  
    }
  
    toggle2() {
      this.setState(state => ({ q2: !state.q2 }));
      this.setState(state => ({ b2: !state.b2 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }
  
    toggle3() {
      this.setState(state => ({ q3: !state.q3 }));
      this.setState(state => ({ b3: !state.b3 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }
  
    toggle4() {
      this.setState(state => ({ q4: !state.q4 }));
      this.setState(state => ({ b4: !state.b4 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }
    toggle5() {
      this.setState(state => ({ q5: !state.q5 }));
      this.setState(state => ({ b5: !state.b5 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }
  
    toggle6() {
      this.setState(state => ({ q6: !state.q6 }));
      this.setState(state => ({ b6: !state.b6 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }

    toggle7() {
      this.setState(state => ({ q7: !state.q7 }));
      this.setState(state => ({ b7: !state.b7 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
     
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }

    toggle8() {
      this.setState(state => ({ q8: !state.q8 }));
      this.setState(state => ({ b8: !state.b8 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
     
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }

    toggle9() {
      this.setState(state => ({ q9: !state.q9 }));
      this.setState(state => ({ b9: !state.b9 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
     
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }

    toggle10() {
      this.setState(state => ({ q10: !state.q10 }));
      this.setState(state => ({ b10: !state.b10 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }
    toggle11() {
      this.setState(state => ({ q11: !state.q11 }));
      this.setState(state => ({ b11: !state.b11 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});

      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }

    toggle12() {
      this.setState(state => ({ q12: !state.q12 }));
      this.setState(state => ({ b12: !state.b12 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});

      this.setState({q13: false}, function () {});
      this.setState({b13: true}, function () {});
    }

    toggle13() {
      this.setState(state => ({ q13: !state.q13 }));
      this.setState(state => ({ b13: !state.b13 }));
  
      this.setState({q1: false}, function () {});
      this.setState({b1: true}, function () {});
      this.setState({q2: false}, function () {});
      this.setState({b2: true}, function () {});
      this.setState({q3: false}, function () {});
      this.setState({b3: true}, function () {});
      this.setState({q4: false}, function () {});
      this.setState({b4: true}, function () {});
      this.setState({q5: false}, function () {});
      this.setState({b5: true}, function () {});
      this.setState({q6: false}, function () {});
      this.setState({b6: true}, function () {});
      this.setState({q7: false}, function () {});
      this.setState({b7: true}, function () {});
      this.setState({q8: false}, function () {});
      this.setState({b8: true}, function () {});
      this.setState({q9: false}, function () {});
      this.setState({b9: true}, function () {});
      this.setState({q10: false}, function () {});
      this.setState({b10: true}, function () {});
      this.setState({q11: false}, function () {});
      this.setState({b11: true}, function () {});
      this.setState({q12: false}, function () {});
      this.setState({b12: true}, function () {});
    }

    componentDidMount(){
      
      $(document).ready(function(){
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#myData h5").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
          });
      
        });
      });
    }
  
   render(){
  

       return(
        <div>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
   
        
        <style dangerouslySetInnerHTML={{__html: "\n  /* @media only screen and (min-width: 992px)\n/* {\n.custom-pad-mar {\n  padding-left:25rem;\n  padding-right:25rem;\n\n} */\n\n  .heart-banner-sec-wrap .heart-banner-sec {\n    background: url(./img/help/banner-i.png);\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n  .activenavbar{\n    color:#4f5be7!important;\n  }\n  .card-body{\n    text-align: left!important;\n  }\n  .heart-banner-sec-wrap .heart-banner-sec{\n    background: url(./img/help/banner-i.png);\n    background-position:center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n  .i-button{\n    display: inline-block;\n  border-radius: 60px;\n  box-shadow: 0px 0px 2px #888;\n  padding: 0.5em 0.6em;\n  }\n  " }} />
        <link rel="stylesheet" href="./clinicians_style-mobile2.css"/>
        <link rel="stylesheet" href="./clinicians_style-mobile.css"/>
        <link rel="stylesheet" href="./clinicians_newsticky.css"/>
       
        <MainHeader category="help"></MainHeader>
        <section className="heart-banner-sec-wrap banner-position">
          <div className=" heart-banner-sec ">
            <div className="container pt-4 mt-2">
              <h1 className><strong>For Individuals</strong></h1>
              <h2 className="my-3" style={{fontSize: '24px', fontWeight: 300}}>The below information will help you to find answers to common questions, such as to learn about how to book your genetic counselling session, about the truGeny genetic test, how to order, provide a sample, and how to access your reports.</h2>
              <div className="input-group " style={{height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff', borderRadius: '2rem'}}>
                <input type="text" className="form-control m-0" id="validationTooltip01" placeholder="Search for help.." name="name" style={{borderTopLeftRadius: '2rem', borderBottomLeftRadius: '2rem', height: '75px', boxShadow: '0 17px 40px rgba(92, 103, 108, 0.2)', backgroundColor: '#ffffff'}} required />
                <div className="valid-tooltip">
                  Looks good!
                </div>
                <div className="input-group-prepend border-0 d-flex justify-content-center">
                <span className="btn btn-outline-primary input-group-text border-0 justify-content-center helpSearchB" id="validationTooltip01">SEARCH</span>
                </div>
              </div>
            </div>
            {/* eo-container*/}
            <div className="d-flex justify-content-center" id="secc1">
              <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#secc1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
            </div>
          </div>
          {/* eo-heart-banner-sec */}
        </section>
        {/* eo-heart-banner-sec-wrap */}
        {/* ------------------------------------------------------heart-banner-sec over------------------------------------------------------------------ */}
        <section className="container ">
          <nav aria-label="breadcrumb mt-5 ">
            <ol className="breadcrumb " style={{backgroundColor: '#fff', paddingTop: '3rem'}}>
              <li className="breadcrumb-item">
                <a href="/clinicians_help" className="roboto-reg-18px" style={{color: '#4f5be7'}}><b>Support</b></a>
              </li>
              {/* <li class="breadcrumb-item"><a href="#">Library</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">
                <a className="roboto-reg-18px">Individuals</a>
              </li>
            </ol>
          </nav>
          {/* Support / Genetic Counselling */}
          <section className=" useful-resources-sec">
            {/* <div class="container ur-header-wrap ">
          <h2 class="text-center" >Useful Resources for you to learn more</h2>
        </div> */}
            {/* eo what-you-sec-wrap */}
            <div className="ur-pill-wrap pl-0 container row ">
              <div className="  pill-1 col-sm col-md-6 col-lg-6 col-xl-3 mb-3 ur-pill">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill1.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">truGeny<br />Account<br />Creation</span>
                </a>
              </div>{/* eo-ur-pill*/}
              <div className="ur-pill pill-2  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill2.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">truGeny<br />Test Kit</span>
                </a>
              </div>{/* eo-ur-pill*/}
              <div className="ur-pill pill-3 col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill3.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">My<br />Sample</span>
                </a>
              </div>{/* eo-ur-pill*/}
              <div className="ur-pill pill-4  col-sm col-md-6 col-lg-6 col-xl-3 mb-3">
                <a className=" ur-link btn rounded-pill clearfix" href="#" role="button">
                  <span className="ur-img-wrap float-left">
                    <img className="card-img-top img-fluid text-center p-2 " src="img/help/pill4.png" alt="Card image cap" />
                  </span>
                  <span className="ur-link-title float-left ">My<br />report</span>
                </a>
              </div>{/* eo-ur-pill*/}
              {/*    </div> */}
            </div>{/* eo-ur-pill-wrap */}
          </section>
          <div className=" mt-5 ">
            <h1 className="roboto-black-36px" style={{color: '#4f5be7'}}>truGeny Account Creation</h1>
            <div className="text-center">
              <div className="p-2 circle">
                <div className="pr-5 pb-5  hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q1?'#2C333C':'#4F5BE7'}}>
                          Why do in need to create an account
                          {this.state.b1?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1} className="fa fa-minus-circle" /></a>}
                          {/* <a   class="float-right roboto-bold-18px btn btn-link rounded-circle" style="color: #4f5be7;"data-toggle="collapse" data-target="#collapseOne"><p class="d-none"></p><i class="fa fa-plus-circle"></i></a> */}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q1}>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p><b>Ans :</b> At truGeny we ensure that all details pertaining to every individual is treated as an independent account. Once you choose a username
                            it will reserved just for you on truGeny. Your account will remain unique and all data pertaining to your account will stored with maximum
                            security.</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q2?'#2C333C':'#4F5BE7'}}>
                          Trouble creating an Account
                          {this.state.b2?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q2}>
                      <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px"><b>Ans :</b> You will need to enter your name and the email address that you would want your account to be under. Ensure that the email address you choose is working and that you can send and receive you want your account to be under. The account owner must be over 18 years of age at the time of creating the account.
                            You will then be asked to confirm your email address. The confirmation email should appear in your email account within a few minutes, but can take up to 24 hours (depending on your service provider) or might be in your trash or spam folders. Once you have located this email, follow the instructions to confirm your email address and activate your account.</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q3?'#2C333C':'#4F5BE7'}}>
                          Trouble logging into an existing account
                          {this.state.b3?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q3}>
                      <div id="collapse3" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Ans ; To login to an existing account, navigate to <a href="https://www.truGeny.com/start">https://www.truGeny.com/start</a>
                            Click on the <a href="https://www.truGeny.com/start">Sign In</a> link and enter your account email address and password. If you forgot your password, you can reset it <a href="https://www.truGeny.com/">here.</a><a /></p><a>
                          </a></div><a>
                        </a></div>
                      </Collapse>
                       <a>
                      </a></div><a>
                    </a><div className="card "><a>
                      </a><div className="card-header" id="headingThree"><a>
                        </a><h5 className="acc-header collapse-color mb-0" style={{color:this.state.q4?'#2C333C':'#4F5BE7'}}><a>
                            I forgot my username?
                          </a>
                          {this.state.b4?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q4}>
                      <div id="collapse4" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Your username is the email address that you used to create your truGeny account. If you do not remember your email id that you had used
                            while creating your truGeny account, or no longer have access, you can send us a message and our <a href="callto:9393939939">Customer Care</a> will work to address
                            your issue.</p>
                          <p className="pt-2">Have more questions? <a href>Submit a request</a></p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q5?'#2C333C':'#4F5BE7'}}>
                          I cant remember my password or my password isn’t working. What should I do?
                          {this.state.b5?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q5}>
                      <div id="collapse5" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>On the screen where you are asked to enter your password, you can click on the link that says “Forgot Password”. You will then be asked to
                            enter enter the email address you use to sign into your truGeny account. Once you enter your email address, you will receive instructions to
                            reset your password for your truGeny account.</p>
                          {/* <p class="pt-2" >Have more questions? <A href="">Submit a request</a></p> */}
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q6?'#2C333C':'#4F5BE7'}}>
                          I haven’t received the email address confirmation and my account creation confirmation email.
                          {this.state.b6?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle6}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle6} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q6}>
                      <div id="collapse6" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you haven’t received the truGeny account creation confirmation mail, try the following:</p>
                          <div className="container">
                            <p>Check your spam and trash folders for emails from donotreply@truGeny.com.</p>
                            <p>There may be a delay in sending or receiving this email. Typically all our customers will receive the email confirmation within a few minutes but in some cases it may take up to 24 hours.</p>
                            <p>Ensure that the spelling of the email address you have provided is accurate and you have access to this mail account. , and that the email address is able to send and receive emails.</p>
                            <p>Check your spam and trash folders for emails from donotreply@truGeny.com.</p>
                            <p>Send an email to our customer service team - donotreply@truGeny.com and our team will help you address the problem.</p>
                          </div>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q7?'#2C333C':'#4F5BE7'}}>
                          I’ve mailed my sample without activating my kit. What should I do now?
                          {this.state.b7?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle7}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle7} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q7}>
                      <div id="collapse8" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you haven’t received the truGeny account creation confirmation mail, try the following:</p>
                          <p>If you have mailed your sample before completing the kit activation process, you will be able to activate your kit once your
                            sample arrives at the lab. When your sample is received at the lab, an email titled "Action Required" will be sent to your email
                            address provided in your account details. This email will contain the barcode from your sample collection tube (kit), which
                            you can then use to activate your kit online.
                            <b><br /><br /> Please note:</b> The Processing of your sample will not start until the activation is complete.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q8?'#2C333C':'#4F5BE7'}}>
                          While Activating my kit I’m getting an error
                          {this.state.b8?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle8}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle8} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q8}>
                      <div id="collapse7" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Please select your error from the list below:</p>
                          <div className="container">
                            <ul>
                              <li>Sorry, we are not being able to accept your kit activation at this time</li>
                              <li>This barcode is invalid</li>
                              <li>This barcode is already in use</li>
                              <li>The kit has expired</li>
                              <li>Site error</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q9?'#2C333C':'#4F5BE7'}}>
                          Sorry, we are not being able to accept your kit activation at this time
                          {this.state.b9?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle9}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle9} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q9}>
                      <div id="collapse88" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>When creating the account, you must ensure that the owner of the account is over 18 years old.
                            To agree to our <a style={{color: 'black'}} href="clinicians_termsofservices">terms of service</a> on behalf of yourself or those for whom you have legal authority over, you must be over 18
                            years old.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q10?'#2C333C':'#4F5BE7'}}>
                          Invalid barcode
                          {this.state.b10?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle10}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle10} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q10}>
                      <div id="collapse99" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>You need to make sure that you are entering the barcode as it appears on the side of your truGeny collection tube. This is a
                            14 digit barcode and will appear in the following format: XX--XXXX--XXXX--XXXX
                            You can enter the barcode either with or without the dashes. If you have entered the barcode accurately and still seeing a
                            message of “Invalid Barcode”, please contact out <b>Customer Care</b></p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q11?'#2C333C':'#4F5BE7'}}>
                          This barcode has already been used
                          {this.state.b11?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle11}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle11} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q11}>
                      <div id="collapse122" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>You or someone on your behalf might have already completed the activation process for this kit. Look through your email for
                            the activation confirmation message, or sign in to your truGeny account and look for the sample status timeline on the
                            homepage. If your kit is already activated, your sample status will reflect your kit's progress; if it doesn't show any status,
                            click the Start Activate Kit button to begin the process.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q12?'#2C333C':'#4F5BE7'}}>
                          The kit has expired
                          {this.state.b12?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle12}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle12} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q12}>
                      <div id="collapseOne3e" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>truGeny sample collection kits expire 12 months from the date of purchase and will need to be replaced. You can contact
                            Customer Care to request a replacement. You need to ensure to include the kit barcode in your message.</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q13?'#2C333C':'#4F5BE7'}}>
                          Site Error
                          {this.state.b13?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle13}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle13} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q13}>
                      <div id="collapseOne11" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you are receiving a site error message, please contact our <b><a href>Customer Care team.</a></b></p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    {/* end of accordian code */}
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
          {/* 2nd qa */}
          <div className=" mt-5 ">
            <h1 className="roboto-black-36px" style={{color: '#4f5be7'}}>truGeny Test Kit</h1>
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q21?'#2C333C':'#4F5BE7'}}>
                          How do I activate my kit?
                          {this.state.b21?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details1}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details1} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q21}>
                      <div id="collapsey5" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Before you mail us back your sample, you will need to activate your kit online at www.truGeny.com/activatekit . The easy to
                            follow steps will guide you to complete the activation. Once you have activated your kit, you must mail us the sample within
                            24 hours. This will help ensure that the sample you have provided is not damaged or contaminated.
                            You will need the two numbers printed on your saliva tube.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q22?'#2C333C':'#4F5BE7'}}>
                          Why do i need to Activate my kit barcode?
                          {this.state.b22?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details2}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details2} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q22}>
                      <div id="collapse66" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>The barcode on your saliva kit helps us to connect the sample in the tube to you and your account. This is why activating
                            your kit before mailing it back to us is necessary. Without the activation of barcode, we wouldn't know who has provided the
                            sample and therefore who does the report belong to. Therefore we will not be able to process samples that are unregistered.</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q32?'#2C333C':'#4F5BE7'}}>
                          I’ve received truGeny test as a gift. How do I get started?
                          {this.state.b32?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details3}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details3} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q32}>
                      <div id="collapseOne7" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>To begin the truGeny experience and receive your reports, you will first need to activate your kit:
                            If you have already created your account, use the Sign In link during the activation process and enter your account’s email
                            address and password.
                            If you are new to truGeny, you can create an account at the beginning of the registration process by filling out the required
                            fields.
                            To begin registration, goto https://www.truGeny.com/start</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q42?'#2C333C':'#4F5BE7'}}>
                          How do i add more than one kit to my account?
                          {this.state.b42?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details4}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details4} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q42}>
                      <div id="collapseOner" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you have ordered more than one kit, and are participating in the truGeny experience with family or friends, make sure that
                            you each activate the barcode that corresponds to the kit you use to provide your sample. This will help avoid delays and
                            issues regarding reports. You can choose to activate multiple kits under a single account or to have individual accounts for
                            each kit.
                            We recommend for you to review these important considerations before registering multiple family members to a single
                            account.
                            To begin registration of an additional kit, navigate to https://www.truGeny.com/start and select the Sign In option at the
                            bottom.Once you enter the barcode of the kit, you will be asked to select whether you or someone else will be using the kit.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headcp">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q52?'#2C333C':'#4F5BE7'}}>
                          Where do i find the barcode of my Kit?
                          {this.state.b52?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details5}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.details5} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q52}>
                      <div id="cp" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px">The 14-digit barcode is located on the side of the collection tube included in your kit. You can enter your barcode number either with or without the spaces and dashes.</p>
                          <img src="img/help/torch.png" className="img-fluid" />
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
          {/* 3rd and 4th */}
          <div className=" mt-5 ">
            <h1 className="roboto-black-36px" style={{color: '#4f5be7'}}>My Sample</h1>
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5 hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q111?'#2C333C':'#4F5BE7'}}>
                          I might have made a mistake while providing my sample. What should I do?
                          {this.state.b111?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle111}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle111} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q111}>
                      <div id="collapseOnee" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>Let us know what error was made when providing your sample and we will get back to you with a response and next steps.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q222?'#2C333C':'#4F5BE7'}}>
                          Once I receive the kit, how quickly do I need to return my sample?
                          {this.state.b222?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle222}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle222} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q222}>
                      <div id="collapseOnek" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>We recommend that you should send in your saliva sample as soon as you have activated your kit. This will allow us for the sequencing to
                            begin so you can get your reports sooner.<br /><br />
                            Have more questions? Submit a request</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q333?'#2C333C':'#4F5BE7'}}>
                          If I do not send my sample right away, will my saliva sample be stable or will it go bad?
                          {this.state.b333?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle333}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle333} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q333}>
                      <div id="collapseOneh" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>We recommend you to send in your sample as soon as you have collected your sample, mixed the DNA stabilising buffer liquid (found in the funnel lid) and activated the kit.</p>
                          <p>The liquid in the funnel lid is designed to ensure that your sample stays stable for transport. In an unlikely situation of your sample not
                            meeting our lab’s rigorous quality requirements for sample processing and analysis, our team will contact you right away and send you a
                            new kit at no additional charge.</p>
                          <p>Note: The saliva collection kit used for the truGeny test has been studied at a wide range of temperatures and times. Samples studied for
                            stability remained viable for genetic testing for 12 months after the sample was provided.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    {/* put here your code */}
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q444?'#2C333C':'#4F5BE7'}}>
                          How do i Provide my Saliva Sample? What should I do with the sample collection tube?
                          {this.state.b444?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle444}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle444} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q444}>
                      <div id="collapseOne4-4" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>truGeny’s saliva collection kits are easy to use. Detailed instructions are provided in each kit.<br /><br />
                          You can provide your sample under most health conditions, including while you have a cold / flu, while you are pregnant, or while you are taking common medications.<br /><br />
                          Once your Saliva is mixed with the DNA stabilising liquid (found in the funnel lid) Your saliva sample is stable at a wide range of temperatures once it is mixed with
                          the DNA stabilization buffer liquid. The sample collection and mailing process is composed of a few basic steps. These instructions are included in your kit. There are
                          a few important points to keep in mind while providing your sample:</p><br /><br />
                        <div className="d-flex align-items-center">
                          <img className="p-4" height="195px" width="195px" src="img/help/1.png" />
                          <p className="roboto-bold-18px">Do not eat, drink, smoke, chew gum, brush your teeth, or use mouthwash for at least 30 minutes prior to providing your sample.</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="p-4" height="195px" width="195px" src="img/help/2.png" />
                          <p className="roboto-bold-18px">Collect the recommended volume of saliva. Your saliva sample should reach and be just above the fill line. truGeny recommended volume of saliva to provide is 2 mL, or about 1⁄2 teaspoon.</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="p-4" height="195px" width="195px" src="img/help/3.png" />
                          <p className=" roboto-bold-18px">Once you have provided your saliva sample., the DNA buffer stabilisation liquid should be releases into the tube immediately. After securing the cap of the tube, shake the tube for a minute or two so that the DNA buffer stabilisation liquid mixes well with the saliva sample. Waiting for longer than 30 minutes to mix the Saliva sample with the DNA stabilisation liquid could decrease the yield and quality of your DNA.</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="p-4" height="195px" width="195px" src="img/help/4.png" />
                          <p className="roboto-bold-18px">Cap securely before shipping. Remember to remove and discard the funnel lid and place the tube cap on securely before mailing your sample to our laboratory.</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="p-4" height="195px" width="195px" src="img/help/5.png" />
                          <p className="roboto-bold-18px">The transportation supplies for your saliva sample are included in your kit. Simply place the collection tube containing your sample in the plastic specimen bag, and then place the bag directly into the box. The plastic clamshell should be discarded. Remove the adhesive strip on the kit flap and seal the kit.</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="p-4" height="195px" width="195px" src="img/help/6.png" />
                          <p className="roboto-bold-18px">Return your sample by calling our <b><a style={{color: '#4f5be7'}} href="#">Customer Support</a></b> team and they will ensure that your sample is collected at the earliest.</p>
                        </div>
                      </div>
                    </div>
                      </Collapse>

                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q555?'#2C333C':'#4F5BE7'}}>
                          I’m having trouble providing enough quantity of saliva sample. How can I produce enough saliva for the tube?
                          {this.state.b555?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle555}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle555} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q555}>
                      <div id="collapseOnen" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>If you are having difficulty in providing adequate sample volume, the following steps may help you increase saliva
                            production:</p>
                          <div className="container">
                            <ul>
                              <li>Try gently rubbing the outside of your cheeks</li>
                              <li>Make chewing motions with your mouth</li>
                              <li>You can try and smell or imagine sour foods such as lemons</li>
                              <li>Thinking about your favourite food or candy.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      </Collapse>
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q666?'#2C333C':'#4F5BE7'}}>
                          Has my sample reached the lab for processing?
                          {this.state.b666?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle666}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle666} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q666}>
                      <div id="collapseOnet" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>The time taken for the sample to reach our lab will vary depending on your location.<br />
                            Once a kit reaches our lab, our system marks it as “received” when the kit is physically opened and the barcode
                            scanned.</p>
                          <p>You will receive an email confirmation once our system completes the process of opening your kit and scanning your barcode.</p>
                          <p><br /><b>Un-Activated Samples</b></p><br />
                          <p>If our lab receives a kit that is not Activated, the barcode of the kit barcode and activation instructions will be sent to
                            the email address of the account holder associated with the order. The un-activated kit can be active at
                            www.truGeny.com/activatekit</p>
                        </div>
                      </div>
                      </Collapse>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q777?'#2C333C':'#4F5BE7'}}>
                          How will I know the status of my sample?
                          {this.state.b777?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle777}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle777} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q777}>
                      <div id="collapseOne-666" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px text-center">Your account page will display the updated status of your sample. Our sample processing time is 5 weeks from the time a the activated sample kit is received at our lab.</p>
                          <h2 className="text-center roboto-black-36px pt-4">Your kit is currently being analyzed</h2>
                          <p className="roboto-reg-18px text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                          <img className="pt-3 img-fluid" src="img/help/line.png" />
                          <div style={{lineHeight: '1.3', marginTop: '0px', paddingTop: '0px'}}>
                            <p className="roboto-bold-24px" style={{color: '#4f5be7'}}>Step 1:</p>
                            <p className="roboto-bold-24px">Kit Activation</p>
                            <p className="roboto-reg-18px">Before you send your sample, you must activate register your kit.</p>
                          </div>
                          <div style={{lineHeight: '1.3', marginTop: '0px', paddingTop: '0px'}}>
                            <p className="roboto-bold-24px" style={{color: '#4f5be7'}}>Step 2:</p>
                            <p className="roboto-bold-24px">Successfully activated; track your kit</p>
                            <p className="roboto-reg-18px">Once you have activated your kit, you will be able to track your sample status.</p>
                          </div>
                          <div style={{lineHeight: '1.3', marginTop: '0px', paddingTop: '0px'}}>
                            <p className="roboto-bold-24px" style={{color: '#4f5be7'}}>Step 3:</p>
                            <p className="roboto-bold-24px">Sample received</p>
                            <p className="roboto-reg-18px">Once your sample reaches our lab you will receive an email confirmation. The sample enters the queue for processing. This step includes the time taken for visual inspection of your sample, DNA extraction, and amplification of DNA from your sample.
                              <b>*If your sample does not yield sufficient quantities of DNA, this step will be need to be repeated.</b></p>
                          </div>
                          <div style={{lineHeight: '1.3', marginTop: '0px', paddingTop: '0px'}}>
                            <p className="roboto-bold-24px" style={{color: '#4f5be7'}}>Step 4:</p>
                            <p className="roboto-bold-24px">DNA analysis</p>
                            <p className="roboto-reg-18px">*If your sample does not yield a call rate that meets our standards, this step may be repeated.</p>
                          </div>
                          <div style={{lineHeight: '1.3', marginTop: '0px', paddingTop: '0px'}}>
                            <p className="roboto-bold-24px" style={{color: '#4f5be7'}}>Step 5:</p>
                            <p className="roboto-bold-24px">Computung</p>
                            <p className="roboto-reg-18px">Samples proceed to this step after they pass quality review. Computes compare sample data with the truGeny database to generate reports.</p>
                          </div>
                          <div style={{lineHeight: '1.3', marginTop: '0px', paddingTop: '0px'}}>
                            <p className="roboto-bold-24px" style={{color: '#4f5be7'}}>Step 6:</p>
                            <p className="roboto-bold-24px">Results Ready</p>
                            <p className="roboto-reg-18px">Once the compute is complete, data will be loaded into your account. You will receive an email once your reports are available.</p>
                          </div>
                        </div>
                      </div>
                      </Collapse>
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree-0">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q888?'#2C333C':'#4F5BE7'}}>
                          Why did my sample fail?
                          {this.state.b888?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle888}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle888} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q888}>
                      <div id="collapseOne-0" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p className="roboto-reg-18px">There are many reasons as to why samples fail or why analysis of a sample may not be completed. Sometimes it could be due to the sample having inadequate viable DNA for analysis. Other times it could be that the sample is contaminated or sometimes it could be due to the fact that the spit and the fluid in the kit were not mixed well (not shaken enough)<br /><br />The lab will make multiple attempts at all stages of the process in order to provide reports.<br /><br />On rare occasions if your sample does fail, truGeny will at it’s sole discretion:<br /><br />a. Try and repeat the analysis steps with your leftover sample (if a sufficient amount is available and the sample if appropriate for re-processing), and your sample status will update.<br /><br />or<br /><br />b. Send you one additional Test Kit so that you may submit another sample for our lab to process.<br />In either a or b, there will be no additional charges to you.<br />If you submit a second sample and it still cannot be processed, please contact
                            <a style={{color: '#4f5be7'}} href="mailto:support@truGeny.com">support@truGeny.com</a> to discuss your options.
                          </p>
                          <h2 className="roboto-black-36px text-center pt-5">Replacement Sample</h2>
                          <p className="roboto-reg-18px text-center pt-2">Do not eat, drink, smoke, chew gum, brush your teeth, or use mouthwash for at least 30 minutes prior to providing your sample.</p>
                          <div className="d-flex align-items-center">
                            <img className="p-4" height="195px" width="195px" src="img/help/r1.png" />
                            <p className="roboto-bold-18px">Do not eat, drink, smoke, chew gum, brush your teeth, or use mouthwash for at least 30 minutes prior to providing your sample.</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <img className="p-4" height="195px" width="195px" src="img/help/r2.png" />
                            <p className="roboto-bold-18px">Collect the recommended volume of saliva. Your saliva sample should reach and be just above the fill line. truGeny recommended volume of saliva to provide is 2 mL, or about 1⁄2 teaspoon.</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <img className="p-4" height="195px" width="195px" src="img/help/r3.png" />
                            <p className=" roboto-bold-18px">Once you have provided your saliva sample., the DNA buffer stabilisation liquid should be releases into the tube immediately. After securing the cap of the tube, shake the tube for a minute or two so that the DNA buffer stabilisation liquid mixes well with the saliva sample. Waiting for longer than 30 minutes to mix the Saliva sample with the DNA stabilisation liquid could decrease the yield and quality of your DNA.</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <img className="p-4" height="195px" width="195px" src="img/help/r4.png" />
                            <p className="roboto-bold-18px">Cap securely before shipping. Remember to remove and discard the funnel lid and place the tube cap on securely before mailing your sample to our laboratory.</p>
                          </div>
                        </div>
                      </div>
                      </Collapse>
                    </div>
                    {/* end of accordian code */}
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
          {/* 2nd qa */}
          <div className=" mt-5 ">
            <h1 className="roboto-black-36px" style={{color: '#4f5be7'}}>MY REPORT</h1>
            <div className="text-center">
              <div className="card-block  p-2 circle">
                <div className="pr-5 pb-5  hc-accordian-wrap">
                  <div className="accordion" id="accordionIhc">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q1111?'#2C333C':'#4F5BE7'}} >
                          Why is it important to provide my health history?
                          {this.state.b1111?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1111}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle1111} className="fa fa-minus-circle" /></a>}
                          </h5>
                      </div>
                      <Collapse isOpen={this.state.q1111}>
                      <div id="collapseOne87" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>The information you provide about your health history enables our scientific team to better interpret and to personalise your
                            truGeny test reports. This interpretation in turn will help your Doctor to create a personalised health plan for you.</p>
                        </div>
                      </div>
                      </Collapse>
                     
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q2222?'#2C333C':'#4F5BE7'}}>
                          Why is it important to provide my family health history?
                          {this.state.b2222?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2222}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle2222} className="fa fa-minus-circle" /></a>}
                          </h5>
                      </div>
                      <Collapse isOpen={this.state.q2222}>
                      <div id="collapseOnete" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>By combining your genetic and family information, genetic counsellors and Doctors can guide you better and also personalise
                            prevention plans or treatment decisions. To prevent or treat some hereditary cancers, taking an individuals full family health history
                            is critical. For example genetic factors and family history can help inform when a man should be screened for prostrate cancer.
                            Similarly information about an individuals genetic and family history can help doctors to pick a course of medication or therapy best
                            suited for the individual to prevent cardiovascular disease.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q3333?'#2C333C':'#4F5BE7'}}>
                          How long will it take for my report to be ready ?
                          {this.state.b3333?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3333}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle3333} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q3333}>
                      <div id="collapseOneme" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>truGeny’s test undergoes a very rigorous process and the emphasis is on providing you with the highest quality report possible.
                            Our standard turn around time to provide you with a report is 5 weeks after we receive your activated kit. In some cases the actual
                            time for report might take longer than 5 weeks.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q4444?'#2C333C':'#4F5BE7'}}>
                          How should I prepare for my report
                          {this.state.b4444?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4444}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle4444} className="fa fa-minus-circle" /></a>}
                        </h5>
                      </div>
                      <Collapse isOpen={this.state.q4444}>
                      <div id="collapseOnemmn" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                      <div className="card-body">
                        <p>Once your report is ready you will receive an email from our customer support team. truGeny offers you a complimentary genetic
                          counselling session for you to understand your report. You can fix an appointment with our genetic counsellors, who will interpret
                          and help you to understand your report and guide you on next steps.</p>
                        <p><br /><b>Important :</b></p>
                        <p>truGeny test is not designed to inform you about your current state of health, The test is a screening test and is not a substitute for
                          visiting your health care provider.</p>
                        <p>Always talk to your doctor, before making any changes to your health or medications based on the report. You should discuss the
                          results of the genetic test with your doctor, who will help you create a personalised health plan for prevention or treatment.</p>
                      </div>
                    </div>
                      </Collapse>
                     
                    </div>
                    <div className="card ">
                      <div className="card-header" id="headingThree">
                        <h5 className="acc-header collapse-color mb-0" style={{color:this.state.q5555?'#2C333C':'#4F5BE7'}}>
                          How can i keep track of the status of my sample?
                          {this.state.b5555?<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5555}className="fa fa-plus-circle" /></a>:<a className="float-right roboto-bold-18px btn btn-link rounded-circle" style={{color: '#4f5be7'}} data-toggle="collapse" data-target="#a4"><i onClick={this.toggle5555} className="fa fa-minus-circle" /></a>}
                          </h5>
                      </div>
                      <Collapse isOpen={this.state.q5555}>
                      <div id="collapseOnemm" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionIhc">
                        <div className="card-body">
                          <p>You will receive regular update notifications via email for each step of the sample processing. For instance, we update you once
                            the kit has been dispatched to you, when you've successfully activated the kit, when your sample reaches our lab, and an update
                            once your reports are ready.</p>
                        </div>
                      </div>
                      </Collapse>
                      
                    </div>
                  </div>
                </div>
                {/* eo card-block */}
              </div>
              {/* eo card body */}
            </div>
            {/* eo card */}
          </div>
          {/* eo-heart-accordian-sec-wrap */}
        </section>
        {/* eo-heart-accordian-sec */}
        {/* **********Schedule your Genetic Counseling Session Ends Here************** */}
        {/* Footer Links */}
       <Footer></Footer>
       <MobileFooter></MobileFooter>
        {/* end of footer */}
        {/* --------eo sticky form----------------- */}
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>

        
       )
   }

}
export default CliniciansHelpIndividual;