import React from 'react';
import MainHeader from './MainHeader';
import SubFooter from './SubFooter';
import StickyHeader from 'react-sticky-header';
import axios from '../axios/axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

class CliniciansForgotPwd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      errorMsg: null,
      errors: {},
      loginForm: {},
      loginErrors: {},
      loginType: ''
    };
    this.toVerifyMobile = this.toVerifyMobile.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    if (e.target.name === "phone") {
      if (e.target.value.match(/^[0-9]{10}$/)) {
        this.setState({ loginType: 'otp' })
      }
      else {
        this.setState({ loginType: 'password' })
      }
    }
  }

  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your mobile no.";
    }
    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid mobile no.";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  toVerifyMobile() {
    let mobile = this.state.phone;
    if(this.validateForm()) {
    axios.get('users/counsellor/sendOtp/' + mobile)
      .then((response) => {
        localStorage.setItem("phone", response.data.phone)
        localStorage.setItem("email", response.data.email)
        window.location.href = "/clinicians_varify-mobile";

      }).catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/clinicians_error")
          } else {
            this.setState({
              errorMsg: error.response.data.message
            })
            swal(error.response.data.message)
          }
        });
      }
    }


  render() {
    return (
      <div>
             <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
        {/* <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay" crossorigin="anonymous"> */}
        <link rel="stylesheet" href="./fonts/fontawesome-free-5.8.2-web/css/all.css" type="text/css" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
        <title>truGeny</title>
        <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
        <link rel="stylesheet" href="./css/header_footer.css" />
        <link rel="stylesheet" href="./css/stylesheet_custom.css" />
        <link rel="stylesheet" href="stylesheet_custom.css" />
        <link rel="stylesheet" href="./css/fonts.css" />
        <link rel="stylesheet" href="./css/sticky.css" />
        {/* --------------------------------------------------------------------------------------------head section end-------------------------------------------------------------------------------------------- */}
        <MainHeader></MainHeader>

       
        <div style={{ marginTop: '10rem' }}></div>
        <div className=" text-center mb-1 sec-1">
          <div className="container c1 text-center">
            <div className="head-text">
              <h1 className="head-text-h1  text-center img-fluid roboto-black-36px">Reset your truGeny password </h1>
            </div>
            {/* ---------------------------------------------------section 2 started-----------*/}
            <div className="cr-sec-main container mt-5 pt-3  pb-5 mb-2 b1-cr">
              <div className="head-text">
                <h1 className="py-3 text-center img-fluid roboto-reg-24px" style={{ maxWidth: '460px', color: 'grey', margin: 'auto' }}>Please provide us the mobile number of your truGeny account. You will receive an OTP with instructions to reset your password </h1>
              </div>
              <div className="gc-form-card-btm  pb-3">
                <form className="cr-form pt-0 gc-form needs-validation" >
                  <div className="form-row justify-content-center pt-5 ">
                    <div className="cr-col col-sm-10 col-md-8 col-lg-6 mb-3">
                      <div className="input-group">
                        <input type="text" name="phone" onChange={this.handleOnChange} value={this.state.phone} className="form-control cr-email m-0" id="validationTooltipEmailPrepend" placeholder="Mobile Number" />
                        <span className="input-group-text" id="validationTooltipEmailPrepend" style={{ border: 'none', backgroundColor: 'white' }}><img className="img-fluid" src="img/homepage/i3.png" /></span>
                        <div className="invalid-tooltip">
                          Mobile Number
                      </div>

                      </div>
                      <div className="col-xs-12 ">
                        <div className="gc-col input-group-prepend mb-3 mx-auto" style={{ maxWidth: '460px' }}>
                          <span style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.phone}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end of form-row */}
                </form>
              </div>{/* end of gc-form-card-btm */}
              <div className="after-form-sec">
                {/*Grid column*/}
                <div className="row d-flex justify-content-center px-3 pt-3">
                  <button className="btn btn-outline-primary btn-hover-white b-0 my-sm-0 center px-5 " style={{ height: '60px', width: '300px', borderRadius: '1.875rem', color: 'white', background: '#4f5be7' }} type="submit" onClick={this.toVerifyMobile} >
                    <b>SEND ME INSTRUCTIONS</b></button>
                </div>
              </div>{/* end of after-form-sec */}
            </div>{/* end of cr-sec-main */}
            <div className="container text-center mt-5 pb-5">
              <img className="plus-button img-fluid mb-3" src="./img/registration/Icon.png" />
              <p className=" text-center  " style={{ color: '#84888e' }}>Are you a Doctor or a Health Care Provider? If you are trying <br />to order a truGeny Test or wanting to view your patients report, <br />
                <a className="pb-5 cr-1" href="clinicians_login"><strong>CLICK HERE</strong></a> to create your account</p>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '12.25rem' }}>
        </div>
        {/* ------------------------------------------------------------------------------------------------- */}
        <SubFooter></SubFooter>
        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
      </div>
    );
  }
}
export default CliniciansForgotPwd;