import React from 'react';

import NewMainHeader from './NewMainHeader';
import MetaTags from 'react-meta-tags';


class ThanksListen extends React.Component {
  render() {
    return (

        <div>

        <link rel="stylesheet" href="./landing.css" />

        <MetaTags>
        <meta http-equiv="Refresh" content="25; url=https://trugeny.com/know-your-genes"/>

</MetaTags>



        <div className="pt-md-5"></div>
<div className="container background-pay-card mt-lg-5 mt-md-5 mt-sm-0 main-cover" >
<div className="container  d-flex justify-content-center pt-5" >

<a href="index.html" ><img className="align-self-center img-fluid p-5" src="https://trugeny.in/TruGeny_logo.png"/></a>
</div>

<div className="container  d-flex justify-content-center pt-3" >
          <h2 className="  text-center align-self-center  invite-1"  >
          Thank you. You’re Awesome.<br/>We will be in touch with you very soon.</h2>

</div>
<p className="text-center roboto-bold-24px pcolor"><b></b></p>
<p className="text-center roboto-bold-24px">
</p>


<br/>
<br/>
<div className="d-flex d-flex-inline justify-content-center ">
<div className="container footer-wrap text-center">
    <div className="f-social-links">


      </div>
      <p></p>

    </div>

</div>

</div>

</div>






    );
  }
}
export default ThanksListen;
