import React from 'react';
import Header from './Header';
import { Card, Button, CardHeader, CardBody, CardTitle, CardText, FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../styles.css';
import axios from '../axios/axios';
import Cookies from 'js-cookie';
import CommonHeder from './CommonHeder';




class QuestionsWizard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qno: 1,
            dropdownOpen: false,
            gender: null,
            raceOrEthnicity: null,
            type2Diabetes: [],
            healthConditions: [],
            exerciseFrequency: null,

            prediabetes: null,
            gestationalDiabetes: null,
            highBirthWeightBaby: null,
            highOrAbnormalCholestral: null,
            highLDL: null,
            highTriglycerides: null,
            highBloodPressureOrHypertension: null,
            takeMedicineToControlBloodPressure: null,
            tobaccoUse: null,


        }

        this.nextQuestion = this.nextQuestion.bind(this);
        this.toggle = this.toggle.bind(this);
        this.changeValue = this.changeValue.bind(this);
        // this.setFirstQuestion = this.setFirstQuestion.bind(this);
        // this.setThirdQuestion = this.setThirdQuestion.bind(this)
        // this.setFourthQuestion = this.setFourthQuestion.bind(this)
        // this.setFifthQuestion = this.setFifthQuestion.bind(this)

    }
    nextQuestion(questn) {


        var obj = {};
        obj[questn] = this.state[questn];
        console.log(obj)
        axios.patch(`users/user/${Cookies.get('userId')}`, obj, { headers: { Authorization: `Bearer ${Cookies.get('jwt-token')}` } })
            .then((response) => {
               
                //this.props.history.push('/dashboard');
            }).catch(
                error => {
                    if (error.response === undefined) {
                        this.props.history.push("/error")
                    } else {
                        this.setState({
                            errorMsg: error.response.data.message
                        })
                    }
                }
            )

        this.setState({
            qno: this.state.qno + 1
        })
    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    changeValue(e) {
        this.setState({
            dropDownValue: e.currentTarget.textContent,
            raceOrEthnicity: e.currentTarget.textContent
        })
    }
    
    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }
    onCheckBox = (e) => {
        let lists = this.state.type2Diabetes;
        lists.push(e.target.value);

        this.setState(
            {
                type2Diabetes: lists
            }
        )
    }

    onRadioBtn = (e) => {
        let lists = this.state.healthConditions;
        lists.push({
            [e.target.name]: e.target.value
        }
        );

        this.setState(
            {
                healthConditions: lists
            }
        )
    }
    render() {
        if (this.state.qno === 1) {
            return (
                <div>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
                    <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i" rel="stylesheet" />
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />
                    <title>trueGeny</title>
                    <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
                    <link rel="stylesheet" href="./css/stylesheet_custom.css" />
                    <link rel="stylesheet" href="stylesheet_custom.css" />
                    <CommonHeder></CommonHeder>
                    <div style={{ marginTop: '100px' }} className="responsive">
                        <Card body>
                            <CardTitle><b>Start your journey in 5 easy steps</b></CardTitle>
                            <CardText>Question 1 of 5</CardText>
                            <div>
                                <h3>How do you identify your gender?</h3>
                                <hr />
                                <FormGroup tag="fieldset">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" value="male" name="gender" onChange={this.onChange} />{' '}
                                            MALE
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" value="female" name="gender" onChange={this.onChange} />{' '}
                                            FEMALE
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <br />
                            </div>
                            <Button color="danger" onClick={() => this.nextQuestion("gender")} >NEXT</Button>
                        </Card>
                    </div>
                </div>
            );
        } else if (this.state.qno === 2) {
            return (
                <div>
                    <Header></Header>
                    <div style={{ marginTop: '100px' }} className="responsive">
                        <Card body>
                            <CardTitle><b>Start your journey in 5 easy steps</b></CardTitle>
                            <CardText>Question 2 of 5</CardText>
                            <div>
                                <h3>What is your race/ethnicity?</h3>
                                <hr />
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} name="raceOrEthnicity">
                                    <DropdownToggle caret color="primary">
                                        {this.state.dropDownValue ? this.state.dropDownValue : 'Select your ethnicity'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>    <div onClick={this.changeValue}>White</div>
                                        </DropdownItem>
                                        <DropdownItem><div onClick={this.changeValue}>Hispanic or Latino</div></DropdownItem>
                                        <DropdownItem><div onClick={this.changeValue}>Black or African American</div></DropdownItem>
                                        <DropdownItem><div onClick={this.changeValue}>Native American or American Indian</div></DropdownItem>
                                        <DropdownItem><div onClick={this.changeValue}>Asian / Pacific Islander</div></DropdownItem>
                                        <DropdownItem><div onClick={this.changeValue}>Other</div></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <br />
                            </div>
                            <Button color="danger" onClick={() => this.nextQuestion("raceOrEthnicity")} >NEXT</Button>
                        </Card>
                    </div>
                </div>
            );
        } else if (this.state.qno === 3) {
            return (
                <div>
                    <Header></Header>
                    <div style={{ marginTop: '100px' }} className="responsive">
                        <Card body>
                            <CardTitle><b>Start your journey in 5 easy steps</b></CardTitle>
                            <CardText>Question 3 of 5</CardText>
                            <div>
                                <h3>Does anyone in your family have type 2 diabetes?</h3>
                                <hr />
                                <FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" value="parents" name="type2Diabetes" onChange={this.onCheckBox} />{' '}
                                            Parents
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" value="siblings" name="type2Diabetes" onChange={this.onCheckBox} />{' '}
                                            Siblings
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <br />
                            </div>
                            <Button color="danger" onClick={() => this.nextQuestion("type2Diabetes")} >NEXT</Button>
                        </Card>
                    </div>
                </div>
            );
        } else if (this.state.qno === 4) {
            return (
                <div>
                    <Header></Header>
                    <div style={{ marginTop: '100px' }} className="responsive">
                        <Card body>
                            <CardTitle><b>Start your journey in 5 easy steps</b></CardTitle>
                            <CardText>Question 4 of 5</CardText>
                            <div>
                                <h3>How much exercise do you usually get?</h3>
                                <hr />
                                <FormGroup tag="filedset1">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" value="little or no excercise" name="exerciseFrequency" onChange={this.onChange} />
                                            Little or no exercise
                            </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" value="medium or high" name="exerciseFrequency" onChange={this.onchange} />
                                            Medium or high
                            </Label>
                                    </FormGroup>
                                </FormGroup>
                                <br />
                            </div>
                            <Button color="danger" onClick={() => this.nextQuestion("exerciseFrequency")} >NEXT</Button>
                        </Card>
                    </div>
                </div>
            );
        } else if (this.state.qno === 5) {
            return (
                <div>
                    <Header></Header>
                    <div style={{ marginTop: '100px' }} className="responsive">
                        <Card body>
                            <CardTitle><b>Start your journey in 5 easy steps</b></CardTitle>
                            <CardText>Question 5 of 5</CardText>
                            <div>
                                <h3>Which of the following health conditions apply to you?</h3>
                                <hr />
                                <h4>Prediabetes</h4>
                                <h6 style={{ color: 'gray' }}>Blood test in the last year has shown you’re at risk for diabetes</h6>
                                <FormGroup tag="fieldset2">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="prediabetes" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="prediabetes" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>Gestational Diabetes</h4>
                                <h6 style={{ color: 'gray' }}>Diagnosed during a previous pregnancy</h6>
                                <FormGroup tag="fieldset5">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="gestationalDiabetes" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="gestationalDiabetes" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>High Birth Weight Baby</h4>
                                <h6 style={{ color: 'gray' }}>Have given birth to a baby weighing 9 pounds or more</h6>
                                <FormGroup tag="fieldset6">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="highBirthWeightBaby" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="highBirthWeightBaby" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>High or Abnormal Cholestral</h4>
                                <h6 style={{ color: 'gray' }}>Have total cholesterol greater than or equal to 200 mg/dL, or non-HDL Cholesterol greater than equal to 160 mg/dL, or take medicine to control your cholesterol.</h6>
                                <FormGroup tag="fieldset7">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="highOrAbnormalCholestral" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="highOrAbnormalCholestral" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>High LDL</h4>
                                <h6 style={{ color: 'gray' }}>Your bad cholesterol is too high: LDL greater than or equal to 130 mg/dL</h6>
                                <FormGroup tag="fieldset8">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="highLDL" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="highLDL" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>High Triglycerides</h4>
                                <h6 style={{ color: 'gray' }}>Blood test has shown triglycerides greater than or equal to 150 mg/dL</h6>
                                <FormGroup tag="fieldset9">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="highTriglycerides" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="highTriglycerides" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>High Blood Pressure or Hypertension</h4>
                                <h6 style={{ color: 'gray' }}>Systolic blood pressure of 130 or greater or diastolic blood pressure of 80 or greater that stays high over time</h6>
                                <FormGroup tag="fieldset10">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="highBloodPressureOrHypertension" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="highBloodPressureOrHypertension" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>Take Medicine to Control Blood Pressure</h4>
                                <h6 style={{ color: 'gray' }}>Prescribed medicine by a doctor to control blood pressure</h6>
                                <FormGroup tag="fieldset11">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="takeMedicineToControlBloodPressure" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="takeMedicineToControlBloodPressure" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <hr />
                                <h4>Tobacco Use</h4>
                                <h6 style={{ color: 'gray' }}>Current or former habitual use of cigarettes, pipes, cigars, or other tobacco products</h6>
                                <FormGroup tag="fieldset12">
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="yes" name="tobaccoUse" onChange={this.onRadioBtn} />
                                            YES
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label >
                                            <Input type="radio" value="no" name="tobaccoUse" onChange={this.onRadioBtn} />
                                            NO
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                                <br />
                            </div>
                            <Button color="danger" onClick={() => this.nextQuestion("healthConditions")} >NEXT</Button>
                        </Card>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <Header></Header>
                    <div style={{ marginTop: '50px' }} className="responsive">
                        <Card>
                            <CardHeader>Preview</CardHeader>
                            <CardBody>
                                <div>
                                    <h3 className="preQtn">How do you identify your gender?</h3>
                                    <h4 className="preAns">{this.state.gender}</h4>
                                    <hr />
                                    <h3 className="preQtn">What is your race/ethnicity?</h3>
                                    <h4 className="preAns">{this.state.raceOrEthnicity}</h4>
                                    <hr />
                                    <h3 className="preQtn">Does anyone in your family have type 2 diabetes?</h3>
                                    {this.state.type2Diabetes.map((diabetes, index) =>
                                        <h4 className="preAns" key={index}>{diabetes}</h4>
                                    )}
                                    <hr />
                                    <h3 className="preQtn">How much exercise do you usually get?</h3>
                                    <h4 className="preAns">{this.state.exerciseFrequency}</h4>
                                    <hr />
                                    <h3 className="preQtn">Which of the following health conditions apply to you?</h3>
                                    {this.state.healthConditions.map((item, index) =>
                                        (<h4 className="preAns" key={index}>{Object.getOwnPropertyNames(item)} : {Object.values(item)}</h4>)
                                    )}
                                    <hr />
                                </div>
                                <Button color="danger" size="sm" block  >Get Result</Button>
                            </CardBody>
                        </Card>





                    </div>
                </div>
            );
        }

    }

}
export default QuestionsWizard;