import React, { Component } from "react";
import Cookies from 'js-cookie';
import axios from '../axios/axios';

import Carousel from "react-bootstrap/Carousel";
class LoginSuccessCarouselCards extends Component {
  constructor(props) {
    super(props);
    this.state={
      lastorderHistory:[],
    }
    this.viewReports = this.viewReports.bind(this)

  }
  componentDidMount(){
    this.orderHistory()   
  }
  orderHistory(){
    axios.get('cartservice/'+Cookies.get("userId")+'/orderHistoryForProduct', 
    { headers: { Authorization: `Bearer ${Cookies.get("jwt-token")}` } }
    ).then((response) => {
       if(response.data){this.setState({lastorderHistory:response.data},()=>{})}
      }).catch(error => {});
  }
  toBookSession() {
    window.location.href = "/book-counselling";
  }
  toProductPage() {
    window.location.href = "/product-page";
  }
  toActiveKit() {
    window.location.href = "/activate-kit";
  }
  viewReports(){
    this.props.history.push('/view-reports');
  }

  render() {
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="false"
      >
        <div className="carousel-inner new">
          <Carousel controls={true} indicators={false} interval={false}>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className="col-sm-12 col-md-12 col-lg-4 mb-md-5 mb-sm-2 py-md-5 py-sm-2">
                  <div className="card  rounded shadow-lg  mb-5  r4 newcard ">
                    <div className="card-body text-center pb-5">
                      <div className="card-block mt-2 p-2 circle ">
                        <img
                          className="card-img-top text-center  p-2"
                          src="./img/login/1.png"
                          style={{ height: "160px", width: "160px" }}
                          alt="Card image cap"
                        />
                      </div>
                      <h3 className="font-card roboto-reg-18px py-4">
                        Schedule your appointment with our genetic experts to
                        start your genetic journey.
                      </h3>
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
                      <button
                        style={{
                          height: "40px",
                          color: "white",
                          background: "#4f5be7"
                        }}
                        className="mx-auto px-5 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                        onClick={this.toBookSession}
                        type="submit"
                      >
                        <b>COUNSELLING</b>
                      </button>
                      {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
                    </div>
                  </div>
                  {/* eo-card */}
                </div>
                {/* eo-card-deck-wrap */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
                  <div className="card  rounded shadow-lg  mb-5  r4 newcard">
                    <div className="card-body text-center pb-5">
                      <div className="card-block mt-2 p-2 circle ">
                        <img
                          className="card-img-top text-center  p-2"
                          src="./img/login/2.png"
                          style={{ height: "160px", width: "160px" }}
                          alt="Card image cap"
                        />
                      </div>
                      <h3 className="font-card roboto-reg-18px py-4">
                        Understand your genes and evaluate your risks for cancer
                        and cardiac conditions.
                      </h3>
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
                      <button
                        style={{
                          height: "40px",
                          color: "white",
                          background: "#4f5be7"
                        }}
                        className="mx-auto px-5 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                        onClick={this.toProductPage}
                      >
                        <b>BUY truGeny</b>
                      </button>
                      {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
       {this.state.lastorderHistory.orderId?      <Carousel.Item>
              <div className="carousel-item active">
                <div className=" col-sm-12 col-md-12 col-lg-4 mb-5">
                  <div className="card  rounded shadow-lg  mb-5  r4 newcard">
                    <div className="card-body text-center pb-5">
                      <div className="card-block mt-2 p-2 circle ">
                        <img
                          className="card-img-top text-center  p-2"
                          src="./img/login/3.png"
                          style={{ height: "160px", width: "160px" }}
                          alt="Card image cap"
                        />
                      </div>
                      <h3 className="font-card roboto-reg-18px pt-4 pb-1">
                        Activating your kit will only take a few minutes. You
                        will need the barcode found on your saliva tube.
                      </h3>
                      {/* <h3 class="font-card">Activating your kit will only take a few minutes. You will need the barcode found on your saliva tube.</h3> */}
                      {/* <button  style="height:40px;" class="mt-3 mb-2 rounded-pill  btn btn-outline-primary btn-hover-white roboto-bold-14px" onclick="window.location.href = 'view-reports.html';" type="submit" >ACTIVE KIT</button> */}
                      <button
                        style={{
                          height: "40px",
                          color: "white",
                          background: "#4f5be7"
                        }}
                        className="mx-auto px-5 rounded-pill btn btn-outline-primary btn-hover-white my-2 my-sm-0 mb-1 p-2"
                        onClick={this.toActiveKit}
                        type="submit"
                      >
                        <b>ACTIVATE KIT</b>
                      </button>
                      {/* <a href="#"><img class="card-img-top text-center  p-2" src="./img/login/card-b3.png"/></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          :""

       }</Carousel>
        </div>
      </div>
    );
  }
}
export default LoginSuccessCarouselCards;
